import React, { FC, useRef, useState } from "react";
import { Tab, Tabs, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { SpPanel, TabPanel } from "../../../common";
import { useDispatch } from "react-redux";
import { SiteInfoTab } from "./index";
import { useComputeSize } from "../../../services/useComputeSize";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SiteInfoInputs, SiteInfoSchema } from "./SiteInfoTab";
import { postNewSiteInfo } from "../redux/apiCalls";
import { AppDispatch } from "../../../appStore";
import { MAIN_PAGES, MAIN_PATH } from "../../../router/pathConstants";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles<Theme, { tabsHeaderHeight: number }>((theme) => ({
  title: {
    paddingBottom: theme.spacing(2),
  },
  mainPanel: {
    padding: theme.spacing(2),
    height: "calc(100vh - 163px)",
    overflow: "auto",
  },
  tabPanel: ({ tabsHeaderHeight }) => ({
    height: `calc(100% - ${tabsHeaderHeight}px)`,
  }),
}));

type NewSiteDetailProps = {};

const NewSiteDetail: FC<NewSiteDetailProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const tabsRef = useRef<HTMLButtonElement | null>(null);
  const { height: tabsHeight } = useComputeSize(tabsRef, []);

  const classes = useStyles({ tabsHeaderHeight: tabsHeight });

  const [tabValue, setTabValue] = React.useState<number>(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const [alertMessage, setAlertMessage] = useState<string>();

  const { register, handleSubmit, control, errors } = useForm<SiteInfoInputs>({
    resolver: yupResolver(SiteInfoSchema),
  });

  const onSubmitSite = (formInputData: SiteInfoInputs) => {
    setAlertMessage(undefined);

    dispatch(
      postNewSiteInfo({
        siteName: formInputData?.siteName,
        company: formInputData?.company,
        siteOwnerName: formInputData?.siteOwnerName,
        siteOwnerContactNumber: formInputData?.siteOwnerContactNumber,
        siteOwnerEmail: formInputData?.siteOwnerContactEmail,
        protectionAdvisoryManagerName:
          formInputData?.protectionAdvisoryManagerName,
        protectionAdvisoryContactNumber:
          formInputData?.protectionAdvisoryManagerContactNumber,
        protectionAdvisoryEmail: formInputData?.protectionAdvisoryManagerEmail,
        certificationExpirationThreshold:
          formInputData.certificationExpirationThreshold,
        clubId: formInputData?.clubId,
        apiKey: formInputData?.abcFinancialApiKey,
        hardwareApiKey: formInputData?.hardwareApiKey,
      })
    )
      .then(() => history.replace(`/${MAIN_PATH.ADMIN}/${MAIN_PAGES.SITES}`))
      .catch((error) => {
        if (
          typeof error.response.data === "string" ||
          error.response.data instanceof String
        ) {
          setAlertMessage(error.response.data);
        }
      });
  };

  return (
    <>
      <Typography variant={"h1"} className={classes.title}>
        NEW SITE
      </Typography>
      <SpPanel withoutFooter>
        <div className={classes.mainPanel}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            ref={tabsRef}
          >
            <Tab label="Site info" />
          </Tabs>
          <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
            <form onSubmit={handleSubmit(onSubmitSite)}>
              <SiteInfoTab
                errors={errors}
                register={register}
                control={control}
                alertMessage={alertMessage}
                newSiteMode
              />
            </form>
          </TabPanel>
        </div>
      </SpPanel>
    </>
  );
};

export default NewSiteDetail;
