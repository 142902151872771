import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Badge, Grid, Typography } from "@material-ui/core";
import { COLORS } from "../../appTheme";
import { isNil } from "ramda";
import { SpIcon } from "../../common";

const activeStyle = {
  backgroundColor: `${COLORS.BLUE}4D`, // plus alpha 30%
  cursor: "pointer",
};

let mainEnhancement = (isActive?: boolean) => {
  if (isActive) {
    return activeStyle;
  }
  return {};
};

const useStyles = makeStyles<Theme>((theme) => ({
  main: ({ isActive }: { isActive?: boolean }) => ({
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(1) / 2,
    paddingTop: theme.spacing(1) / 2,
    borderTop: "1px solid #E2E3E5",
    "&:hover": activeStyle,
    ...mainEnhancement(isActive),
  }),
  icon: {
    height: 20,
    width: 20,
    paddingRight: theme.spacing(2),
  },
  badge: {
    paddingBottom: 12,
    paddingLeft: 2,
  },
  textWrap: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const MessageListItem: React.FC<MessageListItemProps> = ({
  title,
  description,
  numberOfFiles,
  onClick,
  isActive,
}) => {
  const classes = useStyles({ isActive });

  return (
    <div className={classes.main} onClick={onClick}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid item xs={10}>
          <Typography variant={"h3"} className={classes.textWrap}>
            {title}
          </Typography>
          <Typography variant={"subtitle1"} className={classes.textWrap}>
            {description}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {!isNil(numberOfFiles) && numberOfFiles > 0 && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Grid item xs={8}>
                <SpIcon iconName="attachment" className={classes.icon} />
              </Grid>
              <Grid item xs={4}>
                <Badge
                  badgeContent={numberOfFiles}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  className={classes.badge}
                >
                  <div />
                </Badge>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default MessageListItem;

export interface MessageListItemProps {
  title: string | null;
  description: string | null;
  numberOfFiles: number;
  onClick: () => void;
  isActive?: boolean;
}
