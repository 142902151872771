import { makeStyles } from "@material-ui/core/styles";
import React, { ReactNode, useRef } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  CameraReportItemViewModel,
  ContractorsReportItemViewModel,
  ContractorsStatusReportItemViewModel,
  FormSubmissionViewModel,
  ReportResponse,
  AllContractorsReportItemViewModel,
  SiteHelpRequestReportItemViewModel,
  SiteQualificationReportItemViewModel,
  SiteStatisticsReportItemViewModel,
} from "./helpers/apiCalls";
import { REPORT_TYPES } from "./helpers/constants";
import moment from "moment";
import { FRONTEND_DATE_FORMAT_REPORTS } from "../../services/helpers";
import { isNil } from "ramda";
import BaseTable, {
  AutoResizer,
  Column,
  ColumnShape,
  unflatten,
} from "react-base-table";
import "react-base-table/styles.css";
import { FONT_FAMILY, FONT_WEIGHT } from "../../appTheme";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { TablePhoto } from "../../common";
import { useComputeSize } from "../../services/useComputeSize";

interface ContractorsReport {
  date?: string;
  firstName?: string;
  lastName?: string;
  employeeId?: string;
  companyName?: string;
  validFrom?: string;
  validTo?: string;
  timeIn?: ReactNode;
  timeOut?: ReactNode;
  totalTime?: string;
}

interface ContractorsStatusReport {
  access?: string;
  companyName?: string;
  employeeId?: string;
  firstName?: string;
  lastName?: string;
  timestamp?: string;
}

interface AllContractorsReport {
  employeeId?: string;
  companyName?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  validFrom?: string;
  validTo?: string;
  status?: string;
}

interface SiteStatisticsReport {
  companyName?: string;
  contractors?: number;
  time?: string;
}

interface CameraReport {
  photoId?: string;
  time?: string;
  date?: string;
  allowed?: boolean;
  contractor?: string;
  companyName?: string;
}

interface SiteQualificationReport {
  contractorName?: string;
  certificationName?: string;
  certificationValidTo?: string;
}

interface SiteHelpRequestsReport {
  date?: string;
  time?: string;
  ID?: number;
  company?: string;
  lastName?: string;
  firstName?: string;
  helpRequest?: string;
}

interface FormReport {
  entryId?: number;
  date?: string;
  time?: string;
  parentId?: string;
}

export interface ReportDataRows
  extends ContractorsReport,
    ContractorsStatusReport,
    AllContractorsReport,
    SiteStatisticsReport,
    CameraReport,
    SiteQualificationReport,
    SiteHelpRequestsReport,
    FormReport {
  id: string;
  rowDetail?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
  },
  tableContainer: {
    width: "100%",
  },
  reportSearchBar: {
    width: "100%",
  },
  header: {
    padding: theme.spacing(2),
  },
  dataGrid: {
    marginLeft: 1,
    marginRight: 4,
    height: `calc(100vh - 385px - ${theme.spacing(1)}px)`,
  },
  dataGridHeader: {
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
    fontFamily: FONT_FAMILY.MANROPE,
  },
  dataGridRow: {
    padding: theme.spacing(1),
  },
  rowDetail: {
    padding: theme.spacing(1),
  },
}));

interface ReportTableProps {
  dataReport?: ReportResponse;
  selectedReportType?: string;
  reportHeaderHeight: number;
  isLoading?: boolean;
}

const ReportTable: React.FC<ReportTableProps> = ({
  dataReport,
  selectedReportType,
  reportHeaderHeight,
  isLoading,
}) => {
  const classes = useStyles();
  let rows: ReportDataRows[] = [];
  const expandColumnKey = "entryId";
  const reportSearchBarRef = useRef<HTMLDivElement | null>(null);
  const { height: reportSearchBarHeight } = useComputeSize(reportSearchBarRef, [
    dataReport,
    selectedReportType,
  ]);

  if (dataReport?.items) {
    if (
      selectedReportType === REPORT_TYPES.CONTRACTORS ||
      selectedReportType === REPORT_TYPES.INDIVIDUAL_CONTRACTOR
    ) {
      rows =
        (dataReport?.items as ContractorsReportItemViewModel[]).map(
          (item, id) => ({
            id: `${REPORT_TYPES.CONTRACTORS}_${id}`,
            firstName: item.firstName,
            lastName: item.lastName,
            date:
              item.date &&
              moment(item.date).format(FRONTEND_DATE_FORMAT_REPORTS),
            employeeId: item.employeeId,
            companyName: item.companyName,
            validFrom:
              item.validFrom &&
              moment(item.validFrom).format(FRONTEND_DATE_FORMAT_REPORTS),
            validTo:
              item.validTo &&
              moment(item.validTo).format(FRONTEND_DATE_FORMAT_REPORTS),
            timeIn: item.inOutTimes?.filter((times) => !isNil(times.timeIn)),
            timeOut: item.inOutTimes?.filter((times) => !isNil(times.timeOut)),
            totalTime:
              item.totalTime &&
              `${parseInt(
                moment(item.totalTime, "HH:mm:ss").format("HH")
              )}h ${parseInt(
                moment(item.totalTime, "HH:mm:ss").format("mm")
              )}m`,
          })
        ) || [];
    } else if (
      selectedReportType === REPORT_TYPES.CONTRACTORS_STATUS ||
      selectedReportType === REPORT_TYPES.SECONDARY_COMPANY ||
      selectedReportType === REPORT_TYPES.COMPANY
    ) {
      rows =
        (dataReport?.items as ContractorsStatusReportItemViewModel[]).map(
          (item, id) => ({
            id: `${REPORT_TYPES.CONTRACTORS_STATUS}_${id}`,
            date:
              item.timestamp &&
              moment(item.timestamp).format(FRONTEND_DATE_FORMAT_REPORTS),
            access: item.access,
            companyName: item.companyName,
            employeeId: item.employeeId,
            firstName: item.firstName,
            lastName: item.lastName,
            timestamp: item.timestamp && moment(item.timestamp).format("LT"),
          })
        ) || [];
    } else if (selectedReportType === REPORT_TYPES.ALL_CONTRACTORS) {
      rows =
        (dataReport?.items as AllContractorsReportItemViewModel[]).map(
          (item, id) => ({
            id: `${REPORT_TYPES.ALL_CONTRACTORS}_${id}`,
            employeeId: item.employeeId,
            companyName: item.companyName,
            firstName: item.firstName,
            lastName: item.lastName,
            phoneNumber: item.phoneNumber,
            validFrom:
              item.validFrom &&
              moment(item.validFrom).format(FRONTEND_DATE_FORMAT_REPORTS),
            validTo:
              item.validTo &&
              moment(item.validTo).format(FRONTEND_DATE_FORMAT_REPORTS),
            status: item.status,
          })
        ) || [];
    } else if (selectedReportType === REPORT_TYPES.SITE_STATISTICS) {
      rows =
        (dataReport?.items as SiteStatisticsReportItemViewModel[]).map(
          (item, id) => ({
            id: `${REPORT_TYPES.SITE_STATISTICS}_${id}`,
            companyName: item.companyName,
            contractors: item.contractors,
            time:
              item.time &&
              `${parseInt(
                moment(item.time, "HH:mm:ss").format("HH")
              )}h ${parseInt(moment(item.time, "HH:mm:ss").format("mm"))}m`,
          })
        ) || [];
    } else if (selectedReportType === REPORT_TYPES.CAMERA) {
      rows =
        (dataReport?.items as CameraReportItemViewModel[]).map((item, id) => ({
          id: `${REPORT_TYPES.CAMERA}_${id}`,
          photoId: item.photoId,
          time: item.dateTime && moment(item.dateTime).format("LTS"),
          date:
            item.dateTime &&
            moment(item.dateTime).format(FRONTEND_DATE_FORMAT_REPORTS),
          allowed: item.allowed,
          contractor:
            `${item.firstName || ""} ${item.lastName || ""}`.trim() || "N/A",
          companyName: item.companyName || "N/A",
        })) || [];
    } else if (
      selectedReportType === REPORT_TYPES.SITE_QUALIFICATIONS ||
      selectedReportType === REPORT_TYPES.SITE_QUALIFICATIONS_EXPIRING
    ) {
      rows = [];
      (dataReport?.items as SiteQualificationReportItemViewModel[]).forEach(
        (item, id) => {
          if (
            item.certifications &&
            Array.isArray(item.certifications) &&
            item.certifications.length > 0
          ) {
            rows.push(
              ...item.certifications.map((a, index) => ({
                id: `${REPORT_TYPES.SITE_QUALIFICATIONS}_${id}_${index}`,
                contractorName: item.contractorName,
                certificationName: a.name,
                certificationValidTo:
                  a.validTo &&
                  moment(a.validTo).format(FRONTEND_DATE_FORMAT_REPORTS),
              }))
            );
          } else {
            rows.push({
              id: `${REPORT_TYPES.SITE_QUALIFICATIONS}_${id}`,
              contractorName: item.contractorName,
            });
          }
        }
      );
    } else if (selectedReportType === REPORT_TYPES.SITE_HELP_REQUESTS) {
      rows =
        (dataReport?.items as SiteHelpRequestReportItemViewModel[]).map(
          (item, id) => ({
            id: `${REPORT_TYPES.SITE_HELP_REQUESTS}_${id}`,
            date:
              item.dateTime &&
              moment(item.dateTime).format(FRONTEND_DATE_FORMAT_REPORTS),
            time: item.dateTime && moment(item.dateTime).format("LT"),
            ID: item.employeeId,
            company: item.companyName,
            lastName: item.lastName,
            firstName: item.firstName,
            helpRequest: item.eventType,
          })
        ) || [];
    }
  } else if (dataReport && selectedReportType === REPORT_TYPES.FORMS) {
    let subRows: any[] = [];
    rows =
      (dataReport as FormSubmissionViewModel[]).map((item, id) => {
        const parentId = `${REPORT_TYPES.FORMS}_${id}`;

        if (
          item.values &&
          Array.isArray(item.values) &&
          item.values.length > 0
        ) {
          subRows.push({
            id: `${REPORT_TYPES.FORMS}_${id}_${0}`,
            parentId,
            rowDetail: (
              <Grid container direction="column" spacing={1}>
                {item.values.map((a, i) => (
                  <Grid item key={i}>
                    <Typography variant="h3">{a.item1}</Typography>
                    <Typography variant="body2">{a.item2}</Typography>
                  </Grid>
                ))}
              </Grid>
            ),
          });
        }

        return {
          id: parentId,
          entryId: item.metadata.entryId,
          date:
            item.metadata.localDateTime &&
            moment(item.metadata.localDateTime).format(
              FRONTEND_DATE_FORMAT_REPORTS
            ),
          time:
            item.metadata.localDateTime &&
            moment(item.metadata.localDateTime).format("LT"),
        };
      }) || [];
    rows.push(...subRows);
  }

  const treeData = unflatten(rows);

  const rowRenderer = ({
    rowData,
    cells,
  }: {
    isScrolling?: boolean;
    cells: React.ReactNode[];
    columns: ColumnShape<ReportDataRows>;
    rowData: ReportDataRows;
    rowIndex: number;
    depth: number;
  }) => {
    if (rowData.rowDetail) {
      return <div className={classes.rowDetail}>{rowData.rowDetail}</div>;
    }
    return cells;
  };

  const printCellSimpleText = (cellData: any) => (
    <Box height={"100%"}>
      <Typography variant="body2">{cellData}</Typography>
    </Box>
  );

  const loadPhoto = (photoId?: string, rowData?: ReportDataRows) => {
    if (!photoId) {
      return "";
    }
    return <TablePhoto photoId={photoId} rowData={rowData} />;
  };

  let columns: ColumnShape<ReportDataRows>[] = [];
  if (
    selectedReportType === REPORT_TYPES.CONTRACTORS ||
    selectedReportType === REPORT_TYPES.INDIVIDUAL_CONTRACTOR
  ) {
    columns = [
      {
        key: "date",
        title: "Date",
        dataKey: "date",
        width: 120,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
      },
      {
        key: "employeeId",
        title: "ID",
        dataKey: "employeeId",
        width: 60,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
      },
      {
        key: "companyName",
        title: "Client",
        dataKey: "companyName",
        width: 160,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "lastName",
        title: "Last Name",
        dataKey: "lastName",
        width: 160,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "firstName",
        title: "First Name",
        dataKey: "firstName",
        width: 160,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "validFrom",
        title: "Valid From",
        dataKey: "validFrom",
        width: 120,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "validTo",
        title: "Valid To",
        dataKey: "validTo",
        width: 120,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "timeIn",
        title: "Time In",
        dataKey: "timeIn",
        width: 90,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => (
          <Grid
            container
            direction="column"
            style={{ height: "100%" }}
            justifyContent="flex-start"
          >
            {cellData &&
              Array.isArray(cellData) &&
              cellData?.map(
                (times, index) =>
                  times.timeIn && (
                    <Grid item key={index}>
                      <Typography variant="body2">
                        {moment(times.timeIn).format("LT")}
                      </Typography>
                    </Grid>
                  )
              )}
          </Grid>
        ),
      },
      {
        key: "timeOut",
        title: "Time Out",
        dataKey: "timeOut",
        width: 90,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => (
          <Grid
            container
            direction="column"
            style={{ height: "100%" }}
            justifyContent="flex-start"
          >
            {cellData &&
              Array.isArray(cellData) &&
              cellData?.map(
                (times, index) =>
                  times.timeOut && (
                    <Grid item key={index}>
                      <Typography variant="body2">
                        {moment(times.timeOut).format("LT")}
                      </Typography>
                    </Grid>
                  )
              )}
          </Grid>
        ),
      },
      {
        key: "totalTime",
        title: "Total Time On Site",
        dataKey: "totalTime",
        width: 140,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
      },
    ];
  } else if (
    selectedReportType === REPORT_TYPES.CONTRACTORS_STATUS ||
    selectedReportType === REPORT_TYPES.SECONDARY_COMPANY ||
    selectedReportType === REPORT_TYPES.COMPANY
  ) {
    columns = [
      {
        key: "date",
        title: "Date",
        dataKey: "date",
        width: 120,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
      },
      {
        key: "employeeId",
        title: "ID",
        dataKey: "employeeId",
        width: 60,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
      },
      {
        key: "companyName",
        title: "Client",
        dataKey: "companyName",
        width: 160,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "lastName",
        title: "Last Name",
        dataKey: "lastName",
        width: 160,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "firstName",
        title: "First Name",
        dataKey: "firstName",
        width: 160,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "timestamp",
        title: "Timestamp",
        dataKey: "timestamp",
        width: 120,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "access",
        title: "Access",
        dataKey: "access",
        width: 250,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
    ];
  } else if (selectedReportType === REPORT_TYPES.ALL_CONTRACTORS) {
    columns = [
      {
        key: "employeeId",
        title: "ID",
        dataKey: "employeeId",
        width: 60,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
      },
      // {
      //   key: "companyName",
      //   title: "Company",
      //   dataKey: "companyName",
      //   width: 160,
      //   align: Column.Alignment.CENTER,
      //   cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
      //   resizable: true,
      // },
      {
        key: "lastName",
        title: "Last Name",
        dataKey: "lastName",
        width: 160,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "firstName",
        title: "First Name",
        dataKey: "firstName",
        width: 160,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "phoneNumber",
        title: "Phone Number",
        dataKey: "phoneNumber",
        width: 160,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "validFrom",
        title: "Valid From",
        dataKey: "validFrom",
        width: 120,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "validTo",
        title: "Valid To",
        dataKey: "validTo",
        width: 120,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "status",
        title: "Status",
        dataKey: "status",
        width: 120,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
    ];
  } else if (selectedReportType === REPORT_TYPES.SITE_STATISTICS) {
    columns = [
      {
        key: "companyName",
        title: "Client",
        dataKey: "companyName",
        width: 300,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "contractors",
        title: "Users",
        dataKey: "contractors",
        width: 300,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "time",
        title: "Company Onsite Hours",
        dataKey: "time",
        width: 300,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
    ];
  } else if (selectedReportType === REPORT_TYPES.CAMERA) {
    columns = [
      {
        key: "photoId",
        title: "Photo",
        dataKey: "photoId",
        width: 300,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData, rowData }) => (
          <Grid
            container
            direction="column"
            style={{ height: "fit-content" }}
            justifyContent="flex-start"
          >
            <Grid item>{loadPhoto(cellData, rowData)}</Grid>
          </Grid>
        ),
        resizable: true,
      },
      {
        key: "time",
        title: "Time",
        dataKey: "time",
        width: 150,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "date",
        title: "Date",
        dataKey: "date",
        width: 150,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "allowed",
        title: "Allowed",
        dataKey: "allowed",
        width: 100,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) =>
          cellData ? (
            <CheckCircleIcon color="primary" />
          ) : (
            <CancelIcon color="error" />
          ),
        resizable: true,
      },
      {
        key: "contractor",
        title: "Contractor",
        dataKey: "contractor",
        width: 200,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "companyName",
        title: "Company",
        dataKey: "companyName",
        width: 200,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
    ];
  } else if (
    selectedReportType === REPORT_TYPES.SITE_QUALIFICATIONS ||
    selectedReportType === REPORT_TYPES.SITE_QUALIFICATIONS_EXPIRING
  ) {
    columns = [
      {
        key: "contractorName",
        title: "Contractor Name",
        dataKey: "contractorName",
        width: 500,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "certificationName",
        title: "Qualification",
        dataKey: "certificationName",
        width: 200,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "certificationValidTo",
        title: "Date",
        dataKey: "certificationValidTo",
        width: 200,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
    ];
  } else if (selectedReportType === REPORT_TYPES.SITE_HELP_REQUESTS) {
    columns = [
      {
        key: "date",
        title: "Date",
        dataKey: "date",
        width: 100,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "time",
        title: "Time",
        dataKey: "time",
        width: 80,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "ID",
        title: "ID",
        dataKey: "ID",
        width: 120,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "company",
        title: "Company",
        dataKey: "company",
        width: 200,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "lastName",
        title: "Last Name",
        dataKey: "lastName",
        width: 200,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "firstName",
        title: "First Name",
        dataKey: "firstName",
        width: 200,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "helpRequest",
        title: "Help Request",
        dataKey: "helpRequest",
        width: 200,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
    ];
  } else if (selectedReportType === REPORT_TYPES.FORMS) {
    columns = [
      {
        key: "entryId",
        title: "ID",
        dataKey: "entryId",
        width: 300,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "date",
        title: "Date",
        dataKey: "date",
        width: 300,
        align: Column.Alignment.LEFT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
      {
        key: "time",
        title: "Time",
        dataKey: "time",
        width: 300,
        align: Column.Alignment.RIGHT,
        cellRenderer: ({ cellData }) => printCellSimpleText(cellData),
        resizable: true,
      },
    ];
  }

  const renderReportSearchBar = (
    data: ReportResponse,
    withEntrance: boolean = false
  ) => (
    <>
      <Grid item>
        <Typography variant="body2">Location:</Typography>
        <Typography variant="h3">{data.siteName}</Typography>
      </Grid>
      {withEntrance && (
        <Grid item>
          <Typography variant="body2">Entrance Name:</Typography>
          <Typography variant="h3">{data.entranceName}</Typography>
        </Grid>
      )}
      <Grid item xs={2}>
        <Typography variant="body2">Managers:</Typography>
        <Typography variant="h3">
          {data.siteSupervisors && data.siteSupervisors.join(", ")}
        </Typography>
      </Grid>
      <Grid item>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item>
        <Typography variant="body2">Report Period:</Typography>
        <Typography variant="h3">{`${
          (data.startDate &&
            moment(data.startDate).format(FRONTEND_DATE_FORMAT_REPORTS)) ||
          ""
        } - ${
          (data.endDate &&
            moment(data.endDate).format(FRONTEND_DATE_FORMAT_REPORTS)) ||
          ""
        }`}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">Generated:</Typography>
        <Typography variant="h3">
          {(data.generatedDate &&
            moment(data.generatedDate).format(FRONTEND_DATE_FORMAT_REPORTS)) ||
            ""}
        </Typography>
      </Grid>
    </>
  );

  return (
    <>
      <div className={classes.reportSearchBar} ref={reportSearchBarRef}>
        <Divider className={classes.divider} />
        {dataReport && (
          <div>
            {selectedReportType === REPORT_TYPES.CONTRACTORS && (
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.header}
              >
                <Grid item>
                  <Typography variant="h2">
                    Contractors on Site - Report
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    {renderReportSearchBar(dataReport, true)}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {selectedReportType === REPORT_TYPES.INDIVIDUAL_CONTRACTOR && (
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.header}
              >
                <Grid item>
                  <Typography variant="h2">
                    Contractor on Site - Report
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    {renderReportSearchBar(dataReport, true)}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {(selectedReportType === REPORT_TYPES.CONTRACTORS_STATUS ||
              selectedReportType === REPORT_TYPES.SECONDARY_COMPANY ||
              selectedReportType === REPORT_TYPES.COMPANY) && (
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.header}
              >
                <Grid item>
                  <Typography variant="h2">
                    Users on Site - Status Report
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    {renderReportSearchBar(dataReport, true)}
                    <Grid item>
                      <Divider orientation="vertical" />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body2">
                        Access Permitted Users:
                      </Typography>
                      <Typography variant="h3">
                        {dataReport.accessPermittedCount}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body2">
                        Access Denied Users:
                      </Typography>
                      <Typography variant="h3">
                        {dataReport.accessDeniedCount}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body2">Manual Check-ins:</Typography>
                      <Typography variant="h3">
                        {dataReport.manualCheckInCount}
                      </Typography>
                    </Grid>
                    {dataReport.totalContractorsOnSite !== null && (
                      <Grid item xs={1}>
                        <Typography variant="body2">
                          Total contractors on site:
                        </Typography>

                        <Typography variant="h3">
                          {dataReport.totalContractorsOnSite}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {selectedReportType === REPORT_TYPES.ALL_CONTRACTORS && (
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.header}
              >
                <Grid item>
                  <Typography variant="h2">All Contractors Report</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <Typography variant="body2">Generated:</Typography>
                      <Typography variant="h3">
                        {(dataReport.generatedDate &&
                          moment(dataReport.generatedDate).format(
                            FRONTEND_DATE_FORMAT_REPORTS
                          )) ||
                          ""}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Divider orientation="vertical" />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">Company Name:</Typography>
                      <Typography variant="h3">
                        {dataReport.companyName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Divider orientation="vertical" />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body2">All Users:</Typography>
                      <Typography variant="h3">
                        {dataReport.allContractors}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body2">Active Users:</Typography>
                      <Typography variant="h3">
                        {dataReport.activeContractors}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body2">Inactive Users:</Typography>
                      <Typography variant="h3">
                        {dataReport.inactiveContractors}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {selectedReportType === REPORT_TYPES.SITE_STATISTICS && (
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.header}
              >
                <Grid item>
                  <Typography variant="h2">Site Statistics - Report</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    {renderReportSearchBar(dataReport)}
                    <Grid item>
                      <Divider orientation="vertical" />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body2">
                        All on Site Users:
                      </Typography>
                      <Typography variant="h3">
                        {dataReport.allContractorCount}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body2">
                        Total Combined Hours:
                      </Typography>
                      <Typography variant="h3">
                        {dataReport.totalTime &&
                          `${parseInt(
                            moment(dataReport.totalTime, "HH:mm:ss").format(
                              "HH"
                            )
                          )}h ${parseInt(
                            moment(dataReport.totalTime, "HH:mm:ss").format(
                              "mm"
                            )
                          )}m`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {selectedReportType === REPORT_TYPES.CAMERA && (
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.header}
              >
                <Grid item>
                  <Typography variant="h2">Camera - Report</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    {renderReportSearchBar(dataReport, true)}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {(selectedReportType === REPORT_TYPES.SITE_QUALIFICATIONS ||
              selectedReportType ===
                REPORT_TYPES.SITE_QUALIFICATIONS_EXPIRING) && (
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.header}
              >
                <Grid item>
                  <Typography variant="h2">
                    {selectedReportType ===
                      REPORT_TYPES.SITE_QUALIFICATIONS && (
                      <span>Site Qualifications - Report</span>
                    )}
                    {selectedReportType ===
                      REPORT_TYPES.SITE_QUALIFICATIONS_EXPIRING && (
                      <span>Site Qualifications Expiring - Report</span>
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    {renderReportSearchBar(dataReport)}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {selectedReportType === REPORT_TYPES.SITE_HELP_REQUESTS && (
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.header}
              >
                <Grid item>
                  <Typography variant="h2">
                    {selectedReportType ===
                      REPORT_TYPES.SITE_QUALIFICATIONS && (
                      <span>Site Qualifications - Report</span>
                    )}
                    {selectedReportType ===
                      REPORT_TYPES.SITE_QUALIFICATIONS_EXPIRING && (
                      <span>Site Qualifications Expiring - Report</span>
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    {renderReportSearchBar(dataReport)}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        )}
      </div>
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            key={selectedReportType}
            fixed
            width={width - 2}
            height={height - reportSearchBarHeight - reportHeaderHeight - 32}
            columns={columns}
            data={
              (selectedReportType === REPORT_TYPES.FORMS && treeData) || rows
            }
            estimatedRowHeight={
              selectedReportType !== REPORT_TYPES.CAMERA &&
              selectedReportType !== REPORT_TYPES.FORMS
                ? 48
                : 0
            }
            rowHeight={
              (selectedReportType === REPORT_TYPES.CAMERA && 89.95) || undefined
            }
            className={classes.dataGrid}
            headerClassName={classes.dataGridHeader}
            rowClassName={classes.dataGridRow}
            rowRenderer={rowRenderer}
            expandColumnKey={expandColumnKey}
            emptyRenderer={
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Box p={2}>
                    {!isLoading ? (
                      <Typography variant="h3">No data</Typography>
                    ) : (
                      <CircularProgress />
                    )}
                  </Box>
                </Grid>
              </Grid>
            }
          />
        )}
      </AutoResizer>
    </>
  );
};

export default ReportTable;
