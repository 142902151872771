export interface State {
  isLoading: boolean;
  userAccessToken: string | null;
  userRefreshToken: string | null;

  skipAuthentication: boolean;
}

export const initialState: State = {
  isLoading: false,
  userAccessToken: null,
  userRefreshToken: null,

  skipAuthentication: true,
};
