export const LOCAL_STORAGE_TOKENS = {
  ACCESS_TOKEN: "ACCESS_TOKEN",
  REFRESH_TOKEN: "REFRESH_TOKEN",
};

export type AccessTokenType = {
  Email: string;
  Role: string;
  PermissionClaims: string | Array<string>;
  Username: string;
  aud: string;
  exp: number;
  iss: string;
};

export const accessTokenFromLocalStorage = localStorage.getItem(
  LOCAL_STORAGE_TOKENS.ACCESS_TOKEN
);

export const refreshTokenFromLocalStorage = localStorage.getItem(
  LOCAL_STORAGE_TOKENS.REFRESH_TOKEN
);
