import { CounterResponseItem } from "../../../services/apiServiceTypes";

export interface State {
  siteList: Array<CounterResponseItem>;
  siteListHasNextPage: boolean;
  isFetchingSiteList: boolean;
  selectedSiteId?: string;

  entranceList: Array<CounterResponseItem>;
  entranceListHasNextPage: boolean;
  isFetchingEntranceList: boolean;
  selectedEntranceId?: string;
  isNewEntranceMode: boolean;

  isNewSiteSupervisorMode: boolean;
  siteSupervisorList: Array<CounterResponseItem>;
  siteSupervisorListHasNextPage: boolean;
  isFetchingSiteSupervisorList: boolean;
  selectedSiteSupervisorId?: string;
  isEditingCredentials: boolean;
  unassignedSiteSupervisorList: Array<CounterResponseItem>;
  unassignedSiteSupervisorListHasNextPage: boolean;
  isFetchingUnassignedSiteSupervisorList: boolean;
}

export const initialState: State = {
  siteList: [],
  siteListHasNextPage: true,
  isFetchingSiteList: false,
  selectedSiteId: undefined,

  entranceList: [],
  entranceListHasNextPage: true,
  isFetchingEntranceList: false,
  selectedEntranceId: undefined,
  isNewEntranceMode: false,

  isNewSiteSupervisorMode: false,
  siteSupervisorList: [],
  siteSupervisorListHasNextPage: true,
  isFetchingSiteSupervisorList: false,
  selectedSiteSupervisorId: undefined,
  isEditingCredentials: false,
  unassignedSiteSupervisorList: [],
  unassignedSiteSupervisorListHasNextPage: true,
  isFetchingUnassignedSiteSupervisorList: false,
};
