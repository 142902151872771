import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import withAuthorization from "../../auth/hoc/withAutorization";
import { makeStyles } from "@material-ui/core/styles";
import { SCROLL_PANEL_WIDTH } from "../../appTheme";
import { AppDispatch } from "../../appStore";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { ListDetailSplitView, PageContent, SpListItem } from "../../common";
import { SpSearchTextField } from "../../common/components/inputs/SpSearchTextField";
import companyRedux from "./redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { customDebounce } from "../../services/helpers";
import { useComputeSize } from "../../services/useComputeSize";
import CompanyDetailSection from "./companyDetail/CompanyDetailSection";
import { ROLES, USER_PERMISSIONS } from "../../auth/constants";
import { useAuthorization } from "../../auth";
import axios, { CancelToken, CancelTokenSource } from "axios";

const useStyles = makeStyles((theme) => ({
  scrollPanel: {
    width: SCROLL_PANEL_WIDTH,
  },
  mobileScrollPanel: {
    width: "100%"
  },
  main: {
    marginTop: theme.spacing(4),
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  footerContainer: {
    height: "inherit",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  detailPanel: {
    width: `calc(100% - ${SCROLL_PANEL_WIDTH}px -  ${theme.spacing(2)}px)`,
  },
  mobileDetailPanel: {
    height: "200px",
    width: "100%"
  }
}));

const Companies: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [newCompanyMode, setNewCompanyMode] = useState<boolean>();

  const companyHeaderRef = useRef<HTMLDivElement | null>(null);
  const { height: companyHeaderHeight } = useComputeSize(companyHeaderRef, []);
  const spPanelRef = useRef<HTMLDivElement | null>(null);

  const pageSize: number = 50;
  const pageIndex = useRef<number>(0);
  const [searchCompany, setSearchCompany] = useState<string>();
  const companyListHasNextPage = useSelector(
    companyRedux.selectors.getCompanyListHasNextPage
  );
  const isFetchingCompanyList = useSelector(
    companyRedux.selectors.getIsFetchingCompanyList
  );
  const companiesListRequest = useRef<CancelTokenSource>(
    axios.CancelToken.source()
  );

  const { isAllowed: canSelfEditCompany } = useAuthorization(
    [ROLES.COMPANY],
    [USER_PERMISSIONS.COMPANIES_SELF_EDIT]
  );
  const { isAllowed: canAddCompany } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.COMPANIES_ADD]
  );

  const companyList = useSelector(companyRedux.selectors.getCompanyList);

  const resetPagination = useCallback(() => {
    dispatch(companyRedux.actions.setCompanyListHasNextPage(true));
    dispatch(companyRedux.actions.setCompanyList([]));
    pageIndex.current = 0;
  }, [dispatch]);

  const resetSelection = useCallback(() => {
    setSelectedCompanyId(undefined);
    setNewCompanyMode(false);
  }, [setSelectedCompanyId, setNewCompanyMode]);

  const handleChangeSearchCompany = (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    setSearchCompany(event.target.value as string);
    resetPagination();
    resetSelection();
  };

  const debouncedCall = useMemo(
    () =>
      customDebounce(
        (
          searchCompany: string,
          incrementedPageIndex: number,
          isScrollMode: boolean,
          cancelToken: CancelToken
        ) => {
          dispatch(
            companyRedux.dispatchActions.fetchListOfCompanies(
              {
                Pagination: {
                  PageIndex: incrementedPageIndex,
                  PageSize: pageSize,
                },
                SearchVal: searchCompany,
              },
              isScrollMode,
              cancelToken
            )
          )
            .then(() => {
              pageIndex.current = incrementedPageIndex;
            })
            .catch(() => {
              pageIndex.current = 0;
            });
        },
        800
      ),
    [dispatch]
  );

  const fetchNextData = useCallback(
    (isScrollMode: boolean = false) => {
      if (companyListHasNextPage) {
        if (isFetchingCompanyList) {
          companiesListRequest.current.cancel("Cancel previous request");
          companiesListRequest.current = axios.CancelToken.source();
        }
        const incrementedPageIndex = pageIndex.current + 1;
        debouncedCall(
          searchCompany,
          incrementedPageIndex,
          isScrollMode,
          companiesListRequest.current.token
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedCall, searchCompany]
  );

  const refetchCompanies = useCallback(() => {
    resetPagination();
    fetchNextData();
    resetSelection();
  }, [fetchNextData, resetPagination, resetSelection]);

  useEffect(() => {
    if (!canSelfEditCompany) {
      refetchCompanies();
    }
  }, [refetchCompanies, canSelfEditCompany]);

  useEffect(() => {
    return () => {
      dispatch(companyRedux.dispatchActions.resetCompanies());
      companiesListRequest.current.cancel("Companies were unmounted");
    };
  }, [dispatch]);

  const getCompanyListPanel = () => {
      return (
          <div ref={spPanelRef}>
              <div ref={companyHeaderRef}>
                  <SpSearchTextField
                      placeholder="Search"
                      value={searchCompany || ""}
                      onChange={handleChangeSearchCompany}
                  />
              </div>
              <InfiniteScroll
                  dataLength={companyList.length} //This is important field to render the next data
                  next={() => fetchNextData(true)}
                  hasMore={companyListHasNextPage}
                  loader={
                      <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className={classes.footerContainer}
                      >
                          <Grid item>
                              <CircularProgress />
                          </Grid>
                      </Grid>
                  }
                  endMessage={
                      <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ textAlign: "center" }}
                      >
                          No more data
                      </Typography>
                  }
                  height={window.innerWidth > 600 ? `calc(100vh - ${companyHeaderHeight}px  - 196px)` : "200px"}
                  className={window.innerWidth > 600 ? classes.scrollPanel : classes.mobileScrollPanel}
              >
                  {companyList.map((company, index) => (
                      <SpListItem
                          key={index}
                          title={company.displayName}
                          isActive={selectedCompanyId === company.codeId}
                          onClick={() => {
                              setSelectedCompanyId(company.codeId);
                              setNewCompanyMode(false);
                          }}
                          inactiveItem={!company.isActive}
                      />
                  ))}
              </InfiniteScroll>
          </div>
      )
  }

  const getCompanyDetailsPanel = () => {
      return (
        <CompanyDetailSection
            companyId={selectedCompanyId}
            setSelectedCompanyId={setSelectedCompanyId}
            refetchCompanies={refetchCompanies}
            newCompanyMode={newCompanyMode}
            setNewCompanyMode={setNewCompanyMode}
        />
      )
  }

  return (
    <PageContent
      title={canSelfEditCompany ? "Client" : "Clients"}
      addNewButtonText="Add Client"
      showButton={canAddCompany}
      buttonOnClick={() => {
        setNewCompanyMode(true);
        setSelectedCompanyId(undefined);
      }}
    >
        {window.innerWidth <= 600 && !canSelfEditCompany && (
            <>
                {getCompanyListPanel()}
                <div>
                    {getCompanyDetailsPanel()}
                </div>
            </>
        )}

        {window.innerWidth <= 600 && canSelfEditCompany && (
            <div>
                {getCompanyDetailsPanel()}
            </div>
        )}

        {window.innerWidth > 600 && (
            !canSelfEditCompany ? (
                <ListDetailSplitView
                    list={
                        <>
                            {getCompanyListPanel()}
                        </>
                    }
                    details={
                        getCompanyDetailsPanel()
                    }
                />
            ) : (
                getCompanyDetailsPanel()
            )
        )}
    </PageContent>
  );
};

export default withAuthorization(Companies);
