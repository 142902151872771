import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { SpIcon } from "../icons";

const useStyles = makeStyles<Theme>((theme) => ({
  icon: {
    height: 32.372,
    width: 32.372,
    cursor: "pointer",
  },
}));

const PlusButton: React.FC<PlusButtonProps> = ({ onClick }) => {
  const classes = useStyles();

  return <SpIcon iconName="plus" onClick={onClick} className={classes.icon} />;
};

export default PlusButton;

export interface PlusButtonProps {
  onClick: () => void;
}
