import { createSelector } from "@reduxjs/toolkit";
import { State } from "./model";
import { COMMON } from "./constants";

const selectSelf = (state: { [x: string]: State }) =>
  state[COMMON.ROOT_REDUCER];

const getCompanyList = createSelector(
  selectSelf,
  (state: State) => state.companyList
);

const getIsFetchingCompanyList = createSelector(
  selectSelf,
  (state: State) => state.isFetchingCompanyList
);

const getCompanyListHasNextPage = createSelector(
  selectSelf,
  (state: State) => state.companyListHasNextPage
);

const getNewCompanyLogoId = createSelector(
  selectSelf,
  (state: State) => state.newCompanyLogo
);

const getSecondaryCompaniesList = createSelector(
  selectSelf,
  (state: State) => state.allowedSecondaryCompanies
);

const getSelectedSecondaryCompanies = createSelector(
  selectSelf,
  (state: State) =>
    state.allowedSecondaryCompanies
      .filter((item) => item.isChecked)
      .map((item) => parseInt(item.id))
);

const allSelectors = {
  getCompanyList,
  getIsFetchingCompanyList,
  getCompanyListHasNextPage,
  getNewCompanyLogoId,
  getSecondaryCompaniesList,
  getSelectedSecondaryCompanies,
};

export default allSelectors;
