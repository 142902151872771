import React, { FC, useEffect } from "react";

export interface RedirectRouteProps {
  url: string;
}

const RedirectRoute: FC<RedirectRouteProps> = ({ url }) => {
  useEffect(() => {
    window.location.assign(url);
  }, [url]);

  return <section>Redirecting...</section>;
};

export default RedirectRoute;
