import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BORDER_RADIUS, COLORS } from "../../../appTheme";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    border: "dashed 1px",
    borderColor: COLORS.BLUE,
    opacity: 0.5,
    backgroundColor: theme.palette.common.white,
    borderRadius: BORDER_RADIUS.NORMAL,
  },
}));

export interface SpPanelPlaceholderProps {
  height: any;
  width: any;
  children: ReactElement;
}

const SpPanelPlaceholder: React.FC<SpPanelPlaceholderProps> = ({
  height,
  width,
  children,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.mainContainer}
      style={{ height: height, width: width }}
    >
      {children}
    </div>
  );
};

export default SpPanelPlaceholder;
