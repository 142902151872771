import React, { memo, useRef } from "react";
import withAuthorization from "../../auth/hoc/withAutorization";
import eventLogRedux from "./redux";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../appStore";
import { EventPanel } from "./index";
import { ROLES } from "../../auth/constants";
import SpPanel from "../../common/components/SpPanel/SpPanel";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { SpPanelPlaceholderAddContent } from "../../common";
import { useComputeSize } from "../../services/useComputeSize";
import SpPanelPlaceholder from "../../common/components/SpPanel/SpPanelPlaceholder";
import { EVENT_PANEL_WIDTH } from "./EventPanel";
import cx from "classnames";

const useStyles = makeStyles<Theme, { spPanelHeaderHeight: number }>(
  (theme) => ({
    mainPanel: ({ spPanelHeaderHeight }) => ({
      padding: theme.spacing(1),
      overflow: "auto",
      height: `calc(100% - ${spPanelHeaderHeight}px)`,
    }),
    eventGrid: {
      width: "fit-content",
      paddingRight: theme.spacing(2),
      height: "inherit",
    },
    lastEventGrid: {
      paddingRight: theme.spacing(3),
    },
    panelsGrid: {
      height: "inherit",
    },
  })
);

const EventPanelsCatalog: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const spPanelHeaderRef = useRef<HTMLDivElement | null>(null);

  const { height: spPanelHeaderHeight } = useComputeSize(spPanelHeaderRef, []);

  const classes = useStyles({
    spPanelHeaderHeight,
  });
  const panels = useSelector(eventLogRedux.selectors.getPanels);

  return (
    <Grid
      container
      direction={"row"}
      wrap={"nowrap"}
      className={classes.panelsGrid}
    >
      {panels.map((panel, i) => {
        return (
          <Grid
            key={panel.id}
            item
            className={cx(classes.eventGrid, {
              [classes.lastEventGrid]: i === panels.length - 1,
            })}
          >
            {panel.active ? (
              <SpPanel
                headerRef={spPanelHeaderRef}
                style={{ height: "inherit" }}
                withoutHeader
                withoutFooter
              >
                <div className={classes.mainPanel}>
                  <EventPanel
                    additionalPadding={48 + 16 + 16 + spPanelHeaderHeight}
                    allowedRoles={[
                      ROLES.ADMIN,
                      ROLES.COMPANY,
                      ROLES.SITE_SUPERVISOR,
                      ROLES.CONTRACTOR,
                    ]}
                    panelId={panel.id}
                    onClose={() =>
                      dispatch(
                        eventLogRedux.actions.removePanel({ panelId: panel.id })
                      )
                    }
                  />
                </div>
              </SpPanel>
            ) : (
              <SpPanelPlaceholder
                children={
                  <SpPanelPlaceholderAddContent
                    onAdd={() =>
                      dispatch(
                        eventLogRedux.actions.addPanel({ panelId: panel.id })
                      )
                    }
                  />
                }
                width={EVENT_PANEL_WIDTH + "px"}
                height={"100%"}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default memo(withAuthorization(EventPanelsCatalog));
