import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControlWrapper, SpTextField } from "../../../../common";

export type ScheduleInputProps = {
  index: number;
  remove: (index?: number | number[] | undefined) => void;
  disabled: boolean;
};

const useStyles = makeStyles((theme) => ({
  timeInputContainer: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
  },
  closeIcon: {
    marginTop: "-8px",
    marginRight: "-8px",
    borderRadius: "1px",
    width: "36px",
    height: "36px",
  },
  container: {
    marginBottom: theme.spacing(1),
  },
  scheduleRow: {
    overflow: "hidden",
    width: "100%",
  },
}));

const scheduleDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const ScheduleInput: FC<ScheduleInputProps> = ({ index, remove, disabled }) => {
  const { control, register, errors } = useFormContext();
  const classes = useStyles();

  const scheduleErrors = errors?.Schedules?.[index];

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flexStart"
      width="100%"
      className={classes.container}
    >
      <Grid container spacing={2} className={classes.scheduleRow}>
        <Grid item lg={3} xs={12}>
          <FormControlWrapper
            title="Description"
            fullWidth
            input={
              <Controller
                control={control}
                name={`Schedules.${index}.description`}
                defaultValue=""
                inputRef={register}
                as={
                  <SpTextField
                    errorKey="description"
                    disabled={disabled}
                    errors={scheduleErrors}
                  />
                }
              />
            }
          />
        </Grid>
        <Grid item lg={3} xs={12}>
          <FormControlWrapper
            title="Days"
            fullWidth
            input={
              <Controller
                control={control}
                name={`Schedules.${index}.days`}
                defaultValue={[]}
                inputRef={register}
                as={
                  <SpTextField
                    select
                    errorKey="days"
                    disabled={disabled}
                    errors={scheduleErrors}
                    SelectProps={{
                      multiple: true,
                      renderValue: (value: string[]) =>
                        value
                          .sort(
                            (a, b) =>
                              scheduleDays.indexOf(a) - scheduleDays.indexOf(b)
                          )
                          .map((i: string) => i.slice(0, 3))
                          .join(", "),
                      // prevent select menu from moving when clicked in v4
                      // https://github.com/mui/material-ui/issues/20707#issuecomment-618342235
                      MenuProps: {
                        getContentAnchorEl: () => null,
                      },
                    }}
                  >
                    {scheduleDays.map((day) => (
                      <MenuItem key={day} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </SpTextField>
                }
              />
            }
          />
        </Grid>
        <Grid item lg={2} md={3} sm={5}>
          <FormControlWrapper
            title="Start Time"
            fullWidth
            input={
              <Controller
                control={control}
                name={`Schedules.${index}.startTime`}
                inputRef={register}
                defaultValue=""
                as={
                  <SpTextField
                    type="time"
                    disabled={disabled}
                    errors={scheduleErrors}
                    errorKey={"startTime"}
                  />
                }
              />
            }
          />
        </Grid>
        <Grid item lg={2} md={3} sm={5}>
          <FormControlWrapper
            title="End Time"
            fullWidth
            input={
              <Controller
                control={control}
                name={`Schedules.${index}.endTime`}
                inputRef={register}
                defaultValue=""
                as={
                  <SpTextField
                    type="time"
                    disabled={disabled}
                    errors={scheduleErrors}
                    errorKey={"endTime"}
                  />
                }
              />
            }
          />
        </Grid>
        <Grid item xs={1}>
          <Controller
            control={control}
            name={`Schedules.${index}.isEnabled`}
            inputRef={register}
            defaultValue={true}
            render={({ onChange, onBlur, value, ref }) => (
              <FormControlWrapper
                title="Enabled"
                fullWidth
                input={
                  <Checkbox
                    color="primary"
                    onBlur={onBlur}
                    onChange={(event: any, newValue: boolean) =>
                      onChange(newValue)
                    }
                    checked={value}
                    inputRef={ref}
                    disabled={disabled}
                  />
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Box>
        <IconButton onClick={() => remove(index)} className={classes.closeIcon}>
          <Close color="disabled" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ScheduleInput;
