import { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import withAuthorization from "../../auth/hoc/withAutorization";
import { Box, Button, Grid, MenuItem, Typography } from "@material-ui/core";
import { SpTextField, PageContent, FormControlWrapper } from "../../common";
import { makeStyles } from "@material-ui/core/styles";
import { AppDispatch } from "../../appStore";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { CounterResponseModel } from "../../services/apiServiceTypes";
import { useFetchData } from "../../services/useFetchData";
import {
  getEntranceCounter,
  getSiteCounter,
} from "../reports/helpers/apiCalls";
import { getDetailOfContractor } from "../contractors/redux/apiCalls";
import { getEntranceDetail } from "../sites/redux/apiCalls";
import {
  ManualCheckInOutReason,
  postManualCheckIn,
  postManualCheckOut,
} from "../eventLog/redux/apiCalls";
import commonReducer from "../../common/redux";
import FlipCameraIosOutlinedIcon from "@material-ui/icons/FlipCameraIosOutlined";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import moment from "moment-timezone";

import QrReader from "react-qr-reader";

const useStyles = makeStyles((theme) => ({
  camera: {
    minWidth: "150px",
    minHeight: "100px",
    marginBottom: "26px",
  },
  scanQRCodeButton: {
    width: "100%",
    height: "40px",
    marginTop: "26px",
  },
  label: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  cameraButtonGroup: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  qrcodescanContainer: {
    padding: "24px 20px",
    backgroundColor: "#fff",
  },
  topButtonsContainer: {
    marginBottom: theme.spacing(3),
    "& Button": {
      width: "calc(50% - 6px)",
      "&:first-child": {
        marginRight: "12px",
      },
    },
  },
}));

export const QRCodeScanSchema = yup.object().shape({
  site: yup.string().required("Please select Location"),
  entrance: yup.string().required("Please select Entrance"),
});

export interface QRCodeScanInputs {
  site: string;
  entrance: string;
}

const QRCodeScan: FC = () => {
const classes = useStyles();
const dispatch = useDispatch<AppDispatch>();

const { control, setValue, errors, watch } = useForm<QRCodeScanInputs>({
    resolver: yupResolver(QRCodeScanSchema),
});

const { data: siteData } = useFetchData<CounterResponseModel>(
    getSiteCounter()
);

const { data: entranceData } = useFetchData<CounterResponseModel>(
    getEntranceCounter(watch("site")),
    watch("site") !== "" && watch("site") !== undefined,
    undefined,
    [watch("site")],
    () => setValue("entrance", "")
);

const entrance = watch("entrance");

const [validContractorId, setValidContractorId] = useState<boolean>(false);
const [contractorName, setContractorName] = useState<string>("");

const [cameraCount, setCameraCount] = useState<number>(0);
const [facingMode, setFacingMode] = useState<"environment" | "user" | undefined>("environment");

// retrieve initial camera count
useEffect(() => {
    navigator.mediaDevices?.enumerateDevices().then((devices) => {
        setCameraCount(
        devices.filter((device) => {
            return device.kind === "videoinput";
        }).length
        );
    });
}, []);

// periodical refresh of available cameras (needed at least for iOS)
useEffect(() => {
const interval = setInterval(() => {
    navigator.mediaDevices?.enumerateDevices().then((devices) => {
        const count = devices.filter((device) => {
            return device.kind === "videoinput";
        }).length;
        // eslint-disable-next-line eqeqeq
        if (count != cameraCount) {
            setCameraCount(count);
        }
    });
}, 1000);

return () => clearInterval(interval);
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const scantype = watch("scantype");

const checkin = (response: any) => {       
    if (entrance === null || entrance === "null" || entrance === "") {
        toast.error("Location and Entrance is required!", { onClose: () => resetScan() });
    }
    else {
        dispatch(
            postManualCheckIn({
                userId: response.data.userId!,
                reason: "Other" as ManualCheckInOutReason,
                entranceId: entrance!,
            })
        )
            .then(() => {
                dispatch(getEntranceDetail(entrance))
                    .then((entResponse: any) => {
                        if (entResponse &&
                            entResponse.data &&
                            entResponse.data.id.toString() === entrance) {
                            var date = moment(new Date());            
                            var tz = entResponse.data.timeZone;
                            if (tz === null || tz === "") {
                                tz = "America/New_York";
                            }     
                            toast.success(`${response.data.firstName} ${response.data.lastName} successfully checked in! ${date.tz(tz).format('MMMM Do YYYY h:mm a')}`, { onClose: () => resetScan() });
                        }
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            })
            .catch((e) => {
                toast.error(`${response.data.firstName} ${response.data.lastName} failed check in!`, { onClose: () => resetScan() });
                console.log(e);
            });
    }
};

const checkout = (response: any) => {   
    if (entrance === null || entrance === "null" || entrance === "") {
        toast.error("Location and Entrance is required!", { onClose: () => resetScan() });
    }
    else {
        dispatch(
            postManualCheckOut({
                userId: response.data.userId!,
                reason: "Other" as ManualCheckInOutReason,
                entranceId: entrance!,
            })
        )
            .then(() => { 
                dispatch(getEntranceDetail(entrance))
                    .then((entResponse: any) => {
                        if (entResponse &&
                            entResponse.data &&
                            entResponse.data.id.toString() === entrance) {
                            var date = moment(new Date());
                            var tz = entResponse.data.timeZone;
                            if (tz === null || tz === "") {
                                tz = "America/New_York";
                            }
                            toast.success(`${response.data.firstName} ${response.data.lastName} successfully checked out! ${date.tz(tz).format('MMMM Do YYYY h:mm a')}`, { onClose: () => resetScan() });
                        }
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            })
            .catch((e) => {
                toast.error(`${response.data.firstName} ${response.data.lastName} failed check out!`, { onClose: () => resetScan() });
                console.log(e);
            });
    }
    };

const resetScan = () => {
    setValidContractorId(false);
    setContractorName("");
}

return (
    <PageContent title="QR Code Scan" showButton={false}>
        <ToastContainer position="top-center" newestOnTop />
        <Grid className={classes.qrcodescanContainer}>
            <Grid item>
                <Grid container>
                    <Grid item xs={window.innerWidth > 600 ? 3 : 12}>
                        <Box className={classes.topButtonsContainer}>
                        <FormControlWrapper
                            title=""
                            fullWidth
                            input={
                                <Controller
                                    control={control}
                                    name="scantype"
                                    defaultValue={"checkin"}
                                    as={
                                        <SpTextField
                                            select
                                            errors={errors}
                                        >
                                            <MenuItem value="checkin">Check In</MenuItem>
                                            <MenuItem value="checkout">Check Out</MenuItem>
                                        </SpTextField>
                                    }
                                />
                            }
                        />
                    </Box>

                        {cameraCount === 0 && (
                            <Box>
                            <Typography
                            variant={"h4"}
                            color={"error"}
                            className={classes.label}
                            >
                            No camera available
                            </Typography>
                        </Box>
                        )}

                        {cameraCount > 1 && (
                            <Box>
                            <Button
                            className={classes.scanQRCodeButton}
                            color={"primary"}
                            variant="contained"
                            onClick={() => {
                                setFacingMode(
                                facingMode === "environment" ? "user" : "environment"
                                );
                            }}
                            >
                            <FlipCameraIosOutlinedIcon />
                            </Button>
                        </Box>
                        )}

                        {cameraCount > 0 && (
                            <Box className={classes.camera}>
                                <QrReader
                                    delay={300}
                                    facingMode={facingMode}
                                    onError={(error) => {
                                        if (error) {
                                            dispatch(
                                                commonReducer.actions.setSnackBarMessage(
                                                    "Camera error!"
                                                )
                                            );
                                            setValidContractorId(false);
                                            setContractorName("");
                                            console.log(error);
                                        }
                                    }}
                                    onScan={(data: string | null) => {
                                        if (validContractorId) {
                                            return;
                                        }
                                        if (data) {
                                            dispatch(getDetailOfContractor(data, undefined))
                                                .then((response: any) => {
                                                    // eslint-disable-next-line eqeqeq
                                                    if (
                                                        response &&
                                                        response.data &&
                                                        response.data.userId === data
                                                    ) {
                                                        setContractorName(
                                                            `${response.data.firstName} ${response.data.lastName}`
                                                        );
                                                        setValidContractorId(true);

                                                        if (scantype === "checkin") {
                                                            checkin(response);
                                                        }
                                                        else if (scantype === "checkout") {
                                                            checkout(response);
                                                        }
                                                    }
                                                })
                                                .catch(() => {
                                                    dispatch(
                                                        commonReducer.actions.setSnackBarMessage(
                                                            "Failed to verify QR code!"
                                                        )
                                                    );
                                                    setValidContractorId(false);
                                                });
                                        }
                                    }}
                                />
                            </Box>
                        )}

                        {cameraCount > 0 && (
                            <Box>
                                <FormControlWrapper
                                    fullWidth
                                    title="User"
                                    input={
                                        <SpTextField
                                            value={contractorName}
                                            placeholder="Scan QR Code"
                                            fullWidth
                                        />
                                    }
                                />

                                <FormControlWrapper
                                    title="Location"
                                    fullWidth
                                    input={
                                        <Controller
                                        control={control}
                                        name="site"
                                        defaultValue={""}
                                        as={
                                            <SpTextField
                                            select
                                            placeholder="Select Location"
                                            errors={errors}
                                            >
                                            {!siteData && (
                                                <MenuItem value={""}>No data</MenuItem>
                                            )}
                                            {siteData?.map((site) => (
                                                <MenuItem key={site.codeId} value={site.codeId}>
                                                {site.displayName}
                                                </MenuItem>
                                            ))}
                                            </SpTextField>
                                        }
                                        />
                                    }
                                />

                                <FormControlWrapper
                                    fullWidth
                                    title="Entrance"
                                    input={
                                        <Controller
                                            control={control}
                                            name="entrance"
                                            defaultValue={""}
                                            as={
                                                <SpTextField
                                                select
                                                placeholder="Select Entrance"
                                                errors={errors}
                                                >
                                                {!entranceData && (
                                                    <MenuItem value={""}>No data</MenuItem>
                                                )}
                                                {entranceData?.map((ent) => (
                                                    <MenuItem key={ent.codeId} value={ent.codeId}>
                                                    {ent.displayName}
                                                    </MenuItem>
                                                ))}
                                                </SpTextField>
                                            }
                                        />
                                    }
                                />
                        </Box>
                        )}
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    </PageContent>
);
};

export default withAuthorization(QRCodeScan);
