import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { FormSection } from "../../../common";
import { CounterResponseModel } from "../../../services/apiServiceTypes";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../appStore";
import usersRedux from "../redux";

export interface UserPermissionsDetailProps {
  possiblePermissions: CounterResponseModel | undefined;
  allowedPermissions: Array<string>;
}

const UserPermissionsDetail: React.FC<UserPermissionsDetailProps> = ({
  possiblePermissions,
  allowedPermissions,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  function handleToggle(isChecked: boolean, id: string) {
    console.log(isChecked);
    dispatch(
      isChecked
        ? usersRedux.actions.disableAllowedPermissionsItem(id)
        : usersRedux.actions.enableAllowedPermissionsItem(id)
    );
  }

  return (
    <FormSection title="Permissions">
      <List>
        {possiblePermissions?.map((permission) => (
          <ListItem
            key={permission.codeId}
            onClick={() =>
              handleToggle(
                allowedPermissions.includes(permission.codeId),
                permission.codeId
              )
            }
            disableGutters
            dense
          >
            <ListItemIcon>
              <Checkbox
                checked={allowedPermissions.includes(permission.codeId)}
                color="primary"
              />
            </ListItemIcon>
            <ListItemText>{permission.displayName}</ListItemText>
          </ListItem>
        ))}
      </List>
    </FormSection>
  );
};

export default UserPermissionsDetail;
