import { APP_SETTINGS } from "../../../AppSettings";
import {
  CounterResponseItem,
  CounterResponseModel,
  PaginationModel,
  PaginationResponseType,
} from "../../../services/apiServiceTypes";
import { getAuthApi, postAuthApi } from "../../../services/authApi";
import { CancelToken } from "axios";

export type NotificationType = {
  subject: string | null;
  body: string | null;
  id: number;
  created: string;
  files: Array<any>;
};

export interface NotificationRequestType extends PaginationModel {
  SiteId?: number;
  EntranceId?: number;
}

export interface UserPaginatedModel extends PaginationModel {
  CompanyId?: number;
  SiteIds?: number[];
}

export type NotificationEntranceType = {
  id: number;
  name: string;
  siteName: number;
};

export type NotificationSiteType = {
  id: number;
  name: string;
  status: number;
  entrances: Array<NotificationEntranceType>;
};

export type NotificationFileType = {
  id: number;
  name: string;
  dateTime: string;
};

export type NotificationDetailResponseType = {
  sender?: string;
  body?: string;
  dateTime?: string;
  sites: Array<NotificationSiteType>;
  contractorsForDetail: Array<string>;
  files: Array<NotificationFileType>;
  subject?: string;
  formName?: string;
};

export interface CreateNewNotificationFilesType {
  FormFile?: File | null;
}

export const getListOfNotification = <
  T = PaginationResponseType<NotificationType>
>(
  body: NotificationRequestType,
  cancelToken: CancelToken
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Notification`,
    body,
    undefined,
    cancelToken
  );

export const postNewNotification = <T = any>(body: FormData) =>
  postAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Notification/create`, body, {
    "Content-Type": "multipart/form-data",
  });

export const getDetailOfNotification = <T = NotificationDetailResponseType>(
  notificationId?: number
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Notification/${notificationId}`
  );

export const getSitesCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Counter/sites`);

export interface EntranceCounterModel {
  SiteId?: number;
}

export const getEntrancesCounter = <T = CounterResponseModel>(
  body: EntranceCounterModel
) => postAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Counter/entrances`, body);

export const getDetailedSitesCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Counter/detailedSites`);

export const getFormsCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Counter/forms`);

export const getListOfContractors = <
  T = PaginationResponseType<CounterResponseItem>
>(
  body: UserPaginatedModel
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Notification/sendToContractors`,
    body
  );

export const getUserPermissionsCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Counter/permissions/types`);

export const getUserDefaultPermissionsTypes = <T = CounterResponseModel>(
  role: string = ""
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/permissions/default?role=${role}`
  );
