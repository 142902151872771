import React, { FC, memo, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { EntranceEventThumbnailViewModel } from "./redux/apiCalls";
import { BORDER_RADIUS, COLORS } from "../../appTheme";
import noCamera from "../../assets/img/21_SP_NO CAMERA-ICON.png";
import beaconBadgeIcon from "../../assets/img/21_SP_BEACON_BADGE_ICON.png";
import beaconIcon from "../../assets/img/21_SP_BEACON_ICON.png";
import qrCodeIcon from "../../assets/img/21_SP_QR_CODE_ICON.png";
import alertIconRed from "../../assets/img/21_SP_ALERT-ICON_RED.png";
import avatar from "../../assets/img/avatar.png";
import { useFetchData } from "../../services/useFetchData";
import { loadThumbnail } from "../../services/authApi";
import { isNil } from "ramda";
import Announcement from "../../common/components/Announcement";
import { FRONTEND_DATE_FORMAT_REPORTS } from "../../services/helpers";
import moment from "moment";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../appStore";
import eventLogReducer from "./redux";
import CameraEventDetail from "./dialogs/CameraEventDetail";
import cx from "classnames";
import {
  isDirectionalMotionSensor,
  isLeavingEnteringType,
  printEventSource,
} from "./helpers";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const useStyles = makeStyles<Theme, EventItemProps>((theme) => ({
  main: {
    borderBottom: `1px solid ${COLORS.LINE_100}`,
    padding: theme.spacing(1),
  },
  details: ({ eventData }) => ({
    marginLeft: theme.spacing(1),
    flexGrow: 1,
    cursor: (!isNil(eventData.userId) && "pointer") || "unset",
    justifyContent: "flex-start",
  }),
  userPhoto: ({ eventData }) => ({
    width: 60,
    height: 60,
    objectFit: "cover",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderWidth: (!isNil(eventData.userId) && 4) || 0,
    borderColor: eventData.allowed ? COLORS.GREEN : COLORS.RED,
    borderStyle: "solid",
    borderRadius: BORDER_RADIUS.NORMAL,
  }),
  iconEventSource: ({ eventData }) => ({
    width: 18,
    height: 18,
    backgroundColor: eventData.allowed ? COLORS.GREEN : COLORS.RED,
    borderRadius: "50%",
    padding: 3,
    zIndex: 1,
    marginLeft: -15,
  }),
  iconEventDirection: ({ eventData }) => ({
    color: COLORS.WHITE,
    width: 18,
    height: 18,
    marginTop: 13,
    marginLeft: eventData.eventSource ? -10 : -25,
    backgroundColor: eventData.allowed ? COLORS.GREEN : COLORS.RED,
    borderRadius: "50%",
    padding: 3,
  }),
  badgesContainer: ({ eventData }) => ({
    marginTop: -47,
    marginLeft: 2,
  }),
  badges: {
    padding: 4,
    position: "relative",
    display: "flex",
  },
  link: {
    cursor: "pointer",
  },
  eventInfoGrid: {
    paddingBottom: theme.spacing(2),
  },
  announcement: {
    marginTop: "2px",
    marginBottom: "2px",
  },
  unauthorizedMotionLeaveIcon: {
    backgroundColor: COLORS.GREEN,
    height: 70,
    width: 70,
    borderRadius: "50%",
  },
}));

interface EventItemProps {
  eventData: EntranceEventThumbnailViewModel;
  panelId: number;
}

const EventItem: FC<EventItemProps> = ({ eventData, panelId }) => {
  const classes = useStyles({ eventData, panelId });
  const dispatch = useDispatch<AppDispatch>();
  const [photoUrl, setPhotoUrl] = useState<string>();
  const [userPhotoUrl, setUserPhotoUrl] = useState<string>();
  const [openPhotoDialog, setOpenPhotoDialog] = useState<boolean>(false);
  const isUnauthorized = isNil(eventData.userId);
  // const isFacialRecEnabled = !isNil(eventData.facialRecMessage) || !isNil(eventData.recognizedPersonName);
  const iconPath =
    eventData.eventSource === "QRReader"
      ? qrCodeIcon
      : eventData.eventSource === "BeaconReader"
      ? beaconIcon
      : beaconBadgeIcon;
  const isUnauthorizedMotionLeaving =
    isUnauthorized &&
    isDirectionalMotionSensor(eventData.eventType) &&
    !eventData.entering;
  const displayBadges =
    !isUnauthorized ||
    eventData.eventSource !== null ||
    isDirectionalMotionSensor(eventData.eventType);

  const eventTitle = () => {
    if (!isUnauthorized) {
      return `${eventData.firstName ? eventData.firstName : ""}${
        eventData.lastName ? ` ${eventData.lastName}` : ""
      }`;
    } else if (isUnauthorizedMotionLeaving) {
      return "Exit";
    } else {
      return "Unauthorized";
    }
  };

  // TODO add cancellation of this calls (updating state of unmounted component warning)
  useFetchData<string>(
    // @ts-ignore
    loadThumbnail(eventData?.photo),
    !isNil(eventData?.photo),
    undefined,
    [eventData?.photo],
    // @ts-ignore
    (response) => setPhotoUrl(response),
    () => setPhotoUrl(undefined),
    () => setPhotoUrl(undefined)
  );

  useFetchData<string>(
    // @ts-ignore
    loadThumbnail(eventData?.userPhotoId),
    !isNil(eventData?.userPhotoId),
    undefined,
    [eventData?.userPhotoId],
    // @ts-ignore
    (response) => setUserPhotoUrl(response),
    () => setUserPhotoUrl(undefined),
    () => setUserPhotoUrl(undefined)
  );

  const clickOnContractorDetail = () => {
    if (!isUnauthorized) {
      dispatch(
        eventLogReducer.actions.setSelectedEvent({
          selectedEvent: {
            data: eventData,
            type: "CONTRACTOR_DETAIL",
          },
          panelId: panelId,
        })
      );
    }
  };

  const eventItemFooter = () => {
    return (
      <>
        <Typography variant="subtitle1">{eventData.dateTimeString}</Typography>
        {!isUnauthorized && !isNil(eventData.vaccinationCertificate) && (
          <div className={classes.announcement}>
            <Announcement
              color={
                eventData.vaccinationCertificate.vaccinationVerified
                  ? COLORS.GREEN
                  : COLORS.RED
              }
              text={
                eventData.vaccinationCertificate.vaccinationVerified
                  ? `Vaccine verified ${moment(
                      eventData.vaccinationCertificate
                        .vaccinationVerificationDate
                    ).format(FRONTEND_DATE_FORMAT_REPORTS)}`
                  : `Vaccine Not Verified`
              }
            />
          </div>
        )}
        {!isUnauthorized &&
          eventData.expiredCertifications.map((cert, index) => (
            <div className={classes.announcement} key={index}>
              <Announcement
                color={cert.expired ? COLORS.RED : COLORS.ORANGE}
                text={`${cert.name} ${
                  cert.expired ? "expired" : "expires" || ""
                } ${
                  cert.expirationDate &&
                  moment(cert.expirationDate).format(
                    FRONTEND_DATE_FORMAT_REPORTS
                  )
                }`}
              />
            </div>
          ))}
      </>
    );
  };

  return (
    <>
      <Box
        className={classes.main}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        {photoUrl ? (
          <img
            src={photoUrl || noCamera}
            alt="camera-portrait"
            width={182.5}
            height={132.5}
            className={cx(!isNil(photoUrl) && classes.link)}
            onClick={() => {
              if (!isNil(photoUrl)) {
                setOpenPhotoDialog(true);
              }
            }}
          />
        ) : null}
        <Box
          display="flex"
          flexDirection="column"
          className={classes.details}
          onClick={clickOnContractorDetail}
        >
          <Box display="flex" flexDirection="row" alignItems={"flex-start"}>
            <Box>
              {!isUnauthorizedMotionLeaving ? (
                <>
                  <img
                    src={
                      !isUnauthorized ? userPhotoUrl || avatar : alertIconRed
                    }
                    alt="user-portrait"
                    className={classes.userPhoto}
                  />
                  {displayBadges && isLeavingEnteringType(eventData.eventType) && (
                    <div className={classes.badgesContainer}>
                      <div className={classes.badges}>
                        {eventData.eventSource !== null &&
                        eventData.eventSource !== undefined ? (
                          <Tooltip
                            title={printEventSource(eventData.eventSource!)}
                          >
                            <img
                              className={classes.iconEventSource}
                              src={iconPath}
                              alt="event-source"
                            />
                          </Tooltip>
                        ) : (
                          <div style={{ width: 24 }} />
                        )}
                        {eventData.entering ? (
                          <ArrowDownwardIcon
                            className={classes.iconEventDirection}
                          />
                        ) : (
                          <ArrowUpwardIcon
                            className={classes.iconEventDirection}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <Box className={classes.unauthorizedMotionLeaveIcon}></Box>
              )}
            </Box>
            <Box flexGrow={1}>
              <Typography variant="h4">{eventTitle()}</Typography>
              <Typography variant="h6">
                {!isUnauthorized ? eventData.companyName : "No access"}
              </Typography>
              {!photoUrl ? eventItemFooter() : null}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            {photoUrl ? eventItemFooter() : null}
          </Box>
        </Box>
      </Box>
      {openPhotoDialog && (
        <CameraEventDetail
          photoId={eventData.photo}
          isOpen={openPhotoDialog}
          onClose={() => setOpenPhotoDialog(false)}
        />
      )}
    </>
  );
};

export default memo(EventItem);
