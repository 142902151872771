import React, { FC, memo, useEffect } from "react";
import { useFetchData } from "../../../../services/useFetchData";
import { isNil } from "ramda";
import {
  CreateSupervisorViewModel,
  getSiteSupervisorDetail,
  putSiteSupervisorBasicInfo,
  putSiteSupervisorCredentials,
  putSiteSupervisorDetail,
} from "../../redux/apiCalls";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import {
  DetailPanelView,
  FormControlWrapper,
  FormSection,
  SpTextField,
} from "../../../../common";
import * as yup from "yup";
import { FieldName } from "react-hook-form/dist/types/fields";
import { SetFieldValue, SetValueConfig } from "react-hook-form/dist/types/form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../appStore";
import siteRedux from "../../redux";
import { SiteDetailModel } from "../../../forms/redux/apiCalls";
import commonReducer from "../../../../common/redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthorization } from "../../../../auth";
import { ROLES, USER_PERMISSIONS } from "../../../../auth/constants";

export interface SiteSupervisorDetailInputs {
  FirstName: string;
  LastName: string;
  Email: string;
  Password: string;
  ConfirmPassword: string;
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
  },
  inactiveSite: {
    color: theme.palette.text.disabled,
  },
}));

type SiteSupervisorDetailProps = {
  siteDetail?: SiteDetailModel;
  refetchSiteSupervisorList: () => void;
};

const SiteSupervisorDetail: FC<SiteSupervisorDetailProps> = ({
  siteDetail,
  refetchSiteSupervisorList,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const selectedSiteSupervisorId = useSelector(
    siteRedux.selectors.getSelectedSiteSupervisorId
  );
  const isEditingCredentials = useSelector(
    siteRedux.selectors.getIsEditingCredentials
  );

  const selectedSiteId = useSelector(siteRedux.selectors.getSelectedSiteId);

  const { isAllowed: canRemoveSupervisor } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_SUPERVISORS_ADD_REMOVE]
  );
  const { isAllowed: canEditSupervisor } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_SUPERVISORS_EDIT]
  );
  const { isAllowed: canChangePassword } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_SUPERVISORS_CHANGE_CREDENTIALS]
  );

  const SiteSupervisorDetailSchema = yup.object().shape({
    FirstName: canEditSupervisor
      ? yup
          .string()
          .max(50, "Name exceeds 50 character limit.")
          .required("First Name is required.")
      : yup.string().max(50, "Name exceeds 50 character limit."),
    LastName: canEditSupervisor
      ? yup
          .string()
          .max(50, "Name exceeds 50 character limit.")
          .required("Last Name is required.")
      : yup.string().max(50, "Name exceeds 50 character limit."),
    Email: isEditingCredentials
      ? yup
          .string()
          .required("Please enter Company Contact Email")
          .max(80, "Company Contact Email exceeds 80 character limit.")
          .email("Not valid Email form")
      : yup
          .string()
          .max(80, "Company Contact Email exceeds 80 character limit.")
          .email("Not valid Email form"),
    Password: isEditingCredentials
      ? yup
          .string()
          .max(20, "Password exceeds 20 character limit.")
          .required("Password is Required")
      : yup.string().max(20, "Password exceeds 20 character limit."),
    ConfirmPassword: yup
      .string()
      .test("passwords-match", "Passwords must match", function (value) {
        const { Password } = this.parent;
        return Password === value;
      }),
  });

  const prefillSiteSupervisorDetailInputs = (
    setValue: (
      name: FieldName<SiteSupervisorDetailInputs>,
      value: SetFieldValue<SiteSupervisorDetailInputs>,
      config?: SetValueConfig
    ) => void,
    data: CreateSupervisorViewModel
  ) => {
    setValue("FirstName", data?.firstName);
    setValue("LastName", data?.lastName);
    setValue("Email", data?.email);
  };

  const {
    register,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    control,
    setValue,
    reset,
    errors,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    trigger,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    watch,
    setError,
  } = useForm<SiteSupervisorDetailInputs>({
    resolver: yupResolver(SiteSupervisorDetailSchema),
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, [dispatch, reset]);

  const {
    data: siteSupervisorDetail,
    refetchAction: refetchSiteSupervisorDetail,
  } = useFetchData<CreateSupervisorViewModel>(
    // @ts-ignore
    getSiteSupervisorDetail(selectedSiteSupervisorId),
    !isNil(selectedSiteSupervisorId),
    undefined,
    [selectedSiteSupervisorId],
    ({ data }) => prefillSiteSupervisorDetailInputs(setValue, data),
    () => reset(),
    () => reset()
  );

  const onSaveSuccess = () => {
    refetchSiteSupervisorDetail();
    refetchSiteSupervisorList();
  };

  const onSaveError = (error: any) => {
    if (
      typeof error.response.data === "string" ||
      error.response.data instanceof String
    ) {
      dispatch(commonReducer.actions.setSnackBarMessage(error.response.data));
    } else if (error.response.data.errors) {
      Object.keys(error.response.data.errors).forEach((key) => {
        setError(key as keyof SiteSupervisorDetailInputs, {
          message: error.response.data.errors[key],
        });
      });
    }
  };

  const handleOnSave = (formInputData: SiteSupervisorDetailInputs) => {
    if (siteSupervisorDetail) {
      if (isEditingCredentials) {
        if (canEditSupervisor) {
          const body = {
            userId: siteSupervisorDetail.userId,
            firstName: formInputData.FirstName,
            lastName: formInputData.LastName,
            email: formInputData.Email,
            password: formInputData.Password,
            confirmPassword: formInputData.ConfirmPassword,
          };
          dispatch(putSiteSupervisorDetail(body))
            .then(onSaveSuccess)
            .catch(onSaveError);
        } else {
          const body = {
            userId: siteSupervisorDetail.userId,
            email: formInputData.Email,
            password: formInputData.Password,
            confirmPassword: formInputData.ConfirmPassword,
          };
          dispatch(putSiteSupervisorCredentials(body))
            .then(onSaveSuccess)
            .catch(onSaveError);
        }
      } else {
        const body = {
          userId: siteSupervisorDetail.userId,
          firstName: formInputData.FirstName,
          lastName: formInputData.LastName,
        };
        dispatch(putSiteSupervisorBasicInfo(body))
          .then(onSaveSuccess)
          .catch(onSaveError);
      }
    }
  };

  const handleClickOnChangeStatus = () => {
    if (selectedSiteSupervisorId && selectedSiteId) {
      dispatch(
        siteRedux.dispatchActions.onChangeSiteSupervisorStatus(
          selectedSiteSupervisorId,
          selectedSiteId
        )
      ).then(() => {
        refetchSiteSupervisorList();
        dispatch(siteRedux.dispatchActions.resetSiteSupervisorTab());
        reset();
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleOnSave)}>
      <DetailPanelView
        title={
          siteSupervisorDetail
            ? `${siteSupervisorDetail?.firstName} ${siteSupervisorDetail?.lastName}`
            : ""
        }
        showSaveButton={canEditSupervisor}
      >
        {!isNil(selectedSiteSupervisorId) && (
          <>
            <FormSection title="Person Detail">
              <FormControlWrapper
                title="First Name"
                input={
                  <SpTextField
                    name="FirstName"
                    inputRef={register}
                    errors={errors}
                    disabled={!canEditSupervisor}
                    fullWidth
                  />
                }
              />
              <FormControlWrapper
                title="Last Name"
                input={
                  <SpTextField
                    name="LastName"
                    inputRef={register}
                    errors={errors}
                    disabled={!canEditSupervisor}
                    fullWidth
                  />
                }
              />
            </FormSection>

            <FormSection title="Credentials">
              <FormControlWrapper
                title="Email"
                input={
                  <SpTextField
                    name="Email"
                    inputRef={register}
                    errors={errors}
                    disabled={!canChangePassword || !isEditingCredentials}
                    fullWidth
                  />
                }
              />
              {canChangePassword && (
                <FormControlWrapper
                  title="Password"
                  input={
                    <SpTextField
                      name="Password"
                      disabled={!isEditingCredentials}
                      type="password"
                      placeholder="•••••••••••••••"
                      inputRef={register}
                      errors={errors}
                      fullWidth
                    />
                  }
                />
              )}
              {canChangePassword && (
                <FormControlWrapper
                  title="Confirm Password"
                  input={
                    <SpTextField
                      name="ConfirmPassword"
                      disabled={!isEditingCredentials}
                      type="password"
                      placeholder="•••••••••••••••"
                      inputRef={register}
                      errors={errors}
                      fullWidth
                    />
                  }
                />
              )}
              {canChangePassword && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch(
                      siteRedux.actions.setIsEditingCredentials(
                        !isEditingCredentials
                      )
                    )
                  }
                >
                  Edit
                </Button>
              )}
            </FormSection>

            <FormSection title="Allowed Locations">
              {!isNil(selectedSiteSupervisorId) &&
                siteSupervisorDetail?.sites?.map((site) => (
                  <Box key={site.id} paddingBottom={2}>
                    <Grid
                      container
                      direction={"row"}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          className={
                            site.status === "Inactive"
                              ? classes.inactiveSite
                              : undefined
                          }
                        >
                          {site.siteName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
            </FormSection>

            <FormSection title="Actions">
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  {canRemoveSupervisor &&
                    !isNil(selectedSiteSupervisorId) &&
                    siteSupervisorDetail && (
                      <Button
                        variant="contained"
                        onClick={handleClickOnChangeStatus}
                      >
                        Unassign Manager
                      </Button>
                    )}
                </Grid>
                {(canEditSupervisor || canChangePassword) && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isNil(siteSupervisorDetail)}
                    >
                      Save
                    </Button>
                  </Grid>
                )}
              </Grid>
            </FormSection>
          </>
        )}
      </DetailPanelView>
    </form>
  );
};

export default memo(SiteSupervisorDetail);
