import { makeStyles } from "@material-ui/core/styles";
import React, { FC, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import authReducer from "../auth/redux";
import { useAppDispatch } from "../appStore";

const useStyles = makeStyles((theme) => ({
  background: {
    height: "calc(100vh - 4px)",
    width: "100vw",
    overflow: "hidden",
  },
  mainContent: {
    padding: theme.spacing(3),
  },
}));

const CustomRoute: FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  let { url } = useRouteMatch();

  useEffect(() => {
    dispatch(authReducer.actions.setSkipAuthentication(true));
    return () => {
      dispatch(authReducer.actions.setSkipAuthentication(false));
    };
  }, [dispatch]);

  const newUrl = `https://sightpasapp.com${url}`;

  return (
    <iframe
      title="Frame"
      className={classes.background}
      id="redirect"
      name="redirect"
      marginWidth={0}
      marginHeight={0}
      frameBorder={0}
      src={newUrl}
    >
      <p>Your browser does not support iframes.</p>
    </iframe>
  );
};

export default CustomRoute;
