import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COMMON } from "./constants";
import { initialState, State } from "./model";
import { CounterResponseItem } from "../../../services/apiServiceTypes";

const Reducer = createSlice({
  name: COMMON.ROOT_REDUCER,
  initialState,
  reducers: {
    setSiteListHasNextPage: (state: State, action: PayloadAction<boolean>) => ({
      ...state,
      siteListHasNextPage: action.payload,
    }),
    setIsFetchingSiteList: (state: State, action: PayloadAction<boolean>) => ({
      ...state,
      isFetchingSiteList: action.payload,
    }),
    setSiteList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => ({
      ...state,
      siteList: action.payload,
    }),
    appendToSiteList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => {
      const companies = JSON.parse(JSON.stringify(state.siteList));
      companies.push(...action.payload);

      return {
        ...state,
        siteList: companies,
      };
    },
    setSelectedSiteId: (
      state: State,
      action: PayloadAction<string | undefined>
    ) => ({
      ...state,
      selectedSiteId: action.payload,
    }),

    setEntranceListHasNextPage: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      entranceListHasNextPage: action.payload,
    }),
    setIsFetchingEntranceList: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      isFetchingEntranceList: action.payload,
    }),
    setEntranceList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => ({
      ...state,
      entranceList: action.payload,
    }),
    appendToEntranceList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => {
      const companies = JSON.parse(JSON.stringify(state.entranceList));
      companies.push(...action.payload);

      return {
        ...state,
        entranceList: companies,
      };
    },
    setSelectedEntranceId: (
      state: State,
      action: PayloadAction<string | undefined>
    ) => ({
      ...state,
      selectedEntranceId: action.payload,
    }),
    setIsNewEntranceMode: (state: State, action: PayloadAction<boolean>) => ({
      ...state,
      isNewEntranceMode: action.payload,
    }),

    setIsNewSiteSupervisorMode: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      isNewSiteSupervisorMode: action.payload,
    }),
    setSiteSupervisorListHasNextPage: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      siteSupervisorListHasNextPage: action.payload,
    }),
    setIsFetchingSiteSupervisorList: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      isFetchingSiteSupervisorList: action.payload,
    }),
    setSiteSupervisorList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => ({
      ...state,
      siteSupervisorList: action.payload,
    }),
    appendToSiteSupervisorList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => {
      const companies = JSON.parse(JSON.stringify(state.siteSupervisorList));
      companies.push(...action.payload);

      return {
        ...state,
        siteSupervisorList: companies,
      };
    },
    setSelectedSiteSupervisorId: (
      state: State,
      action: PayloadAction<string | undefined>
    ) => ({
      ...state,
      selectedSiteSupervisorId: action.payload,
    }),
    setIsEditingCredentials: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      isEditingCredentials: action.payload,
    }),
    setUnassignedSiteSupervisorListHasNextPage: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      unassignedSiteSupervisorListHasNextPage: action.payload,
    }),
    setIsFetchingUnassignedSiteSupervisorList: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      isFetchingUnassignedSiteSupervisorList: action.payload,
    }),
    setUnassignedSiteSupervisorList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => ({
      ...state,
      unassignedSiteSupervisorList: action.payload,
    }),
    appendToUnassignedSiteSupervisorList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => {
      const companies = JSON.parse(
        JSON.stringify(state.unassignedSiteSupervisorList)
      );
      companies.push(...action.payload);

      return {
        ...state,
        unassignedSiteSupervisorList: companies,
      };
    },
  },
});

export default Reducer;
