import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import siteRedux from "../../redux";
import { AppDispatch } from "../../../../appStore";
import { SpListItem } from "../../../../common";
import InfiniteScroll from "react-infinite-scroll-component";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { BORDER_RADIUS, SCROLL_PANEL_WIDTH } from "../../../../appTheme";
import { customDebounce } from "../../../../services/helpers";
import { putAssignSiteSupervisor } from "../../redux/apiCalls";

const scrollPanelHeight = "calc(100vh - 306px)";

const useStyles = makeStyles<Theme>((theme) => ({
  footerContainer: {
    height: "inherit",
  },
  detailPanel: {
    width: `calc(100% - ${SCROLL_PANEL_WIDTH}px -  ${theme.spacing(2)}px)`,
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: BORDER_RADIUS.NORMAL,
  },
  scrollPanel: {
    width: 500,
  },
  mainDetailBody: {
    height: `calc(100vh - 251px)`,
    overflowY: "auto",
    overflowX: "hidden",
    display: "block",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

type NewSiteSupervisorDialogProps = {
  refetchSiteSupervisorList: () => void;
};

const NewSiteSupervisorDialog: FC<NewSiteSupervisorDialogProps> = ({
  refetchSiteSupervisorList,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const pageSize: number = 50;
  const pageIndex = useRef<number>(0);
  const unassignedSiteSupervisorListHasNextPage = useSelector(
    siteRedux.selectors.getUnassignedSiteSupervisorListHasNextPage
  );
  const unassignedSiteSupervisorList = useSelector(
    siteRedux.selectors.getUnassignedSiteSupervisorList
  );

  const isNewSiteSupervisorMode = useSelector(
    siteRedux.selectors.getIsNewSiteSupervisorMode
  );
  const selectedSiteId = useSelector(siteRedux.selectors.getSelectedSiteId);

  const debouncedCall = useMemo(
    () =>
      customDebounce(
        (
          siteId: string,
          incrementedPageIndex: number,
          isScrollMode: boolean
        ) => {
          dispatch(
            siteRedux.dispatchActions.fetchUnassignedSiteSupervisorList(
              {
                Pagination: {
                  PageIndex: incrementedPageIndex,
                  PageSize: pageSize,
                },
              },
              siteId,
              isScrollMode
            )
          )
            .then(() => {
              pageIndex.current = incrementedPageIndex;
            })
            .catch(() => {
              pageIndex.current = 0;
            });
        },
        800
      ),
    [dispatch]
  );

  const fetchNextData = useCallback(
    (isScrollMode: boolean = false) => {
      if (unassignedSiteSupervisorListHasNextPage) {
        const incrementedPageIndex = pageIndex.current + 1;
        debouncedCall(selectedSiteId, incrementedPageIndex, isScrollMode);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedCall, selectedSiteId]
  );

  const resetPagination = useCallback(() => {
    dispatch(
      siteRedux.actions.setUnassignedSiteSupervisorListHasNextPage(true)
    );
    dispatch(siteRedux.actions.setUnassignedSiteSupervisorList([]));
    pageIndex.current = 0;
  }, [dispatch]);

  const refetchUnassignedSiteSupervisorList = useCallback(() => {
    resetPagination();
    fetchNextData();
  }, [fetchNextData, resetPagination]);

  useEffect(() => {
    refetchUnassignedSiteSupervisorList();
  }, [refetchUnassignedSiteSupervisorList]);

  useEffect(() => {
    return () => {
      dispatch(siteRedux.dispatchActions.resetNewSiteSupervisor());
    };
  }, [dispatch]);

  const handleOnClose = () => {
    dispatch(siteRedux.actions.setIsNewSiteSupervisorMode(false));
  };
  const handleOnSelect = (userId: string) => {
    if (selectedSiteId) {
      dispatch(
        putAssignSiteSupervisor({ siteId: selectedSiteId, userId })
      ).then(() => {
        refetchSiteSupervisorList();
        handleOnClose();
      });
    }
  };

  return (
    <Dialog
      onClose={() => null}
      open={isNewSiteSupervisorMode}
      maxWidth={false}
    >
      <DialogTitle>Add Manager</DialogTitle>
      <DialogContent>
        <InfiniteScroll
          dataLength={unassignedSiteSupervisorList.length} //This is important field to render the next data
          next={() => fetchNextData(true)}
          hasMore={unassignedSiteSupervisorListHasNextPage}
          loader={
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.footerContainer}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          }
          endMessage={
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ textAlign: "center" }}
            >
              No more data
            </Typography>
          }
          height={scrollPanelHeight}
          className={classes.scrollPanel}
        >
          {unassignedSiteSupervisorList.map((item, index) => (
            <SpListItem
              key={index}
              title={item.displayName}
              onClick={() => handleOnSelect(item.codeId)}
              inactiveItem={!item.isActive}
              icon={<Avatar className={classes.avatar}>SS</Avatar>}
            />
          ))}
        </InfiniteScroll>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleOnClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(NewSiteSupervisorDialog);
