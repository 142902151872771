import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COMMON } from "./constants";
import { initialState, State, UpdateTreeStructureData } from "./model";
import { NotificationType } from "./apiCalls";
import {
  CounterResponseItem,
  CounterResponseModel,
} from "../../../services/apiServiceTypes";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";

const authReducer = createSlice({
  name: COMMON.ROOT_REDUCER,
  initialState,
  reducers: {
    setHasNextPage: (state: State, action: PayloadAction<boolean>) => {
      return {
        ...state,
        hasNextPage: action.payload,
      };
    },
    setIsFetching: (state: State, action: PayloadAction<boolean>) => ({
      ...state,
      isFetching: action.payload,
    }),
    setNotificationList: (
      state: State,
      action: PayloadAction<Array<NotificationType>>
    ) => ({
      ...state,
      notificationList: action.payload,
    }),
    appendToNotificationList: (
      state: State,
      action: PayloadAction<Array<NotificationType>>
    ) => {
      const notifications = JSON.parse(JSON.stringify(state.notificationList));
      notifications.push(...action.payload);

      return {
        ...state,
        notificationList: notifications,
      };
    },
    setSitesList: (
      state: State,
      action: PayloadAction<CounterResponseModel>
    ) => ({
      ...state,
      sitesList: action.payload,
    }),
    setSelectedSiteId: (
      state: State,
      action: PayloadAction<number | undefined>
    ) => ({
      ...state,
      selectedSiteId: action.payload,
    }),
    setEntrancesList: (
      state: State,
      action: PayloadAction<CounterResponseModel>
    ) => ({
      ...state,
      entrancesList: action.payload,
    }),
    setSelectedEntranceId: (
      state: State,
      action: PayloadAction<number | undefined>
    ) => ({
      ...state,
      selectedEntranceId: action.payload,
    }),
    setSendToSites: (
      state: State,
      action: PayloadAction<Array<TreeStructureData>>
    ) => ({
      ...state,
      sendToSites: action.payload,
    }),
    setSendToSitesItem: (
      state: State,
      action: PayloadAction<UpdateTreeStructureData>
    ) => {
      const newState = JSON.parse(JSON.stringify(state.sendToSites));
      newState[action.payload.index] = action.payload.data;

      return { ...state, sendToSites: newState };
    },
    setHasNextPageOfSendToContractors: (
      state: State,
      action: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        hasNextPageOfSendToContractors: action.payload,
      };
    },
    setIsFetchingSendToContractors: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      isFetchingSendToContractors: action.payload,
    }),
    setSendToContractors: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => ({
      ...state,
      sendToContractors: action.payload,
    }),
    appendToSendToContractors: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => {
      const notifications = JSON.parse(JSON.stringify(state.sendToContractors));
      notifications.push(...action.payload);

      return {
        ...state,
        sendToContractors: notifications,
      };
    },
  },
});

export default authReducer;
