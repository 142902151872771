import { useCallback, useContext, useEffect, useRef } from "react";
import { isNil } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../appStore";
import eventLogRedux from "../../admin/eventLog/redux";
import { SignalRContext, SignalRType } from "./SignalRProvider";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import authReducer from "../../auth/redux";
import commonReducer from "../../common/redux";
import {
  BatteryLevel,
  EntranceEventThumbnailViewModel,
} from "../../admin/eventLog/redux/apiCalls";

export const useSignalR = (
  refetchAction: (
    eventEntranceId: string,
    entranceEvent?: EntranceEventThumbnailViewModel,
    skipBatteryCheck?: boolean
  ) => void,
  _panelId: number,
  _entranceId?: string
) => {
  const dispatch = useDispatch<AppDispatch>();
  const previousEntranceId = useRef<string>();
  const accessTokenRedux = useSelector(
    authReducer.selectors.getUserAccessToken
  );
  const signalR = useContext(SignalRContext);
  const signalRref = useRef<SignalRType | undefined>();

  const invokeEvents = useCallback(
    async (connection: HubConnection, entrID: string) => {
      try {
        await connection.invoke("MonitorEntrance", parseInt(entrID));
      } catch (err: any) {
        dispatch(commonReducer.actions.setSnackBarMessage(err.toString()));
        return console.error(err.toString());
      }

      try {
        await connection.invoke("HelpRequestGroup");
      } catch (err: any) {
        dispatch(commonReducer.actions.setSnackBarMessage(err.toString()));
        return console.error(err.toString());
      }
      try {
        await connection.invoke("BeaconScannerRequest", parseInt(entrID));
      } catch (err: any) {
        dispatch(commonReducer.actions.setSnackBarMessage(err.toString()));
        return console.error(err.toString());
      }
    },
    [dispatch]
  );

  const stopMonitoringEntrance = useCallback(
    async (connection: HubConnection, entrID: string, panelId: number) => {
      signalRref.current?.panelsPool[entrID].splice(
        signalRref.current?.panelsPool[entrID].findIndex(
          (panel) => panel.id === panelId
        ),
        1
      );
      signalR?.setPanelsPool({ ...signalRref.current?.panelsPool });

      if (signalRref.current?.panelsPool[entrID].length === 0) {
        console.log(`Stop monitoring entrance ${entrID}`);

        try {
          await connection.invoke("StopMonitoringEntrance", parseInt(entrID));
        } catch (err: any) {
          dispatch(commonReducer.actions.setSnackBarMessage(err.toString()));
          return console.error(err.toString());
        }
        try {
          await connection.invoke("StopBeaconScannerRequest", parseInt(entrID));
        } catch (err: any) {
          dispatch(commonReducer.actions.setSnackBarMessage(err.toString()));
          return console.error(err.toString());
        }
      }
    },
    [dispatch, signalR]
  );

  const createNewConnection = useCallback(
    (token: string) => {
      if (signalR) {
        signalR.connection?.stop();
        signalR.setConnection(
          new HubConnectionBuilder()
            .withUrl("/eventHub", {
              accessTokenFactory: () => token,
              headers: { Authorization: `Bearer ${token}` },
            })
            .build()
        );
      }
    },
    [signalR]
  );

  const onEntranceEvent = useCallback(
    (
      entranceId: number,
      eventId: number,
      playAlarm: boolean,
      eventData: string,
      inner?: BatteryLevel,
      outer?: BatteryLevel
    ) => {
      const entranceEvent = JSON.parse(
        eventData || "{}"
      ) as EntranceEventThumbnailViewModel;
      if (signalR && signalR.panelsPool[entranceId] !== undefined) {
        signalR.panelsPool[entranceId].forEach((panel) => {
          dispatch(
            eventLogRedux.dispatchActions.onEntranceEventFunc(
              entranceId?.toString(),
              eventId?.toString(),
              playAlarm,
              entranceEvent,
              panel.id,
              inner,
              outer
            )
          );
          panel.refetchAction(
            entranceId?.toString(),
            entranceEvent,
            !isNil(inner) && !isNil(outer)
          );
        });
      }
    },
    [dispatch, signalR]
  );

  const onHelpRequest = useCallback(
    (entranceId: number, eventId: number, playAlarm: boolean) => {
      dispatch(
        eventLogRedux.dispatchActions.onHelpRequestFunc(
          entranceId?.toString(),
          eventId?.toString(),
          playAlarm
        )
      );
      refetchAction(entranceId?.toString());
    },
    [dispatch, refetchAction]
  );

  const onScannerEvent = useCallback(
    (entranceId: string) => {
      if (signalR && signalR.panelsPool[entranceId] !== undefined) {
        signalR.panelsPool[entranceId].forEach((panel) => {
          dispatch(
            eventLogRedux.dispatchActions.onScannerEventFunc(
              entranceId,
              panel.id
            )
          );
        });
      }
    },
    [dispatch, signalR]
  );

  const startSignalRCommunication = useCallback(
    (entrID: string, panelId: number) => {
      if (
        signalR &&
        signalR.connection &&
        signalR.connection.state === HubConnectionState.Connected
      ) {
        const { connection } = signalR;
        connection?.off("MonitorEntrance");
        connection?.off("HelpRequestGroup");
        connection?.off("BeaconScannerRequest");

        connection?.off("EntranceEvent");
        connection?.off("HelpRequest");
        connection?.off("ScannerEvent");

        connection.on("EntranceEvent", onEntranceEvent);
        connection.on("HelpRequest", onHelpRequest);
        connection.on("ScannerEvent", onScannerEvent);

        invokeEvents(connection, entrID);
        refetchAction(entrID);
      } else if (
        signalR &&
        signalR.connection &&
        signalR.connection.state === HubConnectionState.Disconnected
      ) {
        const { connection } = signalR;
        connection.serverTimeoutInMilliseconds = 60 * 1000; // 60 seconds timeout
        connection?.off("EntranceEvent");
        connection?.off("HelpRequest");
        connection?.off("ScannerEvent");

        connection.on("EntranceEvent", onEntranceEvent);
        connection.on("HelpRequest", onHelpRequest);
        connection.on("ScannerEvent", onScannerEvent);

        connection
          .start()
          .then(() => {
            invokeEvents(connection, entrID);
            refetchAction(entrID);
          })
          .catch(() => {
            if (!isNil(accessTokenRedux)) {
              createNewConnection(accessTokenRedux);
            } else {
              dispatch(
                commonReducer.actions.setSnackBarMessage(
                  "Something went wrong with Event Log connection"
                )
              );
            }
          });
      }

      // add information about entrance subscription of panel to pool
      if (signalR?.panelsPool[entrID] !== undefined) {
        signalR?.panelsPool[entrID].push({
          id: panelId,
          refetchAction: refetchAction,
        });
        signalR?.setPanelsPool({ ...signalR?.panelsPool });
      } else {
        if (signalR) {
          signalR.panelsPool[entrID] = [
            { id: panelId, refetchAction: refetchAction },
          ];
          signalR?.setPanelsPool({ ...signalR?.panelsPool });
        }
      }
    },
    [
      signalR,
      onEntranceEvent,
      onHelpRequest,
      onScannerEvent,
      invokeEvents,
      refetchAction,
      accessTokenRedux,
      createNewConnection,
      dispatch,
    ]
  );

  const registerSignalR = useCallback(
    (token: string) => {
      if (signalR && !signalR.connection) {
        createNewConnection(token);
      }
    },
    [createNewConnection, signalR]
  );

  useEffect(() => {
    if (!isNil(accessTokenRedux)) {
      registerSignalR(accessTokenRedux);
    }
  }, [accessTokenRedux, registerSignalR]);

  // when unmounting stop monitoring of entrance selected on panel
  useEffect(() => {
    return () => {
      if (!isNil(previousEntranceId.current)) {
        stopMonitoringEntrance(
          signalRref.current!.connection!,
          previousEntranceId.current!,
          _panelId
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNil(_entranceId) && previousEntranceId.current !== _entranceId) {
      console.log(`Entrance Id changed to ${_entranceId}`);
      if (previousEntranceId.current && signalR?.connection) {
        stopMonitoringEntrance(
          signalR.connection,
          previousEntranceId.current,
          _panelId
        );
      }
      previousEntranceId.current = _entranceId;
      signalRref.current = signalR;
      startSignalRCommunication(_entranceId, _panelId);
      console.log("Actual panels pool:");
      console.log(signalR?.panelsPool);
    }
  }, [
    _entranceId,
    _panelId,
    signalR,
    signalR?.connection,
    startSignalRCommunication,
    stopMonitoringEntrance,
  ]);
};
