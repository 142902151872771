import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { SpIcon } from "../icons";
import cx from "classnames";

const useStyles = makeStyles<Theme>((theme) => ({
  icon: {
    height: 32.372,
    width: 32.372,
    cursor: "pointer",
    paddingRight: 0,
  },
}));

const SpIconButton: React.FC<SpIconButtonProps> = ({
  onClick,
  iconName,
  className,
}) => {
  const classes = useStyles();

  return (
    <SpIcon
      iconName={iconName}
      onClick={onClick}
      className={cx(classes.icon, className)}
    />
  );
};

export default SpIconButton;

export interface SpIconButtonProps {
  onClick?: () => void;
  iconName: string;
  className?: any;
}
