import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Grid, MenuItem } from "@material-ui/core";
import { FormControlWrapper, SpTextField } from "../../common";
import { Controller } from "react-hook-form";
import { Control, UnpackNestedValue } from "react-hook-form/dist/types/form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import {
  DeepPartial,
  LiteralToPrimitive,
} from "react-hook-form/dist/types/utils";
import {
  FieldElement,
  FieldName,
  Ref,
} from "react-hook-form/dist/types/fields";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { CounterResponseModel } from "../../services/apiServiceTypes";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(4.3),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
}));

export interface FormsInputs {
  site: string;
  contractor: string;
  siteSupervisor: string;
  form: string;
}

export const FormsSchema = yup.object().shape({
  site: yup.string().required("Please select Location"),
  contractor: yup.string().required("Please select User"),
  siteSupervisor: yup.string().required("Please select Manager"),
  form: yup.string().required("Please select Form"),
});

interface FormsProps<TFieldValues> {
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  watch(): UnpackNestedValue<TFieldValues>;
  watch<TFieldName extends string, TFieldValue>(
    name?: TFieldName,
    defaultValue?: TFieldName extends keyof TFieldValues
      ? UnpackNestedValue<TFieldValues[TFieldName]>
      : UnpackNestedValue<LiteralToPrimitive<TFieldValue>>
  ): TFieldName extends keyof TFieldValues
    ? UnpackNestedValue<TFieldValues[TFieldName]>
    : UnpackNestedValue<LiteralToPrimitive<TFieldValue>>;
  watch<TFieldName extends keyof TFieldValues>(
    names: TFieldName[],
    defaultValues?: UnpackNestedValue<
      DeepPartial<Pick<TFieldValues, TFieldName>>
    >
  ): UnpackNestedValue<Pick<TFieldValues, TFieldName>>;
  watch(
    names: string[],
    defaultValues?: UnpackNestedValue<DeepPartial<TFieldValues>>
  ): UnpackNestedValue<DeepPartial<TFieldValues>>;

  register<TFieldElement extends FieldElement<TFieldValues>>(
    rules?: RegisterOptions
  ): (ref: (TFieldElement & Ref) | null) => void;
  register(name: FieldName<TFieldValues>, rules?: RegisterOptions): void;
  register<TFieldElement extends FieldElement<TFieldValues>>(
    ref: (TFieldElement & Ref) | null,
    rules?: RegisterOptions
  ): void;
  siteCounterData?: CounterResponseModel;
  contractorCounterData?: CounterResponseModel;
  siteSupervisorCounterData?: CounterResponseModel;
  formCounterData?: CounterResponseModel;
  isSubmitted: boolean;
}

const FormsHeader = forwardRef<HTMLDivElement | null, FormsProps<FormsInputs>>(
  (
    {
      control,
      errors,
      watch,
      register,
      siteCounterData,
      contractorCounterData,
      siteSupervisorCounterData,
      formCounterData,
      isSubmitted,
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <div className={classes.header} ref={ref}>
        <Grid container direction="row" alignItems="flex-start" spacing={2}>
          <FormControlWrapper
            title="Location"
            input={
              <Controller
                control={control}
                name="site"
                defaultValue={""}
                as={
                  <SpTextField select errors={errors}>
                    {(!siteCounterData || siteCounterData.length === 0) && (
                      <MenuItem value={""}>No Data Available</MenuItem>
                    )}
                    {siteCounterData?.map((company) => (
                      <MenuItem key={company.codeId} value={company.codeId}>
                        {company.displayName}
                      </MenuItem>
                    ))}
                  </SpTextField>
                }
              />
            }
          />
          <FormControlWrapper
            title="User"
            input={
              <Controller
                control={control}
                name="contractor"
                defaultValue={""}
                as={
                  <SpTextField select errors={errors}>
                    {(!contractorCounterData ||
                      contractorCounterData.length === 0) && (
                      <MenuItem value={""}>No Data Available</MenuItem>
                    )}
                    {contractorCounterData?.map((company) => (
                      <MenuItem key={company.codeId} value={company.codeId}>
                        {company.displayName}
                      </MenuItem>
                    ))}
                  </SpTextField>
                }
              />
            }
          />
        </Grid>
        <Grid container direction="row" alignItems="flex-start" spacing={2}>
          <FormControlWrapper
            title="Manager"
            input={
              <Controller
                control={control}
                name="siteSupervisor"
                defaultValue={""}
                as={
                  <SpTextField select errors={errors}>
                    {(!siteSupervisorCounterData ||
                      siteSupervisorCounterData.length === 0) && (
                      <MenuItem value={""}>No Data Available</MenuItem>
                    )}
                    {siteSupervisorCounterData?.map((company) => (
                      <MenuItem key={company.codeId} value={company.codeId}>
                        {company.displayName}
                      </MenuItem>
                    ))}
                  </SpTextField>
                }
              />
            }
          />
          <FormControlWrapper
            title="Form"
            input={
              <Controller
                control={control}
                name="form"
                defaultValue={""}
                as={
                  <SpTextField select errors={errors}>
                    {(!formCounterData || formCounterData.length === 0) && (
                      <MenuItem value={""}>No Data Available</MenuItem>
                    )}
                    {formCounterData?.map((company) => (
                      <MenuItem key={company.codeId} value={company.codeId}>
                        {company.displayName}
                      </MenuItem>
                    ))}
                  </SpTextField>
                }
              />
            }
          />
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submitButton}
              fullWidth
            >
              New Form
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </div>
    );
  }
);

export default FormsHeader;
