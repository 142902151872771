import { createSelector } from "@reduxjs/toolkit";
import { State } from "./model";
import { COMMON } from "./constants";

const selectSelf = (state: { [x: string]: State }) =>
  state[COMMON.ROOT_REDUCER];

const getNotificationList = createSelector(
  selectSelf,
  (state: State) => state.notificationList
);

const getHasNextPage = createSelector(
  selectSelf,
  (state: State) => state.hasNextPage
);

const getIsFetching = createSelector(
  selectSelf,
  (state: State) => state.isFetching
);

const getSitesList = createSelector(
  selectSelf,
  (state: State) => state.sitesList
);

const getSelectedSiteId = createSelector(
  selectSelf,
  (state: State) => state.selectedSiteId
);

const getEntrancesList = createSelector(
  selectSelf,
  (state: State) => state.entrancesList
);

const getSelectedEntranceId = createSelector(
  selectSelf,
  (state: State) => state.selectedEntranceId
);

const getSendToSitesList = createSelector(
  selectSelf,
  (state: State) => state.sendToSites
);

const getSendToContractorsList = createSelector(
  selectSelf,
  (state: State) => state.sendToContractors
);

const getHasNextPageOfSendToContractors = createSelector(
  selectSelf,
  (state: State) => state.hasNextPageOfSendToContractors
);

const getIsFetchingSendToContractors = createSelector(
  selectSelf,
  (state: State) => state.isFetchingSendToContractors
);

const allSelectors = {
  getNotificationList,
  getHasNextPage,
  getIsFetching,
  getSitesList,
  getSelectedSiteId,
  getEntrancesList,
  getSelectedEntranceId,
  getSendToSitesList,
  getSendToContractorsList,
  getHasNextPageOfSendToContractors,
  getIsFetchingSendToContractors,
};

export default allSelectors;
