import React, { ReactNode } from "react";
import { Grid, GridSize, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cx from "classnames";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
  },
}));

const FormControlWrapper: React.FC<FormControlWrapperProps> = ({
  title = "",
  input,
  className,
  fullWidth,
  width,
}) => {
  const classes = useStyles();
  const size: GridSize = !!width ? width : fullWidth ? 12 : 5;
  return (
    <Grid item lg={size} xs={12}>
      <div className={cx(className)}>
        <Typography variant={"h4"}>{title}</Typography>
        <div className={classes.input}>{input}</div>
      </div>
    </Grid>
  );
};

export default FormControlWrapper;

export interface FormControlWrapperProps {
  title?: string | null;
  input: ReactNode;
  className?: any;
  fullWidth?: boolean;
  width?: GridSize | undefined;
}
