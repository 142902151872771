import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COMMON } from "./constants";
import { initialState, State } from "./model";
import { CounterResponseItem } from "../../../services/apiServiceTypes";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";
import { UpdateTreeStructureData } from "../../messages/redux/model";

const authReducer = createSlice({
  name: COMMON.ROOT_REDUCER,
  initialState,
  reducers: {
    setCompanyListHasNextPage: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      companyListHasNextPage: action.payload,
    }),
    setIsFetchingCompanyList: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      isFetchingCompanyList: action.payload,
    }),
    setCompanyList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => ({
      ...state,
      companyList: action.payload,
    }),
    appendToCompanyList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => {
      const companies = JSON.parse(JSON.stringify(state.companyList));
      companies.push(...action.payload);

      return {
        ...state,
        companyList: companies,
      };
    },
    setNewCompanyLogo: (
      state: State,
      action: PayloadAction<string | undefined>
    ) => ({
      ...state,
      newCompanyLogo: action.payload,
    }),
    setAllowedSecondaryCompanies: (
      state: State,
      action: PayloadAction<Array<TreeStructureData>>
    ) => ({
      ...state,
      allowedSecondaryCompanies: action.payload,
    }),
    updateAllowedSecondaryCompanies: (
      state: State,
      action: PayloadAction<UpdateTreeStructureData>
    ) => {
      const newState = JSON.parse(
        JSON.stringify(state.allowedSecondaryCompanies)
      );
      newState[action.payload.index] = action.payload.data;
      return { ...state, allowedSecondaryCompanies: newState };
    },
  },
});

export default authReducer;
