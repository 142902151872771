import React, { FC, useMemo, useState } from "react";
import {
  DetailPanelView,
  SpTextField,
  FormControlWrapper,
  FormSection,
} from "../../../common";
import { Box, Button, Grid, MenuItem, Typography } from "@material-ui/core";
import { useFetchData } from "../../../services/useFetchData";
import { SiteDetailModel } from "../../forms/redux/apiCalls";
import { getCompanyList, putDownloadClubMembers } from "../redux/apiCalls";
import { CounterResponseModel } from "../../../services/apiServiceTypes";
import * as yup from "yup";
import { FieldName } from "react-hook-form/dist/types/fields";
import { SetFieldValue, SetValueConfig } from "react-hook-form/dist/types/form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { Controller, useForm } from "react-hook-form";
import { useAuthorization } from "../../../auth";
import { ROLES, USER_PERMISSIONS } from "../../../auth/constants";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../appStore";
import siteRedux from "../redux";
import { isEmpty, isNil } from "ramda";
import { FRONTEND_DATE_FORMAT_REPORTS } from "../../../services/helpers";
import moment from "moment";
import { phoneNumberMessage, phoneNumberRegex } from "../../../validators";
import {
  CERTIFICATE_EXPIRATION_THRESHOLD_TYPES,
  THRESHOLD_TYPE,
} from "../../../counters";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

export interface SiteInfoInputs {
  siteName: string;
  company: string;
  siteOwnerName: string;
  siteOwnerContactNumber: string;
  siteOwnerContactEmail: string;
  protectionAdvisoryManagerName: string;
  protectionAdvisoryManagerContactNumber: string;
  protectionAdvisoryManagerEmail: string;
  clubId: string;
  abcFinancialApiKey: string;
  certificationExpirationThreshold?: string;
  hardwareApiKey: string;
}

const useStyles = makeStyles((theme) => ({
  actionButton: {
    marginBottom: theme.spacing(2.2),
  },
}));

export const SiteInfoSchema = yup.object().shape({
  siteName: yup
    .string()
    .max(30, "Site Name exceeds 30 character limit.")
    .required("Site Name is required."),
  company: yup.string().required("Company is required."),
  siteOwnerName: yup
    .string()
    .max(50, "Name exceeds 50 character limit.")
    .required("Name is required."),
  siteOwnerContactNumber: yup
    .string()
    .matches(phoneNumberRegex, phoneNumberMessage)
    .required("Contact Number is required."),
  siteOwnerContactEmail: yup
    .string()
    .email("Not valid Email form")
    .required("Email is required.")
    .max(80, "Email exceeds 80 character limit."),
  protectionAdvisoryManagerName: yup
    .string()
    .max(50, "Name exceeds 50 character limit.")
    .required("Name is required."),
  protectionAdvisoryManagerContactNumber: yup
    .string()
    .matches(phoneNumberRegex, phoneNumberMessage)
    .required("Contact Number is required."),
  protectionAdvisoryManagerEmail: yup
    .string()
    .email("Not valid Email form")
    .required("Email is required.")
    .max(80, "Email exceeds 80 character limit."),
  clubId: yup.string(),
  abcFinancialApiKey: yup.string(),
  certificationExpirationThreshold: yup.string(),
  hardwareApiKey: yup.string(),
});

export const prefillSiteInfoInputs = (
  setValue: (
    name: FieldName<SiteInfoInputs>,
    value: SetFieldValue<SiteInfoInputs>,
    config?: SetValueConfig
  ) => void,
  data: SiteDetailModel
) => {
  setValue("siteName", data?.siteName || "");
  setValue("company", (data?.company?.id || "").toString());
  setValue("siteOwnerName", data?.siteOwnerName || "");
  setValue("siteOwnerContactNumber", data?.siteOwnerContactNumber || "");
  setValue("siteOwnerContactEmail", data?.siteOwnerContactEmail || "");
  setValue(
    "protectionAdvisoryManagerName",
    data?.protectionAdvisoryManagerName || ""
  );
  setValue(
    "protectionAdvisoryManagerContactNumber",
    data?.protectionAdvisoryManagerContactNumber || ""
  );
  setValue(
    "protectionAdvisoryManagerEmail",
    data?.protectionAdvisoryManagerEmail || ""
  );
  setValue("clubId", data?.clubId || "");
  setValue("abcFinancialApiKey", data?.abcFinancialApiKey || "");
  setValue(
    "certificationExpirationThreshold",
    data?.certificationExpirationThreshold || ""
  );

  setValue("hardwareApiKey", data.hardwareApiKey || "");
};

type SiteInfoTabProps = {
  siteDetail?: SiteDetailModel;
  refetchSiteDetail?: () => void;
  setInboundApiKey?: (apiKey: string) => void;
  register: any;
  control: any;
  errors: FieldErrors<SiteInfoInputs>;
  alertMessage?: string;
  newSiteMode: boolean;
};

const SiteInfoTab: FC<SiteInfoTabProps> = ({
  siteDetail,
  refetchSiteDetail,
  setInboundApiKey,
  errors,
  register,
  control,
  alertMessage,
  newSiteMode,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles();
  const { setValue } = useForm();

  const [newSiteApiKey, setNewSiteApiKey] = useState<string>("");

  const [isDownloadingClubMembers, setIsDownloadingClubMembers] =
    React.useState<boolean>(false);

  const { isAllowed: canChangeStatus } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_CHANGE_STATUS]
  );

  const { isAllowed: canDownloadClubMembers } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_DOWNLOAD_CLUB_MEMBERS]
  );

  const { isAllowed: canEditInfo } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_EDIT_INFO]
  );

  const { isAllowed: canAddSite } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_ADD]
  );

  const { isAllowed: canChangeInboundApiKey } = useAuthorization(
    [ROLES.ADMIN, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_EDIT_INBOUND_API_KEY]
  );

  const { data: companyList } = useFetchData<CounterResponseModel>(
    getCompanyList(),
    true,
    undefined,
    []
  );

  const handleClickOnChangeStatus = () => {
    if (siteDetail) {
      dispatch(siteRedux.dispatchActions.onChangeStatus(siteDetail)).finally(
        () => {
          if (refetchSiteDetail) {
            refetchSiteDetail();
          }
        }
      );
    }
  };

  const downloadClubMembers = () => {
    if (siteDetail?.siteId) {
      setIsDownloadingClubMembers(true);
      dispatch(putDownloadClubMembers(siteDetail?.siteId)).finally(() => {
        if (refetchSiteDetail) {
          refetchSiteDetail();
        }
        setIsDownloadingClubMembers(false);
      });
    }
  };

  const generateInboundApiKey = () => {
    const validCharacters =
      "0123456789" +
      "abcdefghijklmnopqrstuvwxyz" +
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    const length = 64;
    let apiKey = "";
    for (let i = 0; i < length; i++) {
      const random = window.crypto.getRandomValues(new Uint8Array(1))[0];
      const index = Math.floor((random / 256) * validCharacters.length);
      apiKey += validCharacters[index];
      }

    setValue("hardwareApiKey", apiKey);
    if(setInboundApiKey !== undefined){
        setInboundApiKey(apiKey);
    }
    if(newSiteMode){
        setNewSiteApiKey(apiKey);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const disabledFields = useMemo(
    () => !((canEditInfo && !newSiteMode) || (canAddSite && newSiteMode)),
    [canEditInfo, newSiteMode, canAddSite]
  );

  return (
    <DetailPanelView title="Location" showSaveButton={!disabledFields}>
      <FormSection title="Location Info" inline={true}>
        <FormControlWrapper
          title="Location Name"
          input={
            <SpTextField
              name="siteName"
              inputRef={register}
              errors={errors}
              fullWidth
              disabled={disabledFields}
            />
          }
        />
        <FormControlWrapper
          title="Client"
          input={
            <Controller
              control={control}
              name="company"
              defaultValue={""}
              as={
                <SpTextField
                  select
                  name="company"
                  errors={errors}
                  disabled={disabledFields}
                  fullWidth
                >
                  {!companyList && <MenuItem value={""}>No data</MenuItem>}
                  {companyList?.map((company) => (
                    <MenuItem key={company.codeId} value={company.codeId}>
                      {company.displayName}
                    </MenuItem>
                  ))}
                </SpTextField>
              }
            />
          }
        />
      </FormSection>

      <FormSection title="Location Owner" inline={true}>
        <FormControlWrapper
          title="Name"
          input={
            <SpTextField
              name="siteOwnerName"
              inputRef={register}
              errors={errors}
              disabled={disabledFields}
              fullWidth
            />
          }
        />
        <FormControlWrapper
          title="Contact number"
          input={
            <SpTextField
              name="siteOwnerContactNumber"
              inputRef={register}
              errors={errors}
              disabled={disabledFields}
              fullWidth
            />
          }
        />
        <FormControlWrapper
          title="Email"
          input={
            <SpTextField
              name="siteOwnerContactEmail"
              inputRef={register}
              errors={errors}
              disabled={disabledFields}
              fullWidth
            />
          }
        />
      </FormSection>

      <FormSection title="Protection Advisory Manager" inline={true}>
        <FormControlWrapper
          title="Name"
          input={
            <SpTextField
              name="protectionAdvisoryManagerName"
              inputRef={register}
              errors={errors}
              disabled={disabledFields}
              fullWidth
            />
          }
        />

        <FormControlWrapper
          title="Contact number"
          input={
            <SpTextField
              name="protectionAdvisoryManagerContactNumber"
              inputRef={register}
              errors={errors}
              disabled={disabledFields}
              fullWidth
            />
          }
        />

        <FormControlWrapper
          title="Email"
          input={
            <SpTextField
              name="protectionAdvisoryManagerEmail"
              inputRef={register}
              errors={errors}
              disabled={disabledFields}
              fullWidth
            />
          }
        />
      </FormSection>

      <FormSection title="Qualification Notification" inline={true}>
        <FormControlWrapper
          title="Threshold"
          input={
            <Controller
              control={control}
              name="certificationExpirationThreshold"
              defaultValue={THRESHOLD_TYPE["7_DAYS"]}
              disabled={disabledFields}
              as={
                <SpTextField select errors={errors} fullWidth>
                  {CERTIFICATE_EXPIRATION_THRESHOLD_TYPES?.map((company) => (
                    <MenuItem key={company.codeId} value={company.codeId}>
                      {company.displayName}
                    </MenuItem>
                  ))}
                </SpTextField>
              }
            />
          }
        />
      </FormSection>

      <FormSection title="ABC Financial API Information" inline={true}>
        <FormControlWrapper
          title="Club ID"
          input={
            <SpTextField
              name="clubId"
              inputRef={register}
              errors={errors}
              disabled={disabledFields}
              fullWidth
            />
          }
        />

        <FormControlWrapper
          title="API key"
          input={
            <SpTextField
              name="abcFinancialApiKey"
              inputRef={register}
              errors={errors}
              disabled={disabledFields}
              fullWidth
            />
          }
        />
      </FormSection>

      {canChangeInboundApiKey ? (
        <FormSection title="Hardware API Information">
          <Grid container direction="row" alignItems="flex-end" spacing={2}>
            <Grid item xs={10}>
                {newSiteMode &&
                    <FormControlWrapper
                        title="Hardware API Key"
                        fullWidth
                        input={
                            <SpTextField
                                name="hardwareApiKey"
                                inputRef={register}
                                errors={errors}
                                disabled={disabledFields}
                                value={newSiteApiKey}
                                fullWidth
                            />
                        }
                    />
                }
                {!newSiteMode &&
                    <FormControlWrapper
                        title="Hardware API Key"
                        fullWidth
                        input={
                            <SpTextField
                                name="hardwareApiKey"
                                inputRef={register}
                                errors={errors}
                                disabled={disabledFields}
                                fullWidth
                            />
                        }
                    />
                }
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                onClick={() => generateInboundApiKey()}
              >
                Generate Key
              </Button>
            </Grid>
          </Grid>
        </FormSection>
      ) : null}

      <Grid item xs={12}>
        {alertMessage && (
          <Alert variant="outlined" severity="error">
            {alertMessage}
          </Alert>
        )}
      </Grid>
      {!disabledFields && !newSiteMode && (
        <FormSection title="Actions">
          <Box display="flex" flexDirection="row" gridGap="10px">
            {canDownloadClubMembers &&
              !isNil(siteDetail?.clubId) &&
              !isEmpty(siteDetail?.clubId) && (
                <Box display="flex" flexDirection="column">
                  <Button
                    variant="contained"
                    onClick={downloadClubMembers}
                    disabled={isDownloadingClubMembers}
                  >
                    Download Club Members
                  </Button>
                  <Typography
                    variant="subtitle1"
                    style={{ textAlign: "center" }}
                  >
                    {!isNil(siteDetail?.lastApiCall)
                      ? `Last download of members: ${moment(
                          siteDetail?.lastApiCall
                        ).format(FRONTEND_DATE_FORMAT_REPORTS)}`
                      : "Members was never downloaded"}
                  </Typography>
                </Box>
              )}
            <Box>
              {siteDetail?.status && canChangeStatus && (
                <Button variant="contained" onClick={handleClickOnChangeStatus}>
                  {siteDetail.status === "Active" ? "Deactivate" : "Activate"}
                </Button>
              )}
            </Box>
          </Box>
        </FormSection>
      )}
    </DetailPanelView>
  );
};

export default SiteInfoTab;
