import React, { FC, useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import eventLogReducer from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "ramda";
import { getBackgroundColorOfHelpRequest, printEventType } from "../helpers";
import { SpTitle } from "../../../common";
import noCamera from "../../../assets/img/21_SP_NO CAMERA-ICON.png";
import { useFetchData } from "../../../services/useFetchData";
import { loadFile } from "../../../services/authApi";
import { AppDispatch } from "../../../appStore";
import { PlayAudio } from "../../../services/PlayAudio";
import { EventType } from "../redux/apiCalls";
import EntranceTimestamps from "../EntranceTimestamps";

const widthOfContent = "35vw";

const useStyles = makeStyles<Theme, { type?: EventType }>((theme) => ({
  photo: {
    width: widthOfContent,
    height: "50vh",
    objectFit: "contain",
  },
  timestamps: {
    width: widthOfContent,
    maxHeight: "20vh",
    overflow: "auto",
  },
  background: ({ type }) => getBackgroundColorOfHelpRequest(type),
}));

type HelpRequestDialogProps = {
  panelId: number;
};

const HelpRequestDialog: FC<HelpRequestDialogProps> = ({ panelId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [photoUrl, setPhotoUrl] = useState<string>();
  const helpRequest = useSelector(eventLogReducer.selectors.getHelpRequest);
  const isOpen = !isNil(helpRequest);
  const classes = useStyles({ type: helpRequest?.data.type });

  useEffect(() => {
    if (helpRequest) {
      // Reset on change in case 1st help request has photo other not
      setPhotoUrl(undefined);
    }
  }, [helpRequest]);

  useFetchData<string>(
    // @ts-ignore
    loadFile(helpRequest?.data.photo),
    !isNil(helpRequest?.data.photo),
    undefined,
    [helpRequest],
    // @ts-ignore
    (response) => setPhotoUrl(response),
    () => setPhotoUrl(undefined),
    () => setPhotoUrl(undefined)
  );

  const handleOnClose = () => {
    dispatch(eventLogReducer.actions.setHelpRequest(undefined));
  };

  return (
    <>
      <PlayAudio
        path={require("../../../assets/sounds/alarm2.mp3")}
        play={helpRequest?.playAlarm}
      />
      <Dialog onClose={() => null} open={isOpen} maxWidth={false}>
        <div className={classes.background}>
          <DialogTitle>
            {helpRequest?.data.type && printEventType(helpRequest?.data.type)}
            {` ${helpRequest?.data.dateTimeString}`}
          </DialogTitle>
          <DialogContent>
            <img
              src={photoUrl || noCamera}
              alt="contractor-portrait"
              className={classes.photo}
            />
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems={"center"}
            >
              <Typography variant="h3">{`${
                (helpRequest?.data.firstName && helpRequest?.data.firstName) ||
                ""
              }${
                (helpRequest?.data.lastName &&
                  ` ${helpRequest?.data.lastName}`) ||
                ""
              }`}</Typography>
              <Typography variant="subtitle1">
                {helpRequest?.data.companyName}
              </Typography>
            </Grid>
            <SpTitle title="Timestamps" />
            <div className={classes.timestamps}>
              <EntranceTimestamps
                entranceEventViewModels={
                  helpRequest?.data.entranceEventViewModels || []
                }
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleOnClose}>
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default HelpRequestDialog;
