import {
  CounterResponseModel,
  DomainBase,
} from "../../../services/apiServiceTypes";
import { getAuthApi } from "../../../services/authApi";
import { APP_SETTINGS } from "../../../AppSettings";
import { Status } from "../../users/redux/apiCalls";

export const getContractorCounterBySite = <T = CounterResponseModel>(
  siteId: string
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/Forms/Contractors?siteId=${siteId}`
  );

export const getSiteCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/sites?onlyActiveSites=false&companyCanCreateForms=true`
  );

export const getSiteSupervisorCounterBySite = <T = CounterResponseModel>(
  siteId: string
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/Forms/SiteSupervisors?siteId=${siteId}`
  );

export const getFormsCounterBySite = <T = CounterResponseModel>(
  siteId: string
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/Forms/Forms?siteId=${siteId}`
  );

export interface Company extends DomainBase {
  companyName: string;
  // TODO
}

export interface SiteDetailModel extends DomainBase {
  status: Status;
  siteId: string;
  siteName: string;
  company: Company;
  siteOwnerName: string;
  siteOwnerContactNumber: string;
  siteOwnerContactEmail: string;
  protectionAdvisoryManagerName: string;
  protectionAdvisoryManagerContactNumber: string;
  protectionAdvisoryManagerEmail: string;
  clubId: string;
  abcFinancialApiKey: string;
  lastApiCall: string;
  certificationExpirationThreshold: string;
  lastAwxDevicesSyncDate?: string;
  hardwareApiKey?: string;
}

export const getSiteDetail = <T = SiteDetailModel>(siteId: string) =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Site/${siteId}`);

const allActions = {
  getContractorCounterBySite,
  getSiteSupervisorCounterBySite,
  getFormsCounterBySite,
  getSiteDetail,
  getSiteCounter,
};

export default allActions;
