import React, { FC, memo, useCallback, useEffect, useState } from "react";
import {
  DetailPanelView,
  FormControlWrapper,
  FormSection,
  SpTextField,
} from "../../../common";
import { Box, Button } from "@material-ui/core";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchData } from "../../../services/useFetchData";
import {
  AdminViewModel,
  getAdminDetail,
  postChangeAdminStatus,
  postCreateAdmin,
  putSelfAdminUpdate,
  SelfAdminViewModel,
} from "../redux/apiCalls";
import authSelector from "../../../auth/redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../appStore";
import { putAdminUpdate } from "../redux/apiCalls";
import { isNil } from "ramda";
import { MAIN_PATH } from "../../../router/pathConstants";
import { APP_SETTINGS } from "../../../AppSettings";
import commonReducer from "../../../common/redux";

const AdminSchema = yup.object().shape({
  FirstName: yup
    .string()
    .required("The First Name field is required.")
    .max(50, "First Name exceeds 50 character limit."),
  LastName: yup
    .string()
    .required("The Last Name field is required.")
    .max(50, "Last Name exceeds 50 character limit."),
  Email: yup
    .string()
    .required("The Email field is required.")
    .max(80, "Email exceeds 80 character limit."),
  Password: yup.string().max(20, "Password exceeds 20 character limit."),
  ConfirmPassword: yup
    .string()
    .test("passwords-match", "Passwords must match", function (value) {
      const { Password } = this.parent;
      return Password === value;
    }),
});

interface AdminInputs {
  FirstName: string;
  LastName: string;
  Email: string;
  Password: string;
  ConfirmPassword: string;
}

interface AdminDetailType {
  adminId?: string;
  isNewAdminMode: boolean;
  refetchUsers: (resetIsNewAdminMode?: boolean) => void;
}

const AdminDetail: FC<AdminDetailType> = ({
  adminId,
  isNewAdminMode,
  refetchUsers,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isSelfUser, setIsSelfUser] = useState<boolean>(false);
  const authUserEmail = useSelector(authSelector.getUserEmail);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    errors,
    clearErrors,
    setError,
  } = useForm<AdminInputs>({
    resolver: yupResolver(AdminSchema),
  });

  const prefillAdminInputs = (data: AdminViewModel & SelfAdminViewModel) => {
    setValue("FirstName", data.firstName);
    setValue("LastName", data.lastName);
    setValue("Email", data.email);
    setValue("Password", data.password);
    setValue("ConfirmPassword", data.confirmPassword);
  };

  const resetInputs = useCallback(() => {
    reset();
    clearErrors();
    setIsSelfUser(false);
  }, [clearErrors, reset]);

  useEffect(() => {
    if (isNewAdminMode) {
      resetInputs();
    }
  }, [isNewAdminMode, resetInputs]);

  const { data: userData, refetchAction: refetchAdminDetail } = useFetchData<
    AdminViewModel & SelfAdminViewModel
  >(
    getAdminDetail(adminId),
    !isNil(adminId),
    undefined,
    [adminId, isNewAdminMode],
    (response) => {
      resetInputs();
      setIsSelfUser(response.data.email === authUserEmail);
      prefillAdminInputs(response.data);
    },
    () => resetInputs()
  );

  const onSubmitAdmin = (formInputData: AdminInputs) => {
    const promises = [];
    if (isNewAdminMode) {
      promises.push(
        dispatch(
          postCreateAdmin({
            resetPasswordUrl: `${APP_SETTINGS.FRONTEND_BASE_URL}/${MAIN_PATH.RESET_PASSWORD}`,
            email: formInputData.Email,
            firstName: formInputData.FirstName,
            lastName: formInputData.LastName,
          })
        )
      );
    } else if (!isNewAdminMode && userData && userData?.userId) {
      if (!isSelfUser && userData?.status) {
        promises.push(
          dispatch(
            putAdminUpdate({
              email: formInputData.Email,
              firstName: formInputData.FirstName,
              lastName: formInputData.LastName,
              userId: userData?.userId,
              status: userData?.status,
            })
          )
        );
      } else if (isSelfUser) {
        promises.push(
          dispatch(
            putSelfAdminUpdate({
              email: formInputData.Email,
              firstName: formInputData.FirstName,
              lastName: formInputData.LastName,
              userId: userData?.userId,
              password:
                formInputData.Password !== ""
                  ? formInputData.Password
                  : undefined,
              confirmPassword:
                formInputData.ConfirmPassword !== ""
                  ? formInputData.ConfirmPassword
                  : undefined,
            })
          )
        );
      }
    }
    Promise.all(promises)
      .then(() => {
        refetchUsers(true);
      })
      .catch((error) => {
        if (error.response) {
          if (
            typeof error.response?.data === "string" ||
            error.response?.data instanceof String
          ) {
            dispatch(
              commonReducer.actions.setSnackBarMessage(error.response.data)
            );
          } else if (error.response.data.errors) {
            let newErrorObject: any = {};
            Object.keys(error.response.data.errors).forEach((key) => {
              if (error.response.data.errors[key].length > 0) {
                setError(key as keyof AdminInputs, {
                  message: error.response.data.errors[key],
                });
                newErrorObject[key] = {
                  message: error.response.data.errors[key],
                };
              }
            });
          }
        }
      });
  };

  const onChangeAdminStatus = () => {
    if (!isNil(userData?.status) && !isNil(adminId)) {
      dispatch(
        postChangeAdminStatus({
          id: adminId,
          status: userData?.status === "Active" ? "Inactive" : "Active",
        })
      ).then(() => {
        refetchUsers();
        refetchAdminDetail();
      });
    }
  };

  return isNewAdminMode || userData ? (
    <form onSubmit={handleSubmit(onSubmitAdmin)}>
      <DetailPanelView
        title={
          userData && !isNewAdminMode
            ? `${userData?.firstName} ${userData?.lastName}`
            : undefined
        }
      >
        <FormSection title="Personal Info">
          <FormControlWrapper
            title="First Name"
            input={
              <SpTextField
                name="FirstName"
                inputRef={register}
                errors={errors}
                fullWidth
              />
            }
          />
          <FormControlWrapper
            title="Last Name"
            input={
              <SpTextField
                name="LastName"
                inputRef={register}
                errors={errors}
                fullWidth
              />
            }
          />
          <FormControlWrapper
            title="Email"
            input={
              <SpTextField
                name="Email"
                inputRef={register}
                errors={errors}
                fullWidth
              />
            }
          />
          {isSelfUser && (
            <>
              <FormControlWrapper
                title="Password"
                input={
                  <SpTextField
                    name="Password"
                    inputRef={register}
                    errors={errors}
                    fullWidth
                    type="password"
                  />
                }
              />
              <FormControlWrapper
                title="Confirm Password"
                input={
                  <SpTextField
                    name="ConfirmPassword"
                    inputRef={register}
                    errors={errors}
                    fullWidth
                    type="password"
                  />
                }
              />
            </>
          )}
        </FormSection>
        {!!userData?.status && !isNewAdminMode && (
          <FormSection title="Actions">
            <Box>
              <Button variant="contained" onClick={onChangeAdminStatus}>
                {userData.status === "Active"
                  ? "Deactivate admin"
                  : "Activate admin"}
              </Button>
            </Box>
          </FormSection>
        )}
      </DetailPanelView>
    </form>
  ) : null;
};

export default memo(AdminDetail);
