import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import contractorSelector from "../redux/selectors";
import contractorReducer from "../redux/reducer";
import contractorActions from "../redux/dispatchActions";
import { AppDispatch } from "../../../appStore";
import { isNil } from "ramda";
import { TreeStructure } from "../../../common";
import { useFetchData } from "../../../services/useFetchData";
import { CounterResponseModel } from "../../../services/apiServiceTypes";
import { getDetailedSitesCounter } from "../../messages/redux/apiCalls";
import { ContractorDetailResponseType } from "../redux/apiCalls";

export interface AccessDetailProps {
  contractorId?: string;
  newContractorMode?: boolean;
  data?: ContractorDetailResponseType;
  canAssignEntrances: boolean;
}

const AccessDetail: React.FC<AccessDetailProps> = ({
  contractorId,
  newContractorMode,
  data,
  canAssignEntrances,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const accessList = useSelector(contractorSelector.getAccessList);

  const { data: detailedSites } = useFetchData<CounterResponseModel>(
    getDetailedSitesCounter(),
    true,
    false,
    [contractorId, newContractorMode, data?.entrances],
    (response) =>
      dispatch(
        contractorActions.prefillAccessList(
          response.data,
          (!newContractorMode && data?.entrances) || []
        )
      )
  );

  return (
    <>
      {detailedSites?.map(
        (site, index) =>
          !isNil(accessList[index]) && (
            <Grid item key={index}>
              <TreeStructure
                title={site.displayName}
                description={`${site?.children?.length} Entrances`}
                itemHeaderTitle="Entrances"
                items={
                  site?.children?.map((entrance) => ({
                    name: entrance.displayName,
                    id: entrance.codeId,
                  })) || []
                }
                state={accessList[index]}
                onChangeState={(state) => {
                  dispatch(
                    contractorReducer.actions.setAccessListItem({
                      index,
                      data: state,
                    })
                  );
                }}
                disabled={!canAssignEntrances}
              />
            </Grid>
          )
      )}
    </>
  );
};

export default memo(AccessDetail);
