import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { DetailPanelView } from "../../../common";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchData } from "../../../services/useFetchData";
import {
  getSiteSupervisorDetail,
  SiteSupervisorViewModel,
  postChangeSiteSupervisorStatus,
  putSiteSupervisorUpdate,
  postSiteSupervisorCreate,
} from "../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../appStore";
import usersActions from "../redux/dispatchActions";
import { isNil } from "ramda";
import { CounterResponseModel } from "../../../services/apiServiceTypes";
import {
  getDetailedSitesCounter,
  getUserDefaultPermissionsTypes,
  getUserPermissionsCounter,
} from "../../messages/redux/apiCalls";
import usersRedux from "../redux";
import commonReducer from "../../../common/redux";
import SiteSupervisorDetailTabs from "./SiteSupervisorDetailTabs";
import { ROLES } from "../../../auth/constants";

export interface AdminInputs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface AdminDetailType {
  siteSupervisorId?: string;
  isNewSiteSupervisorMode: boolean;
  refetchUsers: (resetIsNewSupervisorMode?: boolean) => void;
}

const SiteSupervisorDetailSection: FC<AdminDetailType> = ({
  siteSupervisorId,
  isNewSiteSupervisorMode,
  refetchUsers,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [editCredentials, setEditCredentials] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const allowedSites = useSelector(usersRedux.selectors.getAllowedSites);
  const selectedAllowedSites = useSelector(
    usersRedux.selectors.getSelectedAllowedSites
  );
  const allowedPermissions = useSelector(
    usersRedux.selectors.getAllowedPermissions
  );

  const AdminSchema = (requiredMode: boolean) =>
    yup.object().shape({
      firstName: yup
        .string()
        .required("The First Name field is required.")
        .max(50, "First Name exceeds 50 character limit."),
      lastName: yup
        .string()
        .required("The Last Name field is required.")
        .max(50, "Last Name exceeds 50 character limit."),

      email: requiredMode
        ? yup
            .string()
            .required("The Email field is required.")
            .max(80, "Email exceeds 80 character limit.")
        : yup.string().notRequired(),

      password: requiredMode
        ? yup
            .string()
            .required("The Password field is required")
            .max(20, "Password exceeds 20 character limit.")
            .min(
              8,
              '"Password must contain at least 8 characters, one lowercase letter, one uppercase letter, one special character (!"#$%&\'()*+,-./:;<=>?@[\\]^_`{."'
            )
        : yup.string().notRequired(),
      confirmPassword: requiredMode
        ? yup
            .string()
            .test("passwords-match", "Passwords must match", function (value) {
              return this.parent.password === value;
            })
        : yup.string().notRequired(),
    });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    errors,
    clearErrors,
    setError,
  } = useForm<AdminInputs, Boolean>({
    resolver: yupResolver(
      AdminSchema(editCredentials || isNewSiteSupervisorMode)
    ),
  });

  const prefillAdminInputs = (data: SiteSupervisorViewModel) => {
    setValue("firstName", data.firstName);
    setValue("lastName", data.lastName);
    setValue("email", data.email);
    setValue("password", data.password);
    setValue("confirmPassword", data.confirmPassword);
  };

  const resetInputs = useCallback(() => {
    reset();
    clearErrors();
    setEditCredentials(false);
    usersRedux.actions.setAllowedSites([]);
  }, [clearErrors, reset]);

  useEffect(() => {
    if (isNewSiteSupervisorMode) {
      resetInputs();
    }
  }, [isNewSiteSupervisorMode, resetInputs]);

  const { data: userData, refetchAction: refetchSiteSupervisorDetail } =
    useFetchData<SiteSupervisorViewModel>(
      getSiteSupervisorDetail(siteSupervisorId),
      !isNil(siteSupervisorId),
      undefined,
      [siteSupervisorId, isNewSiteSupervisorMode],
      (response) => {
        setEditCredentials(false);
        prefillAdminInputs(response.data);
        dispatch(
          usersRedux.actions.setAllowedPermissions(
            response.data.userPermissions
          )
        );
      },
      () => resetInputs()
    );

  const { data: detailedSites } = useFetchData<CounterResponseModel>(
    getDetailedSitesCounter(),
    !isNil(siteSupervisorId) || isNewSiteSupervisorMode,
    undefined,
    [isNewSiteSupervisorMode],
    ({ data }) => {
      dispatch(
        usersActions.prefillAllowedSites(
          data,
          (!isNewSiteSupervisorMode && userData?.sites) || []
        )
      );
    }
  );

  useEffect(() => {
    if (detailedSites) {
      dispatch(
        usersActions.prefillAllowedSites(
          detailedSites,
          (!isNewSiteSupervisorMode && userData?.sites) || []
        )
      );
    }
  }, [detailedSites, dispatch, isNewSiteSupervisorMode, userData?.sites]);

  const { data: possiblePermissions } = useFetchData<CounterResponseModel>(
    getUserPermissionsCounter(),
    !isNil(siteSupervisorId) || isNewSiteSupervisorMode
  );

  // in new supervisor mode fetch default permissions and fill them to form
  useFetchData<Array<string>>(
    getUserDefaultPermissionsTypes(ROLES.SITE_SUPERVISOR),
    !isNil(siteSupervisorId) || isNewSiteSupervisorMode,
    undefined,
    [isNewSiteSupervisorMode, siteSupervisorId],
    ({ data }) => {
      if (isNewSiteSupervisorMode)
        dispatch(usersRedux.actions.setAllowedPermissions(data || []));
    }
  );

  const onSubmitContractor = (formInputData: AdminInputs) => {
    const promises = [];
    if (isNewSiteSupervisorMode) {
      promises.push(
        dispatch(
          postSiteSupervisorCreate({
            firstName: formInputData.firstName,
            lastName: formInputData.lastName,
            email: formInputData.email,
            password: formInputData.password,
            confirmPassword: formInputData.confirmPassword,
            sites: selectedAllowedSites || [],
            userPermissions: allowedPermissions || [],
          })
        )
      );
    } else if (!isNewSiteSupervisorMode && userData) {
      promises.push(
        dispatch(
          putSiteSupervisorUpdate({
            userId: userData.userId,
            status: userData?.status,
            firstName: formInputData.firstName,
            lastName: formInputData.lastName,
            email: formInputData.email,
            password:
              formInputData.password !== ""
                ? formInputData.password
                : undefined,
            confirmPassword:
              formInputData.confirmPassword !== ""
                ? formInputData.confirmPassword
                : undefined,
            editCredentials,
            sites: selectedAllowedSites || [],
            userPermissions: allowedPermissions || [],
          })
        )
      );
    }
    Promise.all(promises)
      .then(() => {
        refetchUsers(true);
      })
      .catch((error) => {
        if (
          typeof error.response.data === "string" ||
          error.response.data instanceof String
        ) {
          dispatch(
            commonReducer.actions.setSnackBarMessage(error.response.data)
          );
        } else if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((key) => {
            setError(key.toLowerCase() as keyof AdminInputs, {
              message: error.response.data.errors[key],
            });
          });
        }
      });
  };

  const onChangeSiteSupervisorStatus = () => {
    if (!isNil(userData?.status) && !isNil(siteSupervisorId)) {
      dispatch(
        postChangeSiteSupervisorStatus({
          id: siteSupervisorId,
          status: userData?.status === "Active" ? "Inactive" : "Active",
        })
      ).then(() => {
        refetchUsers();
        refetchSiteSupervisorDetail();
      });
    }
  };

  return isNewSiteSupervisorMode || userData ? (
    <form onSubmit={handleSubmit(onSubmitContractor)}>
      <DetailPanelView
        title={
          userData && !isNewSiteSupervisorMode
            ? `${userData?.firstName} ${userData?.lastName}`
            : undefined
        }
      >
        <SiteSupervisorDetailTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          register={register}
          errors={errors}
          editCredentials={editCredentials}
          setEditCredentials={setEditCredentials}
          isNewSiteSupervisorMode={isNewSiteSupervisorMode}
          detailedSites={detailedSites}
          allowedSites={allowedSites}
          possiblePermissions={possiblePermissions}
          allowedPermissions={allowedPermissions}
          userStatus={userData?.status}
          onChangeSiteSupervisorStatus={() => onChangeSiteSupervisorStatus()}
        />
      </DetailPanelView>
    </form>
  ) : null;
};

export default memo(SiteSupervisorDetailSection);
