import React, { FC, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Divider, Grid, Typography } from "@material-ui/core";
import { CounterResponseModel } from "../../services/apiServiceTypes";
import { useFetchData } from "../../services/useFetchData";
import { isNil } from "ramda";
import {
  ContractorDetailResponseType,
  getDetailOfContractor,
} from "../contractors/redux/apiCalls";
import { loadFile } from "../../services/authApi";
import {
  getSiteCounter,
  getSiteDetail,
  getSiteSupervisorCounterBySite,
  SiteDetailModel,
} from "./redux/apiCalls";
import { SpPanel } from "../../common";
import { useComputeSize } from "../../services/useComputeSize";
import withAuthorization from "../../auth/hoc/withAutorization";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
  },
  submitButton: {
    marginBottom: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(4),
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  mainPanel: {
    height: `calc(100vh - ${theme.spacing(17)}px)`,
  },
}));

export type FormsDetailParams = {
  submittedContractor: string;
  submittedSite: string;
  submittedForm: string;
  submittedSiteSupervisor: string;
};

const FormsDetail: FC = () => {
  const classes = useStyles();

  const [contractorDetail, setContractorDetail] =
    useState<ContractorDetailResponseType>();
  const [photoUrl, setPhotoUrl] = useState<string>();

  const formsHeaderRef = useRef<HTMLDivElement | null>(null);
  const { height: formsHeaderHeight } = useComputeSize(formsHeaderRef);

  const {
    submittedContractor,
    submittedSite,
    submittedForm,
    submittedSiteSupervisor,
  } = useParams<FormsDetailParams>();

  const { data: siteCounterData } = useFetchData<CounterResponseModel>(
    getSiteCounter()
  );

  const { data: siteSupervisorCounterData } =
    useFetchData<CounterResponseModel>(
      // @ts-ignore
      getSiteSupervisorCounterBySite(submittedSite),
      !isNil(submittedSite) && submittedSite !== "",
      undefined,
      [submittedSite]
    );

  // getDetailOfContractor
  useFetchData<ContractorDetailResponseType>(
    // @ts-ignore
    getDetailOfContractor(submittedContractor),
    !isNil(submittedContractor),
    undefined,
    [submittedContractor],
    // @ts-ignore
    (response) => setContractorDetail(response.data),
    () => setContractorDetail(undefined),
    () => setContractorDetail(undefined)
  );

  useFetchData<string>(
    // @ts-ignore
    loadFile(contractorDetail?.photo),
    !isNil(contractorDetail?.photo),
    undefined,
    [contractorDetail?.photo],
    // @ts-ignore
    (response) => setPhotoUrl(response),
    () => setPhotoUrl(undefined),
    () => setPhotoUrl(undefined)
  );

  const { data: siteDetail } = useFetchData<SiteDetailModel>(
    // @ts-ignore
    getSiteDetail(submittedSite),
    !isNil(submittedSite),
    undefined,
    [submittedSite]
  );

  return (
    <div className={classes.main}>
      <Typography variant={"h1"} className={classes.title}>
        FORMS
      </Typography>
      <SpPanel withoutFooter>
        <div className={classes.mainPanel}>
          <div className={classes.header} ref={formsHeaderRef}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Typography variant="body2">Site:</Typography>
                <Typography variant="body1">
                  {
                    siteCounterData?.find(
                      (i) => i.codeId.toString() === submittedSite
                    )?.displayName
                  }
                </Typography>
                <Typography variant="subtitle1">
                  {siteDetail?.company.companyName}
                </Typography>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="body2">Contractor:</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" spacing={1}>
                      <Grid item>
                        <Avatar src={photoUrl} />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{`${
                          contractorDetail?.firstName || ""
                        }${
                          contractorDetail?.lastName
                            ? ` ${contractorDetail?.lastName}`
                            : ""
                        }`}</Typography>
                        <Typography variant="subtitle1">
                          {contractorDetail?.secondaryCompany}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Typography variant="body2">Site Supervisor:</Typography>
                <Typography variant="body1">
                  {
                    siteSupervisorCounterData?.find(
                      (i) => i.codeId.toString() === submittedSiteSupervisor
                    )?.displayName
                  }
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div>
            <iframe
              title="Formbuilder"
              style={{
                height: `calc(100vh - ${154}px - ${formsHeaderHeight}px)`,
                overflow: "auto",
              }}
              width="100%"
              id="contactform123"
              name="contactform123"
              marginWidth={0}
              marginHeight={0}
              frameBorder={0}
              src={`https://form.123formbuilder.com/my-contact-form-${submittedForm}.html`}
            >
              <p>Your browser does not support iframes.</p>
            </iframe>
          </div>
        </div>
      </SpPanel>
    </div>
  );
};

export default withAuthorization(FormsDetail);
