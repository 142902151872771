import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import withAuthorization from "../../auth/hoc/withAutorization";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { FormControlWrapper, SpListItem, SpTextField } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import usersReducer from "./redux";
import { USER_COUNTER_TYPES, USER_TYPES } from "../../counters";
import { SpSearchTextField } from "../../common/components/inputs/SpSearchTextField";
import InfiniteScroll from "react-infinite-scroll-component";
import { customDebounce } from "../../services/helpers";
import { AppDispatch } from "../../appStore";
import { useComputeSize } from "../../services/useComputeSize";
import { useFetchData } from "../../services/useFetchData";
import { getSiteCounter } from "./redux/apiCalls";
import { CounterResponseModel } from "../../services/apiServiceTypes";
import { AdminDetail, SiteSupervisorDetailSection } from "./details";
import { isNil } from "ramda";
import axios, { CancelToken, CancelTokenSource } from "axios";
import {
  ListDetailSplitView,
  PageContent,
} from "../../common/components/containers";
import { SCROLL_PANEL_WIDTH } from "../../appTheme";

const useStyles = makeStyles((theme) => ({
  scrollPanel: {
    height: "calc(100vh - 411px)",
    width: SCROLL_PANEL_WIDTH,
  },
  mobileScrollPanel: {
    height: "200px",
    width: "100%"
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  filterPanel: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  footerContainer: {
    height: "inherit",
  },
  detailPanel: {},
  mainBody: {},
}));

const Users: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const selectedUserTypeCodeId = useSelector(
    usersReducer.selectors.getSelectedUserTypeCodeId
  );
  const [searchUser, setSearchUser] = useState<string>();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [isNewUserMode, setIsNewUserMode] = useState<boolean>(false);
  const [selectedSiteId, setSelectedSiteId] = useState<string>();
  const pageSize: number = 50;
  const pageIndex = useRef<number>(0);
  const userListHasNextPage = useSelector(
    usersReducer.selectors.getUserListHasNextPage
  );
  const isFetchingUserList = useSelector(
    usersReducer.selectors.getIsFetchingUserList
  );
  const userList = useSelector(usersReducer.selectors.getUserList);
  const userListRequest = useRef<CancelTokenSource>(axios.CancelToken.source());

  const { data: siteList } = useFetchData<CounterResponseModel>(
    getSiteCounter(),
    selectedUserTypeCodeId === USER_TYPES.SITE_SUPERVISOR,
    undefined,
    [selectedUserTypeCodeId]
  );

  const filterHeaderRef = useRef<HTMLDivElement | null>(null);
  const { height: filterHeaderHeight } = useComputeSize(filterHeaderRef, [
    selectedUserTypeCodeId,
  ]);

  const resetPagination = useCallback(() => {
    dispatch(usersReducer.actions.setUserListHasNextPage(true));
    dispatch(usersReducer.actions.setUserList([]));
    pageIndex.current = 0;
  }, [dispatch]);

  const resetSelection = () => {
    setSelectedUserId(undefined);
    setIsNewUserMode(false);
  };

  const handleChangeUserType = (event: React.ChangeEvent<{ value: any }>) => {
    dispatch(
      usersReducer.actions.setSelectedUserTypeCodeId(
        event.target.value as string
      )
    );
    resetPagination();
    resetSelection();
  };

  const handleChangeSite = (event: React.ChangeEvent<{ value: any }>) => {
    setSelectedSiteId(event.target.value as string);
    resetPagination();
    resetSelection();
  };

  const handleChangeSearchUser = (event: React.ChangeEvent<{ value: any }>) => {
    setSearchUser(event.target.value as string);
    resetPagination();
    resetSelection();
  };

  const debouncedCall = useMemo(
    () =>
      customDebounce(
        (
          searchContractor: string,
          incrementedPageIndex: number,
          isScrollMode: boolean,
          userType: string,
          cancelToken: CancelToken,
          siteId?: string
        ) => {
          const promises = [];
          if (userType === USER_TYPES.ADMIN) {
            promises.push(
              dispatch(
                usersReducer.dispatchActions.fetchListOfAdmins(
                  {
                    Pagination: {
                      PageIndex: incrementedPageIndex,
                      PageSize: pageSize,
                    },
                    SearchVal: searchContractor,
                  },
                  isScrollMode,
                  cancelToken
                )
              )
            );
          }
          if (userType === USER_TYPES.SITE_SUPERVISOR) {
            promises.push(
              dispatch(
                usersReducer.dispatchActions.fetchListOfSiteSupervisors(
                  {
                    siteId,
                    Pagination: {
                      PageIndex: incrementedPageIndex,
                      PageSize: pageSize,
                    },
                    SearchVal: searchContractor,
                  },
                  isScrollMode,
                  cancelToken
                )
              )
            );
          }

          Promise.all(promises)
            .then(() => {
              pageIndex.current = incrementedPageIndex;
            })
            .catch(() => {
              pageIndex.current = 0;
            });
        },
        800
      ),
    [dispatch]
  );

  const fetchNextData = useCallback(
    (isScrollMode: boolean = false) => {
      if (userListHasNextPage) {
        if (isFetchingUserList) {
          userListRequest.current.cancel("Cancel previous request");
          userListRequest.current = axios.CancelToken.source();
        }
        const incrementedPageIndex = pageIndex.current + 1;
        debouncedCall(
          searchUser,
          incrementedPageIndex,
          isScrollMode,
          selectedUserTypeCodeId,
          userListRequest.current.token,
          selectedSiteId
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedCall, searchUser, selectedUserTypeCodeId, selectedSiteId]
  );

  const refetchUsers = useCallback(
    (resetNewMode: boolean = false) => {
      resetPagination();
      if (resetNewMode) {
        resetSelection();
      }
      fetchNextData();
    },
    [fetchNextData, resetPagination]
  );

  useEffect(() => {
    refetchUsers();
  }, [refetchUsers]);

  useEffect(() => {
    return () => {
      dispatch(usersReducer.dispatchActions.resetUsers());
    };
  }, [dispatch]);

  const getFilterPanel = () => {
      return (
          <div ref={filterHeaderRef} className={classes.filterPanel}>
              <FormControlWrapper
                  title="User Type"
                  fullWidth
                  input={
                      <SpTextField
                          select
                          value={selectedUserTypeCodeId || ""}
                          onChange={handleChangeUserType}
                          withoutHelperText
                      >
                          {USER_COUNTER_TYPES.map((site) => (
                              <MenuItem key={site.codeId} value={site.codeId}>
                                  {site.displayName}
                              </MenuItem>
                          ))}
                      </SpTextField>
                  }
              />
              {selectedUserTypeCodeId === USER_TYPES.SITE_SUPERVISOR && (
                  <FormControlWrapper
                      title="Location"
                      fullWidth
                      input={
                          <SpTextField
                              select
                              value={selectedSiteId || ""}
                              onChange={handleChangeSite}
                              withoutHelperText
                          >
                              <MenuItem value={undefined}>
                                  <em>All Locations</em>
                              </MenuItem>
                              {siteList?.map((site) => (
                                  <MenuItem key={site.codeId} value={site.codeId}>
                                      {site.displayName}
                                  </MenuItem>
                              ))}
                          </SpTextField>
                      }
                  />
              )}
          </div>
      )
  }

  const getUsersListPanel = () => {
      return (
          <>
              <SpSearchTextField
                  placeholder="Search"
                  value={searchUser || ""}
                  onChange={handleChangeSearchUser}
              />
              <InfiniteScroll
                  dataLength={userList.length} //This is important field to render the next data
                  next={() => fetchNextData(true)}
                  hasMore={userListHasNextPage}
                  loader={
                      <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className={classes.footerContainer}
                      >
                          <Grid item>
                              <CircularProgress />
                          </Grid>
                      </Grid>
                  }
                  endMessage={
                      <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ textAlign: "center" }}
                      >
                          No more data
                      </Typography>
                  }
                  height={window.innerWidth > 600 ? `calc(100vh - ${filterHeaderHeight}px - ${140}px)` : "200px"}
                  className={window.innerWidth > 600 ? classes.scrollPanel : classes.mobileScrollPanel}
              >
                  {userList.map((user, index) => (
                      <SpListItem
                          key={index}
                          title={user.displayName}
                          isActive={selectedUserId === user.codeId}
                          onClick={() => {
                              setSelectedUserId(user.codeId);
                              setIsNewUserMode(false);
                          }}
                          inactiveItem={!user.isActive}
                      />
                  ))}
              </InfiniteScroll>
          </>
      )
  }

  const getUserDetailsPanel = () => {
      return (
          <Grid item className={classes.detailPanel}>
              {selectedUserTypeCodeId === USER_TYPES.ADMIN &&
                  (isNewUserMode || !isNil(selectedUserId)) && (
                      <AdminDetail
                          adminId={selectedUserId}
                          isNewAdminMode={isNewUserMode}
                          refetchUsers={refetchUsers}
                      />
                  )}
              {selectedUserTypeCodeId === USER_TYPES.SITE_SUPERVISOR &&
                  (isNewUserMode || !isNil(selectedUserId)) && (
                      <SiteSupervisorDetailSection
                          siteSupervisorId={selectedUserId}
                          isNewSiteSupervisorMode={isNewUserMode}
                          refetchUsers={refetchUsers}
                      />
                  )}
          </Grid>
      )
  }

  return (
    <PageContent
      title="Administrators"
      addNewButtonText="Add User"
      showButton
      buttonOnClick={() => {
        setIsNewUserMode(true);
        setSelectedUserId(undefined);
      }}
      >
        {window.innerWidth <= 600 && (
            <>
                {getFilterPanel()}
                {getUsersListPanel()}
                <div>
                      {getUserDetailsPanel()}
                </div>
            </>
        )}

        {window.innerWidth > 600 && (
            <ListDetailSplitView
                list={
                    <>
                        {getFilterPanel()}
                        {getUsersListPanel()}
                    </>
                }
                details={
                    getUserDetailsPanel()
                }
            />
        )}
    </PageContent>
  );
};

export default withAuthorization(memo(Users));
