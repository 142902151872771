import { getAuthApi, postAuthApi } from "../../../services/authApi";
import { APP_SETTINGS } from "../../../AppSettings";
import {
  DomainBase,
  PaginationModel,
  PaginationResponseType,
} from "../../../services/apiServiceTypes";

export type EntranceInfoModel = {
  siteName: string;
  entranceName: string;
  cameraName: string;
};

// TODO transform as SignalR call
export const getEntranceInfo = <T = EntranceInfoModel>(entranceId?: string) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/EntranceEvent/EntranceInfo?entranceId=${entranceId}`
  );

export type BeaconScannerInfo = {
  beaconScannerLastScanTime: string;
  beaconScannerLastScanValue: string;
};

export const getLastBeaconScannerInfo = <T = BeaconScannerInfo>(
  entranceId?: string
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/EntranceEvent/LastBeaconScannerEvent?entranceId=${entranceId}`
  );

export type BatteryLevel =
  | "Unknown"
  | "Low"
  | "MediumLow"
  | "MediumHigh"
  | "High";

export const getBatteryLevelsInfo = <T = BatteryLevel[]>(entranceId?: string) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/EntranceEvent/BatteryLevels?entranceId=${entranceId}`
  );

export type EventType =
  | "Enter"
  | "Leave"
  | "ManualEnter"
  | "ManualLeave"
  | "HelpRequested"
  | "HelpConfirmed"
  | "HelpCanceled"
  | "MotionEnter"
  | "MotionLeave";

export type EventSource = "QRReader" | "BeaconScanner" | "BeaconReader";

export type EntranceEventViewModel = {
  dateTimeString: string;
  entranceName: string;
  eventType: EventType;
  eventSource?: EventSource;
};
export type HelpRequestViewModel = {
  closeButton: boolean;
  companyName: string;
  dateTimeString: string;
  entranceEventViewModels: EntranceEventViewModel[];
  firstName: string;
  lastName: string;
  photo: string;
  type: EventType;
  userId: string;
};

export const fetchHelpRequestData = <T = HelpRequestViewModel>(
  eventId?: string
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/EntranceEvent/HelpRequest?eventId=${eventId}`
  );

export interface LastEventsPaginatedModel extends PaginationModel {
  entranceId: number;
}

export type CertificationType = "Osha" | "Other";

export interface ExpiredCertification extends DomainBase {
  type: CertificationType;
  name: string;
  expirationDate: string;
  expired: boolean;
}

export interface VaccinationCertificate {
  vaccinationVerificationDate?: string;
  vaccinationVerified: boolean;
}

export type EntranceEventThumbnailViewModel = {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  userPhotoId: string;
  photo?: string;
  companyName?: string;
  dateTimeString: string;
  photoDateTimeString?: string;
  entranceId: string;
  entering: boolean;
  eventType: EventType;
  eventSource?: EventSource;
  allowed: boolean;
  expiredCertifications: ExpiredCertification[];
  vaccinationCertificate?: VaccinationCertificate;
  recognizedPersonName?: string;
  confidence?: number;
  facialRecMessage?: string;
  identityConfirmed: boolean;
};

export const getLastEventsList = <
  T = PaginationResponseType<EntranceEventThumbnailViewModel>
>(
  body: LastEventsPaginatedModel
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/EntranceEvent/LastEvents`,
    body
  );

export const fetchEntranceEventThumbnailData = <
  T = EntranceEventThumbnailViewModel
>(
  eventId?: string
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/EntranceEvent/EntranceEventThumbnail?eventId=${eventId}`
  );

export interface ContractorEventsViewModel {
  userId: string;
  firstName: string;
  lastName: string;
  photo?: string;
  companyName: string;
  entranceEventViewModels: EntranceEventViewModel[];
  closeButton: boolean;
  dateTimeString: string;
}

export const fetchContractorEventData = <T = ContractorEventsViewModel>(
  entranceId: string,
  userId: string
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/EntranceEvent/ContractorEvents?userId=${userId}&entranceId=${entranceId}`
  );

export type ManualCheckInOutReason =
  | "Other"
  | "ForgotPhone"
  | "PhoneNotCharged"
  | "UninstalledMobileApp";

export type ManualCheckInOutModel = {
  userId: string;
  entranceId: string;
  reason: ManualCheckInOutReason;
};

export const postManualCheckIn = <T = any>(body: ManualCheckInOutModel) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/EntranceEvent/ManualCheckIn`,
    body
  );

export const postManualCheckOut = <T = any>(body: ManualCheckInOutModel) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/EntranceEvent/ManualCheckOut`,
    body
  );

const allActions = {
  getEntranceInfo,
  getLastBeaconScannerInfo,
  getBatteryLevelsInfo,
  fetchHelpRequestData,
  getLastEventsList,
  fetchEntranceEventThumbnailData,
  fetchContractorEventData,
  postManualCheckIn,
  postManualCheckOut,
};

export default allActions;
