import { createSelector } from "@reduxjs/toolkit";
import { State } from "./model";
import { COMMON } from "./constants";

const selectSelf = (state: { [x: string]: State }) =>
  state[COMMON.ROOT_REDUCER];

const getSelectedEntrance = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state
      .selectedEntrance
);
const getSelectedSites = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state.selectedSites
);

const getFirstSelectedSite = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) => {
    return state.panelStates
      .find((value) => value.id === panelId)!
      .state.selectedSites.filter(
        (item) =>
          item.children?.filter((ch) => ch.isChecked === true).length === 1
      )[0];
  }
);

const getFirstSelectedEntrance = createSelector(
  getFirstSelectedSite,
  (selectedSite) =>
    selectedSite?.children &&
    selectedSite?.children.filter((item) => item.isChecked)[0]
);

const getBeaconScannerLastScanTime = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state
      .beaconScannerLastScanTime
);

const getBeaconScannerLastScanValue = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state
      .beaconScannerLastScanValue
);

const getInnerBeaconBatteryLevel = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state
      .innerBeaconBatteryLevel
);

const getOuterBeaconBatteryLevel = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state
      .outerBeaconBatteryLevel
);

const getSelectedEntranceInfo = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state
      .selectedEntranceInfo
);

const getHelpRequest = createSelector(
  selectSelf,
  (state: State) => state.helpRequest
);

const getEventList = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state.eventList
);

const getEventListHasNextPage = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state
      .eventListHasNextPage
);

const getIsFetchingEventList = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state
      .isFetchingEventList
);

const getUnauthorizedAccessEvent = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state
      .unauthorizedAccessEvent
);

const getSelectedEvent = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state.selectedEvent
);

// contractors in manual check in/out
const getContractorList = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state
      .contractorList
);

const getContractorListHasNextPage = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state
      .contractorListHasNextPage
);

const getIsFetchingContractorList = createSelector(
  selectSelf,
  (_: { [x: string]: State }, panelId: number) => panelId,
  (state: State, panelId: number) =>
    state.panelStates.find((value) => value.id === panelId)!.state
      .isFetchingContractorList
);

const getPanels = createSelector(
  selectSelf,
  (state: State) => state.panelStates
);

const allSelectors = {
  getSelectedEntrance,
  getSelectedSites,
  getFirstSelectedSite,
  getFirstSelectedEntrance,
  getBeaconScannerLastScanTime,
  getBeaconScannerLastScanValue,
  getInnerBeaconBatteryLevel,
  getOuterBeaconBatteryLevel,
  getSelectedEntranceInfo,
  getHelpRequest,
  getEventList,
  getEventListHasNextPage,
  getIsFetchingEventList,
  getUnauthorizedAccessEvent,
  getSelectedEvent,
  getContractorList,
  getContractorListHasNextPage,
  getIsFetchingContractorList,
  getPanels,
};

export default allSelectors;
