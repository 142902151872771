import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COMMON } from "./constants";
import { initialState, State } from "./model";

const authReducer = createSlice({
  name: COMMON.ROOT_REDUCER,
  initialState,
  reducers: {
    setSnackBarMessage: (
      state: State,
      action: PayloadAction<string | null>
    ) => ({ ...state, snackBarMessage: action.payload }),
    setAudioEnabled: (state: State, action: PayloadAction<boolean>) => ({
      ...state,
      audioEnabled: action.payload,
    }),
    setPlayAudio: (state: State) => ({
      ...state,
      playAudio: state.playAudio + 1,
    }),
    setAudioSrc: (state: State, action: PayloadAction<string | null>) => ({
      ...state,
      audioSrc: action.payload,
    }),
  },
});

export default authReducer;
