import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COMMON } from "./constants";
import { initialState, State } from "./model";
import { CounterResponseItem } from "../../../services/apiServiceTypes";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";
import { UpdateTreeStructureData } from "../../messages/redux/model";
import { remove, uniq } from "lodash";

const authReducer = createSlice({
  name: COMMON.ROOT_REDUCER,
  initialState,
  reducers: {
    setSelectedUserTypeCodeId: (
      state: State,
      action: PayloadAction<string>
    ) => ({
      ...state,
      selectedUserTypeCodeId: action.payload,
    }),
    setUserListHasNextPage: (state: State, action: PayloadAction<boolean>) => ({
      ...state,
      userListHasNextPage: action.payload,
    }),
    setIsFetchingUserList: (state: State, action: PayloadAction<boolean>) => ({
      ...state,
      isFetchingUserList: action.payload,
    }),
    setUserList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => ({
      ...state,
      userList: action.payload,
    }),
    appendToUserList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => {
      const newList = JSON.parse(JSON.stringify(state.userList));
      newList.push(...action.payload);

      return {
        ...state,
        userList: newList,
      };
    },
    setAllowedSites: (
      state: State,
      action: PayloadAction<Array<TreeStructureData>>
    ) => ({
      ...state,
      allowedSites: action.payload,
    }),
    setAllowedSitesItem: (
      state: State,
      action: PayloadAction<UpdateTreeStructureData>
    ) => {
      const newState = JSON.parse(JSON.stringify(state.allowedSites));
      newState[action.payload.index] = action.payload.data;

      return { ...state, allowedSites: newState };
    },
    setAllowedPermissions: (
      state: State,
      action: PayloadAction<Array<string>>
    ) => ({
      ...state,
      allowedPermissions: action.payload,
    }),
    enableAllowedPermissionsItem: (
      state: State,
      action: PayloadAction<string>
    ) => {
      let newPermissions = JSON.parse(
        JSON.stringify(state.allowedPermissions)
      ) as Array<string>;
      newPermissions.push(action.payload);
      newPermissions = uniq(newPermissions);

      return { ...state, allowedPermissions: newPermissions };
    },
    disableAllowedPermissionsItem: (
      state: State,
      action: PayloadAction<string>
    ) => {
      const newPermissions = JSON.parse(
        JSON.stringify(state.allowedPermissions)
      );
      remove(newPermissions, (perm) => perm === action.payload);

      return { ...state, allowedPermissions: newPermissions };
    },
  },
});

export default authReducer;
