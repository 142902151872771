import { Grid } from "@material-ui/core";
import { isNil } from "ramda";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../appStore";
import { FormSection, TreeStructure } from "../../../common";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";
import { CounterResponseModel } from "../../../services/apiServiceTypes";
import usersRedux from "../redux";

export interface SitesForUserProps {
  detailedSites: CounterResponseModel | undefined;
  allowedSites: Array<TreeStructureData>;
}

const SitesForUser: React.FC<SitesForUserProps> = ({
  detailedSites,
  allowedSites,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <FormSection title="Allowed Locations">
      {detailedSites?.map(
        (site, index) =>
          !isNil(allowedSites[index]) && (
            <Grid item key={index}>
              <TreeStructure
                hideChildren
                title={site.displayName}
                description={`${site?.children?.length} Entrances`}
                itemHeaderTitle="Entrances"
                items={
                  site?.children?.map((entrance) => ({
                    name: entrance.displayName,
                    id: entrance.codeId,
                  })) || []
                }
                state={allowedSites[index]}
                onChangeState={(state) => {
                  dispatch(
                    usersRedux.actions.setAllowedSitesItem({
                      index,
                      data: state,
                    })
                  );
                }}
              />
            </Grid>
          )
      )}
    </FormSection>
  );
};

export default SitesForUser;
