import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COMMON } from "./constants";
import {
  getDefaultState,
  HelpRequestEvent,
  initialState,
  PanelState,
  SelectedEntranceEventDetail,
  State,
  UnauthorizedAccessEvent,
} from "./model";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";
import { UpdateTreeStructureData } from "../../messages/redux/model";
import {
  BatteryLevel,
  EntranceEventThumbnailViewModel,
  EntranceInfoModel,
} from "./apiCalls";
import { CounterResponseItem } from "../../../services/apiServiceTypes";

const authReducer = createSlice({
  name: COMMON.ROOT_REDUCER,
  initialState,
  reducers: {
    setSelectedEntrance: (
      state: State,
      action: PayloadAction<{
        selectedEntrance: CounterResponseItem | undefined;
        panelId: number;
      }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.selectedEntrance = action.payload.selectedEntrance;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setSelectedSites: (
      state: State,
      action: PayloadAction<{
        selectedSites: Array<TreeStructureData>;
        panelId: number;
      }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.selectedSites = action.payload.selectedSites;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setLastSelectedSiteItem: (
      state: State,
      action: PayloadAction<{
        updateTreeStructure: UpdateTreeStructureData;
        panelId: number;
      }>
    ) => {
      let newState: Array<TreeStructureData> = JSON.parse(
        JSON.stringify(
          state.panelStates.find(
            (value) => value.id === action.payload.panelId
          )!.state.selectedSites
        )
      );

      newState = newState.map((item, parentIndex) => ({
        id: item.id,
        isChecked: false,
        children: (item.children || []).map((ch, childrenIndex) => {
          return {
            ...ch,
            isChecked:
              (parentIndex === action.payload.updateTreeStructure.index &&
                action.payload.updateTreeStructure.data.children &&
                action.payload.updateTreeStructure.data.children[
                  childrenIndex
                ] &&
                action.payload.updateTreeStructure.data.children[childrenIndex]
                  .isChecked) ||
              false,
          };
        }),
      }));

      const newPanelsState = JSON.parse(JSON.stringify(state));
      newPanelsState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.selectedSites = newState;
      return { ...state, panelStates: newPanelsState.panelStates };
    },

    setBeaconScannerLastScanTime: (
      state: State,
      action: PayloadAction<{
        lastScanTime: string | undefined;
        panelId: number;
      }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.beaconScannerLastScanTime = action.payload.lastScanTime;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setBeaconScannerLastScanValue: (
      state: State,
      action: PayloadAction<{
        lastScanValue: string | undefined;
        panelId: number;
      }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.beaconScannerLastScanValue = action.payload.lastScanValue;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setInnerBeaconBatteryLevel: (
      state: State,
      action: PayloadAction<{ batteryLevel: BatteryLevel; panelId: number }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.innerBeaconBatteryLevel = action.payload.batteryLevel;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setOuterBeaconBatteryLevel: (
      state: State,
      action: PayloadAction<{ batteryLevel: BatteryLevel; panelId: number }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.outerBeaconBatteryLevel = action.payload.batteryLevel;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setSelectedEntranceInfo: (
      state: State,
      action: PayloadAction<{
        entranceInfo: EntranceInfoModel | undefined;
        panelId: number;
      }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.selectedEntranceInfo = action.payload.entranceInfo;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setHelpRequest: (
      state: State,
      action: PayloadAction<HelpRequestEvent | undefined>
    ) => ({
      ...state,
      helpRequest: action.payload,
    }),
    setEventListHasNextPage: (
      state: State,
      action: PayloadAction<{ eventListHasNextPage: boolean; panelId: number }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.eventListHasNextPage = action.payload.eventListHasNextPage;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setIsFetchingEventList: (
      state: State,
      action: PayloadAction<{ isFetchingEventList: boolean; panelId: number }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.isFetchingEventList = action.payload.isFetchingEventList;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setEventList: (
      state: State,
      action: PayloadAction<{
        eventList: Array<EntranceEventThumbnailViewModel>;
        panelId: number;
      }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.eventList = action.payload.eventList;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    appendToEventList: (
      state: State,
      action: PayloadAction<{
        eventList: Array<EntranceEventThumbnailViewModel>;
        panelId: number;
      }>
    ) => {
      const newList = JSON.parse(
        JSON.stringify(
          state.panelStates.find(
            (value) => value.id === action.payload.panelId
          )!.state.eventList
        )
      );
      newList.push(...action.payload.eventList);

      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.eventList = newList;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    unshiftToEventList: (
      state: State,
      action: PayloadAction<{
        eventList: EntranceEventThumbnailViewModel;
        panelId: number;
      }>
    ) => {
      const newList = JSON.parse(
        JSON.stringify(
          state.panelStates.find(
            (value) => value.id === action.payload.panelId
          )!.state.eventList
        )
      );
      newList.unshift(action.payload.eventList);

      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.eventList = newList;

      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setUnauthorizedAccessEvent: (
      state: State,
      action: PayloadAction<{
        unauthorizedAccessEvent: UnauthorizedAccessEvent | undefined;
        panelId: number;
      }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.unauthorizedAccessEvent = action.payload.unauthorizedAccessEvent;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setSelectedEvent: (
      state: State,
      action: PayloadAction<{
        selectedEvent: SelectedEntranceEventDetail | undefined;
        panelId: number;
      }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.selectedEvent = action.payload.selectedEvent;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    // contractors in manual check in/out
    setContractorListHasNextPage: (
      state: State,
      action: PayloadAction<{
        contractorListHasNextPage: boolean;
        panelId: number;
      }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.contractorListHasNextPage =
        action.payload.contractorListHasNextPage;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setIsFetchingContractorList: (
      state: State,
      action: PayloadAction<{
        isFetchingContractorList: boolean;
        panelId: number;
      }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.isFetchingContractorList =
        action.payload.isFetchingContractorList;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    setContractorList: (
      state: State,
      action: PayloadAction<{
        contractorList: Array<CounterResponseItem>;
        panelId: number;
      }>
    ) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.contractorList = action.payload.contractorList;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    appendToContractorList: (
      state: State,
      action: PayloadAction<{
        contractorList: Array<CounterResponseItem>;
        panelId: number;
      }>
    ) => {
      const notifications = JSON.parse(
        JSON.stringify(
          state.panelStates.find(
            (value) => value.id === action.payload.panelId
          )!.state.contractorList
        )
      );
      notifications.push(...action.payload.contractorList);

      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (val: PanelState) => val.id === action.payload.panelId
      ).state.contractorList = notifications;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    addPanel: (state: State, action: PayloadAction<{ panelId: number }>) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.panelStates.find(
        (panel: PanelState) => panel.id === action.payload.panelId
      ).active = true;
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
    removePanel: (state: State, action: PayloadAction<{ panelId: number }>) => {
      const newState = JSON.parse(JSON.stringify(state));
      const index = newState.panelStates.findIndex(
        (panel: PanelState) => panel.id === action.payload.panelId
      );
      newState.panelStates[index] = getDefaultState(
        action.payload.panelId,
        false
      );
      return {
        ...state,
        panelStates: newState.panelStates,
      };
    },
  },
});

export default authReducer;
