import React, { FC, memo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";


type DuplicateUserDialogProps = {
  open: boolean;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const DuplicateUserDialog: FC<DuplicateUserDialogProps> = ({
  open,
  firstName,
  lastName,
  phoneNumber,
  email,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog onClose={() => null} open={open}>
      <DialogTitle>Duplicate User</DialogTitle>
      <DialogContent>
        <Typography variant="h3">This user may already exist.</Typography>
        <Typography variant="body2">Name: {firstName} {lastName}</Typography>
        <Typography variant="body2">Phone: {phoneNumber}</Typography>
        <Typography variant="body2">Email: {email}</Typography>
        <Typography variant="h3">Do you want to edit this user instead?</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(DuplicateUserDialog);
