import {
  CounterResponseItem,
  CounterResponseModel,
  PaginationModel,
  PaginationResponseType,
} from "../../../services/apiServiceTypes";
import { getAuthApi, postAuthApi, putAuthApi } from "../../../services/authApi";
import { APP_SETTINGS } from "../../../AppSettings";
import { CancelToken } from "axios";

export interface ContractorListRequestType extends PaginationModel {}

export const getAdminList = <T = PaginationResponseType<CounterResponseItem>>(
  body: ContractorListRequestType,
  cancelToken: CancelToken
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Admin`,
    body,
    undefined,
    cancelToken
  );

export const getSiteCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Counter/sites`);

export type AdminViewModel = {
  userId: string;
  status: "Active" | "Inactive";
  firstName: string;
  lastName: string;
  email: string;
};

export type SelfAdminViewModel = {
  userId: string;
  firstName: string;
  lastName: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
};

export const getAdminDetail = <T = AdminViewModel | SelfAdminViewModel>(
  adminId?: string
) => getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Admin/${adminId}`);

export const putAdminUpdate = <T = any>(body: AdminViewModel) =>
  putAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Admin/update`, body);

export const putSelfAdminUpdate = <T = any>(body: SelfAdminViewModel) =>
  putAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Admin/updateSelf`, body);

export type Status = "Active" | "Inactive";

export interface ChangeStatusModel {
  id: string;
  status: Status;
}
export const postChangeAdminStatus = <T = null>(body: ChangeStatusModel) =>
  postAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Admin/changeStatus`, body);

export interface CreateAdminViewModel {
  firstName: string;
  lastName: string;
  email: string;
  resetPasswordUrl: string;
}
export const postCreateAdmin = <T = null>(body: CreateAdminViewModel) =>
  postAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Admin/create`, body);

export interface SiteSupervisorPaginatedModel extends PaginationModel {
  siteId?: string;
}

export const getSiteSupervisorList = <
  T = PaginationResponseType<CounterResponseItem>
>(
  body: SiteSupervisorPaginatedModel,
  cancelToken: CancelToken
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/SiteSupervisor`,
    body,
    undefined,
    cancelToken
  );

export type SiteSupervisorViewModel = {
  userId: string;
  status: "Active" | "Inactive";
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  confirmPassword?: string;
  editCredentials: boolean;
  sites: number[];
  userPermissions: string[];
};

export const getSiteSupervisorDetail = <T = SiteSupervisorViewModel>(
  siteSupervisorId?: string
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/SiteSupervisor/${siteSupervisorId}`
  );

export const postChangeSiteSupervisorStatus = <T = null>(
  body: ChangeStatusModel
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/SiteSupervisor/changeStatus`,
    body
  );

export const putSiteSupervisorUpdate = <T = any>(
  body: SiteSupervisorViewModel
) =>
  putAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/SiteSupervisor/update`, body);

export type CreateSiteSupervisorViewModel = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  sites: number[];
  userPermissions: string[];
};

export const postSiteSupervisorCreate = <T = any>(
  body: CreateSiteSupervisorViewModel
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/SiteSupervisor/create`,
    body
  );

const allActions = {
  getAdminList,
  getSiteCounter,
  getAdminDetail,
  putAdminUpdate,
  putSelfAdminUpdate,
  postChangeAdminStatus,
  postCreateAdmin,
  getSiteSupervisorList,
  getSiteSupervisorDetail,
  postChangeSiteSupervisorStatus,
  putSiteSupervisorUpdate,
};

export default allActions;
