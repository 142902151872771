import React from "react";
import { useAppDispatch } from "../../appStore";
import { useSelector } from "react-redux";
import commonReducer from "../redux";
import { IconButton, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useSnackBar = () => {
  const dispatch = useAppDispatch();

  const snackBarMessage = useSelector(
    commonReducer.selectors.getSnackBarMessage
  );

  const onCloseSnackBar = () =>
    dispatch(commonReducer.actions.setSnackBarMessage(null));

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={!!snackBarMessage}
      autoHideDuration={null}
      disableWindowBlurListener
      onClose={onCloseSnackBar}
      message={snackBarMessage}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onCloseSnackBar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default useSnackBar;
