import { AppDispatch } from "../../../appStore";
import companyReducer from "./index";
import {
  CounterResponseModel,
  PaginationModel,
} from "../../../services/apiServiceTypes";
import { getCompanyList } from "./apiCalls";
import { CancelToken } from "axios";
import { isNil } from "ramda";

const fetchListOfCompanies =
  (
    body: PaginationModel,
    isScrollMode: boolean = false,
    cancelToken: CancelToken
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(companyReducer.actions.setIsFetchingCompanyList(true));
    return dispatch(getCompanyList(body, cancelToken))
      .then(({ data }) => {
        if (isScrollMode) {
          dispatch(companyReducer.actions.appendToCompanyList(data.items));
        } else {
          dispatch(companyReducer.actions.setCompanyList(data.items));
        }
        dispatch(
          companyReducer.actions.setCompanyListHasNextPage(data.hasNextPage)
        );
      })
      .catch(() => {
        dispatch(companyReducer.actions.setCompanyListHasNextPage(true));
      })
      .finally(() => {
        dispatch(companyReducer.actions.setIsFetchingCompanyList(false));
      });
  };

const resetCompaniesList = () => (dispatch: AppDispatch) => {
  dispatch(companyReducer.actions.setCompanyList([]));
  dispatch(companyReducer.actions.setCompanyListHasNextPage(true));
  dispatch(companyReducer.actions.setIsFetchingCompanyList(false));
};

const resetCompanies = () => (dispatch: AppDispatch) => {
  dispatch(resetCompaniesList());

  dispatch(companyReducer.actions.setNewCompanyLogo(undefined));
};

const prefillAllowedSecondaryCompanies =
  (accessList: CounterResponseModel, allowedSecondaryCompanies: number[]) =>
  (dispatch: AppDispatch) => {
    let newAccessList = accessList.map((item) => ({
      id: item.codeId,
      isChecked: !isNil(
        allowedSecondaryCompanies.find(
          (e) => e.toString() === item.codeId.toString()
        )
      ),
      children: [],
    }));
    dispatch(
      companyReducer.actions.setAllowedSecondaryCompanies(newAccessList)
    );
  };

const allActions = {
  fetchListOfCompanies,
  resetCompanies,
  prefillAllowedSecondaryCompanies,
};

export default allActions;
