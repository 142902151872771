import { CounterResponseItem } from "../../../services/apiServiceTypes";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";

export interface State {
  companyList: Array<CounterResponseItem>;
  companyListHasNextPage: boolean;
  isFetchingCompanyList: boolean;
  newCompanyLogo?: string;
  allowedSecondaryCompanies: Array<TreeStructureData>;
}

export const initialState: State = {
  companyList: [],
  companyListHasNextPage: true,
  isFetchingCompanyList: false,
  newCompanyLogo: undefined,
  allowedSecondaryCompanies: [],
};
