import * as constants from "./constants";
import reducer from "./reducer";
import selectors from "./selectors";
import dispatchActions from "./dispatchActions";

const reducerExport = {
  ...reducer,
  constants,
  selectors,
  dispatchActions,
};

export default reducerExport;
