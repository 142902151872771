import React, { FC } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import { EntranceEventViewModel } from "./redux/apiCalls";
import {
  isEnteringType,
  isLeavingEnteringType,
  printEventSource,
} from "./helpers";
import beaconBadgeIcon from "../../assets/img/21_SP_BEACON_BADGE_ICON.png";
import beaconIcon from "../../assets/img/21_SP_BEACON_ICON.png";
import qrCodeIcon from "../../assets/img/21_SP_QR_CODE_ICON.png";
import { COLORS } from "../../appTheme";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import cx from "classnames";

const useStyles = makeStyles<Theme>((theme) => ({
  containerRow: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  item: {
    paddingLeft: theme.spacing(1),
  },
  badge: {
    height: 30,
    width: 30,
    filter: "grayscale(100%)",
    backgroundColor: COLORS.GREEN,
    borderRadius: "50%",
    color: COLORS.WHITE,
  },
  eventSourceIcon: {
    borderRadius: "50%",
    height: 25,
    width: 25,
    backgroundColor: COLORS.GREEN,
    filter: "grayscale(100%)",
    padding: 3,
  },
  badgesGrid: {
    width: "fit-content",
  },
}));

export type EntranceTimestampsProps = {
  entranceEventViewModels: EntranceEventViewModel[];
};

const EntranceTimestamps: FC<EntranceTimestampsProps> = ({
  entranceEventViewModels,
}) => {
  const classes = useStyles();

  const getIconPath = (event: EntranceEventViewModel): string => {
    return event.eventSource === "QRReader"
      ? qrCodeIcon
      : event.eventSource === "BeaconReader"
      ? beaconIcon
      : beaconBadgeIcon;
  };

  return (
    <div>
      {entranceEventViewModels?.map((entranceEvent, index) => (
        <Grid
          container
          key={index}
          className={classes.containerRow}
          justifyContent="center"
          direction="row"
        >
          <Grid
            item
            container
            alignItems={"center"}
            className={cx(classes.item, classes.badgesGrid)}
          >
            {isLeavingEnteringType(entranceEvent.eventType) ? (
              <div>
                {isEnteringType(entranceEvent.eventType) ? (
                  <ArrowDownwardIcon className={classes.badge} />
                ) : (
                  <ArrowUpwardIcon className={classes.badge} />
                )}
              </div>
            ) : (
              <div className={classes.badge} />
            )}
            {entranceEvent.eventSource != null && (
              <Grid item className={classes.item}>
                <Tooltip title={printEventSource(entranceEvent.eventSource!)}>
                  <img
                    className={classes.eventSourceIcon}
                    src={getIconPath(entranceEvent)}
                    alt="event-source"
                  />
                </Tooltip>
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.item}>
            <Grid container alignItems="flex-end" direction="column">
              <Grid item>
                <Typography variant="h3">
                  {entranceEvent.dateTimeString}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {entranceEvent.entranceName || " "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default EntranceTimestamps;
