import { makeStyles } from "@material-ui/core/styles";
import React, { forwardRef } from "react";
import { Box, Button, Grid, MenuItem } from "@material-ui/core";
import { FormControlWrapper, SpTextField } from "../../common";
import { Controller } from "react-hook-form";
import {
  ENTRANCE_HELPER_VALUES,
  REPORT_TYPES,
  SITE_HELPER_VALUES,
} from "./helpers/constants";
import moment from "moment";
import { Control, UnpackNestedValue } from "react-hook-form/dist/types/form";
import * as yup from "yup";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { CounterResponseModel } from "../../services/apiServiceTypes";
import {
  DeepPartial,
  LiteralToPrimitive,
} from "react-hook-form/dist/types/utils";
import {
  FieldElement,
  FieldName,
  Ref,
} from "react-hook-form/dist/types/fields";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import {
  FRONTEND_DATE_FORMAT,
  FRONTEND_DATE_TIME_FORMAT,
} from "../../services/helpers";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  buttonsContainer: {
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(1),
  },
}));

export const ReportsSchema = yup.object().shape({
  reportType: yup.string().required("Please select Report Type"),
  secondaryCompany: yup.string().when("reportType", {
    is: REPORT_TYPES.SECONDARY_COMPANY,
    then: yup.string().required("Please select Client Name"),
    otherwise: yup.string().nullable(),
  }),
  company: yup.string().when("reportType", (reportType: string) => {
    if (
      reportType === REPORT_TYPES.COMPANY ||
      reportType === REPORT_TYPES.ALL_CONTRACTORS
    ) {
      return yup.string().required("Please select Client Name");
    }
    return yup.string().nullable();
  }),
  site: yup.string().when("reportType", (reportType: string) => {
    if (
      reportType === REPORT_TYPES.SECONDARY_COMPANY ||
      reportType === REPORT_TYPES.FORMS ||
      reportType === REPORT_TYPES.ALL_CONTRACTORS
    ) {
      return yup.string().nullable();
    }
    return yup.string().required("Please select Site");
  }),
  entrance: yup.string(),
  form: yup.string().when("reportType", {
    is: REPORT_TYPES.FORMS,
    then: yup.string().required("Please select Form"),
    otherwise: yup.string().nullable(),
  }),
  contractor: yup.string().when("reportType", {
    is: REPORT_TYPES.INDIVIDUAL_CONTRACTOR,
    then: yup.string().required("Please select USer"),
    otherwise: yup.string().nullable(),
  }),
  startDate: yup.string().when("reportType", {
    is: REPORT_TYPES.ALL_CONTRACTORS,
    then: yup.string().nullable(),
    otherwise: yup.string().required("Please enter Start Date"),
  }),
  endDate: yup.string().when("reportType", {
    is: REPORT_TYPES.ALL_CONTRACTORS,
    then: yup.string().nullable(),
    otherwise: yup
      .string()
      .required("Please enter End Date")
      .test(
        "dates-compare",
        "End Date has to be greater that the Start Date",
        function (value) {
          return moment(value).isSameOrAfter(this.parent.startDate);
        }
      ),
  }),
});

export interface ReportsInputs {
  reportType: string;
  secondaryCompany: string;
  company: string;
  site: string;
  entrance: string;
  contractor: string;
  form: string;
  startDate: string;
  endDate: string;
}

interface ReportHeaderProps<TFieldValues> {
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  watch(): UnpackNestedValue<TFieldValues>;
  watch<TFieldName extends string, TFieldValue>(
    name?: TFieldName,
    defaultValue?: TFieldName extends keyof TFieldValues
      ? UnpackNestedValue<TFieldValues[TFieldName]>
      : UnpackNestedValue<LiteralToPrimitive<TFieldValue>>
  ): TFieldName extends keyof TFieldValues
    ? UnpackNestedValue<TFieldValues[TFieldName]>
    : UnpackNestedValue<LiteralToPrimitive<TFieldValue>>;
  watch<TFieldName extends keyof TFieldValues>(
    names: TFieldName[],
    defaultValues?: UnpackNestedValue<
      DeepPartial<Pick<TFieldValues, TFieldName>>
    >
  ): UnpackNestedValue<Pick<TFieldValues, TFieldName>>;
  watch(
    names: string[],
    defaultValues?: UnpackNestedValue<DeepPartial<TFieldValues>>
  ): UnpackNestedValue<DeepPartial<TFieldValues>>;

  register<TFieldElement extends FieldElement<TFieldValues>>(
    rules?: RegisterOptions
  ): (ref: (TFieldElement & Ref) | null) => void;
  register(name: FieldName<TFieldValues>, rules?: RegisterOptions): void;
  register<TFieldElement extends FieldElement<TFieldValues>>(
    ref: (TFieldElement & Ref) | null,
    rules?: RegisterOptions
  ): void;
  reportTypeData?: CounterResponseModel;
  companyData?: CounterResponseModel;
  secondaryCompanyNameData?: CounterResponseModel;
  siteData?: CounterResponseModel;
  entranceData?: CounterResponseModel;
  contractorData?: CounterResponseModel;
  formCounterData?: CounterResponseModel;
  onDownloadCSV: () => void;
  onCopyCsvUrl: () => void;
  csvToken?: string;
}

const ReportHeader = forwardRef<
  HTMLDivElement | null,
  ReportHeaderProps<ReportsInputs>
>(
  (
    {
      reportTypeData,
      secondaryCompanyNameData,
      companyData,
      siteData,
      entranceData,
      contractorData,
      formCounterData,
      control,
      errors,
      watch,
      register,
      onDownloadCSV,
      onCopyCsvUrl,
      csvToken,
    },
    ref
  ) => {
    const classes = useStyles();

    const selectedReportType = watch("reportType");

    return (
      <div className={classes.header} ref={ref}>
        <Grid container direction="row" spacing={2}>
          <FormControlWrapper
            title="Report Type"
            width={3}
            input={
              <Controller
                control={control}
                name="reportType"
                defaultValue={""}
                as={
                  <SpTextField
                    select
                    placeholder="Select Report Type"
                    errors={errors}
                  >
                    {!reportTypeData && <MenuItem value={""}>No data</MenuItem>}
                    {reportTypeData?.map((type) => (
                      <MenuItem key={type.codeId} value={type.codeId}>
                        {type.displayName}
                      </MenuItem>
                    ))}
                  </SpTextField>
                }
              />
            }
          />
          {selectedReportType === REPORT_TYPES.SECONDARY_COMPANY && (
            <FormControlWrapper
              title="Client Name"
              width={3}
              input={
                <Controller
                  control={control}
                  name="secondaryCompany"
                  defaultValue={""}
                  as={
                    <SpTextField
                      select
                      placeholder="Select Client"
                      errors={errors}
                    >
                      {!secondaryCompanyNameData && (
                        <MenuItem value={""}>No data</MenuItem>
                      )}
                      {secondaryCompanyNameData?.map((company) => (
                        <MenuItem key={company.codeId} value={company.codeId}>
                          {company.displayName}
                        </MenuItem>
                      ))}
                    </SpTextField>
                  }
                />
              }
            />
          )}
          {(selectedReportType === REPORT_TYPES.COMPANY ||
            selectedReportType === REPORT_TYPES.ALL_CONTRACTORS) && (
            <FormControlWrapper
              title="Client Name"
              width={3}
              input={
                <Controller
                  control={control}
                  name="company"
                  defaultValue={""}
                  as={
                    <SpTextField
                      select
                      placeholder="Select Company"
                      errors={errors}
                    >
                      {!companyData && <MenuItem value={""}>No data</MenuItem>}
                      {companyData?.map((company) => (
                        <MenuItem key={company.codeId} value={company.codeId}>
                          {company.displayName}
                        </MenuItem>
                      ))}
                    </SpTextField>
                  }
                />
              }
            />
          )}
          {selectedReportType !== REPORT_TYPES.ALL_CONTRACTORS && (
            <FormControlWrapper
              title="Location"
              width={3}
              input={
                <Controller
                  control={control}
                  name="site"
                  defaultValue={""}
                  as={
                    <SpTextField
                      select
                      placeholder="Select Site"
                      errors={errors}
                    >
                      {(selectedReportType === REPORT_TYPES.SECONDARY_COMPANY ||
                        selectedReportType === REPORT_TYPES.COMPANY) &&
                        siteData && (
                          <MenuItem value={SITE_HELPER_VALUES.ALL_SITES}>
                            <em>All Locations</em>
                          </MenuItem>
                        )}
                      {!siteData && <MenuItem value={""}>No data</MenuItem>}
                      {siteData?.map((site) => (
                        <MenuItem key={site.codeId} value={site.codeId}>
                          {site.displayName}
                        </MenuItem>
                      ))}
                    </SpTextField>
                  }
                />
              }
            />
          )}
          {selectedReportType !== REPORT_TYPES.SITE_QUALIFICATIONS &&
            selectedReportType !== REPORT_TYPES.SITE_HELP_REQUESTS &&
            selectedReportType !== REPORT_TYPES.ALL_CONTRACTORS &&
            selectedReportType !== REPORT_TYPES.SITE_STATISTICS &&
            selectedReportType !== REPORT_TYPES.SITE_QUALIFICATIONS_EXPIRING &&
            selectedReportType !== REPORT_TYPES.FORMS && (
              <FormControlWrapper
                title="Entrance"
                width={3}
                input={
                  <Controller
                    control={control}
                    name="entrance"
                    defaultValue={""}
                    as={
                      <SpTextField
                        select
                        placeholder="Select Entrance"
                        errors={errors}
                      >
                        {!entranceData && (
                          <MenuItem value={""}>No data</MenuItem>
                        )}
                        {entranceData && (
                          <MenuItem
                            value={ENTRANCE_HELPER_VALUES.ALL_ENTRANCES}
                          >
                            <em>All Entrances</em>
                          </MenuItem>
                        )}
                        {entranceData?.map((company) => (
                          <MenuItem key={company.codeId} value={company.codeId}>
                            {company.displayName}
                          </MenuItem>
                        ))}
                      </SpTextField>
                    }
                  />
                }
              />
            )}
          {selectedReportType === REPORT_TYPES.INDIVIDUAL_CONTRACTOR && (
            <FormControlWrapper
              title="User"
              width={3}
              input={
                <Controller
                  control={control}
                  name="contractor"
                  defaultValue={""}
                  as={
                    <SpTextField
                      select
                      placeholder="Select User"
                      errors={errors}
                    >
                      {(!contractorData || contractorData.length === 0) && (
                        <MenuItem value={""}>No data</MenuItem>
                      )}
                      {contractorData?.map((contractor) => (
                        <MenuItem
                          key={contractor.codeId}
                          value={contractor.codeId}
                        >
                          {contractor.displayName}
                        </MenuItem>
                      ))}
                    </SpTextField>
                  }
                />
              }
            />
          )}
          {selectedReportType === REPORT_TYPES.FORMS && (
            <FormControlWrapper
              title="Form"
              width={3}
              input={
                <Controller
                  control={control}
                  name="form"
                  defaultValue={""}
                  as={
                    <SpTextField
                      select
                      placeholder="Select Form"
                      errors={errors}
                    >
                      {(!formCounterData || formCounterData.length === 0) && (
                        <MenuItem value={""}>No data</MenuItem>
                      )}
                      {formCounterData?.map((company) => (
                        <MenuItem key={company.codeId} value={company.codeId}>
                          {company.displayName}
                        </MenuItem>
                      ))}
                    </SpTextField>
                  }
                />
              }
            />
          )}
        </Grid>
        <Grid container direction="row" spacing={2}>
          {selectedReportType === REPORT_TYPES.SITE_QUALIFICATIONS ||
            selectedReportType === REPORT_TYPES.SITE_HELP_REQUESTS ||
            selectedReportType === REPORT_TYPES.SITE_STATISTICS ||
            selectedReportType === REPORT_TYPES.SITE_QUALIFICATIONS_EXPIRING ||
            selectedReportType === REPORT_TYPES.FORMS}

          <FormControlWrapper
            title="Start date"
            width={3}
            input={
              <SpTextField
                name="startDate"
                inputRef={register}
                fullWidth
                type={
                  selectedReportType === REPORT_TYPES.CAMERA
                    ? "datetime-local"
                    : "date"
                }
                defaultValue={moment().format(
                  selectedReportType === REPORT_TYPES.CAMERA
                    ? FRONTEND_DATE_TIME_FORMAT
                    : FRONTEND_DATE_FORMAT
                )}
                errors={errors}
              />
            }
          />
          <FormControlWrapper
            title="End date"
            width={3}
            input={
              <SpTextField
                name="endDate"
                inputRef={register}
                fullWidth
                type={
                  selectedReportType === REPORT_TYPES.CAMERA
                    ? "datetime-local"
                    : "date"
                }
                defaultValue={moment().format(
                  selectedReportType === REPORT_TYPES.CAMERA
                    ? FRONTEND_DATE_TIME_FORMAT
                    : FRONTEND_DATE_FORMAT
                )}
                errors={errors}
              />
            }
          />
          <Grid item lg={6} xs={12}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.buttonsContainer}
              gridGap={16}
            >
              {selectedReportType !== REPORT_TYPES.FORMS &&
                selectedReportType !== REPORT_TYPES.CAMERA && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onDownloadCSV}
                    >
                      Download
                    </Button>
                    {!!csvToken && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onCopyCsvUrl}
                      >
                        Copy CSV URL
                      </Button>
                    )}
                  </>
                )}
              <Button variant="contained" color="primary" type="submit">
                Load
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
);

export default ReportHeader;
