const UsAndSvkRegex: RegExp = /^((\+421[0-9]{9})|((\+1)?[0-9]{10}))$/;

// const UsAndSvkRegexMessage =
//   "Invalid phone number. Please enter US or Slovak phone number including country code.";

const UsRegexMessage =
  "Invalid phone number. Please enter US phone number without any delimiters.";

export const phoneNumberRegex =
  // eslint-disable-next-line no-restricted-globals
  // location.hostname === "localhost" ? UsAndSvkRegex :
  UsAndSvkRegex;
export const phoneNumberMessage =
  // eslint-disable-next-line no-restricted-globals
  // location.hostname === "localhost" ? UsAndSvkRegexMessage :
  UsRegexMessage;

export const beaconScannerCard =
  /^$|[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}$/;
export const beaconScannerCardMessage = "Invalid beacon card id.";
