import React from "react";
import { useAuthorization } from "../hooks";
import { InsufficientRolePage } from "../index";
import { isNil } from "ramda";

export interface AuthorizationProps {
  allowedRoles: Array<string>;
  allowedPermissions?: Array<string>;
}

function withAuthorization<P>(WrappedComponent: React.ComponentType<P>) {
  const EnhancedComponent = ({
    allowedRoles,
    allowedPermissions,
    ...rest
  }: P & AuthorizationProps) => {
    const { isAllowed } = useAuthorization(allowedRoles, allowedPermissions);

    if (isNil(allowedRoles) || (!isNil(allowedRoles) && isAllowed)) {
      // @ts-ignore
      return <WrappedComponent {...rest} allowedRoles={allowedRoles} />;
    }
    return <InsufficientRolePage />;
  };

  return EnhancedComponent;
}

export default withAuthorization;
