import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useCallback } from "react";
import { isNil } from "ramda";
import { SpIcon } from "../../icons";
import { Grid, Typography, Radio, RadioProps } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";

const useStyles = makeStyles<Theme, SpRadioButtonStyleProps>((theme) => ({
  icon: {
    height: 32.372,
    width: 32.372,
    paddingRight: theme.spacing(2),
  },
  link: ({ disabled }) => ({
    width: "100%",
    cursor: (disabled && "not-allowed") || "pointer",
  }),
}));

interface SpRadioButtonStyleProps {
  disabled?: boolean;
}

const SpRadioButton: React.FC<SpRadioButtonProps> = ({
  onClick,
  children,
  customTrueIcon,
  customFalseIcon,
  checked,
  variant = "h3",
  color = "inherit",
  onChange,
  disabled,
  ...rest
}) => {
  const classes = useStyles({ disabled });

  const handleOnClick = useCallback(() => {
    if (!isNil(onClick)) {
      const newState = !checked;
      onClick(newState);
    } else {
      if (!isNil(onChange)) {
        const newValue = !checked;
        onChange(newValue);
      }
    }
  }, [onChange, onClick, checked]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.link}
      onClick={!disabled ? handleOnClick : undefined}
    >
      <Grid item>
        <Radio
          checkedIcon={
            <SpIcon iconName={customTrueIcon ? customTrueIcon : "dotYes"} />
          }
          icon={
            <SpIcon iconName={customFalseIcon ? customFalseIcon : "dotNo"} />
          }
          className={classes.icon}
          checked={checked}
          onChange={handleOnClick}
          disabled={disabled}
          {...rest}
        />
      </Grid>
      <Grid item>
        <Typography variant={variant} color={color}>
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SpRadioButton;

// @ts-ignore
export interface SpRadioButtonProps extends RadioProps {
  onClick?: (value: boolean) => void;
  customFalseIcon?: string;
  customTrueIcon?: string;
  checked?: boolean;
  variant?: Variant | "inherit";
  color?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  onChange?: (newValue: boolean) => void;
}
