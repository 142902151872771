import React, { useEffect, useRef, useState } from "react";
import { isNil } from "ramda";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { loadFile, loadThumbnail } from "../../services/authApi";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../appStore";
import noCamera from "../../assets/img/21_SP_NO CAMERA-ICON.png";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { ReportDataRows } from "../../admin/reports/ReportTable";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

const useStyles = makeStyles<Theme>((theme) => ({
  image: {
    width: 125,
    height: 70,
    objectFit: "contain",
    cursor: "pointer",
  },
  largeImage: {
    width: "100%",
    height: "80%",
  },
}));

export interface PersonalInfoDetailProps {
  photoId: string;
  rowData?: ReportDataRows;
}

const TablePhoto: React.FC<PersonalInfoDetailProps> = ({
  photoId,
  rowData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const [photoThumbnailUrl, setPhotoThumbnailUrl] = useState<string>();
  const [photoUrl, setPhotoUrl] = useState<string>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const componentToPrintRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    dispatch(loadThumbnail(photoId))
      .then((url: string) => {
        setPhotoThumbnailUrl(url);
      })
      .catch(() => {
        setPhotoThumbnailUrl(undefined);
      });
  }, [dispatch, photoId]);

  useEffect(() => {
    if (openDialog && !photoUrl) {
      dispatch(loadFile(photoId))
        .then((url: string) => {
          setPhotoUrl(url);
        })
        .catch(() => {
          setPhotoUrl(undefined);
        });
    }
  }, [dispatch, photoId, openDialog, photoUrl]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  return (
    <>
      {!isNil(photoThumbnailUrl) && (
        <>
          <img
            alt="thumbnail"
            src={photoThumbnailUrl}
            className={classes.image}
            onClick={() => setOpenDialog(true)}
          />
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="xl"
          >
            <DialogContent ref={componentToPrintRef}>
              <img
                alt="large"
                src={photoUrl || noCamera}
                className={classes.largeImage}
              />

              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent={"center"}
              >
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body1">Date</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{rowData?.date}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body1">Time</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {moment(rowData?.time).format("LTS")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body1">Contractor</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {rowData?.contractor || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body1">Company</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {rowData?.companyName || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} variant="contained">
                Close
              </Button>
              <Button
                onClick={handlePrint}
                color="primary"
                variant="contained"
                autoFocus
              >
                Print
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {isNil(photoUrl) && null}
    </>
  );
};

export default TablePhoto;
