import React, { FC, useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import eventLogReducer from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "ramda";
import {
  getBackgroundColorOfHelpRequest,
  printEventSource,
  printEventType,
} from "../helpers";
import { SpTitle } from "../../../common";
import noCamera from "../../../assets/img/21_SP_NO CAMERA-ICON.png";
import { useFetchData } from "../../../services/useFetchData";
import { loadFile } from "../../../services/authApi";
import { AppDispatch } from "../../../appStore";
import {
  ContractorEventsViewModel,
  EventType,
  fetchContractorEventData,
} from "../redux/apiCalls";
import EntranceTimestamps from "../EntranceTimestamps";
import { State } from "../redux/model";

const widthOfContent = "35vw";

const useStyles = makeStyles<Theme, { type?: EventType }>((theme) => ({
  photo: {
    width: widthOfContent,
    height: "50vh",
    objectFit: "contain",
  },
  timestamps: {
    width: widthOfContent,
    maxHeight: "20vh",
    overflow: "auto",
  },
  background: ({ type }) => getBackgroundColorOfHelpRequest(type),
}));

type ContractorEventDialogProps = {
  panelId: number;
};

const ContractorEventDialog: FC<ContractorEventDialogProps> = ({ panelId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [photoUrl, setPhotoUrl] = useState<string>();
  const selectedEvent = useSelector((state: { [x: string]: State }) =>
    eventLogReducer.selectors.getSelectedEvent(state, panelId)
  );
  const classes = useStyles({ type: selectedEvent?.data?.eventType });
  const isOpen = !isNil(selectedEvent);

  useEffect(() => {
    if (selectedEvent) {
      // Reset on change
      setPhotoUrl(undefined);
    }
  }, [selectedEvent]);

  useFetchData<string>(
    // @ts-ignore
    loadFile(selectedEvent?.data.userPhotoId), // TODO or photo
    !isNil(selectedEvent?.data.userPhotoId),
    undefined,
    [selectedEvent],
    // @ts-ignore
    (response) => setPhotoUrl(response),
    () => setPhotoUrl(undefined),
    () => setPhotoUrl(undefined)
  );

  const { data: eventData } = useFetchData<ContractorEventsViewModel>(
    fetchContractorEventData(
      // @ts-ignore
      selectedEvent?.data?.entranceId,
      selectedEvent?.data?.userId
    ),
    !isNil(selectedEvent?.data?.entranceId) &&
      !isNil(selectedEvent?.data?.userId),
    undefined,
    [selectedEvent]
  );

  const handleOnClose = () => {
    dispatch(
      eventLogReducer.actions.setSelectedEvent({
        selectedEvent: undefined,
        panelId: panelId,
      })
    );
  };

  return (
    <>
      <Dialog onClose={() => null} open={isOpen} maxWidth={false}>
        <div className={classes.background}>
          <DialogTitle>
            {selectedEvent?.data.eventType &&
              printEventType(selectedEvent?.data.eventType)}
            {` ${selectedEvent?.data.dateTimeString}`}
            {selectedEvent?.data.eventSource &&
              ` - ${printEventSource(selectedEvent?.data.eventSource)}`}
          </DialogTitle>
          <DialogContent>
            <img
              src={photoUrl || noCamera}
              alt="contractor-portrait"
              className={classes.photo}
            />
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems={"center"}
            >
              <Typography variant="h3">{`${
                (selectedEvent?.data.firstName &&
                  selectedEvent?.data.firstName) ||
                ""
              }${
                (selectedEvent?.data.lastName &&
                  ` ${selectedEvent?.data.lastName}`) ||
                ""
              }`}</Typography>
              <Typography variant="subtitle1">
                {selectedEvent?.data.companyName}
              </Typography>
            </Grid>
            <SpTitle title="Timestamps" />
            <div className={classes.timestamps}>
              <EntranceTimestamps
                entranceEventViewModels={
                  eventData?.entranceEventViewModels || []
                }
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleOnClose}>
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default ContractorEventDialog;
