import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SitesTabsDetail } from "../../admin/sites/details";
import withAuthorization from "../../auth/hoc/withAutorization";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../appStore";
import siteReducer from "../../admin/sites/redux";

const SiteRoutes: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedSiteId } = useParams<{ selectedSiteId: string }>();

  useEffect(() => {
    dispatch(siteReducer.actions.setSelectedSiteId(selectedSiteId));
  }, [dispatch, selectedSiteId]);

  return <SitesTabsDetail />;
};

export default withAuthorization(SiteRoutes);
