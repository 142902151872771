import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import withAuthorization from "../../auth/hoc/withAutorization";
import { PageContent, SpPanel } from "../../common";
import ReportTable from "./ReportTable";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchData } from "../../services/useFetchData";
import { CounterResponseModel } from "../../services/apiServiceTypes";
import {
    getContractorsCounter,
    getEntranceCounter,
    getFormCounter,
    getReportsTypesCounter,
    getCompanyCounter,
    getSecondaryCompanyCounter,
    getSiteCounter,
    getSitesFromCompanyCounter,
    postCreateReport,
    postDownloadCSVReport,
    ReportResponse,
} from "./helpers/apiCalls";
import {
    ENTRANCE_HELPER_VALUES,
    REPORT_TYPES,
    SITE_HELPER_VALUES,
} from "./helpers/constants";
import moment from "moment";
import ReportHeader, { ReportsInputs, ReportsSchema } from "./ReportHeader";
import {
    BACKEND_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
    FRONTEND_DATE_FORMAT,
    FRONTEND_DATE_TIME_FORMAT,
} from "../../services/helpers";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../appStore";
import { useComputeSize } from "../../services/useComputeSize";
import { getCsvAccessToken } from "./helpers/apiCalls";
import commonReducer from "../../common/redux";
import { APP_SETTINGS } from "../../AppSettings";
import copy from "copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: theme.spacing(4),
    },
    title: {
        paddingBottom: theme.spacing(2),
    },
    mainPanel: {
        height: `calc(100vh - ${theme.spacing(17)}px)`
    },
    mobileMainPanel: {
        height: `calc(100vh - ${theme.spacing(17)}px)`,
        width: `${window.innerWidth - theme.spacing(12)}px`
    }
}));

const Reports: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch<AppDispatch>();
    const [dataReport, setDataReport] = useState<ReportResponse>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const reportHeaderRef = useRef<HTMLDivElement | null>(null);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        errors,
        watch,
        formState,
    } = useForm<ReportsInputs>({
        resolver: yupResolver(ReportsSchema),
    });

    const { height: reportHeaderHeight } = useComputeSize(reportHeaderRef, [
        watch("companyName"),
    ]);

    const { data: reportTypeData } = useFetchData<CounterResponseModel>(
        getReportsTypesCounter()
    );

    const { data: siteData } = useFetchData<CounterResponseModel>(
        watch("reportType") === REPORT_TYPES.COMPANY
            ? getSitesFromCompanyCounter({ companyId: watch("company") })
            : getSiteCounter(),
        watch("reportType") !== REPORT_TYPES.COMPANY ||
        (watch("reportType") === REPORT_TYPES.COMPANY &&
            watch("company") !== undefined),
        undefined,
        [watch("reportType"), watch("company")]
    );

    const { data: companyData } = useFetchData<CounterResponseModel>(
        getCompanyCounter(),
        watch("reportType") === REPORT_TYPES.COMPANY ||
        watch("reportType") === REPORT_TYPES.ALL_CONTRACTORS,
        undefined,
        [watch("reportType")],
        () => setValue("site", SITE_HELPER_VALUES.ALL_SITES)
    );

    const { data: secondaryCompanyNameData } = useFetchData<CounterResponseModel>(
        getSecondaryCompanyCounter(),
        watch("reportType") === REPORT_TYPES.SECONDARY_COMPANY,
        undefined,
        [watch("reportType")],
        () => setValue("site", SITE_HELPER_VALUES.ALL_SITES)
    );

    const { data: entranceData } = useFetchData<CounterResponseModel>(
        getEntranceCounter(watch("site")),
        watch("site") !== "" && watch("site") !== undefined,
        undefined,
        [watch("site")],
        () => setValue("entrance", ENTRANCE_HELPER_VALUES.ALL_ENTRANCES)
    );

    const { data: formCounterData } = useFetchData<CounterResponseModel>(
        getFormCounter(),
        watch("reportType") === REPORT_TYPES.FORMS,
        undefined,
        [watch("site")]
    );

    const { data: contractorData } = useFetchData<CounterResponseModel>(
        getContractorsCounter(watch("site"), watch("entrance")),
        watch("site") !== "" &&
        watch("site") !== undefined &&
        watch("site") !== SITE_HELPER_VALUES.ALL_SITES,
        undefined,
        [watch("site"), watch("entrance")]
    );

    const reportType = watch("reportType");
    const startDate = watch("startDate");
    const endDate = watch("endDate");
    const entrance = watch("entrance");
    const site = watch("site");
    const timeFormat = "HH:mm";

    const [csvToken, setCsvToken] = useState(undefined);

    useFetchData<string>(
        // @ts-ignore
        getCsvAccessToken(),
        !csvToken,
        undefined,
        [],
        // @ts-ignore
        (response) => setCsvToken(response.data),
        () => setCsvToken(undefined),
        () => setCsvToken(undefined)
    );

    useEffect(() => {
        if (formState.isDirty) {
            if (reportType === REPORT_TYPES.CAMERA) {
                if (startDate === "") {
                    setValue(
                        "startDate",
                        moment().startOf("day").format(FRONTEND_DATE_TIME_FORMAT)
                    );
                }
                if (startDate?.length === 10) {
                    setValue(
                        "startDate",
                        moment(
                            `${startDate} ${moment().startOf("day").format(timeFormat)}`
                        ).format(FRONTEND_DATE_TIME_FORMAT)
                    );
                }
                if (endDate === "") {
                    setValue(
                        "endDate",
                        moment().endOf("day").format(FRONTEND_DATE_TIME_FORMAT)
                    );
                }
                if (endDate?.length === 10) {
                    setValue(
                        "endDate",
                        moment(
                            `${endDate} ${moment().endOf("day").format(timeFormat)}`
                        ).format(FRONTEND_DATE_TIME_FORMAT)
                    );
                }
            } else {
                if (startDate === "") {
                    setValue(
                        "startDate",
                        moment().startOf("day").format(FRONTEND_DATE_FORMAT)
                    );
                }
                if (startDate?.length > 10) {
                    setValue(
                        "startDate",
                        moment(startDate).startOf("day").format(FRONTEND_DATE_FORMAT)
                    );
                }
                if (endDate === "") {
                    setValue(
                        "endDate",
                        moment().endOf("day").format(FRONTEND_DATE_FORMAT)
                    );
                }
                if (endDate?.length > 10) {
                    setValue(
                        "endDate",
                        moment(endDate).endOf("day").format(FRONTEND_DATE_FORMAT)
                    );
                }
            }
        }
    }, [
        reportType,
        startDate,
        endDate,
        formState.isDirty,
        formState.isSubmitted,
        setValue,
        formState,
    ]);

    useEffect(() => {
        // Clean selected contractor when site or entrance is changed in individual report
        if (
            reportType === REPORT_TYPES.INDIVIDUAL_CONTRACTOR &&
            watch("contractor") !== undefined
        ) {
            setValue("contractor", undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportType, entrance, site]);

    useEffect(() => {
        // Clean results on parameters change
        setDataReport(undefined);
    }, [reportType, startDate, endDate, entrance, site]);

    const onSubmitLoadReport = (formInputData: ReportsInputs) => {
        if (
            formInputData.reportType &&
            formInputData.startDate &&
            formInputData.endDate
        ) {
            setDataReport(undefined);
            setIsLoading(true);
            dispatch(
                postCreateReport({
                    selectedReportType: formInputData.reportType,
                    selectedCompany: formInputData.company,
                    selectedSecondaryCompany: formInputData.secondaryCompany,
                    selectedSite:
                        formInputData.site === SITE_HELPER_VALUES.ALL_SITES
                            ? undefined
                            : (formInputData.site !== "" && formInputData.site) || undefined,
                    selectedEntrance:
                        formInputData.entrance === ENTRANCE_HELPER_VALUES.ALL_ENTRANCES
                            ? undefined
                            : formInputData.entrance,
                    selectedContractor: formInputData.contractor,
                    selectedForm: formInputData.form,
                    startDate:
                        formInputData.reportType === REPORT_TYPES.ALL_CONTRACTORS
                            ? moment().format(BACKEND_DATE_TIME_FORMAT_WITHOUT_TIMEZONE)
                            : moment(formInputData.startDate).format(
                                BACKEND_DATE_TIME_FORMAT_WITHOUT_TIMEZONE
                            ),
                    endDate:
                        formInputData.reportType === REPORT_TYPES.ALL_CONTRACTORS
                            ? moment().format(BACKEND_DATE_TIME_FORMAT_WITHOUT_TIMEZONE)
                            : moment(formInputData.endDate).format(
                                BACKEND_DATE_TIME_FORMAT_WITHOUT_TIMEZONE
                            ),
                })
            )
                .then((response) => {
                    setDataReport(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    setDataReport(undefined);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const onDownloadCSV = (formInputData: ReportsInputs) => {
        if (
            formInputData.reportType &&
            formInputData.startDate &&
            formInputData.endDate
        ) {
            dispatch(
                postDownloadCSVReport({
                    selectedReportType: formInputData.reportType,
                    selectedCompany: formInputData.company,
                    selectedSecondaryCompany: formInputData.secondaryCompany,
                    selectedSite:
                        formInputData.site === SITE_HELPER_VALUES.ALL_SITES
                            ? undefined
                            : (formInputData.site !== "" && formInputData.site) || undefined,
                    selectedEntrance:
                        formInputData.entrance === ENTRANCE_HELPER_VALUES.ALL_ENTRANCES
                            ? undefined
                            : formInputData.entrance,
                    selectedContractor: formInputData.contractor,
                    selectedForm: formInputData.form,
                    startDate: moment(formInputData.startDate).format(
                        BACKEND_DATE_TIME_FORMAT_WITHOUT_TIMEZONE
                    ),
                    endDate: moment(formInputData.endDate).format(
                        BACKEND_DATE_TIME_FORMAT_WITHOUT_TIMEZONE
                    ),
                })
            );
        }
    };

    const onCopyCSVUrl = (formInputData: ReportsInputs) => {
        if (
            formInputData.reportType &&
            formInputData.startDate &&
            formInputData.endDate
        ) {
            const startDate =
                moment(formInputData.startDate).format(
                    BACKEND_DATE_TIME_FORMAT_WITHOUT_TIMEZONE
                ) || "";
            const endDate =
                moment(formInputData.endDate).format(
                    BACKEND_DATE_TIME_FORMAT_WITHOUT_TIMEZONE
                ) || "";
            const selectedSite =
                formInputData.site === SITE_HELPER_VALUES.ALL_SITES
                    ? ""
                    : (formInputData.site !== "" && formInputData.site) || "";
            const selectedEntrance =
                formInputData.entrance === ENTRANCE_HELPER_VALUES.ALL_ENTRANCES
                    ? ""
                    : formInputData.entrance;

            const csvUrl =
                `${APP_SETTINGS.BACKEND_BASE_URL}/Report/DownloadCsv?accessToken=${csvToken}` +
                `&selectedCompany=${formInputData.company || ""}` +
                `&selectedReportType=${formInputData.reportType}` +
                `&selectedSecondaryCompany=${formInputData.secondaryCompany || ""}` +
                `&selectedSite=${selectedSite || ""}` +
                `&selectedEntrance=${selectedEntrance || ""}` +
                `&selectedContractor=${formInputData.contractor || ""}` +
                `&startDate=${startDate}&endDate=${endDate}`;

            copy(csvUrl);
            dispatch(
                commonReducer.actions.setSnackBarMessage("CSV url copied to clipboard.")
            );
        }
    };

    return (
        <PageContent title="Reports" showButton={false}>
            <SpPanel withoutFooter>
                <div className={classes.mainPanel}>
                    <form onSubmit={handleSubmit(onSubmitLoadReport)}>
                        <ReportHeader
                            control={control}
                            errors={errors}
                            watch={watch}
                            register={register}
                            reportTypeData={reportTypeData}
                            companyData={companyData}
                            secondaryCompanyNameData={secondaryCompanyNameData}
                            siteData={siteData}
                            entranceData={entranceData}
                            contractorData={contractorData}
                            formCounterData={formCounterData}
                            onDownloadCSV={handleSubmit(onDownloadCSV)}
                            ref={reportHeaderRef}
                            csvToken={csvToken}
                            onCopyCsvUrl={handleSubmit(onCopyCSVUrl)}
                        />
                    </form>
                    <ReportTable
                        selectedReportType={watch("reportType")}
                        dataReport={dataReport}
                        reportHeaderHeight={reportHeaderHeight}
                        isLoading={isLoading}
                    />
                </div>
            </SpPanel>
        </PageContent>
    );
};

export default withAuthorization(Reports);
