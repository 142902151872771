import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { customDebounce } from "../../../services/helpers";
import { AppDispatch } from "../../../appStore";
import { SpSearchTextField } from "../../../common/components/inputs/SpSearchTextField";
import InfiniteScroll from "react-infinite-scroll-component";
import { SpFormControl, SpListItem, SpTextField } from "../../../common";
import {
  CHECK_IN_OUT_REASON_COUNTER_TYPES,
  REASON_TYPE,
} from "../../../counters";
import { isNil } from "ramda";
import {
  ManualCheckInOutReason,
  postManualCheckIn,
  postManualCheckOut,
} from "../redux/apiCalls";
import eventLogRedux from "../redux";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { State } from "../redux/model";

const useStyles = makeStyles<Theme>((theme) => ({
  photo: {
    width: "50vw",
    height: "65vh",
  },
}));

type ManualCheckDialogProps = {
  panelId: number;
  isOpen: boolean;
  onClose: () => void;
  refetchEntranceEventList: () => void;
};

const ManualCheckDialog: FC<ManualCheckDialogProps> = ({
  panelId,
  isOpen,
  onClose,
  refetchEntranceEventList,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const contractorListRequest = useRef<CancelTokenSource>(
    axios.CancelToken.source()
  );
  const isFetchingContractorList = useSelector(
    (state: { [x: string]: State }) =>
      eventLogRedux.selectors.getIsFetchingContractorList(state, panelId)
  );
  const pageSize: number = 50;
  const pageIndex = useRef<number>(0);
  const [selectedReasonType, setSelectedReasonType] = useState<string>(
    REASON_TYPE.OTHER
  );
  const [selectedContractorId, setSelectedContractorId] = useState<string>();
  const [searchContractor, setSearchContractor] = useState<string>();
  const contractorListHasNextPage = useSelector(
    (state: { [x: string]: State }) =>
      eventLogRedux.selectors.getContractorListHasNextPage(state, panelId)
  );
  const contractorList = useSelector((state: { [x: string]: State }) =>
    eventLogRedux.selectors.getContractorList(state, panelId)
  );
  const selectedEntrance = useSelector((state: { [x: string]: State }) =>
    eventLogRedux.selectors.getSelectedEntrance(state, panelId)
  );

  const [checkType, setCheckType] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCheckType(newValue);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    customDebounce(
      (
        searchContractor: string,
        incrementedPageIndex: number,
        isScrollMode: boolean,
        cancelToken: CancelToken
      ) => {
        dispatch(
          eventLogRedux.dispatchActions.fetchListOfContractorsInManualCheckInOut(
            {
              Pagination: {
                PageIndex: incrementedPageIndex,
                PageSize: pageSize,
              },
              SearchVal: searchContractor,
              activeUsers: true,
            },
            isScrollMode,
            cancelToken,
            panelId
          )
        )
          .then(() => {
            pageIndex.current = incrementedPageIndex;
          })
          .catch(() => {
            pageIndex.current = 0;
          });
      },
      800
    ),
    []
  );

  const fetchNextData = useCallback(
    (isScrollMode: boolean = false) => {
      if (contractorListHasNextPage) {
        if (isFetchingContractorList) {
          contractorListRequest.current.cancel("Cancel previous request");
          contractorListRequest.current = axios.CancelToken.source();
        }
        const incrementedPageIndex = pageIndex.current + 1;
        debouncedCall(
          searchContractor,
          incrementedPageIndex,
          isScrollMode,
          contractorListRequest.current.token
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedCall, searchContractor]
  );

  const resetPagination = useCallback(() => {
    dispatch(
      eventLogRedux.actions.setContractorListHasNextPage({
        contractorListHasNextPage: true,
        panelId: panelId,
      })
    );
    dispatch(
      eventLogRedux.actions.setContractorList({
        contractorList: [],
        panelId: panelId,
      })
    );
    pageIndex.current = 0;
  }, [dispatch, panelId]);

  const handleChangeSearchContractor = (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    setSearchContractor(event.target.value as string);
    resetPagination();
  };

  const refetchContractors = useCallback(() => {
    resetPagination();
    fetchNextData();
  }, [fetchNextData, resetPagination]);

  useEffect(() => {
    refetchContractors();
  }, [refetchContractors]);

  useEffect(() => {
    return () => {
      dispatch(
        eventLogRedux.dispatchActions.resetManualCheckInOutDialog(panelId)
      );
      contractorListRequest.current.cancel("Contractors were unmounted");
    };
  }, [dispatch, panelId]);

  const onSubmit = () => {
    if (
      selectedContractorId &&
      selectedReasonType &&
      selectedEntrance?.codeId
    ) {
      let promises: any[] = [];
      if (checkType === 0) {
        promises.push(
          dispatch(
            postManualCheckIn({
              userId: selectedContractorId,
              reason: selectedReasonType as ManualCheckInOutReason,
              entranceId: selectedEntrance?.codeId,
            })
          )
        );
      } else if (checkType === 1) {
        promises.push(
          dispatch(
            postManualCheckOut({
              userId: selectedContractorId,
              reason: selectedReasonType as ManualCheckInOutReason,
              entranceId: selectedEntrance?.codeId,
            })
          )
        );
      }
      Promise.all(promises).then(() => {
        onClose();
        refetchEntranceEventList();
      });
    }
  };

  return (
    <Dialog onClose={() => null} open={isOpen} maxWidth={false}>
      <DialogContent>
        <Tabs
          value={checkType}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Check-In" />
          <Tab label="Check-Out" />
        </Tabs>
        <SpSearchTextField
          fullWidth
          placeholder="SEARCH CONTRACTOR..."
          value={searchContractor || ""}
          onChange={handleChangeSearchContractor}
        />
        <InfiniteScroll
          style={{ width: "30vw" }}
          dataLength={contractorList.length} //This is important field to render the next data
          next={() => fetchNextData(true)}
          hasMore={contractorListHasNextPage}
          loader={
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.footerContainer}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          }
          endMessage={
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ textAlign: "center" }}
            >
              No more data
            </Typography>
          }
          height={400}
          className={classes.scrollPanel}
        >
          {contractorList.map((contractor, index) => (
            <SpListItem
              key={index}
              title={contractor.displayName}
              isActive={selectedContractorId === contractor.codeId}
              onClick={() => {
                setSelectedContractorId(contractor.codeId);
              }}
              inactiveItem={!contractor.isActive}
              withRadio
            />
          ))}
        </InfiniteScroll>
        <SpFormControl
          title="Reason"
          input={
            <SpTextField
              select
              placeholder="Select Reason Type"
              value={selectedReasonType}
              onChange={(event: React.ChangeEvent<{ value: any }>) => {
                setSelectedReasonType(event.target.value as string);
              }}
            >
              {CHECK_IN_OUT_REASON_COUNTER_TYPES.map((reason) => (
                <MenuItem key={reason.codeId} value={reason.codeId}>
                  {reason.displayName}
                </MenuItem>
              ))}
            </SpTextField>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={isNil(selectedContractorId) || isNil(selectedEntrance)}
        >
          {checkType === 0 ? "Check-In User" : "Check-Out User"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ManualCheckDialog);
