import { FormControlWrapper, FormSection, SpTextField } from "../../../common";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { AdminInputs } from "./SiteSupervisorDetailSection";
import { CounterResponseModel } from "../../../services/apiServiceTypes";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";
import { Button } from "@material-ui/core";

export interface UserInfoDetailProps {
  register: any;
  errors: FieldErrors<AdminInputs>;
  editCredentials: boolean;
  setEditCredentials: (val: boolean) => void;
  isNewSiteSupervisorMode: boolean;
  detailedSites: CounterResponseModel | undefined;
  allowedSites: Array<TreeStructureData>;
  onChangeSiteSupervisorStatus: () => void;
  userStatus?: "Active" | "Inactive";
}

const UserInfoDetail: React.FC<UserInfoDetailProps> = ({
  register,
  errors,
  editCredentials,
  setEditCredentials,
  isNewSiteSupervisorMode,
  onChangeSiteSupervisorStatus,
  userStatus,
}) => {
  return (
    <>
      <FormSection title="Personal Info">
        <FormControlWrapper
          title="First Name"
          input={
            <SpTextField
              name="firstName"
              inputRef={register}
              errors={errors}
              fullWidth
            />
          }
        />
        <FormControlWrapper
          title="Last Name"
          input={
            <SpTextField
              name="lastName"
              inputRef={register}
              errors={errors}
              fullWidth
            />
          }
        />
        <FormControlWrapper
          title="Email"
          input={
            <SpTextField
              name="email"
              inputRef={register}
              errors={errors}
              fullWidth
              placeholder="email@mail.com"
            />
          }
        />
        <FormControlWrapper
          title="Password"
          input={
            <SpTextField
              name="password"
              inputRef={register}
              errors={errors}
              fullWidth
              type="password"
              placeholder="•••••••••••••••"
            />
          }
        />
        <FormControlWrapper
          title="Confirm Password"
          input={
            <SpTextField
              name="confirmPassword"
              inputRef={register}
              errors={errors}
              fullWidth
              type="password"
              placeholder="•••••••••••••••"
            />
          }
        />
      </FormSection>
      {!!userStatus && !isNewSiteSupervisorMode ? (
        <FormSection title="Actions">
          <Button variant="contained" onClick={onChangeSiteSupervisorStatus}>
            {userStatus === "Active"
              ? "Deactivate Manager"
              : "Activate Manager"}
          </Button>
        </FormSection>
      ) : null}
    </>
  );
};

export default UserInfoDetail;
