import React, { useMemo } from "react";
import { TabPanel } from "../../../common";
import { Box, Tab, Tabs, Theme } from "@material-ui/core";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { CompanyDetailResponseType } from "../redux/apiCalls";
import CompanyInfoDetail, { CompanyInfoInputs } from "./CompanyInfoDetail";
import CompanySitesDetail from "./CompanySitesDetail";
import CompanySecCompanies from "./CompanySecCompanies";
import { useAuthorization } from "../../../auth";
import { ROLES, USER_PERMISSIONS } from "../../../auth/constants";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles<Theme>((theme) => ({
  mainBody: {
    // TODO find a more elegant way to do this
    height: `calc(100vh - 240px)`,
    overflowY: "auto",
    overflowX: "hidden",
    display: "block",
  },
}));

export interface CompanyDetailTabsProps {
  companyId?: string;
  newCompanyMode?: boolean;
  register: any;
  control: any;
  setSelectedTab: (val: number) => void;
  selectedTab: number;
  errors: FieldErrors<CompanyInfoInputs>;
  data?: CompanyDetailResponseType;
  setNewCompanyLogo: (newFile: Blob | null) => void;
  alertMessage?: string;
  refreshCompanyDetail: (companyId: string) => void;
  canEditCompany: boolean;
  canSelfEditCompany: boolean;
  canEditCredentials: boolean;
  canEditPermissions: boolean;
  canSelectSecondaryCompanies: boolean;
}

const CompanyDetailTabs: React.FC<CompanyDetailTabsProps> = ({
  companyId,
  newCompanyMode,
  setNewCompanyLogo,
  register,
  control,
  setSelectedTab,
  selectedTab,
  errors,
  data,
  alertMessage,
  refreshCompanyDetail,
  canEditCompany,
  canSelfEditCompany,
  canEditCredentials,
  canEditPermissions,
  canSelectSecondaryCompanies,
}) => {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };
  const { isAllowed: canViewSites } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.COMPANIES_SITES_VIEW]
  );
  const { isAllowed: canViewSecCompanies } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.COMPANIES_SECONDARY_COMPANIES_VIEW]
  );

  const showSitesTab = useMemo(
    () => !newCompanyMode && canViewSites,
    [newCompanyMode, canViewSites]
  );
  const secondaryCompaniesTabIdx = useMemo(
    () => (newCompanyMode || !canViewSites ? 1 : 2),
    [newCompanyMode, canViewSites]
  );

  const classes = useStyles();

  return (
    <Box marginTop={2}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Clients Info" />
        {showSitesTab && <Tab label="Locations" />}
        {canViewSecCompanies && <Tab label="Secondary Companies" />}
      </Tabs>
      <div className={classes.mainBody}>
        <TabPanel value={selectedTab} index={0}>
          <CompanyInfoDetail
            register={register}
            control={control}
            data={data}
            companyId={companyId}
            newCompanyMode={newCompanyMode}
            setNewCompanyLogo={setNewCompanyLogo}
            errors={errors}
            alertMessage={alertMessage}
            canEditCompany={canEditCompany}
            canSelfEditCompany={canSelfEditCompany}
            canEditCredentials={canEditCredentials}
            canEditPermissions={canEditPermissions}
            refreshCompanyDetail={refreshCompanyDetail}
          />
        </TabPanel>
        {showSitesTab && (
          <TabPanel value={selectedTab} index={1}>
            <CompanySitesDetail companyId={companyId} />
          </TabPanel>
        )}
        {canViewSecCompanies && (
          <TabPanel value={selectedTab} index={secondaryCompaniesTabIdx}>
            <CompanySecCompanies
              companyDetailData={data}
              refreshCompanyDetail={refreshCompanyDetail}
              canSelectSecondaryCompanies={canSelectSecondaryCompanies}
            />
          </TabPanel>
        )}
      </div>
    </Box>
  );
};

export default CompanyDetailTabs;
