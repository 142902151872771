import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { DetailPanelView } from "../../../common";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isNil } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../appStore";
import companyReducer from "../redux/reducer";
import CompanyDetailTabs from "./CompanyDetailTabs";
import {
  CompanyDetailResponseType,
  getDetailOfCompany,
  getDetailOfSelfCompany,
  postNewCompany,
  putUpdateCompany,
  putUpdateCompanyBaseInfo,
  putUpdateCompanyCredentials,
  putUpdateCompanyPermissionsSecondaryCompanies,
} from "../redux/apiCalls";
import {
  CompanyInfoInputs,
  createCompanyInfoSchema,
  prefillCompanyInfoInputs,
  resetRadioButtons,
} from "./CompanyInfoDetail";
import authRedux from "../../../auth/redux";
import { ROLES, USER_PERMISSIONS } from "../../../auth/constants";
import companiesRedux from "../redux";
import { useAuthorization } from "../../../auth";

export interface CompanyDetailSectionProps {
  companyId?: string;
  setSelectedCompanyId: (valu: string | undefined) => void;
  newCompanyMode?: boolean;
  refetchCompanies: () => void;
  setNewCompanyMode: (value: boolean) => void;
}

const CompanyDetailSection: React.FC<CompanyDetailSectionProps> = ({
  companyId,
  setSelectedCompanyId,
  newCompanyMode,
  refetchCompanies,
  setNewCompanyMode,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [newCompanyLogo, setNewCompanyLogo] = useState<Blob | null>(null);
  const [data, setData] = useState<CompanyDetailResponseType>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const previousCompanyId = useRef<string>();
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [alertMessage, setAlertMessage] = useState<string>();
  const allowedSecondaryCompanies = useSelector(
    companiesRedux.selectors.getSelectedSecondaryCompanies
  );

  const role = useSelector(authRedux.selectors.getRole);

  const { isAllowed: canEditCompany } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.COMPANIES_EDIT]
  );
  const { isAllowed: canSelfEditCompany } = useAuthorization(
    [ROLES.COMPANY],
    [USER_PERMISSIONS.COMPANIES_SELF_EDIT]
  );
  const { isAllowed: canEditCredentials } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.COMPANIES_CHANGE_CREDENTIALS]
  );
  const { isAllowed: canEditPermissions } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.COMPANIES_CHANGE_PERMISSIONS]
  );
  const { isAllowed: canSelectSecondaryCompanies } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.COMPANIES_SECONDARY_COMPANIES_SELECT]
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    errors,
    clearErrors,
    setError,
  } = useForm<CompanyInfoInputs>({
    resolver: yupResolver(
      createCompanyInfoSchema(
        canEditCompany || canSelfEditCompany || newCompanyMode,
        canEditCredentials || canSelfEditCompany || newCompanyMode
      )
    ),
  });

  const prefillInputsByData = useCallback(
    (newData: CompanyDetailResponseType) => {
      if (newData) {
        prefillCompanyInfoInputs(setValue, newData);
        setData(newData);
        previousCompanyId.current = companyId;
      }
      // reset new profile photo
      dispatch(companyReducer.actions.setNewCompanyLogo(undefined));
    },
    [companyId, dispatch, setValue]
  );

  const resetInputs = useCallback(() => {
    resetRadioButtons(setValue);
    reset();
    clearErrors();
    setNewCompanyLogo(null);
    setData(undefined);
    setAlertMessage(undefined);
  }, [clearErrors, reset, setValue]);

  const fetchCompanyDetail = useCallback(() => {
    setIsLoadingData(true);
    resetInputs();
    if (role === ROLES.COMPANY) {
      dispatch(getDetailOfSelfCompany())
        .then((response) => {
          prefillInputsByData(response.data);
        })
        .catch(() => {
          resetInputs();
        })
        .finally(() => {
          setIsLoadingData(false);
        });
    } else {
      dispatch(getDetailOfCompany(companyId))
        .then((response) => {
          prefillInputsByData(response.data);
        })
        .catch(() => {
          resetInputs();
        })
        .finally(() => {
          setIsLoadingData(false);
        });
    }
  }, [companyId, dispatch, prefillInputsByData, resetInputs, role]);

  useEffect(() => {
    if (newCompanyMode) {
      resetInputs();
      previousCompanyId.current = undefined;
    }

    if (
      companyId &&
      !isLoadingData &&
      ((previousCompanyId.current === undefined && !data) ||
        (previousCompanyId.current !== companyId && data))
    ) {
      resetInputs();
      fetchCompanyDetail();
    }

    if (
      !companyId &&
      !isLoadingData &&
      role === ROLES.COMPANY &&
      ((previousCompanyId.current === undefined && !data) ||
        (previousCompanyId.current !== undefined &&
          previousCompanyId.current !== companyId &&
          data))
    ) {
      resetInputs();
      fetchCompanyDetail();
    }
  }, [
    companyId,
    data,
    fetchCompanyDetail,
    isLoadingData,
    newCompanyMode,
    resetInputs,
    previousCompanyId,
    role,
  ]);

  const refreshCompanyDetail = (companyId: string) => {
    refetchCompanies();
    previousCompanyId.current = undefined;
    setNewCompanyMode(false);
    setSelectedCompanyId(companyId.toString());
    dispatch(companyReducer.actions.setNewCompanyLogo(undefined));
  };

  const saveCompanyDetail = (formInputData: CompanyInfoInputs) => {
    if (
      (canEditCompany && canEditCredentials) ||
      canSelfEditCompany ||
      newCompanyMode
    ) {
      onSubmitCompany(formInputData);
    } else {
      savePartialCompanyData(
        formInputData,
        canEditCompany,
        canEditCredentials,
        canEditPermissions,
        canSelectSecondaryCompanies
      );
    }
  };

  const savePartialCompanyData = (
    formInputData: CompanyInfoInputs,
    saveBaseInfo: boolean,
    saveCredentials: boolean,
    savePermissions: boolean,
    saveSecCompanies: boolean
  ) => {
    if (data?.companyId) {
      const formData = new FormData();
      formData.append("CompanyId", data.companyId);
      if (saveBaseInfo) {
        formData.append("Address", formInputData.Address);
        formData.append("CompanyName", formInputData.CompanyName);
        formData.append("CompanyContactName", formInputData.CompanyContactName);
        formData.append(
          "CompanyContactPhone",
          formInputData.CompanyContactPhone
        );

        if (!isNil(newCompanyLogo)) {
          formData.append("Logo", newCompanyLogo);
        }
      }

      if (saveCredentials) {
        formData.append(
          "CompanyContactEmail",
          formInputData.CompanyContactEmail
        );
        if (formInputData.Password !== "")
          formData.append("Password", formInputData.Password);
        if (formInputData.ConfirmPassword !== "")
          formData.append("ConfirmPassword", formInputData.ConfirmPassword);
      }

      // always add permissions settings to request..
      formData.append(
        "CanCreateForms",
        formInputData.CanCreateForms.toString()
      );
      formData.append(
        "VaccinationRequired",
        (formInputData.VaccinationRequired === true).toString()
      );

      // always add selected secondary companies to request
      allowedSecondaryCompanies.forEach((secondaryCompany) => {
        formData.append(
          "AllowedSecondaryCompanies",
          secondaryCompany.toString()
        );
      });

      let saveAction;
      if (saveBaseInfo && !saveCredentials) {
        saveAction = putUpdateCompanyBaseInfo;
      } else if (!saveBaseInfo && saveCredentials) {
        saveAction = putUpdateCompanyCredentials;
      } else if (
        !saveBaseInfo &&
        !saveCredentials &&
        (savePermissions || saveSecCompanies)
      ) {
        saveAction = putUpdateCompanyPermissionsSecondaryCompanies;
      }

      if (saveAction) {
        dispatch(saveAction(formData))
          .then((response) => {
            refetchCompanies();
            previousCompanyId.current = undefined;
            setNewCompanyMode(false);
            dispatch(companyReducer.actions.setNewCompanyLogo(undefined));
            setSelectedCompanyId(response.data.companyId.toString());
          })
          .catch((error) => {
            if (
              error.response == null ||
              typeof error.response?.data === "string" ||
              error.response?.data instanceof String
            ) {
              if (error.response == null) {
                setAlertMessage("Failed to connect.");
              } else {
                setAlertMessage(error.response.data);
              }
              setSelectedTab(0);
            } else if (error.response.data.errors) {
              setSelectedTab(0);
              Object.keys(error.response.data.errors).forEach((key) => {
                if (error.response.data.errors[key].length > 0) {
                  setError(key as keyof CompanyInfoInputs, {
                    message: error.response.data.errors[key],
                  });
                }
              });
            }
          });
      }
    }
  };

  const onSubmitCompany = (formInputData: CompanyInfoInputs) => {
    const promises = [];
    const formData = new FormData();
    formData.append("Address", formInputData.Address);
    formData.append("CanCreateForms", formInputData.CanCreateForms.toString());
    formData.append(
      "VaccinationRequired",
      (formInputData.VaccinationRequired === true).toString()
    );
    formData.append("CompanyName", formInputData.CompanyName);
    formData.append("CompanyContactName", formInputData.CompanyContactName);
    formData.append("CompanyContactPhone", formInputData.CompanyContactPhone);
    formData.append("CompanyContactEmail", formInputData.CompanyContactEmail);
    if (formInputData.Password !== "")
      formData.append("Password", formInputData.Password);
    if (formInputData.ConfirmPassword !== "")
      formData.append("ConfirmPassword", formInputData.ConfirmPassword);

    if (!isNil(newCompanyLogo)) {
      formData.append("Logo", newCompanyLogo);
    }

    allowedSecondaryCompanies.forEach((secondaryCompany) => {
      formData.append("AllowedSecondaryCompanies", secondaryCompany.toString());
    });

    if (newCompanyMode) {
      promises.push(
        dispatch(postNewCompany<{ companyId: string }>(formData)).then(
          (response) => {
            refreshCompanyDetail(response.data.companyId.toString());
          }
        )
      );
    } else if (
      (companyId && data?.companyId) ||
      (data?.companyId && role === ROLES.COMPANY)
    ) {
      formData.append("CompanyId", data.companyId);

      promises.push(
        dispatch(putUpdateCompany(formData)).then((response) => {
          if (role !== ROLES.COMPANY) {
            refetchCompanies();
            previousCompanyId.current = undefined;
            setNewCompanyMode(false);
            dispatch(companyReducer.actions.setNewCompanyLogo(undefined));
          } else {
            prefillInputsByData(response.data);
            dispatch(companyReducer.actions.setNewCompanyLogo(undefined));
          }
          setSelectedCompanyId(response.data.companyId.toString());
        })
      );
    }

    Promise.all(promises).catch((error) => {
      if (
        error.response == null ||
        typeof error.response?.data === "string" ||
        error.response?.data instanceof String
      ) {
        if (error.response == null) {
          setAlertMessage("Failed to connect.");
        } else {
          setAlertMessage(error.response.data);
        }
        setSelectedTab(0);
      } else if (error.response.data.errors) {
        setSelectedTab(0);
        Object.keys(error.response.data.errors).forEach((key) => {
          if (error.response.data.errors[key].length > 0) {
            setError(key as keyof CompanyInfoInputs, {
              message: error.response.data.errors[key],
            });
          }
        });
      }
    });
  };

  return newCompanyMode || companyId ? (
    <form onSubmit={handleSubmit(saveCompanyDetail)}>
      <DetailPanelView
        title={data?.companyName}
        showSaveButton={
          canSelfEditCompany ||
          canEditCompany ||
          newCompanyMode ||
          canEditCredentials ||
          canEditPermissions ||
          canSelectSecondaryCompanies
        }
      >
        {(canSelfEditCompany || companyId || newCompanyMode) && (
          <CompanyDetailTabs
            companyId={companyId}
            newCompanyMode={newCompanyMode}
            setNewCompanyLogo={setNewCompanyLogo}
            register={register}
            control={control}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            data={data}
            errors={errors}
            alertMessage={alertMessage}
            refreshCompanyDetail={refreshCompanyDetail}
            canEditCompany={canEditCompany}
            canSelfEditCompany={canSelfEditCompany}
            canEditCredentials={canEditCredentials}
            canEditPermissions={canEditPermissions}
            canSelectSecondaryCompanies={canSelectSecondaryCompanies}
          />
        )}
      </DetailPanelView>
    </form>
  ) : null;
};

export default memo(CompanyDetailSection);
