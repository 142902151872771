import React, { ReactNode, useCallback, useEffect, useState } from "react";

interface ReturnType {
  uploadInput: ReactNode;
  clickOnUploadFile: () => void;
  selectedFiles?: File[] | null;
  rawFiles?: FileList | null;
  onRemoveFile: (index: number) => void;
  removeAllFiles: () => void;
}

interface PropsType {
  acceptedExtensions?: string;
  multiple?: boolean;
  inputId?: string;
}

export const useUploadFile = ({
  acceptedExtensions = ".pdf,.mp4",
  multiple = false,
  inputId = "fileElement",
}: PropsType): ReturnType => {
  const [fileList, setFileList] = useState<FileList | null>();

  const fileElem = document.getElementById(inputId);

  const clickOnUploadFile = () => {
    if (fileElem) {
      fileElem.click();
    }
  };

  const createFileList = (files: File[]) => {
    const dt = new DataTransfer();
    files.forEach((file) => {
      dt.items.add(file);
    });

    return dt.files;
  };

  const onRemoveFile = (index: number) => {
    if (fileElem) {
      const dt = new DataTransfer();
      const inputFiles = (fileElem as HTMLInputElement).files;
      if (inputFiles) {
        for (let i = 0; i < inputFiles.length; i++) {
          const file = inputFiles[i];

          if (index !== i) {
            dt.items.add(file); // here you exclude the file. thus removing it.
          }
        }

        (fileElem as HTMLInputElement).files = dt.files;
        setFileList(dt.files);
      }
    }
  };

  const removeAllFiles = () => {
    setFileList(null);
  };

  const handleOnChangeFiles = useCallback(
    (event: Event) => {
      const input = event.target as HTMLInputElement;

      const newFileArray = [];
      if (input.files) {
        newFileArray.push(...Array.from(input.files));
      }
      if (fileList) {
        newFileArray.push(...Array.from(fileList));
      }

      const newFileList = createFileList(newFileArray);

      input.files = newFileList;
      setFileList(newFileList);
    },
    [fileList]
  );

  useEffect(() => {
    if (fileElem) {
      fileElem.addEventListener("change", handleOnChangeFiles, false);
    }

    return () => {
      if (fileElem) {
        fileElem.removeEventListener("change", handleOnChangeFiles, false);
      }
    };
  }, [fileElem, handleOnChangeFiles]);

  return {
    uploadInput: (
      <input
        type="file"
        id={inputId}
        multiple={multiple}
        accept={acceptedExtensions}
        style={{ display: "none" }}
        hidden
      />
    ),
    clickOnUploadFile,
    selectedFiles: fileList && Array.from(fileList),
    rawFiles: fileList,
    onRemoveFile,
    removeAllFiles,
  };
};
