import { createSelector } from "@reduxjs/toolkit";
import { State } from "./model";
import { COMMON } from "./constants";

const selectSelf = (state: { [x: string]: State }) =>
  state[COMMON.ROOT_REDUCER];

const getSelectedUserTypeCodeId = createSelector(
  selectSelf,
  (state: State) => state.selectedUserTypeCodeId
);

const getUserList = createSelector(
  selectSelf,
  (state: State) => state.userList
);

const getUserListHasNextPage = createSelector(
  selectSelf,
  (state: State) => state.userListHasNextPage
);

const getIsFetchingUserList = createSelector(
  selectSelf,
  (state: State) => state.isFetchingUserList
);

const getAllowedSites = createSelector(
  selectSelf,
  (state: State) => state.allowedSites
);

const getSelectedAllowedSites = createSelector(selectSelf, (state: State) =>
  state.allowedSites
    .filter((item) => item.isChecked)
    .map((item) => parseInt(item.id))
);

const getAllowedPermissions = createSelector(
  selectSelf,
  (state: State) => state.allowedPermissions
);

const allSelectors = {
  getSelectedUserTypeCodeId,
  getUserList,
  getUserListHasNextPage,
  getIsFetchingUserList,
  getAllowedSites,
  getSelectedAllowedSites,
  getAllowedPermissions,
};

export default allSelectors;
