import {
  ApplicationUser,
  CounterResponseItem,
  CounterResponseModel,
  DomainBase,
  PaginationModel,
  PaginationResponseType,
} from "../../../services/apiServiceTypes";
import { getAuthApi, postAuthApi, putAuthApi } from "../../../services/authApi";
import { APP_SETTINGS } from "../../../AppSettings";
import { ChangeStatusModel, Status } from "../../users/redux/apiCalls";
import { SiteDetailModel } from "../../forms/redux/apiCalls";
import { BatteryLevel } from "../../eventLog/redux/apiCalls";
import { EntranceSchedule } from "../details/entrances/types";

export const getSiteList = <T = PaginationResponseType<CounterResponseItem>>(
  body: PaginationModel
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/sitesPaginated`,
    body
  );

export const getCompanyList = <T = CounterResponseModel>() =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Counter/companies`);

export const putChangeSiteStatus = <T = any>(body: ChangeStatusModel) =>
  putAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Site/changeStatus`, body);

export const putDownloadClubMembers = <T = any>(siteId: string) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/downloadClubMembers/${siteId}`
  );

export type SelectListGroup = {
  disabled: boolean;
  name: string;
};

export type SelectListItem = {
  disabled: boolean;
  group: SelectListGroup | null;
  selected: boolean;
  text: string;
  value: string;
};
export type Entrance = {
  status: Status;
  EntranceName: string;
  SiteId: string;
  // TODO
};

export type CreateSiteViewModel = {
  id?: string;
  status?: Status;
  siteName: string;
  company: string;
  siteOwnerName: string;
  siteOwnerContactNumber: string;
  siteOwnerEmail: string;
  protectionAdvisoryManagerName: string;
  protectionAdvisoryContactNumber: string;
  protectionAdvisoryEmail: string;
  certificationExpirationThreshold?: string;
  entrances?: Entrance[];
  allSites?: SiteDetailModel[];
  siteSupervisors?: ApplicationUser[];
  companies?: SelectListItem[];
  clubId: string;
  apiKey: string;
  lastApiCall?: string;
  hardwareApiKey: string;
};

export const putEditSiteInfo = <T = any>(body: CreateSiteViewModel) =>
  putAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Site/editSite`, body);

export const postNewSiteInfo = <T = any>(body: CreateSiteViewModel) =>
  postAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Site/create`, body);

export const getSiteEntranceList = <
  T = PaginationResponseType<CounterResponseItem>
>(
  body: PaginationModel,
  siteId: string
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/${siteId}/entrances`,
    body
  );

export const getSiteAllEntrancesList = <T = CounterResponseModel>(
  siteId: string
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/${siteId}/allEntrances`
  );

export type CreateEntranceViewModel = {
  id?: string;
  siteId: string | number;
  status?: Status;
  entranceName: string;
  innerBeacon: string;
  outerBeacon: string;
  innerMotionSensorId: string;
  outerMotionSensorId: string;
  innerBatteryLevel?: BatteryLevel;
  outerBatteryLevel?: BatteryLevel;
  accessToken?: string;
  qrReaderId: string;
  imageTimeSpan: string;
  facialRecEnabled?: boolean;
  cameraRebootEnabled?: boolean;
  qrReaderRebootEnabled?: boolean;
  timeZone?: string;
  schedules?: EntranceSchedule[];
};

export const getEntranceDetail = <T = CreateEntranceViewModel>(
  entranceId: string
) =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Site/entrance/${entranceId}`);

export type CanRebootDevicesModel = {
  devicesSearchRelatedId: number;
  deviceIds: string[];
};

export const postCanRebootDevices = <T = string[]>(
  body: CanRebootDevicesModel
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/canRebootDevices`,
    body
  );

export const putEditEntranceDetail = <T = any>(body: CreateEntranceViewModel) =>
  putAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Site/editEntrance`, body);

export type CreateEntranceViewModelResponse = { entranceId: string };

export const postCreateEntranceDetail = <T = CreateEntranceViewModelResponse>(
  body: CreateEntranceViewModel
) =>
  postAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Site/createEntrance`, body);

export const putChangeEntranceStatus = <T = any>(body: ChangeStatusModel) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/changeEntranceStatus`,
    body
  );

export const getSiteSupervisorList = <
  T = PaginationResponseType<CounterResponseItem>
>(
  body: PaginationModel,
  siteId: string
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/${siteId}/siteSupervisor`,
    body
  );

export interface Site extends DomainBase {
  status: Status;
  siteName: string;
}

export type CreateSupervisorViewModel = {
  userId: string;
  status?: Status;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  confirmPassword?: string;
  sites?: Site[];
};

export type UpdateSiteSupervisorBasicInfoViewModel = {
  userId: string;
  firstName: string;
  lastName: string;
};

export type UpdateSiteSupervisorCredentialsViewModel = {
  userId: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export const getSiteSupervisorDetail = <T = CreateSupervisorViewModel>(
  userId: string
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/siteSupervisor/${userId}`
  );

export const putSiteSupervisorDetail = <T = CreateSupervisorViewModel>(
  body: CreateSupervisorViewModel
) =>
  putAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Site/site-supervisor`, body);

export const putSiteSupervisorBasicInfo = <
  T = UpdateSiteSupervisorBasicInfoViewModel
>(
  body: UpdateSiteSupervisorBasicInfoViewModel
) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/site-supervisor-basic`,
    body
  );

export const putSiteSupervisorCredentials = <
  T = UpdateSiteSupervisorBasicInfoViewModel
>(
  body: UpdateSiteSupervisorCredentialsViewModel
) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/site-supervisor-credentials`,
    body
  );

export type UnassignSiteSupervisorModel = {
  siteId: string;
  userId: string;
};

export const putUnassignSiteSupervisor = <T = any>(
  body: UnassignSiteSupervisorModel
) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/unassign-supervisor`,
    body
  );
export const putAssignSiteSupervisor = <T = any>(
  body: UnassignSiteSupervisorModel
) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/assign-supervisor`,
    body
  );

export const getUnassignedSiteSupervisorList = <
  T = PaginationResponseType<CounterResponseItem>
>(
  body: PaginationModel,
  siteId: string
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/${siteId}/unassigned-site-supervisors`,
    body
  );

export type RebootDeviceModel = {
  deviceId: string;
};

export type UnlockDoorModel = {
    deviceId: string;
};

export type RelatedJobModel = {
  relatedJobId: number;
};

export const postRebootDevice = <T = RelatedJobModel>(
  body: RebootDeviceModel
) => postAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Site/rebootDevice`, body);

export const getRebootResult = <T = string>(relatedJobId: number) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/rebootResult?relatedJobId=${relatedJobId}`
  );

export const getRefreshBootableDevices = <T = string>() =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/refreshAwxDevicesWebApp`
  );

export const postUnlockDoor = <T = RelatedJobModel>(
    body: UnlockDoorModel
) => postAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Site/unlockDoor`, body);

export const getUnlockResult = <T = string>(relatedJobId: number) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Site/unlockResult?relatedJobId=${relatedJobId}`
  );

const allActions = {
  getSiteList,
  getCompanyList,
  putDownloadClubMembers,
  putEditSiteInfo,
  postNewSiteInfo,
  getSiteEntranceList,
  postCreateEntranceDetail,
  putChangeEntranceStatus,
  getSiteSupervisorList,
  getSiteSupervisorDetail,
  putUnassignSiteSupervisor,
  putAssignSiteSupervisor,
  getUnassignedSiteSupervisorList,
  putSiteSupervisorDetail,
  postRebootDevice,
  postUnlockDoor,
  getUnlockResult,
};

export default allActions;
