import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COMMON } from "./constants";
import { EditOtherCertification, initialState, State } from "./model";
import { CounterResponseItem } from "../../../services/apiServiceTypes";
import { OtherCertification } from "./apiCalls";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";
import { UpdateTreeStructureData } from "../../messages/redux/model";

const authReducer = createSlice({
  name: COMMON.ROOT_REDUCER,
  initialState,
  reducers: {
    setContractorListHasNextPage: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      contractorListHasNextPage: action.payload,
    }),
    setIsFetchingContractorList: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      isFetchingContractorList: action.payload,
    }),
    setContractorList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => ({
      ...state,
      contractorList: action.payload,
    }),
    appendToContractorList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => {
      const notifications = JSON.parse(JSON.stringify(state.contractorList));
      notifications.push(...action.payload);

      return {
        ...state,
        contractorList: notifications,
      };
    },
    setCompanyList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => ({
      ...state,
      companyList: action.payload,
    }),
    setSiteList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => ({
      ...state,
      siteList: action.payload,
    }),
    setOtherCertifications: (
      state: State,
      action: PayloadAction<Array<OtherCertification>>
    ) => ({
      ...state,
      otherCertifications: action.payload,
    }),
    removeOtherCertification: (state: State, action: PayloadAction<number>) => {
      const certifications: Array<OtherCertification> = JSON.parse(
        JSON.stringify(state.otherCertifications)
      );
      certifications.splice(action.payload, 1);

      return {
        ...state,
        otherCertifications: certifications,
      };
    },
    editOtherCertification: (
      state: State,
      action: PayloadAction<EditOtherCertification>
    ) => {
      const certifications: Array<OtherCertification> = JSON.parse(
        JSON.stringify(state.otherCertifications)
      );

      certifications.splice(action.payload.index, 1, action.payload.data);

      return {
        ...state,
        otherCertifications: certifications,
      };
    },
    addOtherCertification: (state: State) => {
      const certifications: Array<OtherCertification> = JSON.parse(
        JSON.stringify(state.otherCertifications)
      );
      certifications.push({ name: "", validTo: "" });

      return {
        ...state,
        otherCertifications: certifications,
      };
    },
    setAccessList: (
      state: State,
      action: PayloadAction<Array<TreeStructureData>>
    ) => ({
      ...state,
      accessList: action.payload,
    }),
    setAccessListItem: (
      state: State,
      action: PayloadAction<UpdateTreeStructureData>
    ) => {
      const newState = JSON.parse(JSON.stringify(state.accessList));
      newState[action.payload.index] = action.payload.data;

      return { ...state, accessList: newState };
    },
    setNewProfilePhotoId: (
      state: State,
      action: PayloadAction<string | undefined>
    ) => ({
      ...state,
      newProfilePhotoId: action.payload,
    }),
    setSecondaryCompanyName: (
      state: State,
      action: PayloadAction<string | undefined>
    ) => {
      state.secondaryCompanyName = action.payload;
    },
    setSecondaryCompanyList: (
      state: State,
      action: PayloadAction<Array<CounterResponseItem>>
    ) => ({
      ...state,
      secondaryCompanyList: action.payload,
    }),
    setIsFetchingSecondaryCompanyList: (
      state: State,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      isFetchingSecondaryCompanyList: action.payload,
    }),
  },
});

export default authReducer;
