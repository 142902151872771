import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SpIconButton } from "../buttons";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    height: "inherit",
    width: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addButton: {
    width: "150px",
    height: "150px",
  },
}));

export interface SpPanelPlaceholderAddContentProps {
  onAdd: () => void;
}

const SpPanelPlaceholderAddContent: React.FC<
  SpPanelPlaceholderAddContentProps
> = ({ onAdd }) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={classes.contentContainer}
    >
      {hover && (
        <SpIconButton
          className={classes.addButton}
          onClick={onAdd}
          iconName="plus"
        />
      )}
    </div>
  );
};

export default SpPanelPlaceholderAddContent;
