import { NotificationType } from "./apiCalls";
import {
  CounterResponseItem,
  CounterResponseModel,
} from "../../../services/apiServiceTypes";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";

export interface State {
  notificationList: Array<NotificationType>;
  hasNextPage: boolean;
  isFetching: boolean;
  sitesList: CounterResponseModel;
  selectedSiteId?: number;
  entrancesList: CounterResponseModel;
  selectedEntranceId?: number;
  sendToSites: Array<TreeStructureData>;
  sendToContractors: CounterResponseItem[];
  hasNextPageOfSendToContractors: boolean;
  isFetchingSendToContractors: boolean;
}

export const initialState: State = {
  notificationList: [],
  hasNextPage: true,
  isFetching: false,
  sitesList: [],
  selectedSiteId: undefined,
  entrancesList: [],
  selectedEntranceId: undefined,
  sendToSites: [],
  sendToContractors: [],
  hasNextPageOfSendToContractors: true,
  isFetchingSendToContractors: false,
};

export interface UpdateTreeStructureData {
  index: number;
  data: TreeStructureData;
}
