import { Box, Button, Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ReactNode } from "react";

export interface DetailPanelViewProps {
  title?: string;
  children: ReactNode;
  showSaveButton?: boolean;
  buttonText?: string;
}

const useStyles = makeStyles<Theme>((theme) => ({
  mainBody: {
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

const DetailPanelView: React.FC<DetailPanelViewProps> = ({
  title,
  children,
  showSaveButton = true,
  buttonText = "Save",
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.mainBody}
    >
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box>
            <Typography variant="h2">{title}</Typography>
          </Box>
          {showSaveButton ? (
            <Button color="primary" variant="contained" type="submit">
              {buttonText}
            </Button>
          ) : null}
        </Box>
      </Grid>
      {children}
    </Grid>
  );
};

export default DetailPanelView;
