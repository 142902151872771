import { AppDispatch } from "../../../appStore";
import siteReducer from "./index";
import { PaginationModel } from "../../../services/apiServiceTypes";
import {
  CreateEntranceViewModel,
  getSiteAllEntrancesList,
  getSiteEntranceList,
  getSiteList,
  getSiteSupervisorList,
  getUnassignedSiteSupervisorList,
  putChangeEntranceStatus,
  putChangeSiteStatus,
  putUnassignSiteSupervisor,
} from "./apiCalls";
import { SiteDetailModel } from "../../forms/redux/apiCalls";
import { batch } from "react-redux";

const fetchSiteList =
  (body: PaginationModel, isScrollMode: boolean = false) =>
  (dispatch: AppDispatch) => {
    dispatch(siteReducer.actions.setIsFetchingSiteList(true));
    return dispatch(getSiteList(body))
      .then(({ data }) => {
        if (isScrollMode) {
          dispatch(siteReducer.actions.appendToSiteList(data.items));
        } else {
          dispatch(siteReducer.actions.setSiteList(data.items));
        }
        dispatch(siteReducer.actions.setSiteListHasNextPage(data.hasNextPage));
      })
      .catch(() => {
        dispatch(siteReducer.actions.setSiteListHasNextPage(true));
      })
      .finally(() => {
        dispatch(siteReducer.actions.setIsFetchingSiteList(false));
      });
  };

const resetNewSiteSupervisor = () => (dispatch: AppDispatch) => {
  batch(() => {
    dispatch(siteReducer.actions.setIsNewSiteSupervisorMode(false));
    dispatch(
      siteReducer.actions.setUnassignedSiteSupervisorListHasNextPage(true)
    );
    dispatch(
      siteReducer.actions.setIsFetchingUnassignedSiteSupervisorList(false)
    );
    dispatch(siteReducer.actions.setUnassignedSiteSupervisorList([]));
  });
};

const resetSiteSupervisorTab = () => (dispatch: AppDispatch) => {
  batch(() => {
    dispatch(siteReducer.actions.setSiteSupervisorList([]));
    dispatch(siteReducer.actions.setSiteSupervisorListHasNextPage(true));
    dispatch(siteReducer.actions.setIsFetchingSiteSupervisorList(false));

    // selected detail of site supervisor
    dispatch(siteReducer.actions.setSelectedSiteSupervisorId(undefined));
    dispatch(siteReducer.actions.setIsEditingCredentials(false));

    // Add new site supervisor dialog
    dispatch(resetNewSiteSupervisor());
  });
};

const resetEntrancesTab = () => (dispatch: AppDispatch) => {
  batch(() => {
    dispatch(siteReducer.actions.setEntranceList([]));
    dispatch(siteReducer.actions.setEntranceListHasNextPage(true));
    dispatch(siteReducer.actions.setIsFetchingEntranceList(false));
    dispatch(siteReducer.actions.setSelectedEntranceId(undefined));
    dispatch(siteReducer.actions.setIsNewEntranceMode(false));
  });
};

const resetSitesList = () => (dispatch: AppDispatch) => {
  batch(() => {
    dispatch(siteReducer.actions.setSiteList([]));
    dispatch(siteReducer.actions.setSiteListHasNextPage(true));
    dispatch(siteReducer.actions.setIsFetchingSiteList(false));
    dispatch(siteReducer.actions.setSelectedSiteId(undefined));
  });
};

const onChangeStatus =
  (siteDetail: SiteDetailModel) => (dispatch: AppDispatch) => {
    return dispatch(
      putChangeSiteStatus({
        id: siteDetail.siteId,
        status: siteDetail.status === "Active" ? "Inactive" : "Active",
      })
    );
  };

const fetchSiteEntranceList =
  (body: PaginationModel, siteId: string, isScrollMode: boolean = false) =>
  (dispatch: AppDispatch) => {
    dispatch(siteReducer.actions.setIsFetchingEntranceList(true));
    return dispatch(getSiteEntranceList(body, siteId))
      .then(({ data }) => {
        if (isScrollMode) {
          dispatch(siteReducer.actions.appendToEntranceList(data.items));
        } else {
          dispatch(siteReducer.actions.setEntranceList(data.items));
        }
        dispatch(
          siteReducer.actions.setEntranceListHasNextPage(data.hasNextPage)
        );
      })
      .catch(() => {
        dispatch(siteReducer.actions.setEntranceListHasNextPage(true));
      })
      .finally(() => {
        dispatch(siteReducer.actions.setIsFetchingEntranceList(false));
      });
  };

const fetchSiteAllEntranceList =
  (siteId: string) => (dispatch: AppDispatch) => {
    dispatch(siteReducer.actions.setIsFetchingEntranceList(true));
    return dispatch(getSiteAllEntrancesList(siteId))
      .then(({ data }) => {
        dispatch(siteReducer.actions.setEntranceList(data));
      })
      .finally(() => {
        dispatch(siteReducer.actions.setIsFetchingEntranceList(false));
      });
  };

const onChangeEntranceStatus =
  (entranceDetail: CreateEntranceViewModel, selectedEntranceId: string) =>
  (dispatch: AppDispatch) => {
    return dispatch(
      putChangeEntranceStatus({
        id: selectedEntranceId,
        status: entranceDetail.status === "Active" ? "Inactive" : "Active",
      })
    );
  };

const fetchSiteSupervisorList =
  (body: PaginationModel, siteId: string, isScrollMode: boolean = false) =>
  (dispatch: AppDispatch) => {
    dispatch(siteReducer.actions.setIsFetchingSiteSupervisorList(true));
    return dispatch(getSiteSupervisorList(body, siteId))
      .then(({ data }) => {
        if (isScrollMode) {
          dispatch(siteReducer.actions.appendToSiteSupervisorList(data.items));
        } else {
          dispatch(siteReducer.actions.setSiteSupervisorList(data.items));
        }
        dispatch(
          siteReducer.actions.setSiteSupervisorListHasNextPage(data.hasNextPage)
        );
      })
      .catch(() => {
        dispatch(siteReducer.actions.setSiteSupervisorListHasNextPage(true));
      })
      .finally(() => {
        dispatch(siteReducer.actions.setIsFetchingSiteSupervisorList(false));
      });
  };

const onChangeSiteSupervisorStatus =
  (selectedSiteSupervisorId: string, selectedSiteId: string) =>
  (dispatch: AppDispatch) =>
    dispatch(
      putUnassignSiteSupervisor({
        siteId: selectedSiteId,
        userId: selectedSiteSupervisorId,
      })
    );

const fetchUnassignedSiteSupervisorList =
  (body: PaginationModel, siteId: string, isScrollMode: boolean = false) =>
  (dispatch: AppDispatch) => {
    dispatch(
      siteReducer.actions.setIsFetchingUnassignedSiteSupervisorList(true)
    );
    return dispatch(getUnassignedSiteSupervisorList(body, siteId))
      .then(({ data }) => {
        if (isScrollMode) {
          dispatch(
            siteReducer.actions.appendToUnassignedSiteSupervisorList(data.items)
          );
        } else {
          dispatch(
            siteReducer.actions.setUnassignedSiteSupervisorList(data.items)
          );
        }
        dispatch(
          siteReducer.actions.setUnassignedSiteSupervisorListHasNextPage(
            data.hasNextPage
          )
        );
      })
      .catch(() => {
        dispatch(
          siteReducer.actions.setUnassignedSiteSupervisorListHasNextPage(true)
        );
      })
      .finally(() => {
        dispatch(
          siteReducer.actions.setIsFetchingUnassignedSiteSupervisorList(false)
        );
      });
  };

const allActions = {
  fetchSiteList,
  resetSitesList,
  onChangeStatus,
  fetchSiteEntranceList,
  fetchSiteAllEntranceList,
  onChangeEntranceStatus,
  fetchSiteSupervisorList,
  resetSiteSupervisorTab,
  resetEntrancesTab,
  onChangeSiteSupervisorStatus,
  fetchUnassignedSiteSupervisorList,
  resetNewSiteSupervisor,
};

export default allActions;
