import { Tab, Tabs, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { TabPanel } from "../../../common";
import SitesForUser from "./SitesForUser";
import UserInfoDetail, { UserInfoDetailProps } from "./UserInfoDetail";
import UserPermissionsDetail, {
  UserPermissionsDetailProps,
} from "./UserPermissionsDetail";

const useStyles = makeStyles<Theme>((theme) => ({
  mainBody: {
    // TODO find a more elegant way to do this
    height: `calc(100vh - 240px)`,
    overflowY: "auto",
    overflowX: "hidden",
    display: "block",
  },
}));

export interface SiteSupervisorDetailTabsProps
  extends UserInfoDetailProps,
    UserPermissionsDetailProps {
  selectedTab: number;
  setSelectedTab: (val: number) => void;
}

const SiteSupervisorDetailTabs: React.FC<SiteSupervisorDetailTabsProps> = ({
  selectedTab,
  setSelectedTab,
  register,
  errors,
  editCredentials,
  setEditCredentials,
  isNewSiteSupervisorMode,
  detailedSites,
  allowedSites,
  possiblePermissions,
  allowedPermissions,
  userStatus,
  onChangeSiteSupervisorStatus,
}) => {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const classes = useStyles();

  // TODO style tab buttons
  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Person detail" />
        <Tab label="Locations" />
        <Tab label="Permissions" />
      </Tabs>
      <div className={classes.mainBody}>
        <TabPanel value={selectedTab} index={0}>
          <UserInfoDetail
            register={register}
            errors={errors}
            editCredentials={editCredentials}
            setEditCredentials={setEditCredentials}
            isNewSiteSupervisorMode={isNewSiteSupervisorMode}
            detailedSites={detailedSites}
            allowedSites={allowedSites}
            userStatus={userStatus}
            onChangeSiteSupervisorStatus={onChangeSiteSupervisorStatus}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <SitesForUser
            detailedSites={detailedSites}
            allowedSites={allowedSites}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <UserPermissionsDetail
            possiblePermissions={possiblePermissions}
            allowedPermissions={allowedPermissions}
          />
        </TabPanel>
      </div>
    </>
  );
};

export default SiteSupervisorDetailTabs;
