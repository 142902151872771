import React from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, LinearProgress } from "@material-ui/core";
import logo from "../../assets/img/21_SP_SIGHTPAS-APP-HEADER LOGO_DARK.png";
import authReducer from "../redux";
import { useAppDispatch } from "../../appStore";
import * as yup from "yup";
import { SpFormControl, SpTextField } from "../../common";
import { BORDER_RADIUS } from "../../appTheme";
import { isNil } from "ramda";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.common.black,
    height: "100vh",
  },
  avatar: {
    padding: theme.spacing(1),
  },
  mainGrid: {
    height: "inherit",
  },
  widthGrid: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    minWidth: 600,
    borderRadius: BORDER_RADIUS.NORMAL,
  },
  logo: {
    width: 334.82,
    height: 69,
  },
  input: {
    width: 262.4,
  },
  formGrid: {
    paddingBottom: theme.spacing(2),
  },
}));

type Inputs = {
  password: string;
  confirmPassword: string;
};

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter Password")
    .max(20, "Password exceeds 20 character limit."),
  confirmPassword: yup
    .string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

const ResetPasswordScreen: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  let location = useLocation();
  const params = queryString.parse(location.search, {
    decode: false,
  });
  const userId = params.userId;
  const token = params.token;

  const isAuthenticating = useSelector(
    authReducer.selectors.getIsAuthenticating
  );

  const { register, handleSubmit, errors } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: Inputs) => {
    if (!!userId && !!token) {
      dispatch(
        authReducer.dispatchActions.resetPassword(
          {
            userId: userId as string,
            token: token as string,
            newPassword: data.password,
            confirmPassword: data.confirmPassword,
          },
          history
        )
      );
    }
  };

  return (
    <div className={classes.background}>
      <Grid
        container
        alignItems={"center"}
        alignContent={"center"}
        justifyContent={"center"}
        className={classes.mainGrid}
      >
        <Grid item>
          <Paper elevation={1} className={classes.paper}>
            <Box pt={6} pb={6}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  className={classes.formGrid}
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <div className={classes.avatar}>
                      <img
                        src={logo}
                        alt="logo-sightPas"
                        className={classes.logo}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <SpFormControl
                      title="Password"
                      input={
                        <SpTextField
                          inputRef={register}
                          errors={errors}
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          fullWidth
                          autoFocus
                        />
                      }
                      className={classes.input}
                    />
                    <SpFormControl
                      title="Confirm Password"
                      input={
                        <SpTextField
                          inputRef={register}
                          errors={errors}
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          autoComplete="confirm-password"
                          fullWidth
                        />
                      }
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item />
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={
                        isAuthenticating || isNil(token) || isNil(userId)
                      }
                      className={classes.input}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
                {isAuthenticating && <LinearProgress />}
              </form>
            </Box>
          </Paper>
          <Box mt={8}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => window.location.replace("/")}
                >
                  Visit sightpas.com
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPasswordScreen;
