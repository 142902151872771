import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/redux";
import commonReducer from "./common/redux";
import messagesReducer from "./admin/messages/redux";
import contractorsReducer from "./admin/contractors/redux";
import usersReducer from "./admin/users/redux";
import companyReducer from "./admin/companies/redux";
import eventLogReducer from "./admin/eventLog/redux";
import sitesReducer from "./admin/sites/redux";
import thunk from "redux-thunk";
import { useDispatch } from "react-redux";

const rootReducer = combineReducers({
  [authReducer.constants.COMMON.ROOT_REDUCER]: authReducer.reducer,
  [commonReducer.constants.COMMON.ROOT_REDUCER]: commonReducer.reducer,
  [messagesReducer.constants.COMMON.ROOT_REDUCER]: messagesReducer.reducer,
  [contractorsReducer.constants.COMMON.ROOT_REDUCER]:
    contractorsReducer.reducer,
  [usersReducer.constants.COMMON.ROOT_REDUCER]: usersReducer.reducer,
  [companyReducer.constants.COMMON.ROOT_REDUCER]: companyReducer.reducer,
  [eventLogReducer.constants.COMMON.ROOT_REDUCER]: eventLogReducer.reducer,
  [sitesReducer.constants.COMMON.ROOT_REDUCER]: sitesReducer.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(thunk).concat(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
