import React, { Fragment, useEffect, useState, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Button, Grid, IconButton } from "@material-ui/core";
import { BORDER_RADIUS } from "../../../appTheme";
import {
  FormControlWrapper,
  FormSection,
  FormSectionRow,
  SpTextField,
} from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { FieldName } from "react-hook-form/dist/types/fields";
import { SetFieldValue, SetValueConfig } from "react-hook-form/dist/types/form";
import { ContractorDetailResponseType } from "../redux/apiCalls";
import contractorSelector from "../redux/selectors";
import contractorReducer from "../redux/reducer";
import { AppDispatch } from "../../../appStore";
import moment from "moment";
import { BACKEND_DATE_TIME_FORMAT } from "../../../services/helpers";
import { useUploadFile } from "../../../services/useUploadFile";
import { isNil } from "ramda";
import { loadFile } from "../../../services/authApi";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { ContractorInputs } from "./ContractorDetailSection";
import AddCircle from "@material-ui/icons/AddCircle";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles<Theme>((theme) => ({
  image: {
    width: "100%",
    borderRadius: BORDER_RADIUS.MEDIUM,
  },
  fullWidth: {
    width: "100%",
  },
  fullHeight: {
    height: "100%",
  },
  removeButton: {
    borderRadius: 0,
  },
}));

export interface ContractorQualificationsInputs {
  OshaCertificationFrom: string;
  OshaCertificationTo: string;
  VaccinationVerificationDate: string;
}

export const QualificationsSchema = yup.object().shape({
  OshaCertificationFrom: yup
    .string()
    .test(
      "date-to-exists",
      "Valid From has to be defined if Valid To is defined.",
      function (value) {
        if (this.parent.OshaCertificationTo) {
          return value?.length !== 0;
        }
        return true;
      }
    ),
  OshaCertificationTo: yup
    .string()
    .test(
      "dates-compare",
      "Valid To has to be greater than Valid From.",
      function (value) {
        return (
          !this.parent.OshaCertificationFrom ||
          moment(value).isAfter(this.parent.OshaCertificationFrom)
        );
      }
    ),
});

export const prefillContractorQualificationsInputs = (
  setValue: (
    name: FieldName<ContractorQualificationsInputs>,
    value: SetFieldValue<ContractorQualificationsInputs>,
    config?: SetValueConfig
  ) => void,
  data: ContractorDetailResponseType
) => {
  setValue(
    "OshaCertificationFrom",
    (data.oshaCertificationFrom &&
      moment(data.oshaCertificationFrom).format("YYYY-MM-DD")) ||
      undefined
  );
  setValue(
    "OshaCertificationTo",
    (data.oshaCertificationTo &&
      moment(data.oshaCertificationTo).format("YYYY-MM-DD")) ||
      undefined
  );
  setValue(
    "VaccinationVerificationDate",
    (data.vaccinationVerificationDate &&
      moment(data.vaccinationVerificationDate).format("YYYY-MM-DD")) ||
      undefined
  );
};

export interface QualificationsDetailProps {
  contractorId?: string;
  newContractorMode?: boolean;
  register: any;
  control: any;
  data?: ContractorDetailResponseType;
  newCertificationCard: Blob | null;
  setNewCertificationCard: (newFile: Blob | null) => void;
  errors: FieldErrors<ContractorInputs>;
  otherCertificationErrors: any;
  viewOnly: boolean;
  canVerifyVaccination: boolean;
}

const QualificationsDetail: React.FC<QualificationsDetailProps> = ({
  contractorId,
  newContractorMode,
  register,
  control,
  data,
  newCertificationCard,
  setNewCertificationCard,
  errors,
  otherCertificationErrors,
  viewOnly,
  canVerifyVaccination,
}) => {
  const classes = useStyles();
  const previousContractorId = useRef<string>();
  const dispatch = useDispatch<AppDispatch>();
  const otherCertifications = useSelector(
    contractorSelector.getOtherCertifications
  );

  const [listOfCertificationCards, setListOfCertificationCards] = useState<
    Array<string>
  >([]);
  const { uploadInput, clickOnUploadFile, rawFiles, removeAllFiles } =
    useUploadFile({
      acceptedExtensions: "image/*",
      inputId: "certificationInputId",
    });

  const clickOnAddOtherCertification = () => {
    dispatch(contractorReducer.actions.addOtherCertification());
  };

  const clickOnRemoveOtherCertification = (index: number) => {
    dispatch(contractorReducer.actions.removeOtherCertification(index));
  };

  // reset uploaded file by switch to another contractor or creating new contractor
  useEffect(() => {
    if (contractorId !== previousContractorId.current) {
      removeAllFiles();
      previousContractorId.current = contractorId;
    }
  }, [contractorId, dispatch, newContractorMode, removeAllFiles]);

  useEffect(() => {
    if (rawFiles && rawFiles?.length > 0) {
      const blob = rawFiles[0];
      setNewCertificationCard(blob);
    } else {
      setNewCertificationCard(null);
    }
  }, [rawFiles, setNewCertificationCard]);

  useEffect(() => {
    const certificationCards: string[] = [];
    if (!isNil(newCertificationCard)) {
      certificationCards.push(URL.createObjectURL(newCertificationCard));
    }

    if (data?.certificationCards) {
      Promise.all(
        data.certificationCards.map((photo) =>
          dispatch(loadFile(photo)).then((url: string) => {
            certificationCards.push(url);
          })
        )
      ).finally(() => setListOfCertificationCards(certificationCards));
    } else {
      setListOfCertificationCards(certificationCards);
    }
  }, [data?.certificationCards, dispatch, newCertificationCard]);

  return (
    <>
      <FormSection title="OSHA">
        <FormSectionRow>
          <FormControlWrapper
            title="Valid from"
            input={
              <SpTextField
                name="OshaCertificationFrom"
                inputRef={register}
                errors={errors}
                fullWidth
                type="date"
                disabled={viewOnly}
              />
            }
          />
          <FormControlWrapper
            title="Valid to"
            input={
              <SpTextField
                name="OshaCertificationTo"
                inputRef={register}
                errors={errors}
                fullWidth
                type="date"
                disabled={viewOnly}
              />
            }
          />
        </FormSectionRow>
      </FormSection>
      <FormSection title="Covid-19 Vaccination">
        <FormControlWrapper
          title="Verification Date"
          input={
            <SpTextField
              name="VaccinationVerificationDate"
              inputRef={register}
              errors={errors}
              fullWidth
              type="date"
              disabled={!canVerifyVaccination}
            />
          }
        />
      </FormSection>
      <FormSection
        title="Other certifications"
        rightElement={
          !viewOnly ? (
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={clickOnAddOtherCertification}
              endIcon={<AddCircle />}
            >
              Add New Certification
            </Button>
          ) : null
        }
      >
        <Grid container direction="row" className={classes.fullWidth}>
          {otherCertifications.map((cert, index) => (
            <Fragment key={index}>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" gridGap="10px">
                  <FormControlWrapper
                    title="Certification"
                    input={
                      <SpTextField
                        fullWidth
                        value={cert.name}
                        onChange={(
                          event: React.ChangeEvent<{ value: string }>
                        ) => {
                          dispatch(
                            contractorReducer.actions.editOtherCertification({
                              index,
                              data: {
                                id: cert.id,
                                name: event.target.value,
                                validTo: cert.validTo,
                              },
                            })
                          );
                        }}
                        name={`OtherCertifications[${index}].Name`}
                        errors={otherCertificationErrors}
                        disabled={viewOnly}
                      />
                    }
                  />
                  <FormControlWrapper
                    title="Valid to"
                    input={
                      <SpTextField
                        type="date"
                        fullWidth
                        value={moment(cert.validTo).format("YYYY-MM-DD")}
                        onChange={(
                          event: React.ChangeEvent<{ value: string }>
                        ) => {
                          dispatch(
                            contractorReducer.actions.editOtherCertification({
                              index,
                              data: {
                                id: cert.id,
                                name: cert.name,
                                validTo: moment(event.target.value).format(
                                  BACKEND_DATE_TIME_FORMAT
                                ),
                              },
                            })
                          );
                        }}
                        name={`OtherCertifications[${index}].ValidTo`}
                        errors={otherCertificationErrors}
                        disabled={viewOnly}
                      />
                    }
                  />
                  {!viewOnly ? (
                    <Grid item xs={1}>
                      <Box display="flex" flexDirection="column" pt="28px">
                        <IconButton
                          onClick={() => clickOnRemoveOtherCertification(index)}
                          className={classes.removeButton}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </Grid>
                  ) : null}
                </Box>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </FormSection>
      <FormSection title="Certification cards">
        <Box padding={3}>
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={clickOnUploadFile}
                disabled={viewOnly}
              >
                Add certification card
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box padding={1}>
          <Grid container direction="row" spacing={2}>
            <Grid item className={classes.fullWidth}>
              {listOfCertificationCards.map((url, index) => (
                <img
                  key={index}
                  alt="contractor"
                  src={url}
                  className={classes.image}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      </FormSection>
      {uploadInput}
    </>
  );
};

export default QualificationsDetail;
