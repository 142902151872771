import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import withAuthorization from "../../auth/hoc/withAutorization";
import { makeStyles, Theme } from "@material-ui/core/styles";
import EventPanelHeader from "./EventPanelHeader";
import { useFetchData } from "../../services/useFetchData";
import { CounterResponseModel } from "../../services/apiServiceTypes";
import { getDetailedSitesCounter } from "../messages/redux/apiCalls";
import { isNil } from "ramda";
import eventLogRedux from "./redux";
import { batch, useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../appStore";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { TreeStructure } from "../../common";
import { useComputeSize } from "../../services/useComputeSize";
import HelpRequestDialog from "./dialogs/HelpRequestDialog";
import { customDebounce } from "../../services/helpers";
import throttle from "lodash.throttle";
import { useSignalR } from "../../services/signalR/useSignalR";
import InfiniteScroll from "react-infinite-scroll-component";
import EventItem from "./EventItem";
import ContractorEventDialog from "./dialogs/ContractorEventDialog";
import ManualCheckDialog from "./dialogs/ManualCheckDialog";
import { State } from "./redux/model";
import eventLogReducer from "./redux";
import { useAuthorization } from "../../auth";
import { ROLES, USER_PERMISSIONS } from "../../auth/constants";

export const EVENT_PANEL_WIDTH = 400;

const useStyles = makeStyles<Theme, { eventPanelHeaderHeight: number }>(
  (theme) => ({
    main: {
      backgroundColor: theme.palette.common.white,
      height: "100%",
      width: EVENT_PANEL_WIDTH,
    },
    panelBody: ({ eventPanelHeaderHeight }) => ({
      height: `calc(100% - 16px - ${eventPanelHeaderHeight}px)`,
      overflowY: "auto",
    }),
    footerContainer: {
      height: "inherit",
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
    checkInOutButtonContainer: {
      padding: theme.spacing(2),
      width: "100%",
    },
    checkInOutButton: {
      width: "100%",
    },
  })
);

export interface EventPanelProps {
  panelId: number;
  additionalPadding?: number;
  onClose?: () => void;
}

const EventPanel: React.FC<EventPanelProps> = ({
  panelId,
  additionalPadding = 0,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showAllSites, setShowAllSites] = useState<boolean>(false);
  const [isOpenManualCheckDialog, setIsOpenManualCheckDialog] =
    useState<boolean>(false);

  const selectedEntrance = useSelector((state: { [x: string]: State }) =>
    eventLogRedux.selectors.getSelectedEntrance(state, panelId)
  );

  const allowedSites = useSelector((state: { [x: string]: State }) =>
    eventLogRedux.selectors.getSelectedSites(state, panelId)
  );

  const firstSelectedSite = useSelector((state: { [x: string]: State }) =>
    eventLogRedux.selectors.getFirstSelectedSite(state, panelId)
  );

  const firstSelectedEntrance = useSelector((state: { [x: string]: State }) =>
    eventLogRedux.selectors.getFirstSelectedEntrance(state, panelId)
  );

  const pageSize: number = 15;
  const pageIndex = useRef<number>(0);
  const forceNoUpdate = useRef<boolean>(false);
  const eventListHasNextPage = useSelector((state: { [x: string]: State }) =>
    eventLogRedux.selectors.getEventListHasNextPage(state, panelId)
  );
  const eventList = useSelector((state: { [x: string]: State }) =>
    eventLogRedux.selectors.getEventList(state, panelId)
  );

  const unauthorizedAccessEvent = useSelector((state: { [x: string]: State }) =>
    eventLogReducer.selectors.getUnauthorizedAccessEvent(state, panelId)
  );

  const { isAllowed: canCheckInOut } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.TICKER_CHECK_IN_OUT]
  );

  const fetchNextData = useCallback(
    (
      incrementedPageIndex: number,
      isScrollMode: boolean,
      isEventMode: boolean,
      entranceId: number
    ) => {
      dispatch(
        eventLogRedux.dispatchActions.fetchListOfEvents(
          {
            Pagination: {
              PageIndex: incrementedPageIndex,
              PageSize: pageSize,
            },
            entranceId,
          },
          isScrollMode,
          isEventMode,
          panelId,
          forceNoUpdate.current
        )
      )
        .then(() => {
          pageIndex.current = incrementedPageIndex;
        })
        .catch(() => {
          pageIndex.current = 0;
        });
    },
    [dispatch, panelId]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    customDebounce(
      (
        incrementedPageIndex: number,
        isScrollMode: boolean,
        entranceId: number
      ) => {
        fetchNextData(incrementedPageIndex, isScrollMode, false, entranceId);
      },
      800
    ),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSignalEvent = useCallback(
    throttle((eventEntranceId: string, skipBatteryCheck: boolean = false) => {
      processSignalREvent(eventEntranceId, skipBatteryCheck, true);
    }, 500),
    // selected entrance needs to be dependency, otherwise it would be undefined inside processSignalREvent
    [selectedEntrance]
  );

  const processSignalREvent = (
    eventEntranceId: string,
    skipBatteryCheck: boolean = false,
    isEventMode: boolean
  ) => {
    if (eventEntranceId !== selectedEntrance?.codeId) {
      // if eventEntranceId (received from backend) is different as selectedEntrance id, select new entrance
      // this can happen when e.g. help request from entrance 45 is received while entrance 25 is currently selected
      dispatch(
        eventLogRedux.dispatchActions.setSelectedEntrance(
          allowedSites,
          panelId,
          eventEntranceId
        )
      );
    } else {
      dispatch(
        eventLogRedux.dispatchActions.onScannerEventFunc(
          eventEntranceId,
          panelId
        )
      );
      if (!skipBatteryCheck) {
        dispatch(
          eventLogRedux.dispatchActions.onBatteryLevelsFunc(
            eventEntranceId,
            panelId
          )
        );
      }
      dispatch(
        eventLogRedux.dispatchActions.fetchSelectedEntranceInfo(
          eventEntranceId,
          panelId
        )
      );
      refetchEntranceEventList(eventEntranceId, isEventMode); // Override scroll mode to not to clear list

      pageIndex.current = 0;
    }
  };

  const fetchEntranceEventListNextData = useCallback(
    (
      isScrollMode: boolean = false,
      isEventMode: boolean = false,
      forceFetch?: boolean,
      selectedEntranceId?: string
    ) => {
      if (
        (eventListHasNextPage && !isNil(selectedEntrance?.codeId)) ||
        (forceFetch && !isNil(selectedEntranceId))
      ) {
        const incrementedPageIndex = pageIndex.current + 1;
        if (forceFetch) {
          fetchNextData(
            incrementedPageIndex,
            isScrollMode,
            isEventMode,
            parseInt(selectedEntranceId!)
          );
        } else {
          debouncedCall(
            incrementedPageIndex,
            isScrollMode,
            selectedEntrance?.codeId
          );
        }
      }
    },
    [
      eventListHasNextPage,
      selectedEntrance?.codeId,
      debouncedCall,
      fetchNextData,
    ]
  );

  const refetchEntranceEventList = useCallback(
    (selectedEntranceId?: string, isEventMode?: boolean) => {
      pageIndex.current = 0;
      fetchEntranceEventListNextData(
        false,
        isEventMode,
        true,
        selectedEntranceId
      );
    },
    [fetchEntranceEventListNextData]
  );

  useSignalR(
    (eventEntranceId, entranceEvent, skipBatteryCheck) => {
      batch(() => {
        if (entranceEvent) {
          throttledSignalEvent(eventEntranceId, skipBatteryCheck);
        } else {
          processSignalREvent(eventEntranceId, skipBatteryCheck, false);
        }
      });
    },
    panelId,
    selectedEntrance?.codeId
  );

  const { data: detailedSites } = useFetchData<CounterResponseModel>(
    getDetailedSitesCounter(),
    true,
    undefined,
    [],
    ({ data }) => {
      if (!firstSelectedSite) {
        dispatch(
          eventLogRedux.dispatchActions.selectFirstEntranceOfFirstSite(
            data,
            panelId
          )
        );
      }
    }
  );

  useEffect(() => {
    if (
      detailedSites &&
      firstSelectedSite?.id &&
      detailedSites.find((s) => s.codeId === firstSelectedSite.id) &&
      detailedSites.find((s) => s.codeId === firstSelectedSite.id)?.children &&
      detailedSites
        .find((s) => s.codeId === firstSelectedSite.id)
        ?.children?.filter((ch) => ch.codeId === firstSelectedEntrance?.id)[0]
    ) {
      //  const siteToDispatch = detailedSites
      //    .find((s) => s.codeId === firstSelectedSite.id)
      //    ?.children?.filter(
      //      (ch) => ch.codeId === firstSelectedEntrance?.id
      //    )[0];
      //
      // let model: CounterResponseItem = {
      //   codeId: siteToDispatch!.codeId,
      //   panelId: panelId,
      //   displayName: siteToDispatch!.displayName,
      //   isActive: siteToDispatch?.isActive
      // }
      dispatch(
        eventLogRedux.actions.setSelectedEntrance({
          selectedEntrance: detailedSites
            .find((s) => s.codeId === firstSelectedSite.id)
            ?.children?.filter(
              (ch) => ch.codeId === firstSelectedEntrance?.id
            )[0],
          panelId: panelId,
        })
      );
    }
  }, [
    panelId,
    dispatch,
    detailedSites,
    firstSelectedEntrance?.id,
    firstSelectedSite?.id,
  ]);

  const eventPanelHeaderRef = useRef<HTMLDivElement | null>(null);
  const { height: eventPanelHeaderHeight } = useComputeSize(
    eventPanelHeaderRef,
    [showAllSites, unauthorizedAccessEvent, selectedEntrance?.codeId]
  );

  const manualCheckInOutButtonRef = useRef<HTMLDivElement | null>(null);
  const { height: manualCheckInOutButtonHeight } = useComputeSize(
    manualCheckInOutButtonRef,
    [showAllSites]
  );
  const classes = useStyles({
    eventPanelHeaderHeight,
  });

  // TODO add new EventPanelWrapper that handles the onClose functionality from SpPanel
  return (
    <>
      <div className={classes.main}>
        <EventPanelHeader
          panelId={panelId}
          clickOnExpandSites={() => setShowAllSites((prevVal) => !prevVal)}
          ref={eventPanelHeaderRef}
          siteName={
            detailedSites &&
            firstSelectedSite &&
            firstSelectedSite.id &&
            detailedSites.find((s) => s.codeId === firstSelectedSite.id) &&
            detailedSites.find((s) => s.codeId === firstSelectedSite.id)
              ?.displayName
          }
          entranceName={selectedEntrance?.displayName}
          entranceId={selectedEntrance?.codeId}
          onClose={onClose}
          onForceNoUpdateChange={(checked: boolean) => {
            forceNoUpdate.current = checked;
            if (!checked) {
              refetchEntranceEventList(selectedEntrance?.codeId);
            }
          }}
        />
        <Grid
          container
          direction="column"
          className={classes.panelBody}
          wrap="nowrap"
        >
          {showAllSites && (
            <Grid item>
              <Grid container direction="column">
                {detailedSites?.map(
                  (site, index) =>
                    !isNil(allowedSites[index]) && (
                      <Grid item key={index}>
                        <TreeStructure
                          oneChildMode
                          title={site.displayName}
                          description={`${site?.children?.length} Entrances`}
                          itemHeaderTitle="Entrances"
                          items={
                            site?.children?.map((entrance) => ({
                              name: entrance.displayName,
                              id: entrance.codeId,
                            })) || []
                          }
                          state={allowedSites[index]}
                          onChangeState={(state) => {
                            dispatch(
                              eventLogRedux.actions.setLastSelectedSiteItem({
                                updateTreeStructure: {
                                  index,
                                  data: state,
                                },
                                panelId: panelId,
                              })
                            );
                            setShowAllSites(false);
                          }}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>
          )}
          <Grid
            item
            style={{
              height: `calc(100% - ${manualCheckInOutButtonHeight}px - 1px)`,
            }}
          >
            <InfiniteScroll
              dataLength={eventList.length} //This is important field to render the next data
              next={() => fetchEntranceEventListNextData(true)}
              hasMore={eventListHasNextPage}
              loader={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.footerContainer}
                >
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              }
              endMessage={
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textAlign: "center" }}
                >
                  No more data
                </Typography>
              }
              height={`calc(100vh - ${eventPanelHeaderHeight}px - ${manualCheckInOutButtonHeight}px - ${additionalPadding}px - 1px)`}
            >
              {eventList?.map((event) => (
                <EventItem key={event.id} eventData={event} panelId={panelId} />
              ))}
            </InfiniteScroll>
          </Grid>
          {canCheckInOut && (
            <Grid item ref={manualCheckInOutButtonRef}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.checkInOutButtonContainer}
              >
                <Grid item className={classes.checkInOutButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => setIsOpenManualCheckDialog(true)}
                  >
                    Manual Check-In/Out
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
      {isOpenManualCheckDialog && (
        <ManualCheckDialog
          panelId={panelId}
          isOpen={isOpenManualCheckDialog}
          onClose={() => setIsOpenManualCheckDialog(false)}
          refetchEntranceEventList={() => null}
        />
      )}
      <HelpRequestDialog panelId={panelId} />
      <ContractorEventDialog panelId={panelId} />
    </>
  );
};

export default memo(withAuthorization(EventPanel));
