import React, { ChangeEvent, memo, useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { useFetchData } from "../../../services/useFetchData";
import { CounterResponseModel } from "../../../services/apiServiceTypes";
import {
  CompanyDetailResponseType,
  getSecondaryCompanyCounter,
  postCreateSecondaryCompany,
  putUpdateSecondaryCompany,
} from "../redux/apiCalls";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { isNil } from "ramda";
import {
  SpFormControl,
  SpTextField,
  TreeStructure,
  YesNoDialog,
} from "../../../common";
import companiesRedux from "../redux";
import companiesActions from "../redux/dispatchActions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../appStore";
import { useAuthorization } from "../../../auth";
import { ROLES, USER_PERMISSIONS } from "../../../auth/constants";
import * as yup from "yup";

const useStyles = makeStyles<Theme>((theme) => ({
  siteGrid: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    height: "100%",
  },
  textWrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textNotActive: {
    color: theme.palette.text.disabled,
  },
  companiesGridLarge: {
    height: "calc(100vh - 16px - 195px - 64px) !important",
    overflow: "auto",
  },
  companiesGridSmall: {
    height: "calc(100vh - 16px - 116px - 195px - 64px) !important",
    overflow: "auto",
  },
  newCompanyGrid: {
    height: "32px",
  },
  newCompanyButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  newCompanyTextField: {
    width: "calc(100% - 116px)",
  },
}));

export interface CompanySitesProps {
  companyDetailData?: CompanyDetailResponseType;
  refreshCompanyDetail: Function;
  canSelectSecondaryCompanies: boolean;
}

export const NewSecondaryCompanySchema = yup.object().shape({
  NewSecondaryCompany: yup
    .string()
    .required("The Company field is required")
    .max(50, "Company exceeds 50 character limit."),
});

interface CompanyNameUpdateError {
  errorMessage?: string;
  secondaryCompanyCodeId?: string;
}

const CompanySecCompanies: React.FC<CompanySitesProps> = ({
  companyDetailData,
  refreshCompanyDetail,
  canSelectSecondaryCompanies,
}) => {
  const classes = useStyles();
  const { isAllowed: isCompany } = useAuthorization([ROLES.COMPANY]);
  const allowedSecondaryCompanies = useSelector(
    companiesRedux.selectors.getSecondaryCompaniesList
  );
  const dispatch = useDispatch<AppDispatch>();
  const [newSecondaryCompany, setNewSecondaryCompany] = useState<string>("");
  const [newSecondaryCompanyError, setNewSecondaryCompanyError] =
    useState<string>("");
  const [textFieldErrorMessage, setTextFieldErrorMessage] = useState<
    CompanyNameUpdateError | undefined
  >(undefined);
  const [openYesNoDialog, setOpenYesNoDialog] = React.useState<{
    isOpen: boolean;
    newSecondaryCompanyName?: string;
    secondaryCompanyId?: string;
    resetTextField?: () => void;
  }>({
    isOpen: false,
    newSecondaryCompanyName: undefined,
    secondaryCompanyId: undefined,
    resetTextField: undefined,
  });
  const [secondaryCompaniesCounter, setSecondaryCompaniesCounter] = useState<
    CounterResponseModel | undefined
  >(undefined);

  const { isAllowed: canEditCreateSecCompanies } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.COMPANIES_SECONDARY_COMPANIES_EDIT_CREATE]
  );

  const { data: secondaryCompanies } = useFetchData<CounterResponseModel>(
    getSecondaryCompanyCounter(),
    true,
    false,
    [],
    (data) => {
      setSecondaryCompaniesCounter(data.data);
    }
  );

  useEffect(() => {
    if (!isNil(secondaryCompaniesCounter)) {
      dispatch(
        companiesActions.prefillAllowedSecondaryCompanies(
          secondaryCompaniesCounter,
          companyDetailData?.allowedSecondaryCompanies.map(
            (secComp) => secComp.secondaryCompanyId
          ) ?? []
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetailData?.companyId, secondaryCompaniesCounter]);

  const onSubmitNewSecondaryCompany = () => {
    dispatch(
      postCreateSecondaryCompany({
        secondaryCompanyName: newSecondaryCompany,
        companyId: companyDetailData!.companyId,
      })
    )
      .then(() => {
        refreshCompanyDetail(companyDetailData!.companyId);
      })
      .catch((e) => {
        setNewSecondaryCompanyError(
          e.response?.data ?? "Failed to create secondary company"
        );
      });
  };

  const onCompanyNameChanged = (
    newCompanyName: string,
    secondaryCompanyId: string,
    resetTextField: () => void
  ): boolean => {
    if (
      secondaryCompanies?.some(
        (secComp) => secComp.displayName === newCompanyName
      )
    ) {
      setOpenYesNoDialog({
        isOpen: true,
        newSecondaryCompanyName: newCompanyName,
        secondaryCompanyId: secondaryCompanyId,
        resetTextField: resetTextField,
      });
      return true;
    } else {
      newCompanyNameSubmit(newCompanyName, secondaryCompanyId);
      return false;
    }
  };

  const newCompanyNameSubmit = (
    newCompanyName?: string,
    secondaryCompanyId?: string
  ) => {
    setTextFieldErrorMessage(undefined);
    if (newCompanyName && secondaryCompanyId) {
      dispatch(
        putUpdateSecondaryCompany({
          newSecondaryCompanyName: newCompanyName,
          secondaryCompanyId: secondaryCompanyId,
        })
      )
        .then(() => {
          refreshCompanyDetail(companyDetailData!.companyId);
        })
        .catch((error) => {
          if (
            error.response == null ||
            typeof error.response?.data === "string" ||
            error.response?.data instanceof String
          ) {
            if (error.response == null) {
              setTextFieldErrorMessage({
                secondaryCompanyCodeId: secondaryCompanyId,
                errorMessage: "Failed to update secondary company.",
              });
            } else {
              setTextFieldErrorMessage({
                secondaryCompanyCodeId: secondaryCompanyId,
                errorMessage: error.response.data,
              });
            }
          } else if (error.response.data.errors) {
            for (const key of Object.keys(error.response.data.errors)) {
              if (error.response.data.errors[key].length > 0) {
                setTextFieldErrorMessage({
                  secondaryCompanyCodeId: secondaryCompanyId,
                  errorMessage: error.response.data.errors[key],
                });
                break;
              }
            }
          }
        });
    }
    setOpenYesNoDialog({ isOpen: false });
  };

  const onDialogClose = () => {
    setTextFieldErrorMessage(undefined);
    setOpenYesNoDialog({ isOpen: false });
    if (openYesNoDialog.resetTextField) {
      openYesNoDialog.resetTextField();
    }
  };

  return (
    <Grid
      className={classes.siteGrid}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid
        className={
          !isCompany && companyDetailData
            ? classes.companiesGridSmall
            : classes.companiesGridLarge
        }
        item
        xs={12}
      >
        {secondaryCompanies?.map((secondaryCompany, index) => {
          const contractorsCount =
            companyDetailData?.allowedSecondaryCompanies.find(
              (secComp) =>
                secComp.secondaryCompanyId.toString() ===
                secondaryCompany.codeId
            )?.numberOfContractors;

          return (
            !isNil(allowedSecondaryCompanies[index]) && (
              <Grid item key={index}>
                <TreeStructure
                  hideChildren
                  disabled={
                    !canSelectSecondaryCompanies || (contractorsCount ?? 0) > 0
                  }
                  title={secondaryCompany.displayName}
                  description={`${contractorsCount ?? 0} ${
                    contractorsCount === 1 ? "contractor" : "contractors"
                  }`}
                  itemHeaderTitle="Entrances"
                  items={[]}
                  state={allowedSecondaryCompanies[index]}
                  onChangeState={(state) => {
                    dispatch(
                      companiesRedux.actions.updateAllowedSecondaryCompanies({
                        index,
                        data: state,
                      })
                    );
                  }}
                  editable={canEditCreateSecCompanies}
                  textFieldProps={{
                    errorMessage:
                      textFieldErrorMessage?.secondaryCompanyCodeId ===
                      secondaryCompany.codeId
                        ? textFieldErrorMessage?.errorMessage
                        : "",
                    onClose: (value) => true,
                    onSubmit: (value, resetTextField) =>
                      onCompanyNameChanged(
                        value,
                        secondaryCompany.codeId,
                        resetTextField
                      ),
                  }}
                />
              </Grid>
            )
          );
        })}
      </Grid>
      {canEditCreateSecCompanies && companyDetailData && (
        <Grid
          container
          alignItems={"center"}
          justifyContent="flex-start"
          direction={"row"}
          className={classes.newCompanyGrid}
        >
          <Grid item className={classes.newCompanyTextField}>
            <SpFormControl
              title="New company name"
              input={
                <SpTextField
                  onChange={(
                    data: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setNewSecondaryCompany(data.target.value)}
                  name={"NewCompany"}
                  errors={
                    newSecondaryCompanyError.length > 0 && {
                      NewCompany: {
                        message: newSecondaryCompanyError,
                      },
                    }
                  }
                  fullWidth
                />
              }
            />
          </Grid>
          <Grid item className={classes.newCompanyButton}>
            <Button
              color="primary"
              variant="contained"
              onClick={onSubmitNewSecondaryCompany}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      )}
      <YesNoDialog
        open={openYesNoDialog.isOpen}
        onConfirm={() =>
          newCompanyNameSubmit(
            openYesNoDialog.newSecondaryCompanyName,
            openYesNoDialog.secondaryCompanyId
          )
        }
        onCancel={onDialogClose}
        title="Company Name Change"
        message="There is already a company with this name. Do you want to merge these companies?"
      />
    </Grid>
  );
};

export default memo(CompanySecCompanies);
