import { Box } from "@material-ui/core";
import { ReactNode } from "react";
import { COLORS, SCROLL_PANEL_WIDTH } from "../../../appTheme";

export interface ListDetailSplitViewProps {
  list: ReactNode;
  details: ReactNode;
}

const ListDetailSplitView: React.FC<ListDetailSplitViewProps> = ({
  list,
  details,
}) => {
  return (
    <Box display={"flex"} flexDirection="row" flexGrow={1} height="100%">
      <Box
        bgcolor={COLORS.WHITE}
        borderRight="1px solid #E2E3E5"
        width={SCROLL_PANEL_WIDTH}
      >
        {list}
      </Box>
      <Box bgcolor={COLORS.WHITE} flexGrow={1}>
        {details}
      </Box>
    </Box>
  );
};

export default ListDetailSplitView;
