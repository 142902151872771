import { Box } from "@material-ui/core";
import { ReactNode } from "react";

export interface FormSectionRowProps {
  children: ReactNode;
}

const FormSectionRow: React.FC<FormSectionRowProps> = ({ children }) => {
  return (
    <Box
      display={"flex"}
      flexDirection="row"
      gridGap={"10px"}
      alignItems="center"
      width="100%"
      flexBasis="50%"
    >
      {children}
    </Box>
  );
};

export default FormSectionRow;
