export const REPORT_TYPES = {
  COMPANY: "Company",
  SECONDARY_COMPANY: "SecondaryCompany",
  INDIVIDUAL_CONTRACTOR: "IndividualContractor",
  CONTRACTORS: "Contractors",
  CONTRACTORS_STATUS: "ContractorsStatus",
  ALL_CONTRACTORS: "AllContractors",
  SITE_STATISTICS: "SiteStatistics",
  CAMERA: "Camera",
  SITE_QUALIFICATIONS: "SiteQualification",
  SITE_QUALIFICATIONS_EXPIRING: "SiteQualificationExpiring",
  SITE_HELP_REQUESTS: "SiteHelpRequests",
  FORMS: "Forms",
};

export const ENTRANCE_HELPER_VALUES = {
  ALL_ENTRANCES: "All Entrances",
};

export const SITE_HELPER_VALUES = {
  ALL_SITES: "All Locations",
};
