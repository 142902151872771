import { makeStyles } from "@material-ui/core/styles";
import React, { ReactNode } from "react";
import { Divider, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fullWidthRow: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    width: "100%",
  },
}));

const SpTitle: React.FC<SpTitleProps> = ({ title, rightContent }) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.fullWidthRow}
      >
        <Grid item xs={11}>
          <Typography variant="subtitle2">{title}</Typography>
        </Grid>
        {rightContent && (
          <Grid item xs={1}>
            {rightContent}
          </Grid>
        )}
      </Grid>
      <Divider />
    </>
  );
};

export default SpTitle;

export interface SpTitleProps {
  title: string;
  rightContent?: ReactNode;
}
