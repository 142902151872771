import { ContractorListRequestType } from "../../contractors/redux/apiCalls";
import { AppDispatch } from "../../../appStore";
import userReducer from "./reducer";
import {
  getAdminList,
  getSiteSupervisorList,
  SiteSupervisorPaginatedModel,
} from "./apiCalls";
import { CounterResponseModel } from "../../../services/apiServiceTypes";
import { isNil } from "ramda";
import usersRedux from "./index";
import { batch } from "react-redux";
import { USER_TYPES } from "../../../counters";
import { CancelToken } from "axios";

const fetchListOfAdmins =
  (
    body: ContractorListRequestType,
    isScrollMode: boolean = false,
    cancelToken: CancelToken
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(userReducer.actions.setIsFetchingUserList(true));
    return dispatch(getAdminList(body, cancelToken))
      .then(({ data }) => {
        if (isScrollMode) {
          dispatch(userReducer.actions.appendToUserList(data.items));
        } else {
          dispatch(userReducer.actions.setUserList(data.items));
        }
        dispatch(userReducer.actions.setUserListHasNextPage(data.hasNextPage));
      })
      .catch(() => {
        dispatch(userReducer.actions.setUserListHasNextPage(true));
      })
      .finally(() => {
        dispatch(userReducer.actions.setIsFetchingUserList(false));
      });
  };

const fetchListOfSiteSupervisors =
  (
    body: SiteSupervisorPaginatedModel,
    isScrollMode: boolean = false,
    cancelToken: CancelToken
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(userReducer.actions.setIsFetchingUserList(true));
    return dispatch(getSiteSupervisorList(body, cancelToken))
      .then(({ data }) => {
        if (isScrollMode) {
          dispatch(userReducer.actions.appendToUserList(data.items));
        } else {
          dispatch(userReducer.actions.setUserList(data.items));
        }
        dispatch(userReducer.actions.setUserListHasNextPage(data.hasNextPage));
      })
      .catch(() => {
        dispatch(userReducer.actions.setUserListHasNextPage(true));
      })
      .finally(() => {
        dispatch(userReducer.actions.setIsFetchingUserList(false));
      });
  };

const prefillAllowedSites =
  (accessList: CounterResponseModel, sites: number[]) =>
  (dispatch: AppDispatch) => {
    let newAccessList = accessList.map((item) => ({
      id: item.codeId,
      isChecked: !isNil(
        sites.find((e) => e.toString() === item.codeId.toString())
      ),
      children: [],
    }));
    dispatch(usersRedux.actions.setAllowedSites(newAccessList));
  };

const resetUsers = () => (dispatch: AppDispatch) => {
  batch(() => {
    dispatch(usersRedux.actions.setSelectedUserTypeCodeId(USER_TYPES.ADMIN));
    dispatch(usersRedux.actions.setUserList([]));
    dispatch(usersRedux.actions.setUserListHasNextPage(true));
    dispatch(userReducer.actions.setIsFetchingUserList(false));
    dispatch(userReducer.actions.setAllowedSites([]));
    dispatch(userReducer.actions.setAllowedPermissions([]));
  });
};

const allActions = {
  fetchListOfAdmins,
  fetchListOfSiteSupervisors,
  prefillAllowedSites,
  resetUsers,
};

export default allActions;
