import { useSelector } from "react-redux";
import { any, isNil } from "ramda";
import authReducer from "../redux";
import { useMemo } from "react";

export type AuthorizedResultType = {
  isAllowed: true;
};

const useAuthorization = (
  allowedRoles: Array<string>,
  allowedPermissions?: Array<string>
): AuthorizedResultType => {
  const accessTokenRedux = useSelector(
    authReducer.selectors.getUserAccessToken
  );

  const role = useSelector(authReducer.selectors.getRole);
  const permissions = useSelector(authReducer.selectors.getPermissions);

  const roleCheck = useMemo(
    () => !isNil(role) && allowedRoles.includes(role),
    [allowedRoles, role]
  );
  const permissionsCheck = useMemo(
    () =>
      allowedPermissions === undefined ||
      (permissions !== undefined &&
        // checks if any of allowedPermissions is within user permissions list
        any(
          (alPerm) => any((perm) => alPerm === perm, permissions),
          allowedPermissions
        )),
    [allowedPermissions, permissions]
  );

  // User needs to have required role(s) and permissions(s) to allow access
  const isAllowed = roleCheck && permissionsCheck && !isNil(accessTokenRedux);

  return { isAllowed } as AuthorizedResultType;
};

export default useAuthorization;
