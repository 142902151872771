import { AppDispatch } from "../../../appStore";
import {
  getListOfNotification,
  getSitesCounter,
  getEntrancesCounter,
  NotificationRequestType,
  getListOfContractors,
  UserPaginatedModel,
} from "./apiCalls";
import messagesReducer from "./reducer";
import { CancelToken } from "axios";
import { batch } from "react-redux";

const fetchListOfNotifications =
  (
    body: NotificationRequestType,
    isScrollMode: boolean = false,
    cancelToken: CancelToken
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(messagesReducer.actions.setIsFetching(true));
    return dispatch(getListOfNotification(body, cancelToken))
      .then(({ data }) => {
        if (isScrollMode) {
          dispatch(
            messagesReducer.actions.appendToNotificationList(data.items)
          );
        } else {
          dispatch(messagesReducer.actions.setNotificationList(data.items));
        }
        dispatch(messagesReducer.actions.setHasNextPage(data.hasNextPage));
      })
      .catch(() => {
        dispatch(messagesReducer.actions.setHasNextPage(true));
      })
      .finally(() => {
        dispatch(messagesReducer.actions.setIsFetching(false));
      });
  };

const fetchListOfContractors =
  (body: UserPaginatedModel, isScrollMode: boolean = false) =>
  (dispatch: AppDispatch) => {
    dispatch(messagesReducer.actions.setIsFetchingSendToContractors(true));
    return dispatch(getListOfContractors(body))
      .then(({ data }) => {
        if (isScrollMode) {
          dispatch(
            messagesReducer.actions.appendToSendToContractors(data.items)
          );
        } else {
          dispatch(messagesReducer.actions.setSendToContractors(data.items));
        }
        dispatch(
          messagesReducer.actions.setHasNextPageOfSendToContractors(
            data.hasNextPage
          )
        );
      })
      .catch(() => {
        dispatch(
          messagesReducer.actions.setHasNextPageOfSendToContractors(true)
        );
      })
      .finally(() => {
        dispatch(messagesReducer.actions.setIsFetchingSendToContractors(false));
      });
  };

const fetchSitesCounters = () => (dispatch: AppDispatch) => {
  dispatch(getSitesCounter())
    .then(({ data }) => {
      dispatch(messagesReducer.actions.setSitesList(data));
    })
    .catch(() => {
      dispatch(messagesReducer.actions.setSitesList([]));
    });
};

const fetchEntrancesCounters =
  (selectedSiteId?: number) => (dispatch: AppDispatch) => {
    dispatch(getEntrancesCounter({ SiteId: selectedSiteId }))
      .then(({ data }) => {
        dispatch(messagesReducer.actions.setEntrancesList(data));
      })
      .catch(() => {
        dispatch(messagesReducer.actions.setEntrancesList([]));
      });
  };

const resetMessagesReducer = () => (dispatch: AppDispatch) => {
  batch(() => {
    dispatch(messagesReducer.actions.setNotificationList([]));
    dispatch(messagesReducer.actions.setHasNextPage(true));
    dispatch(messagesReducer.actions.setIsFetching(false));
    dispatch(messagesReducer.actions.setSitesList([]));
    dispatch(messagesReducer.actions.setSelectedSiteId(undefined));
    dispatch(messagesReducer.actions.setEntrancesList([]));
    dispatch(messagesReducer.actions.setSelectedEntranceId(undefined));
    dispatch(messagesReducer.actions.setSendToSites([]));
    dispatch(messagesReducer.actions.setSendToContractors([]));
    dispatch(messagesReducer.actions.setHasNextPageOfSendToContractors(true));
    dispatch(messagesReducer.actions.setIsFetchingSendToContractors(true));
  });
};

const allActions = {
  fetchListOfNotifications,
  fetchSitesCounters,
  fetchEntrancesCounters,
  fetchListOfContractors,
  resetMessagesReducer,
};

export default allActions;
