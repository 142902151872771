import React, { FC, forwardRef, RefObject } from "react";
import { TextField } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  BaseTextFieldProps,
  TextFieldProps,
} from "@material-ui/core/TextField/TextField";
import { isNil } from "ramda";
import {
  BORDER_RADIUS,
  COLORS,
  FONT_WEIGHT,
  HEIGHT_OF_INPUT,
} from "../../../../appTheme";
import { FieldErrors } from "react-hook-form/dist/types/errors";

const useStyles = makeStyles<Theme, SpTextFieldProps>((theme) => ({
  root: ({ minRows, fitHeightToContent, disabled }) => ({
    "& label.Mui-focused": {
      color: COLORS.SECONDARY_BLUE_700,
      cursor: "not-allowed !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.primary.light,
      borderWidth: 1,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: BORDER_RADIUS.SMALL,
      height:
        (fitHeightToContent && "auto") ||
        (isNil(minRows) && HEIGHT_OF_INPUT) ||
        undefined,
      backgroundColor: "unset",
      // TODO move color to theme file
      "& fieldset": {
        borderColor: "#D2D5DA",
        borderWidth: 1,
      },
      "&:hover fieldset": {
        borderColor: COLORS.SECONDARY_BLUE_700,
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.SECONDARY_BLUE_700,
        borderWidth: 1,
      },
    },
    "& .MuiSelect-root": {
      backgroundColor: "unset",
    },
    "& .MuiOutlinedInput-input": {
      cursor: (disabled && "not-allowed") || "auto",
      fontSize: 14,
      fontWeight: FONT_WEIGHT.SEMI_BOLD,
      backgroundColor: "unset",
      "&:-webkit-autofill": {
        height: 0,
      },
    },
  }),
}));

const SpTextField: FC<any> = forwardRef<TextFieldProps, SpTextFieldProps>(
  (
    {
      children,
      fitHeightToContent,
      minRows,
      inputRef,
      fullWidth = true,
      name,
      errors,
      withoutHelperText,
      maxRows,
      errorKey,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles({ minRows, fitHeightToContent, ...rest });

    const getSpecificErrors = () => {
      if (!errors) {
        return null;
      }

      if (errorKey) {
        return errors[errorKey];
      }

      if (name) {
        return errors[name];
      }
    };

    return (
      <TextField
        className={classes.root}
        name={name}
        error={!!getSpecificErrors()}
        helperText={withoutHelperText ? null : getSpecificErrors()?.message}
        {...rest}
        variant={"outlined"}
        inputRef={ref || inputRef}
        minRows={minRows}
        fullWidth={fullWidth}
        multiline={maxRows !== undefined}
        maxRows={maxRows}
      >
        {children}
      </TextField>
    );
  }
);

export default SpTextField;

export interface SpTextFieldProps extends BaseTextFieldProps {
  fitHeightToContent?: boolean;
  minRows?: number | string;
  fullWidth?: boolean;
  inputRef?: RefObject<any>;
  errors?: FieldErrors<any>;
  withoutHelperText?: boolean;
  errorKey?: string;
}
