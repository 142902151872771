import React, { MutableRefObject, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
    CircularProgress,
    Grid,
    MenuItem,
    Typography,
} from "@material-ui/core";
import { isNil } from "ramda";
import {
    FormControlWrapper,
    FormSection,
    PlusButton,
    SpIcon,
    SpTextField,
    TreeStructure,
} from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { useFetchData } from "../../../services/useFetchData";
import { getDetailedSitesCounter } from "../redux/apiCalls";
import { FONT_WEIGHT } from "../../../appTheme";
import {
    CounterResponseItem,
    CounterResponseModel,
} from "../../../services/apiServiceTypes";
import contractorRedux from "../redux";
import messagesRedux from "../redux";
import * as yup from "yup";
import { Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AppDispatch } from "../../../appStore";

const useStyles = makeStyles<Theme>((theme) => ({
    fullWidthRowAttachments: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        width: "100%",
    },
    selectNone: {
        fontWeight: FONT_WEIGHT.MEDIUM,
        color: theme.palette.text.secondary,
    },
    selectContractors: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    subtitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        borderBottom: "1px solid #E0E0E0",
    },
    attachments: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
}));

export type NewMessageInputs = {
    sender: string;
    subject: string;
    body: string;
    selectedForm?: string;
};

export const NewMessageSchema = yup.object().shape({
    sender: yup.string().required(),
    subject: yup.string().required(),
    body: yup.string().required(),
    selectedForm: yup.string(),
});

export const selectAllContractorsOption: CounterResponseItem = {
    displayName: "Select all contractors",
    codeId: "",
};

export interface NewMessageProps {
    setSelectedSiteId: (newSiteId: string) => void;
    setSelectedContractors: (newArray: CounterResponseItem[]) => void;
    resetPagination: () => void;
    fetchNextData: (isScrollMode?: boolean) => void;
    formList?: CounterResponseModel;
    clickOnUploadFile: () => void;
    selectedFiles?: File[] | null;
    onRemoveFile: (index: number) => void;
    selectedSiteId: string,
    selectedContractors: Array<CounterResponseItem>;
    contractorFilter: MutableRefObject<string | undefined>;
    register: any;
    control: any;
}

const NewMessage: React.FC<NewMessageProps> = ({
    setSelectedSiteId,
    setSelectedContractors,
    resetPagination,
    fetchNextData,
    formList,
    clickOnUploadFile,
    selectedFiles,
    onRemoveFile,
    selectedSiteId,
    selectedContractors,
    contractorFilter,
    register,
    control,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch<AppDispatch>();
    const sendToSitesList = useSelector(
        messagesRedux.selectors.getSendToSitesList
    );

    const isFetchingSendToContractors = useSelector(
        messagesRedux.selectors.getIsFetchingSendToContractors
    );
    const sendToContractorsList = useSelector(
        messagesRedux.selectors.getSendToContractorsList
    );

    useEffect(() => {
        resetPagination();
    }, [resetPagination]);

    useEffect(() => {
        fetchNextData();
    }, [fetchNextData]);

    const { data: detailedSites } = useFetchData<CounterResponseModel>(
        getDetailedSitesCounter(),
        true,
        false,
        undefined,
        ({ data }) => {
            dispatch(
                messagesRedux.actions.setSendToSites(
                    data.map((item) => ({
                        id: item.codeId,
                        isChecked: false,
                        children: item.children?.map((ch) => ({
                            id: ch.codeId,
                            isChecked: false,
                        })),
                    }))
                )
            );
        }
    );

    const siteList = useSelector(contractorRedux.selectors.getSitesList);

    return (
        <>
            <Grid item>
                <FormSection title="Message Information">

                    <FormControlWrapper
                        title="Sender"
                        fullWidth
                        input={
                            <Controller
                                control={control}
                                name="sender"
                                id="sender"
                                defaultValue={""}
                                as={
                                    <SpTextField
                                        inputRef={register}
                                        required
                                        placeholder="Sender(location) of message..."
                                        fullwidth
                                        select
                                        value={selectedSiteId || ""}
                                        onChange={(event: React.ChangeEvent<{ value: any }>) => {
                                            setSelectedSiteId(event.target.value as string);
                                        }}
                                    >
                                        {siteList.map((site) => (
                                            <MenuItem key={site.codeId} value={site.codeId}>
                                                {site.displayName}
                                            </MenuItem>
                                        ))}
                                    </SpTextField>
                                }
                            />
                        }
                    />


                    <FormControlWrapper
                        title="Subject"
                        fullWidth
                        input={
                            <SpTextField
                                name="subject"
                                id="subject"
                                inputRef={register}
                                required
                                placeholder="Subject of Message..."
                                fullWidth
                            />
                        }
                    />
                    <FormControlWrapper
                        title="Message"
                        fullWidth
                        input={
                            <SpTextField
                                name="body"
                                id="body"
                                inputRef={register}
                                required
                                placeholder="Body of Message..."
                                fullWidth
                                multiline
                                minRows={7}
                            />
                        }
                    />
                    <FormControlWrapper
                        title="Form"
                        input={
                            <Controller
                                control={control}
                                name="selectedForm"
                                defaultValue={""}
                                as={
                                    <SpTextField select placeholder="Select Form">
                                        <MenuItem value={""} className={classes.selectNone}>
                                            Select None
                                        </MenuItem>
                                        {formList?.map((form) => (
                                            <MenuItem key={form.codeId} value={form.codeId}>
                                                {form.displayName}
                                            </MenuItem>
                                        ))}
                                    </SpTextField>
                                }
                            />
                        }
                    />
                </FormSection>
            </Grid>

            <Grid item className={classes.subtitle}>
                <Typography variant="h4">Send To</Typography>
            </Grid>

            {detailedSites?.map(
                (site, index) =>
                    !isNil(sendToSitesList[index]) && (
                        <Grid item key={index}>
                            <TreeStructure
                                title={site.displayName}
                                description={`${site?.children?.length} Entrances`}
                                itemHeaderTitle="Entrances"
                                items={
                                    site?.children?.map((entrance) => ({
                                        name: entrance.displayName,
                                        id: entrance.codeId,
                                    })) || []
                                }
                                state={sendToSitesList[index]}
                                onChangeState={(state) => {
                                    dispatch(
                                        messagesRedux.actions.setSendToSitesItem({
                                            index,
                                            data: state,
                                        })
                                    );
                                }}
                            />
                        </Grid>
                    )
            )}
            <Grid item>
                <Autocomplete
                    multiple
                    id="multiple-limit-tags"
                    limitTags={3}
                    options={[selectAllContractorsOption, ...sendToContractorsList]}
                    loading={isFetchingSendToContractors}
                    onChange={(event, newValue) => {
                        const resultArray: Array<CounterResponseItem> = [];
                        const castedResults = newValue as Array<CounterResponseItem>;

                        if (
                            castedResults.filter(
                                (item) => item.codeId === selectAllContractorsOption.codeId
                            ).length === 0
                        ) {
                            resultArray.push(...castedResults);
                        } else {
                            resultArray.push(selectAllContractorsOption);
                        }

                        setSelectedContractors(resultArray);
                    }}
                    ListboxProps={{
                        onScroll: (event: React.SyntheticEvent) => {
                            const listboxNode = event.currentTarget;
                            if (
                                Math.abs(
                                    listboxNode.scrollHeight -
                                    (listboxNode.scrollTop + listboxNode.clientHeight)
                                ) <= 30
                            ) {
                                fetchNextData(true);
                            }
                        },
                    }}
                    getOptionLabel={(option: { displayName: any }) => option.displayName}
                    renderOption={(option) => (
                        <>
                            {option.codeId === selectAllContractorsOption.codeId && (
                                <Typography color="textSecondary">
                                    {option.displayName}
                                </Typography>
                            )}
                            {option.codeId !== selectAllContractorsOption.codeId &&
                                option.displayName}
                        </>
                    )}
                    value={selectedContractors}
                    filterSelectedOptions
                    filterOptions={(options, { inputValue }) => {
                        if (contractorFilter.current !== inputValue) {
                            resetPagination();
                            contractorFilter.current = inputValue;
                            fetchNextData();
                        }
                        return options;
                    }}
                    renderInput={(params: any) => (
                        <SpTextField
                            {...params}
                            placeholder="Select Contractors"
                            fitHeightToContent
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {isFetchingSendToContractors ? (
                                            <CircularProgress size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                    className={classes.selectContractors}
                />
            </Grid>
            <Grid item className={classes.subtitle}>
                <div className={classes.attachments}>
                    <Typography variant="h4">Attachments</Typography>
                    <PlusButton onClick={clickOnUploadFile} />
                </div>
                {selectedFiles?.map((file, index: number) => (
                    <Grid
                        item
                        key={index}
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.fullWidthRowAttachments}
                    >
                        <Grid item xs={1}>
                            <SpIcon iconName="attachment" />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography>{file.name}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <SpIcon iconName="minus" onClick={() => onRemoveFile(index)} />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default NewMessage;
