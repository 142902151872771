import React, { FC, forwardRef, RefObject } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import { isNil } from "ramda";
import { BORDER_RADIUS } from "../../../../appTheme";
import Search from "@material-ui/icons/Search";

const HEIGHT_OF_INPUT = 64;

const useStyles = makeStyles<Theme, SpTextFieldProps>((theme) => ({
  root: ({ rows, fitHeightToContent }) => ({
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& .MuiInput-root": {
      fontSize: 14,
      borderRadius: BORDER_RADIUS.NONE,
      height:
        (fitHeightToContent && "auto") ||
        (isNil(rows) && HEIGHT_OF_INPUT) ||
        undefined,
      backgroundColor: "unset",
      "&:-webkit-autofill": {
        height: 0,
      },
    },
    "& .MuiInput-underline": {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      "&:hover": {
        borderTop: `1px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      "&:hover:not(.Mui-disabled):before": {
        border: "none",
      },

      "&:before": {
        border: "none",
      },
      "&:after": {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },

    "& .MuiSelect-root": {
      backgroundColor: "unset",
    },
    "& .MuiInputAdornment-root": {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(0),
    },
  }),
}));

const SpSearchTextField: FC<any> = forwardRef<TextFieldProps, SpTextFieldProps>(
  (
    { children, fitHeightToContent, rows, inputRef, fullWidth = true, ...rest },
    ref
  ) => {
    const classes = useStyles({ rows, fitHeightToContent });

    return (
      <TextField
        variant="standard"
        className={classes.root}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        {...rest}
        inputRef={ref || inputRef}
        minRows={rows}
        fullWidth={fullWidth}
      >
        {children}
      </TextField>
    );
  }
);

export default SpSearchTextField;

export interface SpTextFieldProps {
  fitHeightToContent?: boolean;
  rows?: number | string;
  fullWidth?: boolean;
  inputRef?: RefObject<any>;
}
