import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COMMON } from "./constants";
import { initialState, State } from "./model";

const authReducer = createSlice({
  name: COMMON.ROOT_REDUCER,
  initialState,
  reducers: {
    setUserAccessToken: (
      state: State,
      action: PayloadAction<string | null>
    ) => ({ ...state, userAccessToken: action.payload }),
    setUserRefreshToken: (
      state: State,
      action: PayloadAction<string | null>
    ) => ({ ...state, userRefreshToken: action.payload }),
    setIsLoading: (state: State, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),
    setSkipAuthentication: (state: State, action: PayloadAction<boolean>) => ({
      ...state,
      skipAuthentication: action.payload,
    }),
  },
});

export default authReducer;
