import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { BORDER_RADIUS } from "../../../appTheme";
import { FormControlWrapper, FormSection, SpTextField } from "../../../common";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import companyRedux from "../redux";
import * as yup from "yup";
import { FieldName } from "react-hook-form/dist/types/fields";
import { SetFieldValue, SetValueConfig } from "react-hook-form/dist/types/form";
import { isNil } from "ramda";
import { loadFile } from "../../../services/authApi";
import logoUrl from "../../../assets/img/logo-placeholder.png";
import { AppDispatch } from "../../../appStore";
import { CameraPhoto } from "../../../services/CameraPhoto";
import { useUploadFile } from "../../../services/useUploadFile";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import {
  CompanyDetailResponseType,
  postChangeCompanyStatus,
} from "../redux/apiCalls";
import authRedux from "../../../auth/redux";
import { ROLES, USER_PERMISSIONS } from "../../../auth/constants";
import { phoneNumberMessage, phoneNumberRegex } from "../../../validators";
import Alert from "@material-ui/lab/Alert";
import { useAuthorization } from "../../../auth";

const useStyles = makeStyles<Theme>((theme) => ({
  image: {
    width: "auto",
    borderRadius: BORDER_RADIUS.MEDIUM,
    maxHeight: "180px",
    maxWidth: "inherit",
  },
  imageGrid: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  fullWidth: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  title: {
    paddingTop: theme.spacing(1),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  permissions: {
    padding: theme.spacing(3),
  },
  alertGrid: {
    paddingTop: theme.spacing(2),
  },
}));

export interface CompanyInfoInputs {
  Address: string;
  CompanyName: string;
  CompanyContactName: string;
  CompanyContactPhone: string;
  CompanyContactEmail: string;
  CanCreateForms: boolean;
  VaccinationRequired?: boolean;
  Password: string;
  ConfirmPassword: string;
}

export const createCompanyInfoSchema = (
  canEditBaseInfo: boolean,
  canEditCredentials: boolean
) =>
  yup.object().shape({
    Address: canEditBaseInfo
      ? yup
          .string()
          .required("Please enter Address")
          .max(100, "Address exceeds 100 character limit.")
      : yup.string().max(100, "Address exceeds 100 character limit."),
    CanCreateForms: yup.boolean().required("Please enter Forms permissions"),
    CompanyName: canEditBaseInfo
      ? yup
          .string()
          .required("Please enter Client Name")
          .max(30, "Client Name exceeds 30 character limit.")
      : yup.string().max(30, "Client Name exceeds 30 character limit."),
    CompanyContactName: canEditBaseInfo
      ? yup
          .string()
          .required("Please enter Client Contact Name")
          .max(80, "Client Contact Name exceeds 80 character limit.")
      : yup.string().max(80, "Client Contact Name exceeds 80 character limit."),
    CompanyContactPhone: canEditBaseInfo
      ? yup
          .string()
          .required("Please enter Client Contact Phone Number")
          .matches(phoneNumberRegex, phoneNumberMessage)
      : yup.string().matches(phoneNumberRegex, phoneNumberMessage),
    CompanyContactEmail: canEditCredentials
      ? yup
          .string()
          .required("Please enter Client Contact Email")
          .max(80, "Cient Contact Email exceeds 80 character limit.")
          .email("Not valid Email form")
      : yup
          .string()
          .max(80, "Cient Contact Email exceeds 80 character limit.")
          .email("Not valid Email form"),
    Password: yup.string().when("newCompanyMode", {
      is: true,
      then: yup
        .string()
        .required("Please enter Password")
        .max(20, "Password exceeds 20 character limit.")
        .min(8, "Password must contain at least 8 characters"),
      otherwise: yup
        .string()
        .test(
          "password-min",
          "Password must contain at least 8 characters",
          function (value) {
            return (
              value === undefined || value.length === 0 || value.length >= 8
            );
          }
        )
        .max(20, "Password exceeds 20 character limit."),
    }),
    ConfirmPassword: yup
      .string()
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.Password === value;
      }),
  });

export const prefillCompanyInfoInputs = (
  setValue: (
    name: FieldName<CompanyInfoInputs>,
    value: SetFieldValue<CompanyInfoInputs>,
    config?: SetValueConfig
  ) => void,
  data: CompanyDetailResponseType
) => {
  setValue("Address", data.address);
  setValue("CanCreateForms", data.canCreateForms);
  setValue("VaccinationRequired", data.vaccinationRequired);
  setValue("CompanyName", data.companyName);
  setValue("CompanyContactName", data.companyContactName);
  setValue("CompanyContactPhone", data.companyContactPhone);
  setValue("CompanyContactEmail", data.companyContactEmail);
  setValue("Password", undefined);
  setValue("ConfirmPassword", undefined);
};

export const resetRadioButtons = (
  setValue: (
    name: FieldName<CompanyInfoInputs>,
    value: SetFieldValue<CompanyInfoInputs>,
    config?: SetValueConfig
  ) => void
) => {
  setValue("CanCreateForms", false);
  setValue("VaccinationRequired", false);
};

export interface CompanyInfoDetailProps {
  companyId?: string;
  newCompanyMode?: boolean;
  register: any;
  control: any;
  errors: FieldErrors<CompanyInfoInputs>;
  data?: CompanyDetailResponseType;
  setNewCompanyLogo: (newFile: Blob | null) => void;
  alertMessage?: string;
  canEditCompany: boolean;
  canSelfEditCompany: boolean;
  canEditCredentials: boolean;
  canEditPermissions: boolean;
  refreshCompanyDetail: Function;
}

const CompanyInfoDetail: React.FC<CompanyInfoDetailProps> = ({
  companyId,
  newCompanyMode,
  setNewCompanyLogo,
  register,
  control,
  data,
  errors,
  alertMessage,
  canEditCompany,
  canSelfEditCompany,
  canEditCredentials,
  canEditPermissions,
  refreshCompanyDetail,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const newCompanyLogoId = useSelector(
    companyRedux.selectors.getNewCompanyLogoId
  );
  const [photoUrl, setPhotoUrl] = useState<string>();
  const [isOpenCameraPhoto, setIsOpenCameraPhoto] = useState<boolean>(false);
  const { uploadInput, clickOnUploadFile, rawFiles } = useUploadFile({
    acceptedExtensions: "image/*",
  });

  const role = useSelector(authRedux.selectors.getRole);

  useEffect(() => {
    if (
      (!isNil(companyId) || role === ROLES.COMPANY) &&
      !isNil(newCompanyLogoId)
    ) {
      dispatch(loadFile(newCompanyLogoId)).then((url: string) => {
        setPhotoUrl(url);
      });
    } else if (
      data?.logo &&
      (!isNil(companyId) || role === ROLES.COMPANY) &&
      isNil(newCompanyLogoId)
    ) {
      dispatch(loadFile(data.logo)).then((url: string) => {
        setPhotoUrl(url);
      });
    } else {
      setPhotoUrl(undefined);
    }
    if (newCompanyMode) {
      setPhotoUrl(undefined);
    }
  }, [companyId, data?.logo, dispatch, newCompanyMode, newCompanyLogoId, role]);

  const storeBlob = useCallback(
    (blob: Blob) => {
      setPhotoUrl(URL.createObjectURL(blob));
      setNewCompanyLogo(blob);
    },
    [setNewCompanyLogo]
  );

  useEffect(() => {
    if (rawFiles && rawFiles?.length === 1) {
      storeBlob(rawFiles[0]);
    }
  }, [rawFiles, storeBlob]);

  const onSnapImage = (blob: Blob | null) => {
    if (blob) {
      storeBlob(blob);
    }
  };

  const canEditBaseInfo = useMemo(
    () => canEditCompany || canSelfEditCompany || newCompanyMode,
    [canEditCompany, canSelfEditCompany, newCompanyMode]
  );

  const credentialsDisabled = useMemo(
    () => !(canEditCredentials || canSelfEditCompany || newCompanyMode),
    [canEditCredentials, canSelfEditCompany, newCompanyMode]
  );

  const permissionsDisabled = useMemo(
    () => !(canEditPermissions || newCompanyMode),
    [canEditPermissions, newCompanyMode]
  );

  const { isAllowed: canChangeStatus } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.COMPANIES_CHANGE_STATUS]
  );

  return (
    <>
      <FormSection title="Logo">
        <Grid className={classes.imageGrid} item xs={12}>
          <img
            alt="company-portrait"
            src={isNil(photoUrl) ? logoUrl : photoUrl}
            className={classes.image}
          />
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={clickOnUploadFile}
            disabled={!canEditBaseInfo}
          >
            {newCompanyMode ? "Add Logo" : "Change Logo"}
          </Button>
          <Grid className={classes.alertGrid} item xs={12}>
            {alertMessage && (
              <Alert variant="outlined" severity="error">
                {alertMessage}
              </Alert>
            )}
          </Grid>
        </Grid>
      </FormSection>
      <FormSection title="About">
        <FormControlWrapper
          title="Client name"
          input={
            <SpTextField
              name="CompanyName"
              inputRef={register}
              errors={errors}
              fullWidth
              disabled={!canEditBaseInfo}
            />
          }
        />
        <FormControlWrapper
          title="Address"
          input={
            <SpTextField
              name="Address"
              inputRef={register}
              errors={errors}
              minRows={4}
              maxRows={4}
              fullWidth
              disabled={!canEditBaseInfo}
            />
          }
        />
      </FormSection>
      <FormSection title="Client Contact">
        <FormControlWrapper
          title="Name"
          input={
            <SpTextField
              name="CompanyContactName"
              inputRef={register}
              errors={errors}
              fullWidth
              disabled={!canEditBaseInfo}
            />
          }
        />

        <FormControlWrapper
          title="Contact Number"
          input={
            <SpTextField
              name="CompanyContactPhone"
              inputRef={register}
              errors={errors}
              fullWidth
              disabled={!canEditBaseInfo}
            />
          }
        />
        <FormControlWrapper
          title="Email"
          input={
            <SpTextField
              name="CompanyContactEmail"
              inputRef={register}
              errors={errors}
              fullWidth
              disabled={credentialsDisabled}
            />
          }
        />
        <FormControlWrapper
          title="Password"
          input={
            <SpTextField
              name="Password"
              inputRef={register}
              errors={errors}
              type="password"
              placeholder="•••••••••••••••"
              fullWidth
              disabled={credentialsDisabled}
            />
          }
        />
        <FormControlWrapper
          title="Confirm Password"
          input={
            <SpTextField
              name="ConfirmPassword"
              inputRef={register}
              errors={errors}
              type="password"
              placeholder="•••••••••••••••"
              fullWidth
              disabled={credentialsDisabled}
            />
          }
        />
      </FormSection>
      <FormSection title="Client Permissions">
        <Box display="flex" flexDirection="column">
          <Controller
            control={control}
            name="CanCreateForms"
            defaultValue={false}
            render={({ onChange, onBlur, value, ref }) => (
              <FormControlLabel
                label="Create Forms"
                control={
                  <Checkbox
                    color="primary"
                    onBlur={onBlur}
                    onChange={(event: any, newValue: boolean) =>
                      onChange(newValue)
                    }
                    checked={value}
                    inputRef={ref}
                    disabled={permissionsDisabled}
                  />
                }
              />
            )}
          />
          <Controller
            control={control}
            name="VaccinationRequired"
            defaultValue={false}
            render={({ onChange, onBlur, value, ref }) => (
              <FormControlLabel
                label="Vaccination required"
                control={
                  <Checkbox
                    color="primary"
                    onBlur={onBlur}
                    onChange={(event: any, newValue: boolean) =>
                      onChange(newValue)
                    }
                    checked={value}
                    inputRef={ref}
                    disabled={permissionsDisabled}
                  />
                }
              />
            )}
          />
        </Box>
      </FormSection>
      {companyId && data?.status !== undefined && canChangeStatus ? (
        <FormSection title="Actions">
          <Button
            variant="contained"
            onClick={() =>
              dispatch(
                postChangeCompanyStatus({
                  id: companyId,
                  status: data.status === "Active" ? "Inactive" : "Active",
                })
              ).then(() => {
                refreshCompanyDetail(companyId);
              })
            }
          >
            {data.status === "Active" ? "Deactivate Client" : "Activate Client"}
          </Button>
        </FormSection>
      ) : null}
      {isOpenCameraPhoto && (
        <CameraPhoto
          onSaveImage={onSnapImage}
          onCloseDialog={() => setIsOpenCameraPhoto(false)}
        />
      )}
      {uploadInput}
    </>
  );
};

export default CompanyInfoDetail;
