import { HubConnection } from "@microsoft/signalr";
import { createContext } from "react";
import { EntranceEventThumbnailViewModel } from "../../admin/eventLog/redux/apiCalls";

export type SignalRType = {
  connection: HubConnection | undefined;
  setConnection: (newValue: HubConnection | undefined) => void;
  // key: entrance id
  // array: objects which hold panels ids with its refetchAction
  panelsPool: {
    [key: string]: {
      id: number;
      refetchAction: (
        eventEntranceId: string,
        entranceEvent?: EntranceEventThumbnailViewModel,
        skipBatteryCheck?: boolean
      ) => void;
    }[];
  };
  setPanelsPool: (newValue: any | undefined) => void;
};

export const SignalRContext = createContext<SignalRType | undefined>(undefined);
