export const MAIN_PATH = {
  ADMIN: "admin",
  LOGIN: "login",
  RESET_PASSWORD: "reset-password",
  FORGOT_PASSWORD: "forgot-password",
  MONITOR: "monitor",
};

export const MAIN_PAGES = {
  COMPANIES: "companies",
  MESSAGES: "messages",
  SITES: "sites",
  CONTRACTORS: "contractors",
  REPORTS: "reports",
  FORMS: "forms",
  USERS: "users",
  TICKERS: "tickers",
  QRCODESCAN: "qrcodescan",
};

export const COMMON_SITE = {
  NEW: "new",
};

export const FORMS_DETAIL = {
  CONTRACTOR: "contractor",
  SITE: "site",
  SITE_SUPERVISOR: "site-supervisor",
};
