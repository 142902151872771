import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, LinearProgress, Typography } from "@material-ui/core";
import logo from "../../assets/img/21_SP_SIGHTPAS-APP-HEADER LOGO_DARK.png";
import authReducer from "../redux";
import { useAppDispatch } from "../../appStore";
import * as yup from "yup";
import { SpFormControl, SpTextField } from "../../common";
import { BORDER_RADIUS } from "../../appTheme";
import { MAIN_PATH } from "../../router/pathConstants";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.common.black,
    height: "100vh",
  },
  avatar: {
    padding: theme.spacing(1),
  },
  mainGrid: {
    height: "inherit",
  },
  widthGrid: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    minWidth: 600,
    borderRadius: BORDER_RADIUS.NORMAL,
  },
  logo: {
    width: 334.82,
    height: 69,
  },
  input: {
    width: 262.4,
  },
  invalidLoginText: {
    color: "red",
  },
}));

type Inputs = {
  username: string;
  password: string;
};

const schema = yup.object().shape({
  username: yup.string().required("The Username field is required."),
  password: yup.string().required("The Password field is required."),
});

const LoginScreen: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isAuthenticating = useSelector(
    authReducer.selectors.getIsAuthenticating
  );
  const [invalidLoginText, setInvalidLoginText] = useState<string | null>(null);

  const { register, handleSubmit, errors } = useForm<Inputs>({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
  });

  const onSubmit = (data: Inputs) => {
    dispatch(
      authReducer.dispatchActions.login(data.username, data.password, history)
    ).catch((error) => {
      if (
        error.response == null ||
        typeof error.response?.data === "string" ||
        error.response?.data instanceof String
      ) {
        setInvalidLoginText("Invalid login attempt.");
      } else if (error.response.data.errors) {
        if (Object.keys(error.response.data.errors).length === 1) {
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key].length === 1) {
              setInvalidLoginText(error.response.data.errors[key]);
            } else {
              setInvalidLoginText("Invalid login attempt.");
            }
          });
        } else {
          setInvalidLoginText("Invalid login attempt.");
        }
      }
    });
  };

  return (
    <div className={classes.background}>
      <Grid
        container
        alignItems={"center"}
        alignContent={"center"}
        justifyContent={"center"}
        className={classes.mainGrid}
      >
        <Grid item>
          <Paper elevation={1} className={classes.paper}>
            <Box pt={6} pb={6}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <div className={classes.avatar}>
                      <img
                        src={logo}
                        alt="logo-sightPas"
                        className={classes.logo}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <SpFormControl
                      title="Username"
                      input={
                        <SpTextField
                          inputRef={register}
                          errors={errors}
                          id="username"
                          name="username"
                          autoComplete="username"
                          autoFocus
                          fullWidth
                        />
                      }
                      className={classes.input}
                    />
                    <SpFormControl
                      title="Password"
                      input={
                        <SpTextField
                          inputRef={register}
                          errors={errors}
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          fullWidth
                        />
                      }
                      className={classes.input}
                    />
                  </Grid>
                  {invalidLoginText && (
                    <Typography
                      variant={"body1"}
                      className={classes.invalidLoginText}
                    >
                      {invalidLoginText}
                    </Typography>
                  )}
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isAuthenticating}
                      className={classes.input}
                      onClick={() => setInvalidLoginText(null)}
                    >
                      Login
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      color="primary"
                      className={classes.input}
                      onClick={() =>
                        history.push(`${MAIN_PATH.FORGOT_PASSWORD}`)
                      }
                    >
                      Forgot password
                    </Button>
                  </Grid>
                </Grid>
                {isAuthenticating && <LinearProgress />}
              </form>
            </Box>
          </Paper>
          <Box mt={8}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => window.location.replace("/")}
                >
                  Visit sightpas.com
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginScreen;
