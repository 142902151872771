import React, { memo } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Avatar, Grid, Typography } from "@material-ui/core";
import { isNil } from "ramda";
import { SpIcon } from "../../../common";
import { useDispatch } from "react-redux";
import { useFetchData } from "../../../services/useFetchData";
import {
  getDetailOfNotification,
  NotificationDetailResponseType,
  NotificationFileType,
  NotificationSiteType,
} from "../redux/apiCalls";
import { FONT_WEIGHT } from "../../../appTheme";
import { downloadFile } from "../../../services/authApi";
import cx from "classnames";
import { AppDispatch } from "../../../appStore";

const useStyles = makeStyles<Theme>((theme) => ({
  fullWidthRow: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    width: "100%",
  },
  subject: {
    fontWeight: FONT_WEIGHT.EXTRA_BOLD,
  },
  contractorAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  pointerCursor: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  attachedFiles: {
    borderTop: "1px solid #E0E0E0",
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  sentToItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

interface MessageDetailProps {
  notificationId?: number;
}

const MessageDetail: React.FC<MessageDetailProps> = ({ notificationId }) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const { data } = useFetchData<NotificationDetailResponseType>(
    getDetailOfNotification(notificationId),
    !isNil(notificationId),
    undefined,
    [notificationId]
  );

  return (
    <>
      <Grid item className={classes.fullWidthRow}>
        <Typography variant="body1" className={classes.subject}>
          {data?.subject}
        </Typography>
        <Typography variant="body1">{data?.body}</Typography>
      </Grid>
      {data?.formName && (
        <Grid item className={classes.fullWidthRow}>
          <Typography variant="h3">Form</Typography>
          <Typography variant="body1">{data?.formName}</Typography>
        </Grid>
      )}
      {data?.sender && (
        <Grid item className={classes.fullWidthRow}>
          <Typography variant="h3">Sender</Typography>
          <Typography variant="body1">{data?.sender}</Typography>
        </Grid>
      )}
      <Grid item className={classes.fullWidthRow}>
        <Typography variant="h3">Send To</Typography>
      </Grid>
      {data?.sites?.map((site: NotificationSiteType, index: number) => (
        <Grid
          item
          key={index}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          className={classes.sentToItem}
        >
          <Grid item>
            <Avatar>S</Avatar>
          </Grid>
          <Grid item>
            <Typography>{site.name}</Typography>
            <Grid container>
              <Grid item>
                <Typography variant="subtitle1">
                  {site.entrances.map((entrance) => entrance.name).join(", ")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      {data?.contractorsForDetail?.map((name: string, index: number) => (
        <Grid
          item
          key={index}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          className={classes.sentToItem}
        >
          <Grid item>
            <Avatar className={classes.contractorAvatar}>C</Avatar>
          </Grid>
          <Grid item>
            <Typography>{name}</Typography>
          </Grid>
        </Grid>
      ))}
      <Grid item className={classes.attachedFiles}>
        <Typography variant="h3">Attached files</Typography>
      </Grid>
      {data?.files?.map((file: NotificationFileType, index: number) => (
        <Grid
          item
          key={index}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          className={cx(classes.fullWidthRow, classes.pointerCursor)}
          onClick={() => {
            dispatch(downloadFile(file.name, file.id));
          }}
        >
          <Grid item>
            <SpIcon iconName="attachment" />
          </Grid>
          <Grid item>
            <Typography>{file.name}</Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default memo(MessageDetail);
