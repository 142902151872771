import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import authRedux from "../../auth/redux";
import { useHistory } from "react-router-dom";
import { COLORS } from "../../appTheme";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    display: "flex",
    padding: 24,
    flexDirection: "column",
    wordBreak: "break-word",
  },
  username: {
    color: COLORS.WHITE,
    marginBottom: 12,
  },
}));
const MainMenuLeftFooter: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const username = useSelector(authRedux.selectors.getUsername);

  return (
    <Box className={classes.textContainer} alignItems="flex-start">
      <Typography variant="body1" className={classes.username}>
        {username}
      </Typography>
      <Button
        color="secondary"
        variant={"outlined"}
        fullWidth
        onClick={() => dispatch(authRedux.dispatchActions.logout(history))}
      >
        Log out
      </Button>
    </Box>
  );
};

export default MainMenuLeftFooter;
