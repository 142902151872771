import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../appStore";
import withAuthorization from "../../auth/hoc/withAutorization";
import messagesRedux from "./redux";
import MessageListItem from "./MessageListItem";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControlWrapper,
  ListDetailSplitView,
  PageContent,
  SpTextField,
} from "../../common";
import { MessageDetailSection } from "./messageDetail";
import { SCROLL_PANEL_WIDTH } from "../../appTheme";
import { customDebounce } from "../../services/helpers";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { useAuthorization } from "../../auth";
import { ROLES, USER_PERMISSIONS } from "../../auth/constants";

const useStyles = makeStyles((theme) => ({
  scrollPanel: {
    height: "calc(100vh - 411px)",
    width: SCROLL_PANEL_WIDTH,
  },
  mobileScrollPanel: {
    height: "200px !important",
    width: "100%"
  },
  filterPanel: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  footerContainer: {
    height: "inherit",
  },
}));

const Messages: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const pageSize: number = 50;
  const pageIndex = useRef<number>(0);
  const [selectedNotificationId, setSelectedNotificationId] =
    useState<number>();
  const [newMessageMode, setNewMessageMode] = useState<boolean>();
  const getHasNextPage = useSelector(messagesRedux.selectors.getHasNextPage);
  const isFetching = useSelector(messagesRedux.selectors.getIsFetching);
  const notificationList = useSelector(
    messagesRedux.selectors.getNotificationList
  );
  const sitesList = useSelector(messagesRedux.selectors.getSitesList);
  const selectedSiteId = useSelector(messagesRedux.selectors.getSelectedSiteId);
  const entrancesList = useSelector(messagesRedux.selectors.getEntrancesList);
  const selectedEntranceId = useSelector(
    messagesRedux.selectors.getSelectedEntranceId
  );
  const messagesListRequest = useRef<CancelTokenSource>(
    axios.CancelToken.source()
  );

  const { isAllowed: canSendMessage } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.MESSAGES_SEND]
  );

  const resetMessageSelection = () => {
    setNewMessageMode(false);
    setSelectedNotificationId(undefined);
  };

  const resetPagination = useCallback(() => {
    dispatch(messagesRedux.actions.setHasNextPage(true));
    dispatch(messagesRedux.actions.setNotificationList([]));
    pageIndex.current = 0;
  }, [dispatch]);

  const handleChangeSite = (event: React.ChangeEvent<{ value: any }>) => {
    dispatch(
      messagesRedux.actions.setSelectedSiteId(event.target.value as number)
    );
    dispatch(messagesRedux.actions.setSelectedEntranceId(undefined));
    resetPagination();
    resetMessageSelection();
  };

  const handleChangeEntrance = (event: React.ChangeEvent<{ value: any }>) => {
    dispatch(
      messagesRedux.actions.setSelectedEntranceId(event.target.value as number)
    );
    resetPagination();
    resetMessageSelection();
  };

  const debouncedCall = useMemo(
    () =>
      customDebounce(
        (
          incrementedPageIndex: number,
          isScrollMode: boolean,
          entranceId: number,
          siteId: number,
          cancelToken: CancelToken
        ) => {
          dispatch(
            messagesRedux.dispatchActions.fetchListOfNotifications(
              {
                Pagination: {
                  PageIndex: incrementedPageIndex,
                  PageSize: pageSize,
                },
                EntranceId: entranceId,
                SiteId: siteId,
              },
              isScrollMode,
              cancelToken
            )
          )
            .then(() => {
              pageIndex.current = incrementedPageIndex;
            })
            .catch(() => {
              pageIndex.current = 0;
            });
        },
        800
      ),
    [dispatch]
  );

  const fetchNextData = useCallback(
    (isScrollMode: boolean = false) => {
      if (getHasNextPage) {
        if (isFetching) {
          messagesListRequest.current.cancel("Cancel previous request");
          messagesListRequest.current = axios.CancelToken.source();
        }
        const incrementedPageIndex = pageIndex.current + 1;
        debouncedCall(
          incrementedPageIndex,
          isScrollMode,
          selectedEntranceId,
          selectedSiteId,
          messagesListRequest.current.token
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedCall, selectedEntranceId, selectedSiteId]
  );

  const refetchNotifications = useCallback(() => {
    resetPagination();
    fetchNextData();
  }, [fetchNextData, resetPagination]);

  useEffect(() => {
    refetchNotifications();
  }, [refetchNotifications]);

  useEffect(() => {
    return () => dispatch(messagesRedux.dispatchActions.resetMessagesReducer());
  }, [dispatch]);

  // fetching of counters
  useEffect(() => {
    dispatch(
      messagesRedux.dispatchActions.fetchEntrancesCounters(selectedSiteId)
    );
  }, [dispatch, selectedSiteId]);

  useEffect(() => {
    dispatch(messagesRedux.dispatchActions.fetchSitesCounters());
  }, [dispatch]);

  // end of fetching of counters

    const getFilterPanel = () => {
        return(
            <div className={classes.filterPanel}>
                <FormControlWrapper
                    title="Location"
                    fullWidth
                    input={
                        <SpTextField
                            select
                            value={selectedSiteId || ""}
                            onChange={handleChangeSite}
                            withoutHelperText
                        >
                            <MenuItem value={undefined}>
                                <em>All</em>
                            </MenuItem>
                            {sitesList.map((site) => (
                                <MenuItem key={site.codeId} value={site.codeId}>
                                    {site.displayName}
                                </MenuItem>
                            ))}
                        </SpTextField>
                    }
                />
                <FormControlWrapper
                    title="Entrance"
                    fullWidth
                    input={
                        <SpTextField
                            select
                            value={selectedEntranceId || ""}
                            onChange={handleChangeEntrance}
                            withoutHelperText
                        >
                            <MenuItem value={undefined}>
                                <em>All</em>
                            </MenuItem>
                            {entrancesList.map((site) => (
                                <MenuItem key={site.codeId} value={site.codeId}>
                                    {site.displayName}
                                </MenuItem>
                            ))}
                        </SpTextField>
                    }
                />
            </div>
        )
    }

    const getMessageListPanel = () => {
        return(
            <InfiniteScroll
                dataLength={notificationList.length} //This is important field to render the next data
                next={() => fetchNextData(true)}
                hasMore={getHasNextPage}
                loader={
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.footerContainer}
                    >
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                }
                endMessage={
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ textAlign: "center" }}
                    >
                        No more data
                    </Typography>
                }
                className={window.innerWidth > 600 ? classes.scrollPanel : classes.mobileScrollPanel}
            >
                {notificationList.map((notification, index) => (
                    <MessageListItem
                        key={index}
                        title={notification.subject}
                        description={notification.body}
                        numberOfFiles={notification.files.length}
                        onClick={() => {
                            setSelectedNotificationId(notification.id);
                            setNewMessageMode(false);
                        }}
                        isActive={selectedNotificationId === notification.id}
                    />
                ))}
            </InfiniteScroll>
        )
    }

    const getMessageDetailsPanel = () => {
        return(
            <MessageDetailSection
                notificationId={selectedNotificationId}
                newMessageMode={newMessageMode}
                setNewMessageMode={setNewMessageMode}
                refetchNotifications={refetchNotifications}
            />
        )
    }

  return (
    <PageContent
      title="Messages"
      addNewButtonText="New Message"
      showButton={canSendMessage}
      buttonOnClick={() => {
        setNewMessageMode(true);
        setSelectedNotificationId(undefined);
      }}
    >
        {window.innerWidth <= 600 && (
            <>
                {getFilterPanel()}
                {getMessageListPanel()}
                <div>
                      {getMessageDetailsPanel()}
                </div>
            </>
        )}

        {window.innerWidth > 600 && (
            <ListDetailSplitView
                list={
                    <>
                        {getFilterPanel()}
                        {getMessageListPanel()}
                    </>
                }
                details={
                    getMessageDetailsPanel()
                }
            />
        )}
    </PageContent>
  );
};

export default withAuthorization(memo(Messages));
