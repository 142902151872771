import { createSelector } from "@reduxjs/toolkit";
import { State } from "./model";
import { COMMON } from "./constants";
import { isNil } from "ramda";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";

const selectSelf = (state: { [x: string]: State }) =>
  state[COMMON.ROOT_REDUCER];

const getContractorList = createSelector(
  selectSelf,
  (state: State) => state.contractorList
);

const getContractorListHasNextPage = createSelector(
  selectSelf,
  (state: State) => state.contractorListHasNextPage
);

const getIsFetchingContractorList = createSelector(
  selectSelf,
  (state: State) => state.isFetchingContractorList
);

const getCompanyList = createSelector(
  selectSelf,
  (state: State) => state.companyList
);
const getSiteList = createSelector(
  selectSelf,
  (state: State) => state.siteList
);

const getOtherCertifications = createSelector(
  selectSelf,
  (state: State) => state.otherCertifications
);

const getAccessList = createSelector(
  selectSelf,
  (state: State) => state.accessList
);

const getSelectedEntrances = createSelector(
  getAccessList,
  (accessList: Array<TreeStructureData>) => {
    const selectedEntrances: string[] = [];
    accessList
      .map((site) =>
        site.children
          ?.filter((entrance) => entrance.isChecked)
          .map((entrance) => entrance.id)
      )
      .filter((item) => !isNil(item))
      .forEach((entrance) => {
        selectedEntrances.push(...(entrance || []));
      });
    return selectedEntrances;
  }
);

const getSecondaryCompanyName = createSelector(
  selectSelf,
  (state: State) => state.secondaryCompanyName
);

const getNewProfilePhotoId = createSelector(
  selectSelf,
  (state: State) => state.newProfilePhotoId
);

const getSecondaryCompanyList = createSelector(
  selectSelf,
  (state: State) => state.secondaryCompanyList
);

const getIsFetchingSecondaryCompanyList = createSelector(
  selectSelf,
  (state: State) => state.isFetchingSecondaryCompanyList
);

const allSelectors = {
  getContractorList,
  getContractorListHasNextPage,
  getIsFetchingContractorList,
  getCompanyList,
  getSiteList,
  getOtherCertifications,
  getAccessList,
  getNewProfilePhotoId,
  getSelectedEntrances,
  getSecondaryCompanyName,
  getSecondaryCompanyList,
  getIsFetchingSecondaryCompanyList,
};

export default allSelectors;
