import React, { memo, MutableRefObject, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpPanelFooter from "./SpPanelFooter";
import { BORDER_RADIUS, COLORS } from "../../../appTheme";
import Grid from "@material-ui/core/Grid";
import { SpIconButton } from "../buttons";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: BORDER_RADIUS.NORMAL,
  },
  closeButtonGrid: {
    textAlign: "end",
    padding: theme.spacing(1),
    backgroundColor: COLORS.DARK_BLUE,
    borderTopLeftRadius: BORDER_RADIUS.NORMAL,
    borderTopRightRadius: BORDER_RADIUS.NORMAL,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const SpPanel: React.FC<SpPanelProps> = ({
  children,
  footer,
  withoutFooter,
  style,
  withoutHeader = true,
  headerRef,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <div style={style} className={classes.mainContainer}>
      {!withoutHeader && (
        <div ref={headerRef}>
          <Grid item className={classes.closeButtonGrid} xs={12}>
            <SpIconButton onClick={onClose} iconName="close" />
          </Grid>
        </div>
      )}
      {children}
      {!withoutFooter && <SpPanelFooter>{footer}</SpPanelFooter>}
    </div>
  );
};

export default memo(SpPanel);

export interface SpPanelProps {
  footer?: ReactNode;
  withoutFooter?: boolean;
  children?: ReactNode;
  style?: any;
  withoutHeader?: boolean;
  headerRef?: MutableRefObject<HTMLDivElement | null>;
  onClose?: () => void;
}
