import React, { FC, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import commonReducer from "../common/redux";
import { batch, useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { isMobile } from "react-device-detect";

export const AudioPlayer: FC = () => {
  const enableSound = require("../assets/sounds/soundEnabled.wav").default;
  const play = useSelector(commonReducer.selectors.getPlayAudio);
  const path = useSelector(commonReducer.selectors.getAudioSrc) || enableSound;
  const audioRef = useRef(new Audio(path));

  const audioEnabled = useSelector(commonReducer.selectors.getAudioEnabled);
  const dispatch = useDispatch();

  const playAudio = useCallback(() => {
    audioRef.current.src = path;
    audioRef.current.play().catch((e) => console.error(e.message));
  }, [path]);

  useEffect(() => {
    //console.log(path, play);
    if (play > 0 && audioRef.current) {
      playAudio();
    }
  }, [path, play, playAudio]);

  const enableAudio = () => {
    batch(() => {
      playAudio();
      dispatch(commonReducer.actions.setAudioEnabled(true));
    });
  };

  return (
    <>
      {!audioEnabled && isMobile && (
        <AppBar position="static" color="secondary">
          <Toolbar>
            <Button color="primary" variant="contained" onClick={enableAudio}>
              Enable sound
            </Button>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};
