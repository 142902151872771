export const ROLES = {
  ADMIN: "Admin",
  SITE_SUPERVISOR: "SiteSupervisor",
  CONTRACTOR: "Contractor",
  COMPANY: "Company",
};

export const USER_PERMISSIONS = {
  // Companies related permissions
  COMPANIES_LIST_VIEW: "Companies.List.View",
  COMPANIES_ADD: "Companies.Add",
  COMPANIES_SELF_EDIT: "Companies.Self.Edit",
  COMPANIES_EDIT: "Companies.Edit",
  COMPANIES_CHANGE_CREDENTIALS: "Companies.Change.Credentials",
  COMPANIES_CHANGE_PERMISSIONS: "Companies.Change.Permissions",
  COMPANIES_SITES_VIEW: "Companies.Sites.View",
  COMPANIES_SECONDARY_COMPANIES_VIEW: "Companies.Secondary.Companies.View",
  COMPANIES_SECONDARY_COMPANIES_SELECT: "Companies.Secondary.Companies.Select",
  COMPANIES_SECONDARY_COMPANIES_EDIT_CREATE:
    "Companies.Secondary.Companies.Edit.Create",
  COMPANIES_CHANGE_STATUS: "Companies.Change.Status",
  // Sites related permissions - general
  SITES_LIST_VIEW: "Sites.List.View",
  SITES_ADD: "Sites.Add",
  SITES_EDIT_INFO: "Sites.Edit.Info",
  SITES_CHANGE_STATUS: "Sites.Change.Status",
  SITES_DOWNLOAD_CLUB_MEMBERS: "Sites.Download.Club.Members",
  SITES_EDIT_INBOUND_API_KEY: "Sites.Edit.InboundApiKey",
  // Sites related permissions - supervisors
  SITES_SUPERVISORS_VIEW: "Sites.Supervisors.View",
  SITES_SUPERVISORS_ADD_REMOVE: "Sites.Supervisors.Add.Remove",
  SITES_SUPERVISORS_EDIT: "Sites.Supervisors.Edit",
  SITES_SUPERVISORS_CHANGE_CREDENTIALS: "Sites.Supervisors.Change.Credentials",
  // Sites related permissions - entrances
  SITES_ENTRANCES_VIEW: "Sites.Entrances.View",
  SITES_ENTRANCES_ADD: "Sites.Entrances.Add",
  SITES_ENTRANCES_EDIT: "Sites.Entrances.Edit",
  SITES_ENTRANCES_CHANGE_STATUS: "Sites.Entrances.Change.Status",
  SITES_ENTRANCES_REBOOT_DEVICES: "Sites.Entrances.Reboot.Devices",
  SITES_ENTRANCES_UNLOCK_DOORS: "Sites.Entrances.Unlock.Doors",
  SITES_ENTRANCES_REFRESH_BOOTABLE_DEVICES:
    "Sites.Entrances.Refresh.Bootable.Devices",
  // Contractors related permissions
  CONTRACTORS_LIST_VIEW: "Contractors.List.View",
  CONTRACTORS_ADD: "Contractors.Add",
  CONTRACTORS_EDIT: "Contractors.Edit",
  CONTRACTORS_RESEND_AUTH_CODE: "Contractors.Resend.Auth.Code",
  CONTRACTORS_VERIFY_VACCINATION: "Contractors.Verify.Vaccination",
  CONTRACTORS_GET_CLUB_DATA: "Contractors.Get.Club.Data",
  CONTRACTORS_ENTRANCES_ASSIGNMENT: "Contractors.Entrances.Assignment",
  CONTRACTORS_CHANGE_STATUS: "Contractors.Change.Status",
  // Other permissions
  REPORTS_VIEW: "Reports.View",
  MESSAGES_VIEW: "Messages.View",
  MESSAGES_SEND: "Messages.Send",
  FORMS_VIEW: "Forms.View",
  QR_CODE_SCAN_VIEW: "Qr.Code.Scan.View",
  TICKER_VIEW: "Ticker.View",
  TICKER_CHECK_IN_OUT: "Ticker.Check.In.Out",
};
