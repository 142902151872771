import React, { FC, useRef } from "react";
import withAuthorization from "../../auth/hoc/withAutorization";
import { makeStyles } from "@material-ui/core/styles";
import { PageContent, SpPanel } from "../../common";
import FormsHeader, { FormsInputs, FormsSchema } from "./FormsHeader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchData } from "../../services/useFetchData";
import { CounterResponseModel } from "../../services/apiServiceTypes";
import { isNil } from "ramda";
import {
  getContractorCounterBySite,
  getFormsCounterBySite,
  getSiteCounter,
  getSiteSupervisorCounterBySite,
} from "./redux/apiCalls";
// import { useComputeSize } from "../../services/useComputeSize";
import { FORMS_DETAIL, MAIN_PAGES } from "../../router/pathConstants";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(4),
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  mainPanel: {
    height: `calc(100vh - ${theme.spacing(17)}px)`,
  },
}));

const Forms: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { register, handleSubmit, control, errors, watch, formState } =
    useForm<FormsInputs>({
      resolver: yupResolver(FormsSchema),
    });

  const formsHeaderRef = useRef<HTMLDivElement | null>(null);
  // const { height: formsHeaderHeight } = useComputeSize(formsHeaderRef, [
  //   formState.isDirty,
  //   formState.isSubmitted,
  // ]);

  const { data: siteCounterData } = useFetchData<CounterResponseModel>(
    getSiteCounter()
  );

  const { data: contractorCounterData } = useFetchData<CounterResponseModel>(
    getContractorCounterBySite(watch("site")),
    !isNil(watch("site")) && watch("site") !== "",
    undefined,
    [watch("site")]
  );

  const { data: siteSupervisorCounterData } =
    useFetchData<CounterResponseModel>(
      getSiteSupervisorCounterBySite(watch("site")),
      !isNil(watch("site")) && watch("site") !== "",
      undefined,
      [watch("site")]
    );

  const { data: formsCounterData } = useFetchData<CounterResponseModel>(
    getFormsCounterBySite(watch("site")),
    !isNil(watch("site")) && watch("site") !== "",
    undefined,
    [watch("site")]
  );

  const onSubmitNewForm = (formInputData: FormsInputs) => {
    history.push(
      `${MAIN_PAGES.FORMS}/${formInputData.form}/${FORMS_DETAIL.SITE}/${formInputData.site}/${FORMS_DETAIL.CONTRACTOR}/${formInputData.contractor}/${FORMS_DETAIL.SITE_SUPERVISOR}/${formInputData.siteSupervisor}`
    );
  };

  return (
    <PageContent title="Forms" showButton={false}>
      <SpPanel withoutFooter>
        <div className={classes.mainPanel}>
          <form onSubmit={handleSubmit(onSubmitNewForm)}>
            <FormsHeader
              control={control}
              errors={errors}
              watch={watch}
              register={register}
              siteCounterData={siteCounterData}
              contractorCounterData={contractorCounterData}
              siteSupervisorCounterData={siteSupervisorCounterData}
              formCounterData={formsCounterData}
              ref={formsHeaderRef}
              isSubmitted={formState.isSubmitted}
            />
          </form>
        </div>
      </SpPanel>
    </PageContent>
  );
};

export default withAuthorization(Forms);
