import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BORDER_RADIUS, COLORS } from "../../../appTheme";

const useStyles = makeStyles((theme) => ({
  footer: {
    borderBottomLeftRadius: BORDER_RADIUS.NORMAL,
    borderBottomRightRadius: BORDER_RADIUS.NORMAL,
    backgroundColor: COLORS.DARK_BLUE,
    height: 64,
  },
}));

const SpPanelFooter: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.footer}>{children}</div>;
};

export default SpPanelFooter;
