import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { COMMON_SITE, FORMS_DETAIL, MAIN_PAGES } from "./pathConstants";
import { Messages } from "../admin/messages";
import { ROLES, USER_PERMISSIONS } from "../auth/constants";
import MainMenuLeft from "../admin/menu/MainMenuLeft";
import {
  AppBar,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { EventPanelsCatalog } from "../admin/eventLog";
import { COLORS } from "../appTheme";
import { Contractors } from "../admin/contractors";
import { Reports } from "../admin";
import { Users } from "../admin/users";
import { Companies } from "../admin/companies";
import { Forms, FormsDetail } from "../admin/forms";
import { QRCodeScan } from "../admin/qrcodescan";
import Sites from "../admin/sites/Sites";
import { NewSiteRoutes, SiteRoutes } from "./Sites";
import authReducer from "../auth/redux";
import { useAppDispatch } from "../appStore";
import { AudioPlayer } from "../services/AudioPlayer";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../assets/img/21_SP_SIGHTPAS-APP-HEADER LOGO.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: COLORS.PAGE_BACKGROUND,
    height: "100vh",
    overflowX: "auto",
    [`${theme.breakpoints.down("sm")} and (orientation:portrait)`]: {
        minWidth: 575,
    },
    // desktop portrait
    [`${theme.breakpoints.up("md")} and (orientation:portrait)`]: {
        minWidth: 575,
    },
    // phone landscape
    [`${theme.breakpoints.down("md")} and (orientation:landscape)`]: {
        minWidth: 575,
    },
    // desktop landscape
    [`${theme.breakpoints.up("lg")} and (orientation:landscape)`]: {
        minWidth: 575,
    },
  },
  mainContent: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(9),
    },
    paddingTop: theme.spacing(5),
    overflowX: "auto",
    height: "100%",
  },
  mainContainer: {
    height: "100%",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: COLORS.BLACK,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  logo: {
    height: 30,
    marginLeft: theme.spacing(1),
  },
}));

const Routes: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  let { url } = useRouteMatch();

  useEffect(() => {
    dispatch(authReducer.actions.setSkipAuthentication(false));
    return () => {
      dispatch(authReducer.actions.setSkipAuthentication(true));
    };
  }, [dispatch]);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AudioPlayer />
      <div className={classes.background}>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          className={classes.mainContainer}
        >
          <Hidden smUp implementation="css">
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <img src={logo} alt="SightPas-logo" className={classes.logo} />
              </Toolbar>
            </AppBar>
          </Hidden>
          <nav className={classes.drawer}>
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{ paper: classes.drawerPaper }}
                ModalProps={{ keepMounted: true }}
              >
                <MainMenuLeft
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.COMPANY,
                    ROLES.SITE_SUPERVISOR,
                    ROLES.CONTRACTOR,
                  ]}
                  onItemClick={() => setMobileOpen(false)}
                />
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                <MainMenuLeft
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.COMPANY,
                    ROLES.SITE_SUPERVISOR,
                    ROLES.CONTRACTOR,
                  ]}
                  onItemClick={() => setMobileOpen(false)}
                />
              </Drawer>
            </Hidden>
          </nav>
          <Grid item xs={true} className={classes.mainContent}>
            <Switch>
              <Route path={`${url}/${MAIN_PAGES.COMPANIES}`} exact>
                <Companies
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.COMPANY,
                    ROLES.SITE_SUPERVISOR,
                  ]}
                  allowedPermissions={[
                    USER_PERMISSIONS.COMPANIES_LIST_VIEW,
                    USER_PERMISSIONS.COMPANIES_SELF_EDIT,
                  ]}
                />
              </Route>
              <Route path={`${url}/${MAIN_PAGES.SITES}`} exact>
                <Sites
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.SITE_SUPERVISOR,
                    ROLES.COMPANY,
                  ]}
                  allowedPermissions={[USER_PERMISSIONS.SITES_LIST_VIEW]}
                />
              </Route>
              <Route
                path={`${url}/${MAIN_PAGES.SITES}/${COMMON_SITE.NEW}`}
                exact
              >
                <NewSiteRoutes
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.COMPANY,
                    ROLES.SITE_SUPERVISOR,
                  ]}
                  allowedPermissions={[USER_PERMISSIONS.SITES_ADD]}
                />
              </Route>
              <Route path={`${url}/${MAIN_PAGES.SITES}/:selectedSiteId`}>
                <SiteRoutes
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.SITE_SUPERVISOR,
                    ROLES.COMPANY,
                  ]}
                  allowedPermissions={[USER_PERMISSIONS.SITES_LIST_VIEW]}
                />
              </Route>
              <Route path={`${url}/${MAIN_PAGES.CONTRACTORS}`} exact>
                <Contractors
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.COMPANY,
                    ROLES.SITE_SUPERVISOR,
                  ]}
                  allowedPermissions={[USER_PERMISSIONS.CONTRACTORS_LIST_VIEW]}
                />
              </Route>
              <Route path={`${url}/${MAIN_PAGES.REPORTS}`} exact>
                <Reports
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.COMPANY,
                    ROLES.SITE_SUPERVISOR,
                  ]}
                  allowedPermissions={[USER_PERMISSIONS.REPORTS_VIEW]}
                />
              </Route>
              <Route path={`${url}/${MAIN_PAGES.USERS}`} exact>
                <Users allowedRoles={[ROLES.ADMIN, ROLES.COMPANY]} />
              </Route>
              <Route path={`${url}/${MAIN_PAGES.MESSAGES}`}>
                <Messages
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.COMPANY,
                    ROLES.SITE_SUPERVISOR,
                  ]}
                  allowedPermissions={[USER_PERMISSIONS.MESSAGES_VIEW]}
                />
              </Route>
              <Route path={`${url}/${MAIN_PAGES.FORMS}`} exact>
                <Forms
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.COMPANY,
                    ROLES.SITE_SUPERVISOR,
                  ]}
                  allowedPermissions={[USER_PERMISSIONS.FORMS_VIEW]}
                />
              </Route>
              <Route
                path={`${url}/${MAIN_PAGES.FORMS}/:submittedForm/${FORMS_DETAIL.SITE}/:submittedSite/${FORMS_DETAIL.CONTRACTOR}/:submittedContractor/${FORMS_DETAIL.SITE_SUPERVISOR}/:submittedSiteSupervisor`}
              >
                <FormsDetail
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.COMPANY,
                    ROLES.SITE_SUPERVISOR,
                  ]}
                  allowedPermissions={[USER_PERMISSIONS.FORMS_VIEW]}
                />
              </Route>
              <Route path={`${url}/${MAIN_PAGES.TICKERS}`} exact>
                <EventPanelsCatalog
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.COMPANY,
                    ROLES.SITE_SUPERVISOR,
                    ROLES.CONTRACTOR,
                  ]}
                  allowedPermissions={[USER_PERMISSIONS.TICKER_VIEW]}
                />
              </Route>
              <Route path={`${url}/${MAIN_PAGES.QRCODESCAN}`} exact>
                <QRCodeScan
                  allowedRoles={[
                    ROLES.ADMIN,
                    ROLES.COMPANY,
                    ROLES.SITE_SUPERVISOR,
                  ]}
                  allowedPermissions={[USER_PERMISSIONS.QR_CODE_SCAN_VIEW]}
                />
              </Route>
            </Switch>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Routes;
