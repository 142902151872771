import React, { FC, memo, useEffect, useMemo, useState } from "react";
import { useFetchData } from "../../../../services/useFetchData";
import { isNil } from "ramda";
import {
  CreateEntranceViewModel,
  getEntranceDetail,
  getRebootResult,
  getRefreshBootableDevices,
  getUnlockResult,
  postCreateEntranceDetail,
  postRebootDevice,
  postUnlockDoor,
  putEditEntranceDetail,
} from "../../redux/apiCalls";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  Battery,
  DetailPanelView,
  FormControlWrapper,
  FormSection,
  PlusButton,
  SpTextField,
  YesNoDialog,
} from "../../../../common";
import * as yup from "yup";
import { FieldName } from "react-hook-form/dist/types/fields";
import { SetFieldValue, SetValueConfig } from "react-hook-form/dist/types/form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../appStore";
import siteRedux from "../../redux";
import { SiteDetailModel } from "../../../forms/redux/apiCalls";
import commonReducer from "../../../../common/redux";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { theme } from "../../../../appTheme";
import { useAuthorization } from "../../../../auth";
import { ROLES, USER_PERMISSIONS } from "../../../../auth/constants";
import "moment-timezone";
import moment from "moment";
import { FRONTEND_DATE_FORMAT_REPORTS } from "../../../../services/helpers";
import { AddCircle } from "@material-ui/icons";
import ScheduleInput from "./ScheduleInput";
import { EntranceSchedule } from "./types";

export interface EntranceDetailInputs {
  EntranceName: string;
  InnerMotionSensorId: string;
  OuterMotionSensorId: string;
  QRReaderId: string;
  ImageTimeSpan: string;
  InnerBeacon: string;
  OuterBeacon: string;
  FacialRecEnabled: boolean;
  Schedules?: EntranceSchedule[];
  Timezone?: string;
}

export const isTimeOrderOk = (startTime?: string, endTime?: string) => {
  if (!startTime || !endTime) {
    return false;
  }

  const extractValue = (val: string) => parseInt(val.replace(":", ""));
  const adjustedStart = extractValue(startTime);
  const adjustedEnd = extractValue(endTime);
  return adjustedEnd > adjustedStart;
};

export const adjustScheduleTimes = (
  schedules: EntranceSchedule[] | undefined
) => {
  if (!schedules) {
    return [];
  }

  schedules.forEach((schedule: EntranceSchedule) => {
    schedule.startTime = `${schedule.startTime.substring(0, 5)}:00`;
    schedule.endTime = `${schedule.endTime.substring(0, 5)}:59`;
  });

  return schedules;
};

const scheduleSchema = {
  description: yup.string().optional(),
  days: yup.array().of(yup.string()).min(1, "At least 1 day must be selected"),
  startTime: yup.string().required("Start time cannot be empty"),
  endTime: yup
    .string()
    .required("End time cannot be empty")
    .test(
      "time-check",
      "End time must be after start time",
      (value, testContext) => isTimeOrderOk(testContext.parent.startTime, value)
    ),
};

export const EntranceDetailSchema = yup.object().shape({
  EntranceName: yup
    .string()
    .max(80, "Entrance Name exceeds 80 character limit.")
    .required("Site Name is required."),
  InnerMotionSensorId: yup
    .string()
    .test(
      "inner-motion-exists",
      "Inner motion sensor has to be filled out if outer motion sensor is present.",
      function (value) {
        if (this.parent.OuterMotionSensorId) {
          return value?.length !== 0;
        }
        return true;
      }
    ),
  OuterMotionSensorId: yup.string(),
  QRReaderId: yup.string(),
  ImageTimeSpan: yup
    .number()
    .nullable()
    .positive("Image Timespan should be in seconds.")
    .integer("Image Timespan should be in seconds.")
    .typeError("Image Timespan should be in seconds.")
    .transform((_, val) => {
      return !isNaN(val) && val !== ""
        ? parseInt(val)
        : val !== ""
        ? val
        : null;
    }),

  InnerBeacon: yup.string().matches(/^\w{4}-\w{5}$/, {
    excludeEmptyString: true,
    message:
      "Invalid beacon ID. It should consist of 4 characters, dash and 5 characters.",
  }),
  OuterBeacon: yup.string().matches(/^\w{4}-\w{5}$/, {
    excludeEmptyString: true,
    message:
      "Invalid beacon ID. It should consist of 4 characters, dash and 5 characters.",
  }),
  Schedules: yup.array().of(yup.object().shape(scheduleSchema)).optional(),
  Timezone: yup.string().nullable().required("Timezone is required"),

  // FacialRecEnabled: yup.boolean().required("Please enter facial recognition permissions"),
});

export const prefillEntranceDetailInputs = (
  setValue: (
    name: FieldName<EntranceDetailInputs>,
    value: SetFieldValue<EntranceDetailInputs>,
    config?: SetValueConfig
  ) => void,
  data: CreateEntranceViewModel
) => {
  setValue("EntranceName", data?.entranceName);
  setValue("InnerMotionSensorId", data?.innerMotionSensorId);
  setValue("OuterMotionSensorId", data?.outerMotionSensorId);
  setValue("QRReaderId", data?.qrReaderId);
  setValue("ImageTimeSpan", data?.imageTimeSpan);
  setValue("InnerBeacon", data?.innerBeacon);
  setValue("OuterBeacon", data?.outerBeacon);
  setValue("FacialRecEnabled", data?.facialRecEnabled);
  setValue("Timezone", data?.timeZone || "");
  setValue("Schedules", data?.schedules);
  data?.schedules?.forEach((schedule: EntranceSchedule, index) => {
    setValue(`Schedules.${index}.description`, schedule.description);
    setValue(`Schedules.${index}.days`, schedule.days);
    setValue(
      `Schedules.${index}.startTime`,
      schedule.startTime.substring(0, 5)
    );
    setValue(`Schedules.${index}.endTime`, schedule.endTime.substring(0, 5));
    setValue(`Schedules.${index}.isEnabled`, schedule.isEnabled);
  });
};

const useStyles = makeStyles((theme) => ({
  reboot: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    top: "8px",
  },
  unlock: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    top: "8px",
  },
  rebootButton: {
    height: "40px",
    marginLeft: "16px",
  },
  unlockButton: {
    height: "40px",
    marginLeft: "16px",
  },
  battery: {
    height: "40px",
    marginLeft: "16px",
  },
  ctasDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  ctas: {
    display: "flex",
  },
  cta: {
    height: "40px;",
    "&:first-child": {
      marginRight: theme.spacing(2),
    },
    "&:last-child": {
      marginLeft: "auto",
    },
  },
  lastRefresh: {
    display: "flex",
    flexDirection: "column",
  },
  loadingIndicator: {
    paddingLeft: theme.spacing(1),
    display: "flex",
  },
}));

type EntranceDetailProps = {
  siteDetail?: SiteDetailModel;
  refetchSiteEntrancesList: () => void;
};

type BootableDevice = {
  deviceId?: string;
  isRebooting: boolean;
  isBootable: boolean;
};

// eslint-disable-next-line no-empty-pattern
const LoadingIndicator: FC = ({}) => {
  const classes = useStyles();

  return (
    <Box className={classes.loadingIndicator}>
      <CircularProgress size={20} />
    </Box>
  );
};

const EntranceDetail: FC<EntranceDetailProps> = ({
  siteDetail,
  refetchSiteEntrancesList,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setNoOpp] = useState<boolean>(false);
  const selectedSiteId = useSelector(siteRedux.selectors.getSelectedSiteId);
  const selectedEntranceId = useSelector(
    siteRedux.selectors.getSelectedEntranceId
  );
  const isNewEntranceMode = useSelector(
    siteRedux.selectors.getIsNewEntranceMode
  );
  const [outSensorGridOpened, setOutSensorGridOpened] =
    useState<boolean>(false);
  const [innerMotionSensor, setInnerMotionSensor] = useState<BootableDevice>({
    deviceId: undefined,
    isRebooting: false,
    isBootable: false,
  });
  const [qrReader, setQrReader] = useState<BootableDevice>({
    deviceId: undefined,
    isRebooting: false,
    isBootable: false,
  });
  const [isRefreshingBootableDevices, setIsRefreshingBootableDevices] =
    useState<boolean>(false);

  const { isAllowed: isAdmin } = useAuthorization([ROLES.ADMIN]);

  const { isAllowed: canAddEntrances } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_ENTRANCES_ADD]
  );

  const { isAllowed: canEditEntrances } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_ENTRANCES_EDIT]
  );

  const { isAllowed: canChangeStatus } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_ENTRANCES_CHANGE_STATUS]
  );

  const { isAllowed: canRebootDevices } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_ENTRANCES_REBOOT_DEVICES]
  );

  const { isAllowed: canUnlockDevices } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_ENTRANCES_UNLOCK_DOORS]
  );

  const { isAllowed: canRefreshDevices } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_ENTRANCES_REFRESH_BOOTABLE_DEVICES]
  );

  const viewOnly = useMemo(
    () => (isNewEntranceMode ? !canAddEntrances : !canEditEntrances),
    [canAddEntrances, canEditEntrances, isNewEntranceMode]
  );

  const [openYesNoDialog, setOpenYesNoDialog] = React.useState<{
    isOpen: boolean;
    deviceId?: string;
  }>({ isOpen: false });

  const [openYesNoDialog_unlockdoor, setOpenYesNoDialog_unlockdoor] = React.useState<{
    isOpen: boolean;
    deviceId?: string;
  }>({ isOpen: false });

  const {
    register,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    control,
    setValue,
    reset,
    errors,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    trigger,
    setError,
    unregister,
    watch,
    clearErrors,
    formState,
    getValues,
  } = useForm<EntranceDetailInputs>({
    resolver: yupResolver(EntranceDetailSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "Schedules",
  });

  const methods = {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    errors,
    trigger,
    setError,
    unregister,
    watch,
    clearErrors,
    formState,
    getValues,
  };

  useEffect(() => {
    if (isNewEntranceMode) {
      setValue("FacialRecEnabled", false);
      setQrReader({
        deviceId: undefined,
        isRebooting: false,
        isBootable: false,
      });
      setInnerMotionSensor({
        deviceId: undefined,
        isRebooting: false,
        isBootable: false,
      });
      reset();
    }
  }, [isNewEntranceMode, selectedEntranceId, reset, setValue]);

  const { data: entranceDetail, refetchAction: refetchEntranceDetail } =
    useFetchData<CreateEntranceViewModel>(
      // @ts-ignore
      getEntranceDetail(selectedEntranceId),
      !isNil(selectedEntranceId) && !isNewEntranceMode,
      undefined,
      [selectedEntranceId],
      ({ data }) => {
        setOutSensorGridOpened(data?.outerMotionSensorId?.length > 0);
        prefillEntranceDetailInputs(setValue, data);

        innerMotionSensor.deviceId = data?.innerMotionSensorId;
        innerMotionSensor.isBootable = data?.cameraRebootEnabled ?? false;
        setInnerMotionSensor({ ...innerMotionSensor });

        qrReader.isBootable = data?.qrReaderRebootEnabled ?? false;
        qrReader.deviceId = data?.qrReaderId;
        setQrReader({ ...qrReader });
      },
      () => reset(),
      () => reset()
    );

  const handleOnSave = (formInputData: EntranceDetailInputs) => {
    if (isNewEntranceMode && !isNil(selectedSiteId)) {
      dispatch(
        postCreateEntranceDetail({
          siteId: parseInt(selectedSiteId),
          entranceName: formInputData.EntranceName,
          innerBeacon: formInputData.InnerBeacon,
          outerBeacon: formInputData.OuterBeacon,
          innerMotionSensorId: formInputData.InnerMotionSensorId,
          outerMotionSensorId: formInputData.OuterMotionSensorId,
          qrReaderId: formInputData.QRReaderId,
          imageTimeSpan: formInputData.ImageTimeSpan,
          facialRecEnabled: formInputData.FacialRecEnabled,
          schedules: adjustScheduleTimes(formInputData.Schedules),
          timeZone: formInputData.Timezone,
        })
      )
        .then(({ data }) => {
          dispatch(siteRedux.actions.setIsNewEntranceMode(false));
          refetchSiteEntrancesList();
          dispatch(
            siteRedux.actions.setSelectedEntranceId(data.entranceId.toString())
          );
        })
        .catch((error) => {
          if (
            typeof error.response.data === "string" ||
            error.response.data instanceof String
          ) {
            dispatch(
              commonReducer.actions.setSnackBarMessage(error.response.data)
            );
          } else if (error.response.data.errors) {
            Object.keys(error.response.data.errors).forEach((key) => {
              setError(key as keyof EntranceDetailInputs, {
                message: error.response.data.errors[key],
              });
            });
            setNoOpp((val) => !val);
          }
        });
    } else if (
      !isNewEntranceMode &&
      !isNil(siteDetail) &&
      !isNil(selectedEntranceId)
    ) {
      dispatch(
        putEditEntranceDetail({
          id: selectedEntranceId,
          siteId: siteDetail.siteId,
          status: siteDetail.status,
          entranceName: formInputData.EntranceName,
          innerBeacon: formInputData.InnerBeacon,
          outerBeacon: formInputData.OuterBeacon,
          innerMotionSensorId: formInputData.InnerMotionSensorId,
          outerMotionSensorId: formInputData.OuterMotionSensorId,
          qrReaderId: formInputData.QRReaderId,
          imageTimeSpan: formInputData.ImageTimeSpan,
          facialRecEnabled: formInputData.FacialRecEnabled,
          schedules: adjustScheduleTimes(formInputData.Schedules),
          timeZone: formInputData.Timezone,
        })
      )
        .then(() => {
          refetchSiteEntrancesList();
          refetchEntranceDetail();
        })
        .catch((error) => {
          if (
            typeof error.response.data === "string" ||
            error.response.data instanceof String
          ) {
            dispatch(
              commonReducer.actions.setSnackBarMessage(error.response.data)
            );
          } else if (error.response.data.errors) {
            Object.keys(error.response.data.errors).forEach((key) => {
              setError(key as keyof EntranceDetailInputs, {
                message: error.response.data.errors[key],
              });
            });
            setNoOpp((val) => !val);
          }
        });
    }
  };

  const handleClickOnChangeStatus = () => {
    if (entranceDetail && selectedEntranceId) {
      dispatch(
        siteRedux.dispatchActions.onChangeEntranceStatus(
          entranceDetail,
          selectedEntranceId
        )
      ).then(() => {
        refetchSiteEntrancesList();
        refetchEntranceDetail();
      });
    }
  };

  const handleClickOnRefreshBootableDevices = () => {
    setIsRefreshingBootableDevices(true);
    dispatch(getRefreshBootableDevices())
      .then((data) => {
        refetchSiteEntrancesList();
        refetchEntranceDetail();
        dispatch(
          commonReducer.actions.setSnackBarMessage(
            "Bootable devices successfully refreshed."
          )
        );
      })
      .catch((e) => {
        dispatch(
          commonReducer.actions.setSnackBarMessage(
            "Failed to refresh bootable devices."
          )
        );
      })
      .finally(() => {
        setIsRefreshingBootableDevices(false);
      });
  };

  const handleRebootDevice = (deviceId?: string) => {
    if (!isNil(deviceId)) {
      setOpenYesNoDialog({ isOpen: true, deviceId: deviceId });
    }
  };

  const handleUnlockDoor = (deviceId?: string) => {
    if (!isNil(deviceId)) {
        setOpenYesNoDialog_unlockdoor({ isOpen: true, deviceId: deviceId });
    }
  };

  const deviceRebootStateChange = (deviceId: string, isRebooting: boolean) => {
    // eslint-disable-next-line eqeqeq
    if (openYesNoDialog.deviceId == qrReader.deviceId) {
      qrReader.isRebooting = isRebooting;
      setQrReader({ ...qrReader });
    }
    // eslint-disable-next-line eqeqeq
    if (openYesNoDialog.deviceId == innerMotionSensor.deviceId) {
      innerMotionSensor.isRebooting = isRebooting;
      setInnerMotionSensor({ ...innerMotionSensor });
    }
  };

  const handleDialogConfirm = () => {
    if (openYesNoDialog.deviceId) {
      deviceRebootStateChange(openYesNoDialog.deviceId, true);
      // start device reboot
      dispatch(
        postRebootDevice({
          deviceId: openYesNoDialog.deviceId,
        })
      )
        .then((response) => {
          dispatch(
            commonReducer.actions.setSnackBarMessage(
              "Device reboot successfully started."
            )
          );
          // get result of device reboot
          dispatch(getRebootResult(response.data.relatedJobId))
            .then(() => {
              dispatch(
                commonReducer.actions.setSnackBarMessage(
                  "Device reboot successfully completed."
                )
              );
            })
            .catch(() => {
              dispatch(
                commonReducer.actions.setSnackBarMessage(
                  "Device reboot failed."
                )
              );
            })
            .finally(() => {
              if (!isNil(openYesNoDialog.deviceId)) {
                deviceRebootStateChange(openYesNoDialog.deviceId!, false);
              }
            });
        })
        .catch((e) => {
          if (!isNil(openYesNoDialog.deviceId)) {
            deviceRebootStateChange(openYesNoDialog.deviceId!, false);
          }
          dispatch(
            commonReducer.actions.setSnackBarMessage(
              "Failed to start device reboot."
            )
          );
        });
    } else {
      dispatch(
        commonReducer.actions.setSnackBarMessage(
          "Failed to start device reboot."
        )
      );
    }
    setOpenYesNoDialog({ isOpen: false });
    };

  const handleDialogConfirm_unlockdoor = () => {
      if (openYesNoDialog_unlockdoor.deviceId) {
          dispatch(
              postUnlockDoor({
                  deviceId: openYesNoDialog_unlockdoor.deviceId,
              })
          )
              .then((response) => {
                  dispatch(
                      commonReducer.actions.setSnackBarMessage(
                          "Door unlock successfully started."
                      )
                  );
                  dispatch(getUnlockResult(response.data.relatedJobId))
                      .then(() => {
                          dispatch(
                              commonReducer.actions.setSnackBarMessage(
                                  "Door unlock successfully completed."
                              )
                          );
                      })
                      .catch(() => {
                          dispatch(
                              commonReducer.actions.setSnackBarMessage(
                                  "Door unlock failed."
                              )
                          );
                      });
              })
              .catch((e) => {
                  dispatch(
                      commonReducer.actions.setSnackBarMessage(
                          "Failed to unlock door."
                      )
                  );
              });
      } else {
          dispatch(
              commonReducer.actions.setSnackBarMessage(
                  "Failed to unlock door."
              )
          );
      }
  };

  const timeZones = useMemo(() => moment.tz.zonesForCountry("US"), []);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          handleSubmit(handleOnSave)(e);
        }}
      >
        <DetailPanelView
          title={
            isNewEntranceMode ? "New Entrance" : entranceDetail?.entranceName
          }
          showSaveButton={!viewOnly}
        >
          <FormSection title="General Information">
            <FormControlWrapper
              title="Name"
              input={
                <SpTextField
                  name="EntranceName"
                  inputRef={register}
                  errors={errors}
                  disabled={viewOnly}
                  fullWidth
                />
              }
            />
            <FormControlWrapper
              title="Timezone"
              input={
                <Controller
                  control={control}
                  name="Timezone"
                  defaultValue=""
                  inputRef={register}
                  as={
                    <SpTextField select disabled={viewOnly} errors={errors}>
                      {timeZones?.map((zone) => (
                        <MenuItem key={zone} value={zone}>
                          {zone}
                        </MenuItem>
                      ))}
                    </SpTextField>
                  }
                />
              }
            />
          </FormSection>
          <FormSection title="Schedules">
            {fields.map((field, index) => (
              <ScheduleInput
                index={index}
                remove={remove}
                disabled={viewOnly}
                key={field.id}
              />
            ))}
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={append}
              endIcon={<AddCircle />}
              disabled={viewOnly}
            >
              New Schedule
            </Button>
          </FormSection>
          <FormSection title="Motion Sensors">
            <div className={classes.reboot}>
              <FormControlWrapper
                title={
                  outSensorGridOpened
                    ? "Inner Motion Sensor ID"
                    : "Motion Sensor ID"
                }
                input={
                  <SpTextField
                    name="InnerMotionSensorId"
                    inputRef={register}
                    errors={errors}
                    disabled={viewOnly}
                    fullWidth
                  />
                }
              />
              <div className={classes.reboot}>
                {canRebootDevices && (
                  <Button
                    className={classes.rebootButton}
                    disabled={
                      innerMotionSensor.isRebooting ||
                      !innerMotionSensor.isBootable
                    }
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleRebootDevice(innerMotionSensor.deviceId)
                    }
                  >
                    Reboot Camera{" "}
                    {innerMotionSensor.isRebooting && <LoadingIndicator />}
                  </Button>
                )}
                {!outSensorGridOpened && (
                  <Grid style={{ width: theme.spacing(2) }} />
                )}
                {!outSensorGridOpened && isAdmin && (
                  <PlusButton onClick={() => setOutSensorGridOpened(true)} />
                )}
              </div>
            </div>
            {outSensorGridOpened && (
              <FormControlWrapper
                title="Outer Motion Sensor ID"
                input={
                  <SpTextField
                    name="OuterMotionSensorId"
                    inputRef={register}
                    errors={errors}
                    disabled={viewOnly}
                    fullWidth
                  />
                }
              />
            )}
            <FormControlWrapper
              title="Image Timespan (in seconds)"
              input={
                <SpTextField
                  name="ImageTimeSpan"
                  inputRef={register}
                  errors={errors}
                  disabled={viewOnly}
                  fullWidth
                />
              }
            />
          </FormSection>

          <FormSection title="QR Reader">
            <div className={classes.reboot}>
              <FormControlWrapper
                title="QR Reader ID"
                input={
                  <SpTextField
                    name="QRReaderId"
                    inputRef={register}
                    errors={errors}
                    disabled={viewOnly}
                    fullWidth
                  />
                }
              />
              <div className={classes.reboot}>
                {canRebootDevices && (
                  <Button
                    className={classes.rebootButton}
                    disabled={qrReader.isRebooting || !qrReader.isBootable}
                    variant="contained"
                    color="primary"
                    onClick={() => handleRebootDevice(qrReader.deviceId)}
                  >
                    Reboot Reader {qrReader.isRebooting && <LoadingIndicator />}
                  </Button>
                )}
              </div>

              <div className={classes.unlock}>
                {canUnlockDevices && (
                    <Button
                        className={classes.unlockButton}
                        disabled={qrReader.isRebooting}
                        variant="contained"
                        color="primary"
                        onClick={() => handleUnlockDoor(qrReader.deviceId)}
                    >
                        Unlock Door {qrReader.isRebooting && <LoadingIndicator />}
                    </Button>
                )}
              </div>

            </div>
          </FormSection>

          <FormSection title="Beacons">
            <div className={classes.reboot}>
              <FormControlWrapper
                title="Inner Beacon ID"
                input={
                  <SpTextField
                    name="InnerBeacon"
                    inputRef={register}
                    errors={errors}
                    disabled={viewOnly}
                    fullWidth
                  />
                }
              />
              <Box className={classes.reboot} marginLeft="16px">
                {selectedEntranceId && entranceDetail?.innerBatteryLevel && (
                  <Battery batteryLevel={entranceDetail.innerBatteryLevel} />
                )}
              </Box>
            </div>

            <div className={classes.reboot}>
              <FormControlWrapper
                title="Outer Beacon ID"
                input={
                  <SpTextField
                    name="OuterBeacon"
                    inputRef={register}
                    errors={errors}
                    disabled={viewOnly}
                    fullWidth
                  />
                }
              />
              <Box className={classes.reboot} marginLeft="16px">
                {selectedEntranceId && entranceDetail?.outerBatteryLevel && (
                  <Battery batteryLevel={entranceDetail.outerBatteryLevel} />
                )}
              </Box>
            </div>
          </FormSection>

          <Divider className={classes.ctasDivider} />

          {(!viewOnly || canChangeStatus || canRefreshDevices) && (
            <div className={classes.ctas}>
              {canChangeStatus &&
                !isNil(selectedEntranceId) &&
                entranceDetail?.status && (
                  <Button
                    className={classes.cta}
                    variant="contained"
                    onClick={handleClickOnChangeStatus}
                  >
                    {entranceDetail.status === "Active"
                      ? "Deactivate"
                      : "Activate"}
                  </Button>
                )}
              {canRefreshDevices && (
                <div className={classes.lastRefresh}>
                  <Button
                    className={classes.cta}
                    disabled={isRefreshingBootableDevices}
                    variant="contained"
                    onClick={handleClickOnRefreshBootableDevices}
                  >
                    Refresh bootable devices{" "}
                    {isRefreshingBootableDevices && <LoadingIndicator />}
                  </Button>
                  <Typography
                    variant="subtitle1"
                    style={{
                      textAlign: "center",
                      fontSize: "10px",
                      marginTop: "8px",
                      marginLeft: "-16px",
                    }}
                  >
                    {!isNil(siteDetail?.lastAwxDevicesSyncDate)
                      ? `Last refresh of devices: ${moment(
                          siteDetail?.lastAwxDevicesSyncDate
                        ).format(FRONTEND_DATE_FORMAT_REPORTS)}`
                      : "Devices were never refreshed"}
                  </Typography>
                </div>
              )}
              {!viewOnly && (
                <Button
                  className={classes.cta}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              )}
            </div>
          )}
          <YesNoDialog
            open={openYesNoDialog.isOpen}
            onConfirm={() => handleDialogConfirm()}
            onCancel={() => setOpenYesNoDialog({ isOpen: false })}
            title="Device reboot"
            message="Are you sure you want to reboot?"
          />
          <YesNoDialog
            open={openYesNoDialog_unlockdoor.isOpen}
            onConfirm={() => handleDialogConfirm_unlockdoor()}
            onCancel={() => setOpenYesNoDialog_unlockdoor({ isOpen: false })}
            title="Unlock door"
            message="Are you sure you want to unlock door?"
          />
        </DetailPanelView>
      </form>
    </FormProvider>
  );
};

export default memo(EntranceDetail);
