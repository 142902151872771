import { CounterResponseItem } from "./services/apiServiceTypes";

export const USER_TYPES = {
  ADMIN: "admin",
  SITE_SUPERVISOR: "siteSupervisor",
};

export const USER_COUNTER_TYPES: CounterResponseItem[] = [
  { codeId: USER_TYPES.ADMIN, displayName: "Admin" },
  { codeId: USER_TYPES.SITE_SUPERVISOR, displayName: "Site Supervisor" },
];

export const REASON_TYPE = {
  OTHER: "other",
  FORGOT_PHONE: "forgotPhone",
  PHONE_NOT_CHARGED: "phoneNotCharged",
  UNINSTALLED_MOBILE_APP: "uninstalledMobileApp",
};

export const CHECK_IN_OUT_REASON_COUNTER_TYPES: CounterResponseItem[] = [
  { codeId: REASON_TYPE.OTHER, displayName: "Other" },
  { codeId: REASON_TYPE.FORGOT_PHONE, displayName: "Forgot Phone" },
  { codeId: REASON_TYPE.PHONE_NOT_CHARGED, displayName: "Phone not Charged" },
  {
    codeId: REASON_TYPE.UNINSTALLED_MOBILE_APP,
    displayName: "Uninstalled Mobile App",
  },
];

export const THRESHOLD_TYPE = {
  "7_DAYS": "7",
  "10_DAYS": "10",
  "30_DAYS": "30",
  "60_DAYS": "60",
  "90_DAYS": "90",
};

export const CERTIFICATE_EXPIRATION_THRESHOLD_TYPES: CounterResponseItem[] = [
  { codeId: THRESHOLD_TYPE["7_DAYS"], displayName: "7 Days" },
  { codeId: THRESHOLD_TYPE["10_DAYS"], displayName: "10 Days" },
  { codeId: THRESHOLD_TYPE["30_DAYS"], displayName: "30 Days" },
  { codeId: THRESHOLD_TYPE["60_DAYS"], displayName: "60 Days" },
  { codeId: THRESHOLD_TYPE["90_DAYS"], displayName: "90 Days" },
];
