import * as constants from "./constants";
import reducer from "./reducer";
import * as apiCalls from "./apiCalls";
import dispatchActions from "./dispatchActions";
import selectors from "./selectors";

const reducerExport = {
  ...reducer,
  constants,
  apiCalls,
  dispatchActions,
  selectors,
};

export default reducerExport;
