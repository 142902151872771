import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/styles";
import { SiteDetailModel } from "../../../forms/redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import siteRedux from "../../redux";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { ListDetailSplitView, SpListItem } from "../../../../common";
import InfiniteScroll from "react-infinite-scroll-component";
import { isNil } from "ramda";
import { Theme } from "@material-ui/core/styles";
import { SCROLL_PANEL_WIDTH } from "../../../../appTheme";
import { AppDispatch } from "../../../../appStore";
import { customDebounce } from "../../../../services/helpers";
import { SiteSupervisorDetail } from "./index";
import NewSiteSupervisorDialog from "./NewSiteSupervisorDialog";
import { useAuthorization } from "../../../../auth";
import { ROLES, USER_PERMISSIONS } from "../../../../auth/constants";

const useStyles = makeStyles<Theme>((theme) => ({
  footerContainer: {
    height: "inherit",
  },
  detailPanel: {
    width: `calc(100% - ${SCROLL_PANEL_WIDTH}px -  ${theme.spacing(2)}px)`,
  },
  scrollPanel: {
    height: "calc(100vh - 411px)",
    width: SCROLL_PANEL_WIDTH,
  },
  mobileScrollPanel: {
    height: "200px !important",
    width: "100%"
  },
  scrollPanelViewOnly: {
    width: SCROLL_PANEL_WIDTH,
  },
  mainDetailBody: {
    height: `calc(100vh - 251px)`,
    overflowY: "auto",
    overflowX: "hidden",
    display: "block",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

type SiteSupervisorTabProps = {
  siteDetail?: SiteDetailModel;
};

const SiteSupervisorTab: FC<SiteSupervisorTabProps> = ({ siteDetail }) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const selectedSiteId = useSelector(siteRedux.selectors.getSelectedSiteId);

  const pageSize: number = 50;
  const pageIndex = useRef<number>(0);
  const siteSupervisorListHasNextPage = useSelector(
    siteRedux.selectors.getSiteSupervisorListHasNextPage
  );
  const siteSupervisorList = useSelector(
    siteRedux.selectors.getSiteSupervisorList
  );
  const selectedSiteSupervisorId = useSelector(
    siteRedux.selectors.getSelectedSiteSupervisorId
  );
  const isNewSiteSupervisorMode = useSelector(
    siteRedux.selectors.getIsNewSiteSupervisorMode
  );

  const { isAllowed: canAddSupervisor } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_SUPERVISORS_ADD_REMOVE]
  );

  const debouncedCall = useMemo(
    () =>
      customDebounce(
        (
          siteId: string,
          incrementedPageIndex: number,
          isScrollMode: boolean
        ) => {
          dispatch(
            siteRedux.dispatchActions.fetchSiteSupervisorList(
              {
                Pagination: {
                  PageIndex: incrementedPageIndex,
                  PageSize: pageSize,
                },
              },
              siteId,
              isScrollMode
            )
          )
            .then(() => {
              pageIndex.current = incrementedPageIndex;
            })
            .catch(() => {
              pageIndex.current = 0;
            });
        },
        800
      ),
    [dispatch]
  );

  const fetchNextData = useCallback(
    (isScrollMode: boolean = false) => {
      if (siteSupervisorListHasNextPage) {
        const incrementedPageIndex = pageIndex.current + 1;
        debouncedCall(selectedSiteId, incrementedPageIndex, isScrollMode);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedCall, selectedSiteId]
  );

  const resetPagination = useCallback(() => {
    dispatch(siteRedux.actions.setSiteSupervisorListHasNextPage(true));
    dispatch(siteRedux.actions.setSiteSupervisorList([]));
    pageIndex.current = 0;
  }, [dispatch]);

  const refetchSiteSupervisorList = useCallback(() => {
    resetPagination();
    fetchNextData();
  }, [fetchNextData, resetPagination]);

  useEffect(() => {
    refetchSiteSupervisorList();
  }, [refetchSiteSupervisorList]);

  useEffect(() => {
    return () => {
      dispatch(siteRedux.dispatchActions.resetSiteSupervisorTab());
    };
  }, [dispatch]);

  const getUserListPanel = () => {
      return (
          <InfiniteScroll
              dataLength={siteSupervisorList.length} //This is important field to render the next data
              next={() => fetchNextData(true)}
              hasMore={siteSupervisorListHasNextPage}
              loader={
                  <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className={classes.footerContainer}
                  >
                      <Grid item>
                          <CircularProgress />
                      </Grid>
                  </Grid>
              }
              endMessage={
                  <Typography
                      variant="body1"
                      color="textSecondary"
                      style={{ textAlign: "center" }}
                  >
                      No more data
                  </Typography>
              }
              height={`calc(100vh - ${!canAddSupervisor ? "242px" : "306px"})`}
              className={
                  !canAddSupervisor
                      ? classes.scrollPanelViewOnly
                      : window.innerWidth > 600 ? classes.scrollPanel : classes.mobileScrollPanel
              }
          >
              {siteSupervisorList.map((entrance, index) => (
                  <SpListItem
                      key={index}
                      title={entrance.displayName}
                      isActive={selectedSiteSupervisorId === entrance.codeId}
                      onClick={() => {
                          dispatch(
                              siteRedux.actions.setSelectedSiteSupervisorId(
                                  entrance.codeId
                              )
                          );
                          dispatch(siteRedux.actions.setIsNewSiteSupervisorMode(false));
                      }}
                      inactiveItem={!entrance.isActive}
                  />
              ))}
          </InfiniteScroll>
      )
  }

  const getUserDetailsPanel = () => {
      return (
          <div className={classes.mainDetailBody}>
              {(isNewSiteSupervisorMode || !isNil(selectedSiteSupervisorId)) && (
                  <SiteSupervisorDetail
                      siteDetail={siteDetail}
                      refetchSiteSupervisorList={refetchSiteSupervisorList}
                  />
              )}
          </div>
      )
  }

  return (
    <>
        {window.innerWidth <= 600 && (
            <>
                {getUserListPanel()}
                <div>
                    {getUserDetailsPanel()}
                </div>
            </>
        )}

        {window.innerWidth > 600 && (
            <ListDetailSplitView
                list={
                    <>
                        {getUserListPanel()}
                    </>
                }
                details={
                    getUserDetailsPanel()
                }
            />
        )}
      {isNewSiteSupervisorMode && (
        <NewSiteSupervisorDialog
          refetchSiteSupervisorList={refetchSiteSupervisorList}
        />
      )}
    </>
  );
};

export default memo(SiteSupervisorTab);
