import { createSelector } from "@reduxjs/toolkit";
import { State } from "./model";
import { COMMON } from "./constants";

const selectSelf = (state: { [x: string]: State }) =>
  state[COMMON.ROOT_REDUCER];

const getSnackBarMessage = createSelector(
  selectSelf,
  (state: State) => state.snackBarMessage
);

const getAudioEnabled = createSelector(
  selectSelf,
  (state: State) => state.audioEnabled
);

const getPlayAudio = createSelector(
  selectSelf,
  (state: State) => state.playAudio
);

const getAudioSrc = createSelector(
  selectSelf,
  (state: State) => state.audioSrc
);

const allSelectors = {
  getSnackBarMessage,
  getAudioEnabled,
  getPlayAudio,
  getAudioSrc,
};

export default allSelectors;
