import { FC, useEffect } from "react";
// import Button from "@material-ui/core/Button";
import commonReducer from "../common/redux";
import { batch, useDispatch } from "react-redux";

type PlayAudioType = {
  path: any;
  play?: boolean;
};

export const PlayAudio: FC<PlayAudioType> = ({ path, play }) => {
  //const audioEnabled = useSelector(commonReducer.selectors.getAudioEnabled);
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(path.default, play);
    if (play) {
      batch(() => {
        dispatch(commonReducer.actions.setAudioSrc(path.default));
        dispatch(commonReducer.actions.setPlayAudio());
      });
    }
  }, [path.default, play, dispatch]);

  // const testAudio = () => {
  //   batch(() => {
  //     dispatch(commonReducer.actions.setAudioSrc(path.default));
  //     dispatch(commonReducer.actions.setPlayAudio());
  //   });
  // };

  return null;
  // <div>
  //   {false && (
  //     <>
  //       <Button color="primary" variant="contained" onClick={testAudio}>
  //         Test autoplay sound
  //       </Button>
  //     </>
  //   )}
  // </div>
};
