import { useCallback, useEffect, useRef, useState } from "react";
import { isNil } from "ramda";
import { AxiosError, AxiosResponse } from "axios";
import { AppDispatch } from "../appStore";
import { useDispatch } from "react-redux";

interface ReturnType<T> {
  data?: T;
  isLoading: boolean;
  refetchAction: () => void;
}

export const useFetchData = <T>(
  apiCallAction: <T>(
    dispatch: AppDispatch,
    getState: any
  ) => Promise<AxiosResponse<T>>,
  isReadyToFetch = true,
  isReset = false,
  dependencyArray?: any[],
  onSuccess?: (response: AxiosResponse<T>) => void,
  onError?: (response: AxiosError<T>) => void,
  onStartRequest?: () => void
): ReturnType<T> => {
  const previousDependencyArray = useRef<string>();
  const [result, setResult] = useState<T>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const retrievingData = useCallback(() => {
    setIsLoading(true);
    if (!isNil(onStartRequest)) {
      onStartRequest();
    }
    setResult(undefined);
    dispatch(apiCallAction)
      // @ts-ignore
      .then((response: AxiosResponse<T>) => {
        setResult(response.data);
        if (!isNil(onSuccess)) {
          onSuccess(response);
        }
      })
      .catch((response: AxiosResponse<T>) => {
        setResult(undefined);
        if (!isNil(onSuccess)) {
          onSuccess(response);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [apiCallAction, dispatch, onStartRequest, onSuccess]);

  useEffect(() => {
    const newDependencyArray =
      (dependencyArray && JSON.stringify(dependencyArray)) ||
      "__initialFetch__";
    if (
      isReadyToFetch &&
      !isLoading &&
      previousDependencyArray.current !== newDependencyArray &&
      !isReset
    ) {
      previousDependencyArray.current = newDependencyArray;
      retrievingData();
    } else if (isReset) {
      setResult(undefined);
      setIsLoading(false);
      previousDependencyArray.current = undefined;
    }
  }, [dependencyArray, isLoading, isReadyToFetch, isReset, retrievingData]);

  return { isLoading, data: result, refetchAction: retrievingData };
};
