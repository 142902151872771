import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { MAIN_PATH } from "./pathConstants";
import {
  ForgotPasswordScreen,
  LoginScreen,
  ResetPasswordScreen,
  useReloadJwtToken,
} from "../auth";
import useSnackBar from "../common/hooks/useSnackBar";
import AdminRoutes from "./AdminRoutes";
import { HubConnection } from "@microsoft/signalr";
import { SignalRContext } from "../services/signalR/SignalRProvider";
import CustomRoute from "./CustomRoute";
import RedirectRoute from "./RedirectRoute";

const Routes: React.FC = () => {
  const snackBar = useSnackBar();
  useReloadJwtToken();

  const [signalRConnection, setSignalRConnection] = useState<HubConnection>();
  const [panelsPool, setPanelsPool] = useState<any>({});

  return (
    <SignalRContext.Provider
      value={{
        connection: signalRConnection,
        setConnection: setSignalRConnection,
        panelsPool: panelsPool,
        setPanelsPool: setPanelsPool,
      }}
    >
      <Switch>
        <Route path={`/${MAIN_PATH.ADMIN}`}>
          <AdminRoutes />
        </Route>
        <Route path={`/${MAIN_PATH.LOGIN}`} exact>
          <LoginScreen />
        </Route>
        <Route path={`/${MAIN_PATH.RESET_PASSWORD}`}>
          <ResetPasswordScreen />
        </Route>
        <Route path={`/${MAIN_PATH.FORGOT_PASSWORD}`}>
          <ForgotPasswordScreen />
        </Route>
        <Route path={`/${MAIN_PATH.MONITOR}`}>
          <RedirectRoute url={"http://172.24.40.71/nagios4"} />
        </Route>
        <Route path={"/*"}>
          <CustomRoute />
        </Route>
      </Switch>
      {snackBar}
    </SignalRContext.Provider>
  );
};

export default Routes;
