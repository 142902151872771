import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { throttle } from "lodash";

interface useComputeSizeReturnType {
  height: number;
  width: number;
  clientWidth: number;
}

export const useComputeSize = (
  componentRef: MutableRefObject<any | null>,
  dependencyArray?: any[],
  initialHeight = 0,
  initialWidth = 0
): useComputeSizeReturnType => {
  const comparingTolerance = 8; // 8px
  const [height, setHeight] = useState<number>(initialHeight);
  const [width, setWidth] = useState<number>(initialWidth);
  const [clientWidth, setClientWidth] = useState<number>(0);
  const oldDependencyArray = useRef<string>();

  const throttleClientWidth = useMemo(
    () =>
      throttle(() => {
        const newClientWidth =
          componentRef &&
          componentRef.current &&
          componentRef.current.clientWidth
            ? componentRef.current.clientWidth
            : null;
        if (
          newClientWidth !== null &&
          Math.abs(newClientWidth - clientWidth) > comparingTolerance
        ) {
          setClientWidth(newClientWidth);
        }
      }, 850),
    [clientWidth, componentRef]
  );

  const throttleHeight = useMemo(
    () =>
      throttle(() => {
        const newHeight =
          componentRef &&
          componentRef.current &&
          componentRef.current.offsetHeight
            ? componentRef.current.offsetHeight
            : null;
        if (
          newHeight !== null &&
          Math.abs(newHeight - height) > comparingTolerance
        ) {
          setHeight(newHeight);
        }
      }, 850),
    [componentRef, height]
  );

  const throttleWidth = useMemo(
    () =>
      throttle(() => {
        const newWidth =
          componentRef &&
          componentRef.current &&
          componentRef.current.offsetWidth
            ? componentRef.current.offsetWidth
            : null;
        if (
          newWidth !== null &&
          Math.abs(newWidth - width) > comparingTolerance
        ) {
          setWidth(newWidth);
        }
      }, 850),
    [componentRef, width]
  );

  const resize = useCallback(() => {
    throttleHeight();
    throttleWidth();
    throttleClientWidth();
  }, [throttleClientWidth, throttleHeight, throttleWidth]);

  useEffect(() => {
    const newDependencyArray = JSON.stringify(dependencyArray);
    if (
      componentRef &&
      componentRef.current &&
      newDependencyArray !== oldDependencyArray.current
    ) {
      resize();
      oldDependencyArray.current = newDependencyArray;
    }
  }, [componentRef, dependencyArray, resize]);

  useEffect(() => {
    if (componentRef && componentRef.current) {
      resize();
      window.addEventListener("resize", resize);
    }

    return () => {
      if (componentRef) {
        window.removeEventListener("resize", resize);
      }
    };
  }, [componentRef, resize]);

  return { height, width, clientWidth };
};
