import React, { MouseEvent } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ListItem, Typography } from "@material-ui/core";
import { COLORS, FONT_WEIGHT } from "../../appTheme";

const activeStyle = {
  backgroundColor: COLORS.DARK_GRAY2,
  "& h2": {
    fontWeight: FONT_WEIGHT.EXTRA_BOLD,
    color: COLORS.BLACK,
  },
};

interface StyleProps {
  isActive?: boolean;
}

const useStyles = makeStyles<Theme>((theme) => ({
  menuItemContainer: (props: StyleProps) => {
    const style = {
      cursor: "pointer",
      padding: "0 32px",
      "&:hover": activeStyle,
    };
    if (props?.isActive) {
      return { ...style, ...activeStyle };
    }
    return style;
  },

  menuItem: {
    color: COLORS.WHITE,
    fontSize: "14px",
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
    padding: "12px 0",
  },
}));
const MainMenuLeftItem: React.FC<MainMenuLeftItemProps> = ({
  title,
  onClick,
  isActive,
}) => {
  const classes = useStyles({ isActive });
  return (
    <ListItem button onClick={onClick} className={classes.menuItemContainer}>
      <Typography className={classes.menuItem}>{title}</Typography>
    </ListItem>
  );
};

export default MainMenuLeftItem;

export interface MainMenuLeftItemProps {
  title: string;
  onClick: (event: MouseEvent<HTMLInputElement>) => void;
  isActive?: boolean;
}
