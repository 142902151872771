import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import cx from "classnames";
import parseIcon from "./parseIcon";
import { isNil } from "ramda";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 32.372,
    width: 32.372,
    paddingRight: theme.spacing(2),
  },
}));

const SpIcon: React.FC<SpIconProps> = ({ onClick, className, iconName }) => {
  const classes = useStyles();

  const icon = parseIcon(iconName);

  if (!isNil(icon)) {
    return (
      <img
        src={icon}
        alt={`icon-${iconName}`}
        className={cx(classes.icon, className)}
        onClick={onClick}
      />
    );
  }
  return null;
};

export default SpIcon;

export interface SpIconProps {
  className?: any;
  iconName: string;
  onClick?: () => void;
}
