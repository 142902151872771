import React from "react";
import { TabPanel } from "../../../common";
import { Tab, Tabs, Theme } from "@material-ui/core";
import PersonalInfoDetail from "./PersonalInfoDetail";
import { ContractorDetailResponseType } from "../redux/apiCalls";
import QualificationsDetail from "./QualificationsDetail";
import AccessDetail from "./AccessDetail";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { ContractorInputs } from "./ContractorDetailSection";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles<Theme>((theme) => ({
  mainBody: {
    width: "100%",
    height: `calc(100vh - 230px)`,
    overflowY: "auto",
    overflowX: "hidden",
    display: "block",
  },
}));

export interface ContractorDetailTabsProps {
  contractorId?: string;
  newContractorMode?: boolean;
  register: any;
  control: any;
  errors: FieldErrors<ContractorInputs>;
  data?: ContractorDetailResponseType;
  setNewProfilePhoto: (newFile: Blob | null) => void;
  newCertificationCard: Blob | null;
  setNewCertificationCard: (newFile: Blob | null) => void;
  setSelectedTab: (val: number) => void;
  selectedTab: number;
  otherCertificationErrors: any;
  alertMessage?: string;
  refreshContractorDetail: (userId: string) => void;
  viewOnly: boolean;
  canVerifyVaccination: boolean;
  canAssignEntrances: boolean;
}

const ContractorDetailTabs: React.FC<ContractorDetailTabsProps> = ({
  contractorId,
  newContractorMode,
  setNewProfilePhoto,
  register,
  control,
  errors,
  data,
  newCertificationCard,
  setNewCertificationCard,
  setSelectedTab,
  selectedTab,
  otherCertificationErrors,
  alertMessage,
  refreshContractorDetail,
  viewOnly,
  canVerifyVaccination,
  canAssignEntrances,
}) => {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const classes = useStyles();

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Personal info" />
        <Tab label="Qualifications" />
        <Tab label="Access" />
      </Tabs>
      <div className={classes.mainBody}>
        <TabPanel value={selectedTab} index={0}>
          <PersonalInfoDetail
            register={register}
            control={control}
            data={data}
            contractorId={contractorId}
            newContractorMode={newContractorMode}
            setNewProfilePhoto={setNewProfilePhoto}
            errors={errors}
            alertMessage={alertMessage}
            refreshContractorDetail={refreshContractorDetail}
            viewOnly={viewOnly}
            canVerifyVaccination={canVerifyVaccination}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <QualificationsDetail
            register={register}
            control={control}
            data={data}
            errors={errors}
            contractorId={contractorId}
            newContractorMode={newContractorMode}
            newCertificationCard={newCertificationCard}
            setNewCertificationCard={setNewCertificationCard}
            otherCertificationErrors={otherCertificationErrors}
            viewOnly={viewOnly}
            canVerifyVaccination={canVerifyVaccination}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <AccessDetail
            contractorId={contractorId}
            newContractorMode={newContractorMode}
            data={data}
            canAssignEntrances={canAssignEntrances}
          />
        </TabPanel>
      </div>
    </>
  );
};

export default ContractorDetailTabs;
