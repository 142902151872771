import { createTheme } from "@material-ui/core/styles";

export const SCROLL_PANEL_WIDTH = 312.5;
export const HEIGHT_OF_INPUT = 41.3;

export const COLORS = {
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  DARK_BLUE: "#0F6093",
  // BLUE: "#26A9E0",
  // LIGHT_BLUE: "#D9DFE8",
  RED: "#D30000",
  GREEN: "#5ACC17",
  ORANGE: "#EF8200",

  // new UI colors
  BLUE: "#3178F6",
  LIGHT_BLUE: "#E4EFFC",
  DARK_GRAY: "#1D2433",
  DARK_GRAY2: "#23272F",
  HOVER_BLUE: "#2F6FED",
  PAGE_BACKGROUND: "#F9FAFB",
  TEXT_100: "#686C77",
  TEXT_200: "#4A505C",
  TEXT_300: "#1D2433",
  TEXT_400: "#000000",
  // TODO find better label/ name for this
  SECONDARY_BLUE_700: "#354ED3",
  LINE_100: "#E2E3E5",
};

export const FONT_WEIGHT = {
  THIN: 100,
  EXTRA_LIGHT: 200,
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
  BLACK: 900,
  EXTRA_BLACK: 950,
};

export const BORDER_RADIUS = {
  NORMAL: 10.5,
  MEDIUM: 9.629,
  SMALL: 6,
  MINIMAL: 1.5,
  NONE: "unset",
};

export const FONT_FAMILY = {
  MANROPE: "manrope",
};

export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.BLUE,
      contrastText: COLORS.WHITE,
      dark: COLORS.HOVER_BLUE,
      light: COLORS.LIGHT_BLUE,
    },
    secondary: {
      main: COLORS.WHITE,
    },
    error: {
      main: COLORS.RED,
    },
    text: {
      secondary: COLORS.DARK_BLUE,
    },
  },
  typography: {
    // Page content title
    h1: {
      fontSize: 24,
      lineHeight: "32px",
      fontFamily: FONT_FAMILY.MANROPE,
      fontWeight: FONT_WEIGHT.BOLD,
      color: COLORS.TEXT_300,
    },
    // Detail pane title
    h2: {
      fontSize: 18,
      lineHeight: "28px",
      fontFamily: FONT_FAMILY.MANROPE,
      fontWeight: FONT_WEIGHT.BOLD,
      color: COLORS.TEXT_300,
    },
    // Form section header
    h3: {
      fontSize: 16,
      lineHeight: "24px",
      fontFamily: FONT_FAMILY.MANROPE,
      fontWeight: FONT_WEIGHT.SEMI_BOLD,
      color: COLORS.TEXT_300,
    },
    // Table header, form input label (edit mode), list item title
    h4: {
      fontSize: 14,
      lineHeight: "20px",
      fontFamily: FONT_FAMILY.MANROPE,
      fontWeight: FONT_WEIGHT.SEMI_BOLD,
      color: COLORS.TEXT_300,
    },
    // sensor card title
    h5: {
      fontSize: 14,
      lineHeight: "20px",
      fontFamily: FONT_FAMILY.MANROPE,
      fontWeight: FONT_WEIGHT.MEDIUM,
      color: COLORS.TEXT_400,
    },
    // Tree structure list subtitle
    h6: {
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: FONT_WEIGHT.MEDIUM,
      fontFamily: FONT_FAMILY.MANROPE,
      color: COLORS.TEXT_100,
    },
    // Detail pane subtitle, site card subtitle
    subtitle1: {
      fontSize: 14,
      lineHeight: "20px",
      fontFamily: FONT_FAMILY.MANROPE,
      fontWeight: FONT_WEIGHT.MEDIUM,
      color: COLORS.TEXT_200,
    },
    // list item description
    subtitle2: {
      fontSize: 14,
      lineHeight: "20px",
      fontFamily: FONT_FAMILY.MANROPE,
      fontWeight: FONT_WEIGHT.SEMI_BOLD,
      color: COLORS.TEXT_100,
    },
    body1: {
      fontSize: 14,
      lineHeight: "20px",
      fontFamily: FONT_FAMILY.MANROPE,
      fontWeight: FONT_WEIGHT.MEDIUM,
      color: COLORS.TEXT_300,
    },
    // Report table data
    body2: {
      fontSize: 14,
      fontWeight: FONT_WEIGHT.REGULAR,
      fontFamily: FONT_FAMILY.MANROPE,
      color: COLORS.TEXT_300,
      lineHeight: "24px",
    },
    button: {
      fontSize: 14,
      fontWeight: FONT_WEIGHT.SEMI_BOLD,
      fontFamily: FONT_FAMILY.MANROPE,
    },
    // TODO REMOVE (if unused after changes)
    overline: {
      fontSize: 14,
      fontWeight: FONT_WEIGHT.BOLD,
      fontFamily: FONT_FAMILY.MANROPE,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: BORDER_RADIUS.SMALL,
        boxShadow: "none",
        paddingLeft: "20px",
        paddingRight: "20px",
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      containedPrimary: {
        boxShadow: "none",
      },
    },
    MuiTableCell: {
      head: {
        color: COLORS.DARK_GRAY,
        fontWeight: FONT_WEIGHT.SEMI_BOLD,
        fontSize: 14,
      },
      root: {
        padding: "8px 12px",
      },
    },
    MuiTabs: {
      indicator: {
        display: "none",
      },
    },
    MuiTab: {
      root: {
        backgroundColor: COLORS.PAGE_BACKGROUND,
        borderRadius: BORDER_RADIUS.SMALL,
        marginRight: 16,
        minHeight: 36,
        "&.Mui-selected": {
          backgroundColor: COLORS.LIGHT_BLUE,
          border: "0 none",
          "& span": {
            color: COLORS.SECONDARY_BLUE_700,
          },
        },
      },
      wrapper: {
        color: COLORS.TEXT_100,
        fontWeight: FONT_WEIGHT.MEDIUM,
        textTransform: "none",
      },
    },
  },
});
