import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactNode } from "react";
import AddCircle from "@material-ui/icons/AddCircle";

export interface PageContentProps {
  title: string;
  children?: ReactNode;
  addNewButtonText?: string;
  showButton?: boolean;
  buttonOnClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(3),
  },
}));

const PageContent: React.FC<PageContentProps> = ({
  title,
  addNewButtonText,
  showButton,
  buttonOnClick,
  children,
}) => {
  const classes = useStyles();
  return (
    <Box display={"flex"} flexDirection="column" flexGrow={1} height="100%">
      <Box
        display={"flex"}
        flexDirection="row"
        className={classes.header}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant={"h1"}>{title}</Typography>
        {showButton ? (
          <Button
            color={"primary"}
            variant={"contained"}
            onClick={buttonOnClick}
            endIcon={<AddCircle />}
          >
            {addNewButtonText || "Add New"}
          </Button>
        ) : null}
      </Box>
      {children}
    </Box>
  );
};

export default PageContent;
