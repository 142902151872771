import {
  ContractorListRequestType,
  getCompanyCounter,
  getContractorList,
  getSitesFromCompanyCounter,
  putClubData,
} from "./apiCalls";
import { AppDispatch } from "../../../appStore";
import contractorReducer from "./index";
import { isNil } from "ramda";
import { CounterResponseModel } from "../../../services/apiServiceTypes";
import { CancelToken } from "axios";

const fetchListOfContractors =
  (
    body: ContractorListRequestType,
    isScrollMode: boolean = false,
    cancelToken: CancelToken
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(contractorReducer.actions.setIsFetchingContractorList(true));
    return dispatch(getContractorList(body, cancelToken))
      .then(({ data }) => {
        if (isScrollMode) {
          dispatch(
            contractorReducer.actions.appendToContractorList(data.items)
          );
        } else {
          dispatch(contractorReducer.actions.setContractorList(data.items));
        }
        dispatch(
          contractorReducer.actions.setContractorListHasNextPage(
            data.hasNextPage
          )
        );
      })
      .catch(() => {
        dispatch(contractorReducer.actions.setContractorListHasNextPage(true));
      })
      .finally(() => {
        dispatch(contractorReducer.actions.setIsFetchingContractorList(false));
      });
  };

const fetchCompanyCounter = () => (dispatch: AppDispatch) => {
  dispatch(getCompanyCounter())
    .then(({ data }) => {
      dispatch(contractorReducer.actions.setCompanyList(data));
    })
    .catch(() => {
      dispatch(contractorReducer.actions.setCompanyList([]));
    });
};

const fetchSiteCounters =
  (selectedCompanyId?: string) => (dispatch: AppDispatch) => {
    dispatch(getSitesFromCompanyCounter({ companyId: selectedCompanyId }))
      .then(({ data }) => {
        dispatch(contractorReducer.actions.setSiteList(data));
      })
      .catch(() => {
        dispatch(contractorReducer.actions.setSiteList([]));
      });
  };

const fetchGetClubData = (contractorId: string) => (dispatch: AppDispatch) => {
  dispatch(putClubData(contractorId))
    .then(({ data }) => {
      dispatch(contractorReducer.actions.setNewProfilePhotoId(data));
    })
    .catch(() => {
      dispatch(contractorReducer.actions.setNewProfilePhotoId(undefined));
    });
};

const prefillAccessList =
  (accessList: CounterResponseModel, entrances?: number[]) =>
  (dispatch: AppDispatch) => {
    let newAccessList = accessList.map((item) => ({
      id: item.codeId,
      isChecked: false,
      children: item.children?.map((ch) => ({
        id: ch.codeId,
        isChecked: false,
      })),
    }));

    if (!isNil(entrances) && entrances.length > 0) {
      newAccessList = newAccessList.map((item) => ({
        id: item.id,
        isChecked: false,
        children: item.children?.map((ch) => ({
          id: ch.id,
          isChecked: !isNil(
            entrances.find((e) => e.toString() === ch.id.toString())
          ),
        })),
      }));
    }

    dispatch(contractorReducer.actions.setAccessList(newAccessList));
  };

const resetContractorsList =
  (selectedCompanyId?: string) => (dispatch: AppDispatch) => {
    dispatch(contractorReducer.actions.setIsFetchingContractorList(false));
    dispatch(contractorReducer.actions.setContractorListHasNextPage(true));
    dispatch(contractorReducer.actions.setContractorList([]));

    // filter counters
    dispatch(contractorReducer.actions.setCompanyList([]));
    dispatch(contractorReducer.actions.setSiteList([]));
  };

const resetContractorDetail =
  (selectedCompanyId?: string) => (dispatch: AppDispatch) => {
    dispatch(contractorReducer.actions.setOtherCertifications([]));
    dispatch(contractorReducer.actions.setAccessList([]));
    dispatch(contractorReducer.actions.setNewProfilePhotoId(undefined));
  };

const resetContractors =
  (selectedCompanyId?: string) => (dispatch: AppDispatch) => {
    dispatch(resetContractorsList());
    dispatch(resetContractorDetail());
  };

const allActions = {
  fetchListOfContractors,
  fetchCompanyCounter,
  fetchSiteCounters,
  fetchGetClubData,
  prefillAccessList,
  resetContractors,
  resetContractorDetail,
  resetContractorsList,
};

export default allActions;
