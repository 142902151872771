import React, { useCallback, useEffect, useRef, useState } from "react";
import QRCode from "qrcode.react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  BORDER_RADIUS,
  FONT_FAMILY,
  FONT_WEIGHT,
  HEIGHT_OF_INPUT,
} from "../../../appTheme";
// import QrReader from 'react-qr-reader';
import {
  FormControlWrapper,
  FormSection,
  FormSectionRow,
  SpTextField,
} from "../../../common";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import contractorRedux from "../redux";
import * as yup from "yup";
import { FieldName } from "react-hook-form/dist/types/fields";
import { SetFieldValue, SetValueConfig } from "react-hook-form/dist/types/form";
import {
  ContractorDetailResponseType,
  postChangeContractorStatus,
  postCreateTempQrCode,
  postResendAuthenticationCodePhone,
  postResendAuthenticationCodeEmail,
  /* putFaceEnroll, */ TempQrCode,
} from "../redux/apiCalls";
import { isNil } from "ramda";
import { loadFile } from "../../../services/authApi";
import avatarUrl from "../../../assets/img/avatar.png";
import { AppDispatch } from "../../../appStore";
import { CameraPhoto } from "../../../services/CameraPhoto";
import { useUploadFile } from "../../../services/useUploadFile";
import moment from "moment";
import logo from "../../../assets/img/21_SP_SIGHTPAS-APP-HEADER LOGO_DARK.png";
import { useReactToPrint } from "react-to-print";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { ContractorInputs } from "./ContractorDetailSection";
import {
  beaconScannerCard,
  beaconScannerCardMessage,
  phoneNumberMessage,
  phoneNumberRegex,
} from "../../../validators";
import Alert from "@material-ui/lab/Alert";
import axios, { CancelTokenSource } from "axios";
import { useFetchData } from "../../../services/useFetchData";
import cx from "classnames";
// import { DeepPartial, LiteralToPrimitive } from "react-hook-form/dist/types/utils";
// import { ReportsInputs } from "../../reports/ReportHeader";
import ResendAuthCodeDialog from "./ResendAuthCodeDialog";
import { useAuthorization } from "../../../auth";
import { ROLES, USER_PERMISSIONS } from "../../../auth/constants";

const useStyles = makeStyles<Theme>((theme) => ({
  image: {
    width: "100%",
    borderRadius: BORDER_RADIUS.MEDIUM,
  },
  companyImageToPrint: {
    maxHeight: "0.6in",
    maxWidth: "calc(2.125in - 0.630in)",
  },
  companyImageToPrintTemp: {
    maxHeight: "0.4in",
    maxWidth: "calc(2.125in - 0.630in)",
  },
  imageToPrint: {
    maxHeight: "0.7in",
    maxWidth: "calc(2.125in - 0.630in)",
  },
  fullWidth: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  radioButtonGrid: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  inheritWidth: {
    width: "100%",
    textAlign: "center",
  },
  printablePaper: {
    width: "2.125in",
    minWidth: "2.125in",
    maxWidth: "2.125in",
    height: "3.275in",
    minHeight: "3.275in",
    maxHeight: "3.275in",
    paddingTop: "0.12in",
    paddingBottom: "0.12in",
    paddingLeft: "0.315in",
    paddingRight: "0.315in",
    overflow: "hidden",
  },
  printableFont: {
    fontSize: "0.6em",
    lineHeight: "normal",
  },
  printableHeaderFont: {
    fontSize: 10,
    fontWeight: FONT_WEIGHT.EXTRA_BOLD,
    letterSpacing: 2,
    fontFamily: FONT_FAMILY.MANROPE,
    textTransform: "uppercase",
  },
  printableDetailFont: {
    fontSize: 9,
    fontWeight: FONT_WEIGHT.REGULAR,
    fontFamily: FONT_FAMILY.MANROPE,
  },
  divider: {
    marginTop: theme.spacing(3),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  inputButton: {
    marginTop: theme.spacing(4),
    minHeight: HEIGHT_OF_INPUT,
  },
  title: {
    paddingTop: theme.spacing(1),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  logo: {
    maxHeight: "0.2in",
    maxWidth: "calc(2.125in - 0.630in)",
  },
  facialRecEnrollGrid: {
    fontFamily: "proxima-nova",
    textAlign: "center",
    marginTop: "3px",
    fontSize: 12,
  },
}));

export interface ContractorPersonalInfoInputs {
  FirstName: string;
  Company: string;
  UnionMember: boolean;
  VaccinationVerified?: boolean;
  SiteSafetyNumber: string;
  Title: string;
  PhoneNumber: string;
  LastName: string;
  SecondaryCompany: string;
  ValidFrom: string;
  ValidUntil: string;
  BeaconScannerCard: string;
  Email: string;
}

export const ContractorPersonalInfoSchema = yup.object().shape({
  FirstName: yup
    .string()
    .required("The First Name field is required")
    .max(50, "First Name exceeds 50 character limit."),
  Company: yup.string().required("Please select Client"),
  UnionMember: yup.boolean().required("Please enter Union Member"),
  SiteSafetyNumber: yup
    .string()
    .max(50, "Site safety number exceeds 50 character limit."),
  Title: yup.string().max(30, "Title exceeds 30 character limit."),
  PhoneNumber: yup
    .string()
    .required("The Phone Number field is required")
    .matches(phoneNumberRegex, phoneNumberMessage),

  LastName: yup
    .string()
    .required("The Last Name field is required")
    .max(50, "Last Name exceeds 50 character limit."),
  SecondaryCompany: yup.string().required("Please select Company"),
  BeaconScannerCard: yup
    .string()
    .matches(beaconScannerCard, beaconScannerCardMessage),

  Email: yup
    .string(),

  ValidFrom: yup
    .string()
    .typeError("Invalid Date")
    .required("The Valid From field is required"),
  ValidUntil: yup
    .string()
    .typeError("Invalid Date")
    .required("The Valid To field is required")
    .test(
      "dates-compare",
      "Valid To has to be greater than Valid From.",
      function (value) {
        return moment(value).isAfter(this.parent.ValidFrom);
      }
    ),
});

export const prefillContractorPersonalInfoInputs = (
  setValue: (
    name: FieldName<ContractorPersonalInfoInputs>,
    value: SetFieldValue<ContractorPersonalInfoInputs>,
    config?: SetValueConfig
  ) => void,
  data: ContractorDetailResponseType
) => {
  setValue("FirstName", data.firstName);
  setValue("Company", data.company);
  setValue("UnionMember", data.unionMember);
  setValue("VaccinationVerified", data.vaccinationVerified);
  setValue("SiteSafetyNumber", data.siteSafetyNumber);
  setValue("Title", data.title);
  setValue("PhoneNumber", data.phoneNumber);
  setValue("LastName", data.lastName);
  setValue("SecondaryCompany", "");
  // setValue("SecondaryCompany", data.secondaryCompany);
  setValue(
    "ValidFrom",
    (data.validFrom && moment(data.validFrom).format("YYYY-MM-DD")) || undefined
  );
  setValue(
    "ValidUntil",
    (data.validUntil && moment(data.validUntil).format("YYYY-MM-DD")) ||
      undefined
  );
  setValue("BeaconScannerCard", data.beaconScannerCard);
  setValue("Email", data.email);
};

export const resetRadioButtons = (
  setValue: (
    name: FieldName<ContractorPersonalInfoInputs>,
    value: SetFieldValue<ContractorPersonalInfoInputs>,
    config?: SetValueConfig
  ) => void
) => {
  setValue("UnionMember", false);
  setValue("VaccinationVerified", false);
  setValue("Company", "");
};

export interface PersonalInfoDetailProps {
  contractorId?: string;
  newContractorMode?: boolean;
  register: any;
  control: any;
  errors: FieldErrors<ContractorInputs>;
  data?: ContractorDetailResponseType;
  setNewProfilePhoto: (newFile: Blob | null) => void;
  alertMessage?: string;
  refreshContractorDetail: (userId: string) => void;
  viewOnly: boolean;
  canVerifyVaccination: boolean;
}

const PersonalInfoDetail: React.FC<PersonalInfoDetailProps> = ({
  contractorId,
  newContractorMode,
  setNewProfilePhoto,
  register,
  control,
  data,
  errors,
  alertMessage,
  refreshContractorDetail,
  viewOnly,
  canVerifyVaccination,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const companyList = useSelector(contractorRedux.selectors.getCompanyList);
  const secondaryCompanyList = useSelector(
    contractorRedux.selectors.getSecondaryCompanyList
  );
  // const isFetchingSecondaryCompanyList = useSelector(contractorRedux.selectors.getIsFetchingSecondaryCompanyList);
  const newProfilePhotoId = useSelector(
    contractorRedux.selectors.getNewProfilePhotoId
  );
  const secondaryCompanyName = useSelector(
    contractorRedux.selectors.getSecondaryCompanyName
  );
  const [photoUrl, setPhotoUrl] = useState<string>();
  const [companyPhotoUrl, setCompanyPhotoUrl] = useState<string>();
  const isLoadingPhoto = useRef<boolean>(false);
  const [isOpenCameraPhoto, setIsOpenCameraPhoto] = useState<boolean>(false);
  const { uploadInput, clickOnUploadFile, rawFiles } = useUploadFile({
    acceptedExtensions: "image/*",
  });
  // const facialSynchronized = !isNil(data) && moment(data?.facialRecPhotoUpdated).isSameOrAfter(data?.photoUpdated);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [facialRecEnrollError, setFacialRecEnrollError] = useState<string>();
  const [tempQrCode, setTempQrCode] = useState<TempQrCode>();
  const [tempQrCodeError, setTempQrCodeError] = useState<string>();
  const [authCodeSentNow, setAuthCodeSentNow] = useState<boolean>(false);
  const [isOpenResendAuthCodeDialog, setIsOpenResendAuthCodeDialog] =
    useState<boolean>(false);

  const { isAllowed: canResendAuthCode } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.CONTRACTORS_RESEND_AUTH_CODE]
  );

  const { isAllowed: canGetClubData } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.CONTRACTORS_GET_CLUB_DATA]
  );

  const { isAllowed: canChangeStatus } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.CONTRACTORS_CHANGE_STATUS]
  );

  const componentToPrintRef = useRef<HTMLDivElement | null>(null);
  const componentToPrintTempQrRef = useRef<HTMLDivElement | null>(null);

  const showQrCodePrint = useReactToPrint({
    content: () => {
      return componentToPrintRef.current;
    },
  });

  const handleQrCodePrint = () => {
    if (showQrCodePrint) {
      showQrCodePrint();
    }
  };

  const showTempQrCodePrint = useReactToPrint({
    content: () => {
      return componentToPrintTempQrRef.current;
    },
  });

  // you can create temp qr code only in edit mode
  const handleTempQrCodePrint = () => {
    if (tempQrCode === undefined) {
      dispatch(postCreateTempQrCode(contractorId!))
        .then(({ data }) => {
          if (data != null) {
            setTempQrCode(data);
            if (showTempQrCodePrint) {
              showTempQrCodePrint();
            }
          }
        })
        .catch(() => {
          setTempQrCodeError("Failed to generate temp QR code.");
        });
    } else {
      if (showTempQrCodePrint) {
        showTempQrCodePrint();
      }
    }
  };

  const contractorPhotoRequest = useRef<CancelTokenSource>(
    axios.CancelToken.source()
  );

  useFetchData<string>(
    // @ts-ignore
    data?.companyLogo
      ? loadFile(data?.companyLogo)
      : () => Promise.reject(""),
    true,
    undefined,
    [data?.companyLogo],
    // @ts-ignore
    (response) => setCompanyPhotoUrl(response),
    () => setCompanyPhotoUrl(undefined),
    () => setCompanyPhotoUrl(undefined)
  );

  const fetchPhoto = useCallback(
    (photoId: string) => {
      setPhotoUrl(undefined);
      if (isLoadingPhoto.current) {
        contractorPhotoRequest.current.cancel("Previous photo is canceled");
        contractorPhotoRequest.current = axios.CancelToken.source();
      }
      isLoadingPhoto.current = true;
      setPhotoUrl(undefined);
      dispatch(loadFile(photoId, contractorPhotoRequest.current.token))
        .then((url: string) => {
          setPhotoUrl(url);
        })
        .finally(() => {
          isLoadingPhoto.current = false;
        });
    },
    [dispatch]
  );

  useEffect(() => {
    // unmount
    setFacialRecEnrollError(undefined);
    setTempQrCode(undefined);
    setTempQrCodeError(undefined);
    setAuthCodeSentNow(false);
    setIsOpenResendAuthCodeDialog(false);
    if (!isNil(contractorId) && !isNil(newProfilePhotoId)) {
      fetchPhoto(newProfilePhotoId);
    } else if (
      data?.photo &&
      !isNil(contractorId) &&
      isNil(newProfilePhotoId)
    ) {
      fetchPhoto(data.photo);
    } else {
      setPhotoUrl(undefined);
    }
    if (newContractorMode) {
      setPhotoUrl(undefined);
    }
  }, [
    contractorId,
    data?.photo,
    dispatch,
    fetchPhoto,
    newContractorMode,
    newProfilePhotoId,
  ]);

  const storeBlob = useCallback(
    (blob: Blob) => {
      setPhotoUrl(URL.createObjectURL(blob));
      setNewProfilePhoto(blob);
    },
    [setNewProfilePhoto]
  );

  useEffect(() => {
    if (rawFiles && rawFiles?.length === 1) {
      storeBlob(rawFiles[0]);
    }
  }, [rawFiles, storeBlob]);

  const onSnapImage = (blob: Blob | null) => {
    if (blob) {
      storeBlob(blob);
    }
  };

  const handleOnResendAuthCodePhoneClick = () => {
    if (data?.authCodeRecentlySent || authCodeSentNow) {
      setIsOpenResendAuthCodeDialog(true);
    } else {
      resendAuthCodePhone();
    }
  };

  const handleOnResendAuthCodeEmailClick = () => {
    resendAuthCodeEmail();
  };

  const resendAuthCodePhone = (fromBackupNumber?: boolean) => {
    if (isOpenResendAuthCodeDialog) {
      setIsOpenResendAuthCodeDialog(false);
    }
    dispatch(postResendAuthenticationCodePhone(contractorId, fromBackupNumber))
      .then(() => alert("Authentication Code sent."))
      .catch(() => alert("Error: Authentication Code not sent."))
      .finally(() => {
        setAuthCodeSentNow(true);
      });
  };

  const resendAuthCodeEmail = (email?: boolean) => {
    if (isOpenResendAuthCodeDialog) {
        setIsOpenResendAuthCodeDialog(false);
    }
    dispatch(postResendAuthenticationCodeEmail(contractorId))
        .then(() => alert("Authentication Code sent."))
        .catch(() => alert("Error: Authentication Code not sent."))
        .finally(() => {
            setAuthCodeSentNow(true);
        });
  };

  // const handleScan = (data: any) => {
  //   if (data) {
  //     console.log(data);
  //     // this.setState({
  //     //   result: data
  //     // })
  //   }
  // }
  // const handleError = (err: any) => {
  //   console.error(err)
  // }

  const getDesktopHeader = () => {
    return(
      <>
        <Grid item xs={4}>
          <img
              alt="contractor"
              src={isNil(photoUrl) ? avatarUrl : photoUrl}
              className={classes.image}
          />
        </Grid>
        <Grid item xs={3}>
            {getHeaderPhotoButtons()}
        </Grid>
          {!isNil(contractorId) && (
              <Grid item xs={3}>
                  {getHeaderQrButtons(contractorId)}
              </Grid>
          )}
      </>
    )
  }

  const getMobileHeader = () => {
      return (
          <>
              <Grid item xs={12}>
                  <img
                      alt="contractor"
                      src={isNil(photoUrl) ? avatarUrl : photoUrl}
                      className={classes.image}
                  />
              </Grid>
              <Grid item xs={6}>
                  {getHeaderPhotoButtons()}
              </Grid>
              {!isNil(contractorId) && (
                  <Grid item xs={6}>
                      {getHeaderQrButtons(contractorId)}
                  </Grid>
              )}            
          </>
      )
  }

  const getHeaderPhotoButtons = () => {
      return (
          <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              className={classes.fullWidth}
          >
              <Grid item className={classes.fullWidth}>
                  <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={clickOnUploadFile}
                      disabled={viewOnly}
                  >
                      Add Photo
                  </Button>
              </Grid>
              <Grid item className={classes.fullWidth}>
                  <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => setIsOpenCameraPhoto(true)}
                      disabled={isOpenCameraPhoto || viewOnly}
                  >
                      Take Photo
                  </Button>
              </Grid>
              <Grid
                  item
                  className={cx(classes.fullWidth, classes.radioButtonGrid)}
              >
                  <Controller
                      control={control}
                      name="UnionMember"
                      defaultValue={false}
                      render={({ onChange, onBlur, value, ref }) => (
                          <FormControlLabel
                              label="Union Member"
                              control={
                                  <Checkbox
                                      color="primary"
                                      onBlur={onBlur}
                                      onChange={(event: any, newValue: boolean) =>
                                          onChange(newValue)
                                      }
                                      checked={value}
                                      inputRef={ref}
                                      disabled={viewOnly}
                                  />
                              }
                          />
                      )}
                  />
              </Grid>
              <Grid
                  item
                  className={cx(classes.fullWidth, classes.radioButtonGrid)}
              >
                  <Controller
                      control={control}
                      name="VaccinationVerified"
                      defaultValue={false}
                      render={({ onChange, onBlur, value, ref }) => (
                          <FormControlLabel
                              label="Vaccination Verified"
                              control={
                                  <Checkbox
                                      color="primary"
                                      onBlur={onBlur}
                                      onChange={(event: any, newValue: boolean) =>
                                          onChange(newValue)
                                      }
                                      checked={value}
                                      inputRef={ref}
                                      disabled={!canVerifyVaccination}
                                  />
                              }
                          />
                      )}
                  />
              </Grid>
          </Grid>
      )
  }

  const getHeaderQrButtons = (contractorId:any) => {
      return (
          <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              className={classes.fullWidth}
          >
              {!isNil(data?.clubMemberId) && (
                  <Grid item className={classes.fullWidth}>
                      <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={!canGetClubData}
                          onClick={() =>
                              dispatch(
                                  contractorRedux.dispatchActions.fetchGetClubData(
                                      contractorId
                                  )
                              )
                          }
                      >
                          Get club data
                      </Button>
                  </Grid>
              )}
              <Grid item className={classes.fullWidth}>
                  <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={viewOnly}
                      onClick={handleTempQrCodePrint}
                  >
                      Print Temp QR code
                  </Button>
              </Grid>
              <Grid item className={classes.fullWidth}>
                  <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={viewOnly}
                      onClick={handleQrCodePrint}
                  >
                      Print QR code
                  </Button>
                  <div style={{ display: "none" }}>
                      <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="space-between"
                          ref={componentToPrintRef}
                          className={classes.printablePaper}
                          wrap="nowrap"
                      >
                          {(companyPhotoUrl && companyPhotoUrl.length > 0) && (
                              <Grid item className={classes.inheritWidth}>
                                  <img
                                      alt="company"
                                      src={companyPhotoUrl}
                                      className={classes.companyImageToPrint}
                                  />
                              </Grid>
                          )}
                          <Grid item className={classes.inheritWidth}>
                              <img
                                  alt="contractor"
                                  src={isNil(photoUrl) ? avatarUrl : photoUrl}
                                  className={classes.imageToPrint}
                              />
                          </Grid>
                          <Grid item>
                              <Typography
                                  align="center"
                                  className={`${classes.printableFont} ${classes.printableHeaderFont}`}
                              >
                                  {`${data?.firstName} ${data?.lastName}`}
                              </Typography>
                          </Grid>
                          {secondaryCompanyName && secondaryCompanyName !== "" && (
                              <Grid item>
                                  <Typography
                                      align="center"
                                      className={`${classes.printableFont} ${classes.printableHeaderFont}`}
                                  >
                                      {secondaryCompanyName}
                                  </Typography>
                              </Grid>
                          )}
                          {data?.siteSafetyNumber && (
                              <Grid item>
                                  <Typography
                                      align="center"
                                      className={`${classes.printableFont} ${classes.printableDetailFont}`}
                                  >
                                      {`SSN: `}
                                      {data?.siteSafetyNumber}
                                  </Typography>
                              </Grid>
                          )}
                          <Grid item>
                              {contractorId && (
                                  <QRCode value={contractorId} size={85} renderAs="svg" />
                              )}
                          </Grid>
                          <Grid item className={classes.inheritWidth}>
                              <img
                                  src={logo}
                                  alt="SightPas-logo"
                                  className={classes.logo}
                              />
                          </Grid>
                      </Grid>
                  </div>
                  <div style={{ display: "none" }}>
                      <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="space-between"
                          ref={componentToPrintTempQrRef}
                          className={classes.printablePaper}
                          wrap="nowrap"
                      >
                          {(companyPhotoUrl && companyPhotoUrl.length > 0) && (
                              <Grid item className={classes.inheritWidth}>
                                  <img
                                      alt="company"
                                      src={companyPhotoUrl}
                                      className={classes.companyImageToPrintTemp}
                                  />
                              </Grid>
                          )}
                          <Grid item className={classes.inheritWidth}>
                              <img
                                  alt="contractor"
                                  src={isNil(photoUrl) ? avatarUrl : photoUrl}
                                  className={classes.imageToPrint}
                              />
                          </Grid>
                          <Grid item>
                              <Typography
                                  align="center"
                                  className={`${classes.printableFont} ${classes.printableHeaderFont}`}
                              >
                                  {`${data?.firstName} ${data?.lastName}`}
                              </Typography>
                          </Grid>
                          {secondaryCompanyName && secondaryCompanyName !== "" && (
                              <Grid item>
                                  <Typography
                                      align="center"
                                      className={`${classes.printableFont} ${classes.printableHeaderFont}`}
                                  >
                                      {secondaryCompanyName}
                                  </Typography>
                              </Grid>
                          )}
                          {data?.siteSafetyNumber && (
                              <Grid item>
                                  <Typography
                                      align="center"
                                      className={`${classes.printableFont} ${classes.printableDetailFont}`}
                                  >
                                      {`SSN: `}
                                      {data?.siteSafetyNumber}
                                  </Typography>
                              </Grid>
                          )}
                          {contractorId && tempQrCode !== undefined && (
                              <Grid item>
                                  <QRCode
                                      value={tempQrCode.qrCode}
                                      size={75}
                                      renderAs="svg"
                                  />
                              </Grid>
                          )}
                          <Grid item>
                              <Typography
                                  align="center"
                                  className={`${classes.printableFont} ${classes.printableDetailFont}`}
                              >
                                  {"QR Code valid to:"}
                              </Typography>
                              <Typography
                                  align="center"
                                  className={`${classes.printableFont} ${classes.printableDetailFont}`}
                              >
                                  {`${tempQrCode?.validTo}`}
                              </Typography>
                          </Grid>
                          <Grid item className={classes.inheritWidth}>
                              <img
                                  src={logo}
                                  alt="SightPas-logo"
                                  className={classes.logo}
                              />
                          </Grid>
                      </Grid>
                  </div>
              </Grid>
              <Grid item>
                  <QRCode value={contractorId} />
              </Grid>
          </Grid>
      )
  }

  return (
    <div>
      <Box mt="20px" mb="20px">
        <Divider />
      </Box>
      <Grid container direction="row" alignItems="flex-start">
        {window.innerWidth > 600 ? getDesktopHeader() : getMobileHeader()}
        <Grid item xs={12}>
          <Box mt="12px" />
          <FormSectionRow>
            <FormControlWrapper
              title="Company"
              input={
                <Controller
                  control={control}
                  name="SecondaryCompany"
                  defaultValue={""}
                  as={
                    <SpTextField
                      select
                      placeholder="Select Company"
                      errors={errors}
                      disabled={viewOnly}
                    >
                      {(!secondaryCompanyList ||
                        secondaryCompanyList.length === 0) && (
                        <MenuItem value={""}>No data</MenuItem>
                      )}
                      {secondaryCompanyList &&
                        secondaryCompanyList.map((secondaryCompany) => (
                          <MenuItem
                            key={secondaryCompany.codeId}
                            value={secondaryCompany.codeId}
                          >
                            {secondaryCompany.displayName}
                          </MenuItem>
                        ))}
                    </SpTextField>
                  }
                />
              }
            />
            <FormControlWrapper
              title="Site safety number"
              input={
                <SpTextField
                  name="SiteSafetyNumber"
                  inputRef={register}
                  errors={errors}
                  fullWidth
                  disabled={viewOnly}
                />
              }
            />
          </FormSectionRow>
        </Grid>
        <Grid item xs={12}>
          {(alertMessage || tempQrCodeError) && (
            <Alert variant="outlined" severity="error">
              {alertMessage ? alertMessage : tempQrCodeError}
            </Alert>
          )}
        </Grid>
      </Grid>
      <FormSection title="About">
        <FormSectionRow>
          <FormControlWrapper
            title="Title"
            input={
              <SpTextField
                name="Title"
                inputRef={register}
                fullWidth
                errors={errors}
                disabled={viewOnly}
              />
            }
          />
        </FormSectionRow>
        <FormSectionRow>
          <FormControlWrapper
            title="First Name"
            input={
              <SpTextField
                name="FirstName"
                inputRef={register}
                fullWidth
                errors={errors}
                disabled={viewOnly}
              />
            }
          />
          <FormControlWrapper
            title="Last Name"
            input={
              <SpTextField
                name="LastName"
                inputRef={register}
                fullWidth
                errors={errors}
                disabled={viewOnly}
              />
            }
          />
        </FormSectionRow>
        <FormSectionRow>
          <FormControlWrapper
            title="Client"
            input={
              <Controller
                control={control}
                name="Company"
                defaultValue={""}
                as={
                  <SpTextField
                    select
                    placeholder="Select Client"
                    errors={errors}
                    disabled={viewOnly}
                  >
                    {companyList.map((company) => (
                      <MenuItem key={company.codeId} value={company.codeId}>
                        {company.displayName}
                      </MenuItem>
                    ))}
                  </SpTextField>
                }
              />
            }
          />
          <FormControlWrapper
            title="Beacon Card"
            input={
              <SpTextField
                name="BeaconScannerCard"
                inputRef={register}
                fullWidth
                errors={errors}
                disabled={viewOnly}
              />
            }
          />
              </FormSectionRow>

        <FormSectionRow>
            <FormControlWrapper
                title="Email"
                input={
                    <SpTextField
                        name="Email"
                        inputRef={register}
                        fullWidth
                        errors={errors}
                        disabled={viewOnly}
                    />
                }
            />
            <FormControlWrapper
                title="Phone number"
                input={
                    <SpTextField
                        name="PhoneNumber"
                        inputRef={register}
                        fullWidth
                        errors={errors}
                        disabled={viewOnly}
                    />
                }
            />
        </FormSectionRow>
        <FormSectionRow>
          {!isNil(contractorId) && (
            <Grid item xs={5}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.inputButton}
                onClick={handleOnResendAuthCodeEmailClick}
                disabled={!canResendAuthCode}
              >
                Resend Authentication code (Email)
              </Button>
            </Grid>
          )}

          {!isNil(contractorId) && (
              <Grid item xs={5}>
                  <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.inputButton}
                      onClick={handleOnResendAuthCodePhoneClick}
                      disabled={!canResendAuthCode}
                  >
                      Resend Authentication code (Phone)
                  </Button>
              </Grid>
          )}
        </FormSectionRow>
      </FormSection>
      <FormSection title="Validity" inline>
        <FormControlWrapper
          title="Valid from"
          input={
            <SpTextField
              name="ValidFrom"
              inputRef={register}
              fullWidth
              type="date"
              errors={errors}
              disabled={viewOnly}
            />
          }
        />
        <FormControlWrapper
          title="Valid to"
          input={
            <SpTextField
              name="ValidUntil"
              inputRef={register}
              fullWidth
              type="date"
              errors={errors}
              disabled={viewOnly}
            />
          }
        />
      </FormSection>

      {contractorId && data?.status !== undefined && canChangeStatus ? (
        <FormSection title="Actions">
          <Button
            variant="contained"
            onClick={() =>
              dispatch(
                postChangeContractorStatus({
                  id: contractorId,
                  status: data.status === "Active" ? "Inactive" : "Active",
                })
              ).then(() => {
                refreshContractorDetail(data.userId);
              })
            }
          >
            {data.status === "Active"
              ? "Deactivate contractor"
              : "Activate contractor"}
          </Button>
        </FormSection>
      ) : null}

      {isOpenCameraPhoto && (
        <CameraPhoto
          onSaveImage={onSnapImage}
          onCloseDialog={() => setIsOpenCameraPhoto(false)}
        />
      )}
      {isOpenResendAuthCodeDialog && (
        <ResendAuthCodeDialog
          onCancel={() => setIsOpenResendAuthCodeDialog(false)}
          open={isOpenResendAuthCodeDialog}
          onResend={resendAuthCodePhone}
        />
      )}
      {uploadInput}
    </div>
  );
};

export default PersonalInfoDetail;
