import React, { FC, memo, useCallback, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid, MenuItem, Typography } from "@material-ui/core";
import { SpTextField } from "../../../../common";
import { COLORS, SCROLL_PANEL_WIDTH } from "../../../../appTheme";
import { useDispatch, useSelector } from "react-redux";
import siteRedux from "../../redux";
import { AppDispatch } from "../../../../appStore";
import { EntranceDetail } from "./index";
import { isNil } from "ramda";
import { SiteDetailModel } from "../../../forms/redux/apiCalls";

const useStyles = makeStyles<Theme>((theme) => ({
  scrollPanel: {
    width: SCROLL_PANEL_WIDTH,
  },
  scrollPanelViewOnly: {
    width: SCROLL_PANEL_WIDTH,
  },
  entranceSelector: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    alignItems: "center",
    gridGap: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

type EntrancesTabProps = {
  siteDetail?: SiteDetailModel;
};

const EntrancesTab: FC<EntrancesTabProps> = ({ siteDetail }) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const entranceList = useSelector(siteRedux.selectors.getEntranceList);
  const selectedSiteId = useSelector(siteRedux.selectors.getSelectedSiteId);
  const selectedEntranceId = useSelector(
    siteRedux.selectors.getSelectedEntranceId
  );
  const isNewEntranceMode = useSelector(
    siteRedux.selectors.getIsNewEntranceMode
  );

  const refetchSiteEntrancesList = useCallback(() => {
    if (selectedSiteId) {
      dispatch(
        siteRedux.dispatchActions.fetchSiteAllEntranceList(selectedSiteId)
      );
    }
  }, [dispatch, selectedSiteId]);

  useEffect(() => {
    if (selectedSiteId) {
      refetchSiteEntrancesList();
    }
  }, [refetchSiteEntrancesList, selectedSiteId]);

  useEffect(() => {
    return () => {
      dispatch(siteRedux.dispatchActions.resetEntrancesTab());
    };
  }, [dispatch]);

  const changeEntrance = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      dispatch(siteRedux.actions.setSelectedEntranceId(e.target.value));
      dispatch(siteRedux.actions.setIsNewEntranceMode(false));
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      flexGrow={1}
      height="100%"
      bgcolor={COLORS.WHITE}
    >
      {isNewEntranceMode ? null : (
        <Grid className={classes.entranceSelector} container>
          <Grid item>
            <Typography variant={"h4"}>Selected Entrance</Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <SpTextField
              select
              onChange={changeEntrance}
              value={selectedEntranceId}
              defaultValue=""
            >
              {entranceList.map((entrance) => (
                <MenuItem key={entrance.codeId} value={entrance.codeId}>
                  {entrance.displayName}
                </MenuItem>
              ))}
            </SpTextField>
          </Grid>
        </Grid>
      )}
      {(isNewEntranceMode || !isNil(selectedEntranceId)) && (
        <EntranceDetail
          siteDetail={siteDetail}
          refetchSiteEntrancesList={refetchSiteEntrancesList}
        />
      )}
    </Box>
  );
};

export default memo(EntrancesTab);
