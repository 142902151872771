import { AppDispatch } from "../../../appStore";
import { CounterResponseModel } from "../../../services/apiServiceTypes";
import eventLogRedux from "./index";
import {
  BatteryLevel,
  EntranceEventThumbnailViewModel,
  fetchHelpRequestData,
  getBatteryLevelsInfo,
  getEntranceInfo,
  getLastBeaconScannerInfo,
  getLastEventsList,
  LastEventsPaginatedModel,
} from "./apiCalls";
import eventLogReducer from "./index";
import { isNil } from "ramda";
import {
  ContractorListRequestType,
  getContractorList,
} from "../../contractors/redux/apiCalls";
import { CancelToken } from "axios";
import { batch } from "react-redux";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";
import { isDirectionalMotionSensor } from "../helpers";

const selectFirstEntranceOfFirstSite =
  (accessList: CounterResponseModel, panelId: number) =>
  (dispatch: AppDispatch) => {
    if (accessList) {
      let newAccessList = accessList.map((item, parentIndex) => ({
        id: item.codeId,
        isChecked: false,
        children: item.children?.map((ch, index) => ({
          id: ch.codeId,
          isChecked: parentIndex === 0 && index === 0,
        })),
      }));
      batch(() => {
        dispatch(
          eventLogRedux.actions.setSelectedSites({
            selectedSites: newAccessList,
            panelId: panelId,
          })
        );
        dispatch(eventLogRedux.actions.addPanel({ panelId: panelId }));
      });
    }
  };

// Sets selected entrance in detailed sites list (counter)
const setSelectedEntrance =
  (accessList: Array<TreeStructureData>, panelId: number, entranceId: string) =>
  (dispatch: AppDispatch) => {
    let newAccessList = accessList.map((item, parentIndex) => ({
      id: item.id,
      isChecked: false,
      children: item.children?.map((ch, index) => ({
        id: ch.id,
        // eslint-disable-next-line eqeqeq
        isChecked: ch.id == entranceId,
      })),
    }));
    batch(() => {
      dispatch(
        eventLogRedux.actions.setSelectedSites({
          selectedSites: newAccessList,
          panelId: panelId,
        })
      );
    });
  };

const updateBeaconScannerTime = (timeValue: string) => {
  let scanTime = "";
  if (timeValue !== "1/1/0001 1:00 AM EST") {
    scanTime = "Scan time : " + timeValue;
  }
  return scanTime;
};

const updateBeaconScannerValue = (scanValue: string | number) => {
  let scanValueLocator = "";
  if (scanValue !== 0) {
    scanValueLocator = "Scan value : " + scanValue;
  }
  return scanValueLocator;
};

const parseBatteryLevel = (batteryLevel: BatteryLevel) => {
  switch (batteryLevel) {
    case "Unknown":
      return "Unknown";
    case "Low":
      return "Low";
    case "MediumLow":
      return "Medium Low";
    case "MediumHigh":
      return "Medium High";
    case "High":
      return "High";
  }
};

const onScannerEventFunc =
  (entranceId: string, panelId: number) => (dispatch: AppDispatch) => {
    dispatch(
      eventLogReducer.actions.setBeaconScannerLastScanTime({
        lastScanTime: undefined,
        panelId,
      })
    );
    dispatch(
      eventLogReducer.actions.setBeaconScannerLastScanValue({
        lastScanValue: undefined,
        panelId,
      })
    );

    dispatch(getLastBeaconScannerInfo(entranceId))
      .then(({ data }) => {
        if (!isNil(data)) {
          dispatch(
            eventLogReducer.actions.setBeaconScannerLastScanTime({
              lastScanTime: updateBeaconScannerTime(
                data.beaconScannerLastScanTime
              ),
              panelId,
            })
          );
          dispatch(
            eventLogReducer.actions.setBeaconScannerLastScanValue({
              lastScanValue: updateBeaconScannerValue(
                data.beaconScannerLastScanValue
              ),
              panelId,
            })
          );
        }
      })
      .catch(() => {
        dispatch(
          eventLogReducer.actions.setBeaconScannerLastScanTime({
            lastScanTime: undefined,
            panelId,
          })
        );
        dispatch(
          eventLogReducer.actions.setBeaconScannerLastScanValue({
            lastScanValue: undefined,
            panelId,
          })
        );
      });
  };

const onBatteryLevelsFunc =
  (eventId: string, panelId: number) => (dispatch: AppDispatch) => {
    dispatch(
      eventLogReducer.actions.setInnerBeaconBatteryLevel({
        batteryLevel: "Unknown",
        panelId,
      })
    );
    dispatch(
      eventLogReducer.actions.setOuterBeaconBatteryLevel({
        batteryLevel: "Unknown",
        panelId,
      })
    );

    dispatch(getBatteryLevelsInfo(eventId))
      .then(({ data }) => {
        if (!isNil(data)) {
          dispatch(
            eventLogReducer.actions.setInnerBeaconBatteryLevel({
              batteryLevel: data[0],
              panelId,
            })
          );
          dispatch(
            eventLogReducer.actions.setOuterBeaconBatteryLevel({
              batteryLevel: data[1],
              panelId,
            })
          );
        }
      })
      .catch(() => {
        dispatch(
          eventLogReducer.actions.setInnerBeaconBatteryLevel({
            batteryLevel: "Unknown",
            panelId,
          })
        );
        dispatch(
          eventLogReducer.actions.setOuterBeaconBatteryLevel({
            batteryLevel: "Unknown",
            panelId,
          })
        );
      });
  };

const fetchSelectedEntranceInfo =
  (entranceId: string, panelId: number) => (dispatch: AppDispatch) => {
    dispatch(
      eventLogReducer.actions.setSelectedEntranceInfo({
        entranceInfo: undefined,
        panelId: panelId,
      })
    );

    dispatch(getEntranceInfo(entranceId))
      .then(({ data }) => {
        if (!isNil(data)) {
          dispatch(
            eventLogReducer.actions.setSelectedEntranceInfo({
              entranceInfo: data,
              panelId: panelId,
            })
          );
        }
      })
      .catch(() => {
        dispatch(
          eventLogReducer.actions.setSelectedEntranceInfo({
            entranceInfo: undefined,
            panelId: panelId,
          })
        );
      });
  };

const onHelpRequestFunc =
  (
    entranceId: string, // TODO do something with entranceId
    eventId: string,
    playAlarm: boolean
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(eventLogReducer.actions.setHelpRequest(undefined));

    dispatch(fetchHelpRequestData(eventId))
      .then(({ data }) => {
        if (!isNil(data)) {
          dispatch(eventLogReducer.actions.setHelpRequest({ data, playAlarm }));
        }
      })
      .catch(() => {
        dispatch(eventLogReducer.actions.setHelpRequest(undefined));
      });
  };

const fetchListOfEvents =
  (
    body: LastEventsPaginatedModel,
    isScrollMode: boolean = false,
    isEventMode: boolean = false,
    panelId: number,
    forceNoUpdate: boolean = false
  ) =>
  (dispatch: AppDispatch) => {
    //console.log(`fetchListOfEvents ${isScrollMode} ${isEventMode}`);
    batch(() => {
      dispatch(
        eventLogReducer.actions.setIsFetchingEventList({
          isFetchingEventList: true,
          panelId: panelId,
        })
      );
      if (!isScrollMode && !isEventMode) {
        //console.log("Clear EventLog");
        dispatch(
          eventLogReducer.actions.setEventList({
            eventList: [],
            panelId: panelId,
          })
        );
      }
    });

    return dispatch(getLastEventsList(body))
      .then(({ data }) => {
        if ((isScrollMode && !isEventMode) || forceNoUpdate) {
          dispatch(
            eventLogReducer.actions.appendToEventList({
              eventList: data.items,
              panelId: panelId,
            })
          );
        } else {
          dispatch(
            eventLogReducer.actions.setEventList({
              eventList: data.items,
              panelId: panelId,
            })
          );
        }
        dispatch(
          eventLogReducer.actions.setEventListHasNextPage({
            eventListHasNextPage: data.hasNextPage,
            panelId: panelId,
          })
        );
      })
      .catch(() => {
        dispatch(
          eventLogReducer.actions.setEventListHasNextPage({
            eventListHasNextPage: true,
            panelId: panelId,
          })
        );
      })
      .finally(() => {
        dispatch(
          eventLogReducer.actions.setIsFetchingEventList({
            isFetchingEventList: false,
            panelId: panelId,
          })
        );
      });
  };

const onEntranceEventFunc =
  (
    entranceId: string, // TODO do something with entranceId
    eventId: string,
    playAlarm: boolean,
    entranceEvent: EntranceEventThumbnailViewModel,
    panelId: number,
    innerBatteryLevel?: BatteryLevel,
    outerBatteryLevel?: BatteryLevel
  ) =>
  (dispatch: AppDispatch) => {
    if (innerBatteryLevel) {
      dispatch(
        eventLogReducer.actions.setInnerBeaconBatteryLevel({
          batteryLevel: innerBatteryLevel,
          panelId,
        })
      );
    }
    if (outerBatteryLevel) {
      dispatch(
        eventLogReducer.actions.setOuterBeaconBatteryLevel({
          batteryLevel: outerBatteryLevel,
          panelId,
        })
      );
    }

    dispatch(
      eventLogReducer.actions.setUnauthorizedAccessEvent({
        unauthorizedAccessEvent: undefined,
        panelId: panelId,
      })
    );
    if (
      !isNil(entranceEvent) &&
      !entranceEvent.allowed &&
      entranceEvent.eventType !== "HelpCanceled" &&
      entranceEvent.eventType !== "HelpConfirmed" &&
      entranceEvent.eventType !== "HelpRequested" &&
      !(
        isDirectionalMotionSensor(entranceEvent.eventType) &&
        !entranceEvent.entering
      )
    ) {
      dispatch(
        eventLogReducer.actions.setUnauthorizedAccessEvent({
          unauthorizedAccessEvent: {
            data: entranceEvent,
            playAlarm,
          },
          panelId: panelId,
        })
      );
    }
  };

const fetchListOfContractorsInManualCheckInOut =
  (
    body: ContractorListRequestType,
    isScrollMode: boolean = false,
    cancelToken: CancelToken,
    panelId: number
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(
      eventLogReducer.actions.setIsFetchingContractorList({
        isFetchingContractorList: true,
        panelId: panelId,
      })
    );
    return dispatch(getContractorList(body, cancelToken))
      .then(({ data }) => {
        if (isScrollMode) {
          dispatch(
            eventLogReducer.actions.appendToContractorList({
              contractorList: data.items,
              panelId: panelId,
            })
          );
        } else {
          dispatch(
            eventLogReducer.actions.setContractorList({
              contractorList: data.items,
              panelId: panelId,
            })
          );
        }
        dispatch(
          eventLogReducer.actions.setContractorListHasNextPage({
            contractorListHasNextPage: data.hasNextPage,
            panelId: panelId,
          })
        );
      })
      .catch(() => {
        dispatch(
          eventLogReducer.actions.setContractorListHasNextPage({
            contractorListHasNextPage: true,
            panelId: panelId,
          })
        );
      })
      .finally(() => {
        dispatch(
          eventLogReducer.actions.setIsFetchingContractorList({
            isFetchingContractorList: false,
            panelId: panelId,
          })
        );
      });
  };

const resetManualCheckInOutDialog =
  (panelId: number) => (dispatch: AppDispatch) => {
    dispatch(
      eventLogReducer.actions.appendToContractorList({
        contractorList: [],
        panelId: panelId,
      })
    );
    dispatch(
      eventLogReducer.actions.setContractorListHasNextPage({
        contractorListHasNextPage: true,
        panelId: panelId,
      })
    );
    dispatch(
      eventLogReducer.actions.setIsFetchingContractorList({
        isFetchingContractorList: false,
        panelId: panelId,
      })
    );
  };

const allActions = {
  selectFirstEntranceOfFirstSite,
  onScannerEventFunc,
  onBatteryLevelsFunc,
  fetchSelectedEntranceInfo,
  onHelpRequestFunc,
  fetchListOfEvents,
  parseBatteryLevel,
  onEntranceEventFunc,
  fetchListOfContractorsInManualCheckInOut,
  resetManualCheckInOutDialog,
  setSelectedEntrance,
};

export default allActions;
