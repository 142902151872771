import { EventSource, EventType } from "./redux/apiCalls";
import { COLORS } from "../../appTheme";

export const printEventType = (eventType: EventType) => {
  switch (eventType) {
    case "HelpCanceled":
      return "Help Request Canceled";
    case "HelpConfirmed":
      return "Help Request Confirmed";
    case "HelpRequested":
      return "Help Requested";
    case "Enter":
      return "Enter event";
    case "Leave":
      return "Leave event";
    case "ManualEnter":
      return "Manual Enter event";
    case "ManualLeave":
      return "Manual Leave event";
  }
};

export const printEventSource = (eventSource: EventSource) => {
  switch (eventSource) {
    case "QRReader":
      return "QR Reader";
    case "BeaconReader":
      return "Beacon";
    case "BeaconScanner":
      return "Beacon Badge";
  }
};

export const getBackgroundColorOfHelpRequest = (type?: EventType) => ({
  backgroundColor:
    type === "HelpRequested"
      ? COLORS.ORANGE
      : type === "HelpConfirmed"
      ? COLORS.RED
      : type === "HelpCanceled"
      ? COLORS.GREEN
      : COLORS.WHITE,
});

export const isLeavingType = (type: EventType) =>
  type === "Leave" || type === "ManualLeave" || type === "MotionLeave";

export const isEnteringType = (type: EventType) =>
  type === "Enter" || type === "ManualEnter" || type === "MotionEnter";

export const isLeavingEnteringType = (type: EventType) =>
  type === "Enter" ||
  type === "Leave" ||
  type === "ManualEnter" ||
  type === "ManualLeave" ||
  type === "MotionEnter" ||
  type === "MotionLeave";

export const isHelpType = (type: EventType) =>
  type === "HelpRequested" ||
  type === "HelpCanceled" ||
  type === "HelpConfirmed";

export const isDirectionalMotionSensor = (type: EventType) =>
  type === "MotionEnter" || type === "MotionLeave";
