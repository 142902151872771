import authSelectors from "../auth/redux/selectors";
import axios, { CancelToken, Method } from "axios";
import { AppDispatch } from "../appStore";
import { APP_SETTINGS } from "../AppSettings";

const getAuthorization = (accessToken: string | null) => ({
  Authorization: `Bearer ${accessToken}`,
});

const getAuthorization2 = (accessToken: string | null) => ({
  Authorization: `Bearer yCrqdGC9VoYZMmdqOkI2KQFhAm94Aj`,
});

export const getAuthApi =
  <T = any>(url: string, cancelToken?: CancelToken) =>
  (dispatch: AppDispatch, getState: any) => {
    const accessToken = authSelectors.getUserAccessToken(getState());

    return axios.get<T>(url, {
      headers: { ...getAuthorization(accessToken) },
      cancelToken,
    });
  };

export const getAuthApi2 =
  <T = any>(url: string, cancelToken?: CancelToken) =>
  (dispatch: AppDispatch, getState: any) => {
    const accessToken = authSelectors.getUserAccessToken(getState());

    return axios.get<T>(url, {
      headers: { ...getAuthorization2(accessToken) },
      cancelToken,
    });
  };

export const postAuthApi =
  <T = any>(
    url: string,
    body?: any,
    nextHeaders?: any,
    cancelToken?: CancelToken
  ) =>
  (dispatch: AppDispatch, getState: any) => {
    const accessToken = authSelectors.getUserAccessToken(getState());

    return axios.post<T>(url, body, {
      headers: { ...getAuthorization(accessToken), ...nextHeaders },
      cancelToken,
    });
  };

export const putAuthApi =
  <T = any>(
    url: string,
    body?: any,
    nextHeaders?: any,
    cancelToken?: CancelToken
  ) =>
  (dispatch: AppDispatch, getState: any) => {
    const accessToken = authSelectors.getUserAccessToken(getState());

    return axios.put<T>(url, body, {
      headers: { ...getAuthorization(accessToken), ...nextHeaders },
      cancelToken,
    });
  };

export const downloadFile =
  (
    fileName: string,
    fileId?: number,
    url?: string,
    method: Method = "GET",
    body?: any,
    cancelToken?: CancelToken
  ) =>
  (dispatch: AppDispatch, getState: any) => {
    const accessToken = authSelectors.getUserAccessToken(getState());
    return axios(
      url ? url : `${APP_SETTINGS.BACKEND_BASE_URL}/FileApi/file/${fileId}`,
      {
        method,
        data: body,
        headers: {
          ...getAuthorization(accessToken),
        },
        responseType: "blob",
        cancelToken,
      }
    ).then((response) => {
      const type = response.headers["content-type"];
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: type })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

export const loadFile =
  (fileId: string, cancelToken?: CancelToken) =>
  (dispatch: AppDispatch, getState: any) => {
    const accessToken = authSelectors.getUserAccessToken(getState());
    return axios
      .get(`${APP_SETTINGS.BACKEND_BASE_URL}/FileApi/file/${fileId}`, {
        headers: {
          ...getAuthorization(accessToken),
        },
        responseType: "blob",
        cancelToken,
      })
      .then((response) => {
        const type = response.headers["content-type"];

        return window.URL.createObjectURL(
          new Blob([response.data], { type: type })
        );
      });
  };

export const loadThumbnail =
  (
    fileId: string,
    width: number = 160,
    height: number = 120,
    cancelToken?: CancelToken
  ) =>
  (dispatch: AppDispatch, getState: any) => {
    const accessToken = authSelectors.getUserAccessToken(getState());
    return axios
      .get(
        `${APP_SETTINGS.BACKEND_BASE_URL}/FileApi/thumbnail/${fileId}?w=${width}&h=${height}`,
        {
          headers: {
            ...getAuthorization(accessToken),
          },
          responseType: "blob",
          cancelToken,
        }
      )
      .then((response) => {
        const type = response.headers["content-type"];

        return window.URL.createObjectURL(
          new Blob([response.data], { type: type })
        );
      });
  };
