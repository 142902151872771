import { CounterResponseItem } from "../../../services/apiServiceTypes";
import { OtherCertification } from "./apiCalls";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";

export interface State {
  contractorList: Array<CounterResponseItem>;
  contractorListHasNextPage: boolean;
  isFetchingContractorList: boolean;

  // filter counters
  companyList: Array<CounterResponseItem>;
  siteList: Array<CounterResponseItem>;

  // in contractor detail
  otherCertifications: OtherCertification[];
  accessList: Array<TreeStructureData>;
  newProfilePhotoId?: string;
  secondaryCompanyName?: string;

  // secondary companies list
  secondaryCompanyList: Array<CounterResponseItem>;
  isFetchingSecondaryCompanyList: boolean;
}

export const initialState: State = {
  contractorList: [],
  contractorListHasNextPage: true,
  isFetchingContractorList: false,
  companyList: [],
  siteList: [],
  otherCertifications: [],
  accessList: [],
  newProfilePhotoId: undefined,
  secondaryCompanyName: undefined,
  secondaryCompanyList: [],
  isFetchingSecondaryCompanyList: false,
};

export type EditOtherCertification = {
  index: number;
  data: OtherCertification;
};
