import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { ReactNode } from "react";

export interface FormSectionProps {
  title: string;
  children: ReactNode;
  inline?: boolean;
  rightElement?: ReactNode;
}

const FormSection: React.FC<FormSectionProps> = ({
  title,
  children,
  inline = false,
  rightElement,
}) => {
  return (
    <Box mt="20px">
      <Divider />
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={rightElement ? "space-between" : "flex-start"}
          alignItems="center"
          width="100%"
          my="20px"
        >
          <Typography variant="h3">{title}</Typography>
          {rightElement}
        </Box>
      </Grid>
      <Box
        display={inline ? "flex" : "block"}
        flexDirection="row"
        gridGap={"10px"}
        alignItems="center"
        width="100%"
        flexBasis="50%"
      >
        {children}
      </Box>
    </Box>
  );
};

export default FormSection;
