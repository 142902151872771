import { History } from "history";
import {
  checkJwtToken,
  forgotPasswordCall,
  loginCall,
  reauthenticateCall,
  resetPasswordCall,
  ResetPasswordViewModel,
} from "./apiCalls";
import authReducer from "./reducer";
import authSelectors from "./selectors";
import commonReducer from "../../common/redux";
import { AppDispatch } from "../../appStore";
import { MAIN_PATH, MAIN_PAGES } from "../../router/pathConstants";
import {
  accessTokenFromLocalStorage,
  AccessTokenType,
  LOCAL_STORAGE_TOKENS,
  refreshTokenFromLocalStorage,
} from "../../services/localStorageService";
import { APP_SETTINGS } from "../../AppSettings";
import { batch } from "react-redux";
import jwt_decode from "jwt-decode";
import { USER_PERMISSIONS } from "../constants";

const forgotPassword =
  (Email: string, history: History) => (dispatch: AppDispatch) => {
    dispatch(authReducer.actions.setIsLoading(true));

    return forgotPasswordCall({
      Email: Email,
      ResetPasswordUrl: `${APP_SETTINGS.FRONTEND_BASE_URL}/${MAIN_PATH.RESET_PASSWORD}`,
    })
      .then(() => {
        history.push(`${MAIN_PATH.LOGIN}`);
      })
      .catch(() => {
        dispatch(
          commonReducer.actions.setSnackBarMessage(
            "Something went wrong with resetting of your password!"
          )
        );
      })
      .finally(() => {
        dispatch(authReducer.actions.setIsLoading(false));
      });
  };

const resetAuthState = () => (dispatch: AppDispatch) => {
  localStorage.removeItem(LOCAL_STORAGE_TOKENS.ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_TOKENS.REFRESH_TOKEN);
  batch(() => {
    dispatch(authReducer.actions.setUserAccessToken(null));
    dispatch(authReducer.actions.setUserRefreshToken(null));
  });
};

const logout = (history: History) => (dispatch: AppDispatch) => {
  history.replace(`/${MAIN_PATH.LOGIN}`);
  batch(() => {
    dispatch(resetAuthState());
    dispatch(
      commonReducer.actions.setSnackBarMessage("You were logout from the app!")
    );
  });
};

const login =
  (userName: string, password: string, history: History) =>
  (dispatch: AppDispatch) => {
    batch(() => {
      dispatch(resetAuthState());
      dispatch(authReducer.actions.setIsLoading(true));
    });

    return loginCall({ Username: userName, Password: password })
      .then(({ data }) => {
        localStorage.setItem(
          LOCAL_STORAGE_TOKENS.ACCESS_TOKEN,
          data.accessToken
        );
        localStorage.setItem(
          LOCAL_STORAGE_TOKENS.REFRESH_TOKEN,
          data.refreshToken
        );
        batch(() => {
          dispatch(authReducer.actions.setUserAccessToken(data.accessToken));
          dispatch(authReducer.actions.setUserRefreshToken(data.refreshToken));
        });
        redirectToLandingPage(data.accessToken, history);
      })
      .finally(() => {
        dispatch(authReducer.actions.setIsLoading(false));
      });
  };

const redirectToLandingPage = (accessToken: string, history: History) => {
  const tokenPermissions =
    jwt_decode<AccessTokenType>(accessToken)?.PermissionClaims;
  // default page to redirect to if other pages are disabled by permissions
  let redirectSite = "";
  if (tokenPermissions) {
    const perms = Array.isArray(tokenPermissions)
      ? tokenPermissions
      : [tokenPermissions];
    if (perms.includes(USER_PERMISSIONS.SITES_LIST_VIEW)) {
      redirectSite = MAIN_PAGES.SITES;
    } else if (
      perms.includes(USER_PERMISSIONS.COMPANIES_LIST_VIEW) ||
      perms.includes(USER_PERMISSIONS.COMPANIES_SELF_EDIT)
    ) {
      redirectSite = MAIN_PAGES.COMPANIES;
    } else if (perms.includes(USER_PERMISSIONS.CONTRACTORS_LIST_VIEW)) {
      redirectSite = MAIN_PAGES.CONTRACTORS;
    } else if (perms.includes(USER_PERMISSIONS.REPORTS_VIEW)) {
      redirectSite = MAIN_PAGES.REPORTS;
    } else if (perms.includes(USER_PERMISSIONS.MESSAGES_VIEW)) {
      redirectSite = MAIN_PAGES.MESSAGES;
    } else if (perms.includes(USER_PERMISSIONS.FORMS_VIEW)) {
      redirectSite = MAIN_PAGES.FORMS;
    } else if (perms.includes(USER_PERMISSIONS.TICKER_VIEW)) {
      redirectSite = MAIN_PAGES.TICKERS;
    } else if (perms.includes(USER_PERMISSIONS.QR_CODE_SCAN_VIEW)) {
      redirectSite = MAIN_PAGES.QRCODESCAN;
    }
  }
  history.push(`${MAIN_PATH.ADMIN}/${redirectSite}`);
};

const resetPassword =
  (body: ResetPasswordViewModel, history: History) =>
  (dispatch: AppDispatch) => {
    dispatch(authReducer.actions.setIsLoading(true));

    return resetPasswordCall(body)
      .then(() => {
        history.push(`${MAIN_PATH.LOGIN}`);
      })
      .catch(() => {
        dispatch(
          commonReducer.actions.setSnackBarMessage(
            "Something went wrong with resetting of your password!"
          )
        );
      })
      .finally(() => {
        dispatch(authReducer.actions.setIsLoading(false));
      });
  };

const reauthenticate =
  (refreshToken: string, history: History) => (dispatch: AppDispatch) => {
    dispatch(authReducer.actions.setIsLoading(true));

    return dispatch(reauthenticateCall({ RefreshToken: refreshToken }))
      .then(({ data }) => {
        localStorage.setItem(
          LOCAL_STORAGE_TOKENS.ACCESS_TOKEN,
          data.accessToken
        );
        localStorage.setItem(
          LOCAL_STORAGE_TOKENS.REFRESH_TOKEN,
          data.refreshToken
        );
        batch(() => {
          dispatch(authReducer.actions.setUserAccessToken(data.accessToken));
          dispatch(authReducer.actions.setUserRefreshToken(data.refreshToken));
        });
      })
      .catch(() => {
        dispatch(logout(history));
      })
      .finally(() => {
        dispatch(authReducer.actions.setIsLoading(false));
      });
  };
const checkAuthToken =
  (history: History) => async (dispatch: AppDispatch, getState: any) => {
    const accessToken = authSelectors.getUserAccessToken(getState());
    const refreshToken = authSelectors.getUserRefreshToken(getState());

    let result = false;

    if (accessToken) {
      await dispatch(checkJwtToken())
        .then(() => {
          result = true;
        })
        .catch(() => {
          if (refreshToken) {
            dispatch(reauthenticate(refreshToken, history));
          } else {
            dispatch(logout(history));
          }
          result = false;
        });
    } else if (!accessToken && refreshToken) {
      dispatch(reauthenticate(refreshToken, history));
      result = false;
    } else {
      dispatch(logout(history));
      result = false;
    }
    return result;
  };

const initiateTokensFromLocalStorage = () => (dispatch: AppDispatch) => {
  if (accessTokenFromLocalStorage) {
    dispatch(
      authReducer.actions.setUserAccessToken(accessTokenFromLocalStorage)
    );
  }
  if (refreshTokenFromLocalStorage) {
    dispatch(
      authReducer.actions.setUserRefreshToken(refreshTokenFromLocalStorage)
    );
  }
};

const allActions = {
  login,
  reauthenticate,
  initiateTokensFromLocalStorage,
  logout,
  resetPassword,
  forgotPassword,
  checkAuthToken,
};

export default allActions;
