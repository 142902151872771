import {
  CounterResponseItem,
  CounterResponseModel,
  PaginationModel,
  PaginationResponseType,
} from "../../../services/apiServiceTypes";
import { getAuthApi, postAuthApi, putAuthApi } from "../../../services/authApi";
import { APP_SETTINGS } from "../../../AppSettings";
import { SitesCounterModel } from "../../contractors/redux/apiCalls";
import { CancelToken } from "axios";

export const getCompanyList = <T = PaginationResponseType<CounterResponseItem>>(
  body: PaginationModel,
  cancelToken: CancelToken
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Company`,
    body,
    undefined,
    cancelToken
  );

export type CompanyDetailResponseType = {
  address: string;
  canCreateForms: boolean;
  vaccinationRequired?: boolean;
  companyId: string;
  companyContactName: string;
  companyContactPhone: string;
  companyContactEmail: string;
  companyName: string;
  logo?: string; // File id
  status: "Active" | "Inactive";
  allowedSecondaryCompanies: SecondaryCompany[];
};

export type SecondaryCompany = {
  secondaryCompanyId: number;
  numberOfContractors: number;
};

export const getDetailOfCompany = <T = CompanyDetailResponseType>(
  companyId?: string
) => getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Company/${companyId}`);

export const getDetailOfSelfCompany = <T = CompanyDetailResponseType>() =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Company/self`);

export interface ChangeCompanyStatusRequestType {
  id: string;
  status: "Active" | "Inactive";
}
export const postChangeCompanyStatus = <T = null>(
  body: ChangeCompanyStatusRequestType
) =>
  postAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Company/changeStatus`, body);

export const getSitesFromCompanyCounter = <T = CounterResponseModel>(
  body: SitesCounterModel
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/sitesFromCompany?onlyActiveSites=false`,
    body
  );

export const getSecondaryCompanyCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/secondary-companies?onlyActiveContractors=false`
  );

export interface CreateSecondaryCompanyRequestType {
  secondaryCompanyName: string;
  companyId: string;
}
export const postCreateSecondaryCompany = <T = null>(
  body: CreateSecondaryCompanyRequestType
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Company/createSecondaryCompany`,
    body
  );

export interface UpdateSecondaryCompanyRequestType {
  newSecondaryCompanyName: string;
  secondaryCompanyId: string;
}
export const putUpdateSecondaryCompany = <T = null>(
  body: UpdateSecondaryCompanyRequestType
) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Company/updateSecondaryCompany`,
    body
  );

export const postNewCompany = <T = any>(body: FormData) =>
  postAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Company/create`, body, {
    "Content-Type": "multipart/form-data",
  });

export const putUpdateCompany = <T = any>(body: FormData) =>
  putAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Company/update`, body, {
    "Content-Type": "multipart/form-data",
  });

export const putUpdateCompanyCredentials = <T = any>(body: FormData) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Company/updateCredentials`,
    body,
    {
      "Content-Type": "multipart/form-data",
    }
  );

export const putUpdateCompanyBaseInfo = <T = any>(body: FormData) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Company/updateBaseInfo`,
    body,
    {
      "Content-Type": "multipart/form-data",
    }
  );

export const putUpdateCompanyPermissionsSecondaryCompanies = <T = any>(
  body: FormData
) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Company/updatePermissionsSecondaryCompanies`,
    body,
    {
      "Content-Type": "multipart/form-data",
    }
  );

const allActions = {
  getCompanyList,
  getSitesFromCompanyCounter,
  getDetailOfCompany,
  getDetailOfSelfCompany,
  postChangeCompanyStatus,
  postNewCompany,
  putUpdateCompany,
};

export default allActions;
