import React, { memo } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles<Theme, TabPanelProps>((theme) => ({
  panel: ({ value, index }) => ({
    visibility: value !== index ? "hidden" : "visible",
    height: "inherit",
  }),
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: any;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  const classes = useStyles({ value, index });

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <div className={classes.panel}>{children}</div>
    </div>
  );
};

export default memo(TabPanel);
