import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import withAuthorization from "../../auth/hoc/withAutorization";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

// import { SpListItem, SpPanel } from "../../common";
import { makeStyles } from "@material-ui/core/styles";
import siteRedux from "./redux";
import { AppDispatch } from "../../appStore";
import { customDebounce } from "../../services/helpers";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { COMMON_SITE, MAIN_PAGES } from "../../router/pathConstants";
import authRedux from "../../auth/redux";
import { ROLES, USER_PERMISSIONS } from "../../auth/constants";
import { useAuthorization } from "../../auth";
import PageContent from "../../common/components/containers/PageContent";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    height: "inherit",
    paddingLeft: theme.spacing(5),
  },
  scrollPanel: {
    width: "100%",
  },
  avatar: {
    marginRight: theme.spacing(1),
    float: "left",
  },
  displayName: {
    display: "flex",
  },
  inactive: {
    color: "rgba(0, 0, 0, 0.38)",
  },
  tableContainer: {
    overflowX: "initial",
  },
}));

const Sites: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const pageSize: number = 50;
  const pageIndex = useRef<number>(0);
  const siteListHasNextPage = useSelector(
    siteRedux.selectors.getSiteListHasNextPage
  );
  const siteList = useSelector(siteRedux.selectors.getSiteList);
  const role = useSelector(authRedux.selectors.getRole);

  const { isAllowed: canAddSite } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_ADD]
  );

  const resetPagination = useCallback(() => {
    dispatch(siteRedux.actions.setSiteListHasNextPage(true));
    dispatch(siteRedux.actions.setSiteList([]));
    pageIndex.current = 0;
  }, [dispatch]);

  const debouncedCall = useMemo(
    () =>
      customDebounce((incrementedPageIndex: number, isScrollMode: boolean) => {
        dispatch(
          siteRedux.dispatchActions.fetchSiteList(
            {
              Pagination: {
                PageIndex: incrementedPageIndex,
                PageSize: pageSize,
              },
            },
            isScrollMode
          )
        )
          .then(() => {
            pageIndex.current = incrementedPageIndex;
          })
          .catch(() => {
            pageIndex.current = 0;
          });
      }, 800),
    [dispatch]
  );

  const fetchNextData = useCallback(
    (isScrollMode: boolean = false) => {
      if (siteListHasNextPage) {
        const incrementedPageIndex = pageIndex.current + 1;
        debouncedCall(incrementedPageIndex, isScrollMode);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedCall]
  );

  const refetchSiteList = useCallback(() => {
    resetPagination();
    fetchNextData();
  }, [fetchNextData, resetPagination]);

  useEffect(() => {
    return () => {
      dispatch(siteRedux.dispatchActions.resetSitesList());
    };
  }, [dispatch]);

  useEffect(() => {
    refetchSiteList();
  }, [refetchSiteList]);

  return (
    <PageContent
      title="Locations"
      addNewButtonText="Add Location"
      showButton={canAddSite}
      buttonOnClick={() => {
        history.push(`${MAIN_PAGES.SITES}/${COMMON_SITE.NEW}`);
      }}
    >
      <Box
        height={`calc(100vh -  ${canAddSite ? "227px" : "163px"})`}
        pt={2}
        pb={2}
      >
        <InfiniteScroll
          dataLength={siteList.length} //This is important field to render the next data
          next={() => fetchNextData(true)}
          hasMore={siteListHasNextPage}
          loader={
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.footerContainer}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          }
          endMessage={
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ textAlign: "center" }}
            >
              No more data
            </Typography>
          }
          height={`calc(100vh -  ${role === ROLES.ADMIN ? "227px" : "163px"})`}
          className={classes.scrollPanel}
        >
          <TableContainer
            component={Paper}
            classes={{ root: classes.tableContainer }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Display Name</TableCell>
                  <TableCell>Is active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {siteList.map((site, index) => {
                  return (
                    <TableRow
                      key={index}
                      onClick={() => {
                        history.push(`${MAIN_PAGES.SITES}/${site.codeId}`);
                      }}
                      hover
                    >
                      <TableCell className={classes.displayName}>
                        <Typography
                          variant="body1"
                          className={!site.isActive ? classes.inactive : ""}
                        >
                          {site.displayName}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          className={!site.isActive ? classes.inactive : ""}
                        >
                          {site.isActive ? "yes" : "no"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </Box>
    </PageContent>
  );
};

export default withAuthorization(Sites);
