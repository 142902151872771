import { TreeStructureData } from "../../../common/components/trees/TreeStructure";
import {
  BatteryLevel,
  EntranceEventThumbnailViewModel,
  EntranceInfoModel,
  HelpRequestViewModel,
} from "./apiCalls";
import { CounterResponseItem } from "../../../services/apiServiceTypes";

export const getDefaultState = (
  panelId: number,
  active: boolean
): PanelState => {
  return {
    id: panelId,
    active: active,
    state: {
      selectedEntrance: undefined,
      selectedSites: [],
      beaconScannerLastScanTime: undefined,
      beaconScannerLastScanValue: undefined,
      innerBeaconBatteryLevel: "Unknown",
      outerBeaconBatteryLevel: "Unknown",
      selectedEntranceInfo: undefined,
      eventList: [],
      eventListHasNextPage: false,
      isFetchingEventList: false,
      unauthorizedAccessEvent: undefined,
      selectedEvent: undefined,

      // contractors in manual check in/out
      contractorList: [],
      contractorListHasNextPage: true,
      isFetchingContractorList: false,
    },
  };
};

export type HelpRequestEvent = {
  data: HelpRequestViewModel;
  playAlarm: boolean;
};

export type UnauthorizedAccessEvent = {
  data: EntranceEventThumbnailViewModel;
  playAlarm: boolean;
};

export type SelectedEntranceEventDetail = {
  data: EntranceEventThumbnailViewModel;
  type: "CONTRACTOR_DETAIL" | "CAMERA_DETAIL";
};

export type PanelState = {
  id: number;
  active: boolean;
  state: {
    selectedEntrance?: CounterResponseItem;
    selectedSites: Array<TreeStructureData>;
    beaconScannerLastScanTime?: string;
    beaconScannerLastScanValue?: string;
    innerBeaconBatteryLevel: BatteryLevel;
    outerBeaconBatteryLevel: BatteryLevel;
    selectedEntranceInfo?: EntranceInfoModel;
    eventList: Array<EntranceEventThumbnailViewModel>;
    eventListHasNextPage: boolean;
    isFetchingEventList: boolean;
    unauthorizedAccessEvent?: UnauthorizedAccessEvent;
    selectedEvent: SelectedEntranceEventDetail | undefined;

    // contractors in manual check in/out
    contractorList: Array<CounterResponseItem>;
    contractorListHasNextPage: boolean;
    isFetchingContractorList: boolean;
  };
};

export interface State {
  panelStates: Array<PanelState>;
  helpRequest?: HelpRequestEvent;
}

export const initialState: State = {
  panelStates: [
    getDefaultState(1, true),
    getDefaultState(2, false),
    getDefaultState(3, false),
    getDefaultState(4, false),
  ],
  helpRequest: undefined,
};
