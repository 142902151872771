import { USER_TYPES } from "../../../counters";
import { CounterResponseItem } from "../../../services/apiServiceTypes";
import { TreeStructureData } from "../../../common/components/trees/TreeStructure";

export interface State {
  selectedUserTypeCodeId: string;
  userList: Array<CounterResponseItem>;
  userListHasNextPage: boolean;
  isFetchingUserList: boolean;
  allowedSites: Array<TreeStructureData>;
  allowedPermissions: Array<string>;
}

export const initialState: State = {
  selectedUserTypeCodeId: USER_TYPES.ADMIN,
  userList: [],
  userListHasNextPage: true,
  isFetchingUserList: false,
  allowedSites: [],
  allowedPermissions: [],
};
