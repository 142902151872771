import React, { FC, memo, useMemo, useRef, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { SpPanel, TabPanel } from "../../../common";
import { useFetchData } from "../../../services/useFetchData";
import { getSiteDetail, SiteDetailModel } from "../../forms/redux/apiCalls";
import { isNil } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import siteRedux from "../redux";
import { EntrancesTab, SiteInfoTab } from "./index";
import { useComputeSize } from "../../../services/useComputeSize";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  prefillSiteInfoInputs,
  SiteInfoInputs,
  SiteInfoSchema,
} from "./SiteInfoTab";
import { putEditSiteInfo } from "../redux/apiCalls";
import { AppDispatch } from "../../../appStore";
import { SiteSupervisorTab } from "./siteSupervisor";
import { ROLES, USER_PERMISSIONS } from "../../../auth/constants";
import { useAuthorization } from "../../../auth";
import PageContent from "../../../common/components/containers/PageContent";

const useStyles = makeStyles<Theme, { tabsHeaderHeight: number }>((theme) => ({
  main: {
    marginTop: theme.spacing(4),
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  mainPanel: {
    padding: "16px 0",
    height: "calc(100vh - 163px)",
    overflow: "auto",
  },
  tabs: {
    marginLeft: "40px",
    marginTop: "16px",
    marginBottom: "16px",
  },
}));

type SitesTabsDetailProps = {};

const SitesTabsDetail: FC<SitesTabsDetailProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const tabsRef = useRef<HTMLButtonElement | null>(null);
  const { height: tabsHeight } = useComputeSize(tabsRef, []);

  const classes = useStyles({ tabsHeaderHeight: tabsHeight });

  const [tabValue, setTabValue] = React.useState<number>(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const selectedSiteId = useSelector(siteRedux.selectors.getSelectedSiteId);

  const [alertMessage, setAlertMessage] = useState<string>();

  // const isSiteSupervisor = useAuthorization([ROLES.SITE_SUPERVISOR]).isAllowed;

  const { isAllowed: canViewSupervisors } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_SUPERVISORS_VIEW]
  );
  const { isAllowed: canViewEntrances } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_ENTRANCES_VIEW]
  );

  const { isAllowed: canAddEntrances } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_ENTRANCES_ADD]
  );

  const { isAllowed: canAddSupervisor } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_SUPERVISORS_ADD_REMOVE]
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    errors,
    trigger,
    setError,
    unregister,
    watch,
    clearErrors,
    formState,
    getValues,
  } = useForm<SiteInfoInputs>({
    resolver: yupResolver(SiteInfoSchema),
  });

  // TODO: do some redirect to forbidden/not permission data if site detail request fails?
  const { data: siteDetail, refetchAction: refetchSiteDetail } =
    useFetchData<SiteDetailModel>(
      // @ts-ignore
      getSiteDetail(selectedSiteId),
      !isNil(selectedSiteId),
      undefined,
      [selectedSiteId, tabValue],
      ({ data }) => prefillSiteInfoInputs(setValue, data),
      () => reset(),
      () => reset()
    );

    const setInboundApiKey = (apiKey: string) => {
        setValue("hardwareApiKey", apiKey);
    };

  const onSubmitSite = (formInputData: SiteInfoInputs) => {
    setAlertMessage(undefined);
    if (siteDetail?.siteId && siteDetail?.status) {
      dispatch(
        putEditSiteInfo({
          id: siteDetail.siteId,
          status: siteDetail.status,
          siteName: formInputData?.siteName,
          company: formInputData?.company,
          siteOwnerName: formInputData?.siteOwnerName,
          siteOwnerContactNumber: formInputData?.siteOwnerContactNumber,
          siteOwnerEmail: formInputData?.siteOwnerContactEmail,
          protectionAdvisoryManagerName:
            formInputData?.protectionAdvisoryManagerName,
          protectionAdvisoryContactNumber:
            formInputData?.protectionAdvisoryManagerContactNumber,
          protectionAdvisoryEmail:
            formInputData?.protectionAdvisoryManagerEmail,
          certificationExpirationThreshold:
            formInputData?.certificationExpirationThreshold,
          clubId: formInputData?.clubId,
          apiKey: formInputData?.abcFinancialApiKey,
          hardwareApiKey: formInputData?.hardwareApiKey,
        })
      ).catch((error) => {
        if (
          typeof error.response.data === "string" ||
          error.response.data instanceof String
        ) {
          setAlertMessage(error.response.data);
        }
      });
    }
  };

  const supervisorTabIdx = useMemo(
    () => (canViewEntrances ? 2 : 1),
    [canViewEntrances]
  );

  const addNewEntranceCB = () => {
    dispatch(siteRedux.actions.setSelectedEntranceId(undefined));
    dispatch(siteRedux.actions.setIsNewEntranceMode(true));
  };

  const addNewSupervisorCB = () => {
    dispatch(siteRedux.actions.setIsNewSiteSupervisorMode(true));
    dispatch(siteRedux.actions.setSelectedSiteSupervisorId(undefined));
  };

  const methods = {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    errors,
    trigger,
    setError,
    unregister,
    watch,
    clearErrors,
    formState,
    getValues,
  };

  return (
    <div className={classes.main}>
      <PageContent
        title={`Locations: ${siteDetail?.siteName}`}
        addNewButtonText={
          tabValue === 1
            ? "Add New Entrance"
            : tabValue === 2
            ? "Add New Manager"
            : ""
        }
        buttonOnClick={
          tabValue === 1
            ? addNewEntranceCB
            : tabValue === 2
            ? addNewSupervisorCB
            : undefined
        }
        showButton={
          (tabValue === 1 && canAddEntrances) ||
          (tabValue === 2 && canAddSupervisor)
        }
      >
        <SpPanel withoutFooter>
          <div className={classes.mainPanel}>
            <Tabs
              className={classes.tabs}
              value={tabValue}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              ref={tabsRef}
            >
              <Tab label="Location Info" />
              {canViewEntrances && <Tab label="Entrances" />}
              {canViewSupervisors && <Tab label="Manager" />}
            </Tabs>
            {tabValue === 0 && (
              <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmitSite)}>
                    <SiteInfoTab
                      siteDetail={siteDetail}
                      refetchSiteDetail={refetchSiteDetail}
                      setInboundApiKey={setInboundApiKey}
                      errors={errors}
                      register={register}
                      control={control}
                      alertMessage={alertMessage}
                      newSiteMode={false}
                    />
                  </form>
                </FormProvider>
              </TabPanel>
            )}
            {canViewEntrances && tabValue === 1 && (
              <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
                <EntrancesTab siteDetail={siteDetail} />
              </TabPanel>
            )}
            {canViewSupervisors && tabValue === supervisorTabIdx && (
              <TabPanel
                value={tabValue}
                index={supervisorTabIdx}
                className={classes.tabPanel}
              >
                <SiteSupervisorTab siteDetail={siteDetail} />
              </TabPanel>
            )}
          </div>
        </SpPanel>
      </PageContent>
    </div>
  );
};

export default memo(SitesTabsDetail);
