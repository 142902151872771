import React, { memo } from "react";
import { List, ListItem, Typography } from "@material-ui/core";
import { useFetchData } from "../../../services/useFetchData";
import { CounterResponseModel } from "../../../services/apiServiceTypes";
import { getSitesFromCompanyCounter } from "../redux/apiCalls";
import { makeStyles, Theme } from "@material-ui/core/styles";
import cx from "classnames";

const useStyles = makeStyles<Theme>((theme) => ({
  textWrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textNotActive: {
    color: theme.palette.text.disabled,
  },
  item: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    border: "1px solid #E2E3E5;",
    boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.01)",
    borderRadius: "6px",
  },
}));

export interface CompanySitesProps {
  companyId?: string;
}

const CompanySitesDetail: React.FC<CompanySitesProps> = ({ companyId }) => {
  const classes = useStyles();

  const { data: sites } = useFetchData<CounterResponseModel>(
    getSitesFromCompanyCounter({ companyId: companyId }),
    true,
    false,
    [companyId]
  );

  return (
    <List>
      {sites?.map((site, index) => (
        <ListItem className={classes.item}>
          <Typography
            className={cx(classes.textWrap, {
              [classes.textNotActive]: !site.isActive,
            })}
          >
            {" "}
            {site.displayName}{" "}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default memo(CompanySitesDetail);
