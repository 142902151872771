import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { BORDER_RADIUS, FONT_FAMILY, FONT_WEIGHT } from "../../appTheme";
import { isNil } from "ramda";
import cx from "classnames";

const useStyles = makeStyles<Theme, AnnouncementProps>((theme) => ({
  border: ({ color }) => ({
    minHeight: 14.17,
    // width: 159.32,
    backgroundColor: color,
    borderRadius: BORDER_RADIUS.MINIMAL,
    paddingTop: "2px",
    paddingBottom: "2px",
  }),
  text: {
    fontSize: 9,
    fontFamily: FONT_FAMILY.MANROPE,
    fontWeight: FONT_WEIGHT.EXTRA_BOLD,
    textTransform: "uppercase",
    color: theme.palette.common.white,
    textAlign: "center",
  },
  facialRecGrid: ({ color }) => ({
    border: "solid",
    borderColor: color,
    borderRadius: BORDER_RADIUS.NORMAL,
    justifyContent: "center",
    textTransform: "uppercase",
    fontSize: 12,
    fontFamily: FONT_FAMILY.MANROPE,
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
    overflow: "hidden",
  }),
  titleGrid: {
    width: "calc(100% - 30px)",
    textAlign: "center",
    alignSelf: "center",
  },
}));

export interface AnnouncementProps {
  color: string;
  text: string;
  titleText?: string;
  icon?: JSX.Element;
}

const Announcement: React.FC<AnnouncementProps> = ({
  color,
  text,
  titleText,
  icon,
}) => {
  const classes = useStyles({ color, text, titleText, icon });
  const showHeader = !isNil(titleText) || !isNil(icon);

  return (
    <>
      <Grid container className={cx({ [classes.facialRecGrid]: showHeader })}>
        {showHeader && (
          <Grid item className={classes.titleGrid}>
            {titleText}
          </Grid>
        )}
        {showHeader && icon}
        <Box mt={1 / 2} />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.border}
        >
          <Grid item className={classes.text}>
            {text}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Announcement;
