import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Card } from "@material-ui/core";
import { BORDER_RADIUS, FONT_FAMILY, FONT_WEIGHT } from "../../appTheme";
import { SpIcon } from "./icons";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { isNil } from "ramda";

const useStyles = makeStyles<Theme, SpCardProps>((theme) => ({
  border: ({ color }) => ({
    padding: theme.spacing(1),
    borderRadius: BORDER_RADIUS.NORMAL,
    backgroundColor:
      color === "error" ? theme.palette.error.main : theme.palette.primary.main,
    color: theme.palette.common.white,
    borderWidth: 0,
  }),
  headerText: {
    fontSize: 14,
    fontWeight: FONT_WEIGHT.EXTRA_BOLD,
    fontFamily: FONT_FAMILY.MANROPE,
    textTransform: "uppercase",
  },
  messageText: {
    fontSize: 14,
    fontFamily: FONT_FAMILY.MANROPE,
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  headerIcon: {
    height: 19.0244,
    width: 19.0244,
    paddingRight: theme.spacing(1) / 2,
  },
}));

export interface SpCardProps {
  color: "primary" | "error";
  header: string;
  text: string;
  onClose?: () => void;
}

const SpCard: React.FC<SpCardProps> = ({ color, header, text, onClose }) => {
  const classes = useStyles({ color, header, text, onClose });

  return (
    <Card className={classes.border}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center">
          <SpIcon
            iconName="unauthorizedAccess"
            className={classes.headerIcon}
          />
          <Box flexGrow={1} className={classes.headerText}>
            {header}
          </Box>
          {!isNil(onClose) && (
            <Box>
              <IconButton onClick={onClose} size={"small"}>
                <CloseIcon color="secondary" />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box className={classes.messageText}>{text}</Box>
      </Box>
    </Card>
  );
};

export default SpCard;
