export const customDebounce = (
  func: { apply: (arg0: any, arg1: any[]) => void },
  wait?: number,
  immediate?: any
) => {
  let timeout: any;

  return (...args: any[]) => {
    // @ts-ignore
    let context = this;

    let later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const BACKEND_DATE_TIME_FORMAT = "";

export const BACKEND_DATE_TIME_FORMAT_WITHOUT_TIMEZONE = "yyyy-MM-DDTHH:mm:ss";

export const FRONTEND_DATE_TIME_FORMAT = "yyyy-MM-DDTHH:mm";

export const FRONTEND_DATE_FORMAT = "YYYY-MM-DD";

export const FRONTEND_DATE_FORMAT_REPORTS = "MM/DD/YYYY";

export const FRONTEND_DATE_FORMAT_EVENT_LOG = "MM/DD/YYYY hh:mm A";
