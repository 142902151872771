import React, { FC, memo, useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { useFetchData } from "../../../services/useFetchData";
import { loadFile } from "../../../services/authApi";
import { isNil } from "ramda";
import noCamera from "../../../assets/img/21_SP_NO CAMERA-ICON.png";

const useStyles = makeStyles<Theme>((theme) => ({
  photo: {
    width: "50vw",
    height: "65vh",
    objectFit: "contain",
  },
}));

type CameraEventDetailProps = {
  isOpen: boolean;
  onClose: () => void;
  photoId?: string;
};

const CameraEventDetail: FC<CameraEventDetailProps> = ({
  isOpen,
  onClose,
  photoId,
}) => {
  const classes = useStyles();
  const [photoUrl, setPhotoUrl] = useState<string>();

  useEffect(() => {
    // Reset on change
    setPhotoUrl(undefined);
  }, [photoId]);

  useFetchData<string>(
    // @ts-ignore
    loadFile(photoId),
    !isNil(photoId),
    undefined,
    [photoId],
    // @ts-ignore
    (response) => setPhotoUrl(response),
    () => setPhotoUrl(undefined),
    () => setPhotoUrl(undefined)
  );

  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth={false}>
      <DialogContent>
        <img
          src={photoUrl || noCamera}
          alt="camera-event"
          className={classes.photo}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(CameraEventDetail);
