import React, { forwardRef, memo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@material-ui/core";
import { SpCard } from "../../common";
import Battery from "../../common/components/Battery";
import eventLogReducer from "./redux";
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "../../appTheme";
import { PlayAudio } from "../../services/PlayAudio";
import { isNil } from "ramda";
import { State } from "./redux/model";
import { Close, KeyboardArrowDown } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.common.white,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: `1px solid ${COLORS.LINE_100}`,
  },
  entranceSelector: {
    border: `1px solid ${COLORS.LINE_100}`,
    borderRadius: "6px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexGrow: 1,
  },
  closeIconContainer: {
    flexGrow: 1,
    marginTop: "-40px",
    marginRight: "-30px",
  },
  closeIcon: {
    position: "relative",
    display: "flex",
    borderRadius: "0px",
  },
}));

interface EventPanelHeaderProps {
  panelId: number;
  clickOnExpandSites: () => void;
  siteName?: string;
  entranceName?: string;
  entranceId?: string;
  onClose?: () => void;
  onForceNoUpdateChange?: (checked: boolean) => void;
}

const EventPanelHeader = forwardRef<
  HTMLDivElement | null,
  EventPanelHeaderProps
>(
  (
    {
      panelId,
      clickOnExpandSites,
      siteName = " ",
      entranceName = " ",
      entranceId,
      onClose,
      onForceNoUpdateChange,
    },
    ref
  ) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [forceNoUpdate, setForceNoUpdate] = useState<boolean>(false);

    const innerBeaconBatteryLevel = useSelector(
      (state: { [x: string]: State }) =>
        eventLogReducer.selectors.getInnerBeaconBatteryLevel(state, panelId)
    );

    const outerBeaconBatteryLevel = useSelector(
      (state: { [x: string]: State }) =>
        eventLogReducer.selectors.getOuterBeaconBatteryLevel(state, panelId)
    );

    const unauthorizedAccessEvent = useSelector(
      (state: { [x: string]: State }) =>
        eventLogReducer.selectors.getUnauthorizedAccessEvent(state, panelId)
    );

    const handleForceNoUpdateChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (!!onForceNoUpdateChange) {
        onForceNoUpdateChange(event.target.checked);
      }

      setForceNoUpdate(event.target.checked);
    };

    return (
      <div className={classes.main} ref={ref}>
        <PlayAudio
          path={require("../../assets/sounds/alarm.mp3")}
          play={unauthorizedAccessEvent?.playAlarm}
        />
        <Box display="flex" flexDirection="column" gridGap="12px">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="spaceBetween"
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              onClick={clickOnExpandSites}
              className={classes.entranceSelector}
            >
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Typography variant="subtitle1">{siteName}</Typography>
                <Typography variant="h3">{entranceName}</Typography>
              </Box>
              <KeyboardArrowDown color="primary" />
            </Box>
            {!!onClose ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                className={classes.closeIconContainer}
              >
                <IconButton
                  onClick={() => onClose()}
                  className={classes.closeIcon}
                >
                  <Close color="disabled" />
                </IconButton>
              </Box>
            ) : null}
          </Box>
          <Box display="flex" flexDirection="row" gridGap="12px">
            <Box width="50%">
              <Battery
                batteryLevel={innerBeaconBatteryLevel}
                description="Inner Beacon"
              />
            </Box>
            <Box width="50%">
              <Battery
                batteryLevel={outerBeaconBatteryLevel}
                description="Outer Beacon"
              />
            </Box>
          </Box>
          {!!onForceNoUpdateChange ? (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleForceNoUpdateChange}
                  checked={forceNoUpdate}
                  size="small"
                  color="primary"
                />
              }
              label={<Typography variant="h6">Suppress Auto-update</Typography>}
            />
          ) : null}
          {!isNil(unauthorizedAccessEvent) && (
            <SpCard
              color="error"
              header="Unauthorized access"
              text={unauthorizedAccessEvent.data.dateTimeString}
              onClose={() =>
                dispatch(
                  eventLogReducer.actions.setUnauthorizedAccessEvent({
                    unauthorizedAccessEvent: undefined,
                    panelId: panelId,
                  })
                )
              }
            />
          )}
        </Box>
      </div>
    );
  }
);

export default memo(EventPanelHeader);
