import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";

const heightOfVideo = "80vh";
const widthOfVideo = "65vw";

const useStyles = makeStyles<Theme, Boolean>((theme) => ({
  video: (playCamera) => ({
    visibility: !playCamera ? "hidden" : "visible",
    height: !playCamera ? 0 : heightOfVideo,
    width: !playCamera ? 0 : widthOfVideo,
  }),
  canvas: (playCamera) => ({
    visibility: playCamera ? "hidden" : "visible",
    height: playCamera ? 0 : heightOfVideo,
    width: playCamera ? 0 : widthOfVideo,
  }),
}));

interface CameraPhotoProps {
  onSaveImage: BlobCallback;
  onCloseDialog: () => void;
}

export const CameraPhoto: FC<CameraPhotoProps> = ({
  onSaveImage,
  onCloseDialog,
}) => {
  const videoPlayer = useRef<HTMLVideoElement | null>(null);
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const [playCamera, setPlayCamera] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const [activateSave, setActivateSave] = useState<boolean>(false);
  const classes = useStyles(playCamera);

  const setDevice = async (
    { deviceId }: MediaDeviceInfo,
    video: HTMLVideoElement
  ) => {
    video.srcObject = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        deviceId,
        facingMode: "user",
        width: {
          // min: 640,
          ideal: 1280,
          max: 2560,
        },
        height: {
          // min: 480,
          ideal: 960,
          max: 1920,
        },
      },
    });
    const playPromise = video.play();
    if (playPromise !== null) {
      playPromise.catch(() => {});
    }
  };

  const processDevices = useCallback(
    async (devices: MediaDeviceInfo[]) => {
      if (devices.length > 0) {
        const device = devices[0];
        if (device && videoPlayer.current) {
          await setDevice(device, videoPlayer.current);
          setPlayCamera(true);
        }
      }
    },
    [videoPlayer]
  );

  const prepareCameras = useCallback(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    processDevices(videoDevices);
    setActivateSave(false);
  }, [processDevices]);

  useEffect(() => {
    prepareCameras();
  }, [prepareCameras]);

  const stopBothVideoAndAudio = (stream: MediaStream) => {
    stream.getTracks().forEach(function (track) {
      if (track.readyState === "live") {
        track.stop();
      }
    });
  };

  const stopCamera = () => {
    if (videoPlayer.current) {
      if (videoPlayer.current.srcObject) {
        stopBothVideoAndAudio(videoPlayer.current.srcObject as MediaStream);
      }
      videoPlayer.current.pause();
      if (videoPlayer.current.currentTime) {
        videoPlayer.current.currentTime = 0;
      }
      setPlayCamera(false);
    }
  };

  const takePhoto = () => {
    if (canvas.current && videoPlayer.current) {
      canvas.current.height = videoPlayer.current.videoHeight;
      canvas.current.width = videoPlayer.current.videoWidth;

      const context = canvas.current?.getContext("2d");
      if (context) {
        context.drawImage(videoPlayer.current, 0, 0);
      }
      setActivateSave(true);
    }
    stopCamera();
  };

  const handleClose = () => {
    setOpen(false);
    stopCamera();
    onCloseDialog();
  };

  const saveChangesAndClose = () => {
    if (canvas.current) {
      canvas.current?.toBlob(onSaveImage);
      handleClose();
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={false}>
      <DialogContent>
        <video ref={videoPlayer} className={classes.video} playsInline />
        <canvas ref={canvas} className={classes.canvas} />
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Button
                  onClick={prepareCameras}
                  variant="contained"
                  disabled={!activateSave}
                >
                  Start Camera
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={takePhoto}
                  color="primary"
                  variant="contained"
                  disabled={activateSave}
                >
                  Snap photo
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Button
                  onClick={saveChangesAndClose}
                  color="primary"
                  variant="contained"
                  disabled={!activateSave}
                >
                  Save changes
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleClose} variant="contained">
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
