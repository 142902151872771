import axios from "axios";
import { APP_SETTINGS } from "../../AppSettings";
import { getAuthApi, postAuthApi } from "../../services/authApi";

export type ForgotPasswordBodyModel = {
  Email: string;
  ResetPasswordUrl: string;
};

export const forgotPasswordCall = (body: ForgotPasswordBodyModel) =>
  axios.post<any>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/UserAuth/forgotPassword`,
    body
  );

export type LoginResponseType = {
  accessToken: string;
  refreshToken: string;
};

export type LoginBodyModel = {
  Username: string;
  Password: string;
};

export const loginCall = (body: LoginBodyModel) =>
  axios.post<LoginResponseType>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/UserAuth/authenticate`,
    body
  );

export type ReauthenticateBodyModel = {
  RefreshToken: string;
};

export const reauthenticateCall = (body: ReauthenticateBodyModel) =>
  postAuthApi<LoginResponseType>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/UserAuth/reauthenticate`,
    body
  );

export const checkJwtToken = () =>
  getAuthApi<LoginResponseType>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/AuthApi/check-jwt-token`
  );

export type ResetPasswordViewModel = {
  userId: string;
  token: string;
  newPassword: string;
  confirmPassword: string;
};

export const resetPasswordCall = (body: ResetPasswordViewModel) =>
  axios.post<any>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/UserAuth/resetPassword`,
    body
  );
