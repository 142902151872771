import React, { ReactNode } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cx from "classnames";

const useStyles = makeStyles((theme) => ({
  main: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  input: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
  },
}));

const SpFormControl: React.FC<SpFormControlProps> = ({
  title = "",
  input,
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.main, className)}>
      <Typography variant={"h4"}>{title}</Typography>
      <div className={classes.input}>{input}</div>
    </div>
  );
};

export default SpFormControl;

export interface SpFormControlProps {
  title?: string | null;
  input: ReactNode;
  className?: any;
}
