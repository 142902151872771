import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { BORDER_RADIUS, COLORS } from "../../appTheme";
import { BatteryLevel } from "../../admin/eventLog/redux/apiCalls";
import {
  Battery20,
  Battery50,
  Battery80,
  BatteryFull,
  BatteryUnknown,
} from "@material-ui/icons";

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  border: ({ color }) => ({
    padding: theme.spacing(1),
    borderRadius: BORDER_RADIUS.SMALL,
    borderColor: color === "primary" ? COLORS.GREEN : theme.palette.error.main,
    borderWidth: 1,
    borderStyle: "solid",
    textAlign: "center",
  }),
}));

interface StyleProps {
  color: "primary" | "error";
}

export interface BatteryProps {
  batteryLevel: BatteryLevel;
  description?: string;
}

const Battery: React.FC<BatteryProps> = ({ batteryLevel, description }) => {
  const color = batteryLevel === "Unknown" ? "error" : "primary";
  const classes = useStyles({ color });

  const parseBatteryLevel = (batteryLevel: BatteryLevel) => {
    switch (batteryLevel) {
      case "Unknown":
        return "Unknown";
      case "Low":
        return "Low";
      case "MediumLow":
        return "Medium Low";
      case "MediumHigh":
        return "Medium High";
      case "High":
        return "High";
    }
  };

  const getIconForBatteryLevel = (level: BatteryLevel) => {
    switch (batteryLevel) {
      case "Unknown":
        return <BatteryUnknown color="error" />;
      case "Low":
        return <Battery20 />;
      case "MediumLow":
        return <Battery50 />;
      case "MediumHigh":
        return <Battery80 />;
      case "High":
        return <BatteryFull />;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      className={classes.border}
      alignItems="center"
      gridGap="4px"
    >
      {getIconForBatteryLevel(batteryLevel)}
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {description ? (
          <Typography variant="h6">{description}</Typography>
        ) : null}
        <Typography variant="h6">{parseBatteryLevel(batteryLevel)}</Typography>
      </Box>
    </Box>
  );
};

export default Battery;
