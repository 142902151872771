// import appIcon from "../../../assets/img/21_SP-APP ICON.png";
// import moreIcon from "../../../assets/img/21_SP_3 DOT MORE-ICON.png";
// import alertIcon from "../../../assets/img/21_SP_ALERT-ICON.png";
import attachmentIcon from "../../../assets/img/21_SP_ATTACHMENT-ICON.png";
import checkButtonIcon from "../../../assets/img/21_SP_CHECK BUTTON-ICON.png";
import downButtonIcon from "../../../assets/img/21_SP_DOWN BUTTON-ICON.png";
import downIcon from "../../../assets/img/21_SP_DOWN-ICON.png";
// import greenDownIcon from "../../../assets/img/21_SP_GREEN DOWN-ICON.png";
import minusButtonIcon from "../../../assets/img/21_SP_MINUS BUTTON-ICON.png";
// import sightIconActiveIcon from "../../../assets/img/21_SP_MY SIGHT-ICON-ACTIVE.png";
// import sightIconInactiveIcon from "../../../assets/img/21_SP_MY SIGHT-ICON-INACTIVE.png";
// import noCameraIcon from "../../../assets/img/21_SP_NO CAMERA-ICON.png";
import noDotIcon from "../../../assets/img/21_SP_NO DOT-ICON.png";
// import notificationsActiveIcon from "../../../assets/img/21_SP_NOTIFICATIONS-ICON-ACTIVE.png";
// import notificationsInactiveIcon from "../../../assets/img/21_SP_NOTIFICATIONS-ICON-INACTIVE.png";
// import pdfIcon from "../../../assets/img/21_SP_PDF-ICON.png";
import plusButtonIcon from "../../../assets/img/21_SP_PLUS BUTTON-ICON.png";
// import redDownIcon from "../../../assets/img/21_SP_RED DOWN-ICON.png";
// import reorderIcon from "../../../assets/img/21_SP_REORDER-ICON.png";
import searchIcon from "../../../assets/img/21_SP_SEARCH-ICON.png";
// import appHeaderLogo from "../../../assets/img/21_SP_SIGHTPAS-APP-HEADER LOGO.png";
// import appHeaderLogoDark from "../../../assets/img/21_SP_SIGHTPAS-APP-HEADER LOGO_DARK.png";
import unauthorizedAccessIcon from "../../../assets/img/21_SP_UNAUTHORIZED ACCESS-ICON.png";
import yesDotIcon from "../../../assets/img/21_SP_YES DOT-ICON.png";
import closeIcon from "../../../assets/img/21_SP_CLOSE-ICON.png";

const parseIcon = (iconName: string) => {
  switch (iconName) {
    case "attachment":
      return attachmentIcon;
    case "dotYes":
      return yesDotIcon;
    case "dotNo":
      return noDotIcon;
    case "check":
      return checkButtonIcon;
    case "plus":
      return plusButtonIcon;
    case "minus":
      return minusButtonIcon;
    case "down":
      return downIcon;
    case "downButton":
      return downButtonIcon;
    case "search":
      return searchIcon;
    case "unauthorizedAccess":
      return unauthorizedAccessIcon;
    case "close":
      return closeIcon;
  }
  return null;
};

export default parseIcon;
