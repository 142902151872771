import React, { useMemo } from "react";
import withAuthorization from "../../auth/hoc/withAutorization";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/img/21_SP_SIGHTPAS-APP-HEADER LOGO.png";
import { Box, Hidden, List } from "@material-ui/core";
import MainMenuLeftItem from "./MainMenuLeftItem";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { MAIN_PAGES, MAIN_PATH } from "../../router/pathConstants";
import MainMenuLeftFooter from "./MainMenuLeftFooter";
import { useAuthorization } from "../../auth";
import { ROLES, USER_PERMISSIONS } from "../../auth/constants";
import { COLORS } from "../../appTheme";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: COLORS.BLACK,
    height: "100vh",
    overflowY: "auto",
  },
  logo: {
    width: 201.52,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
}));

interface MainMenuLeftProps {
  onItemClick: () => void;
}

const MainMenuLeft: React.FC<MainMenuLeftProps> = ({ onItemClick }) => {
  const classes = useStyles();
  const history = useHistory();
  let { url } = useRouteMatch();
  let location = useLocation();

  const companiesPath = `${url}/${MAIN_PAGES.COMPANIES}`;
  const sitesPath = `${url}/${MAIN_PAGES.SITES}`;
  const contractorsPath = `${url}/${MAIN_PAGES.CONTRACTORS}`;
  const reportsPath = `${url}/${MAIN_PAGES.REPORTS}`;
  const messagesPath = `${url}/${MAIN_PAGES.MESSAGES}`;
  const formsPath = `${url}/${MAIN_PAGES.FORMS}`;
  const usersPath = `${url}/${MAIN_PAGES.USERS}`;
  const tickersPath = `${url}/${MAIN_PAGES.TICKERS}`;
  const qrCodeScanPath = `${url}/${MAIN_PAGES.QRCODESCAN}`;

  const { isAllowed: usersItemVisible } = useAuthorization([ROLES.ADMIN]);
  const { isAllowed: canListCompanies } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.COMPANIES_LIST_VIEW]
  );
  const { isAllowed: canSelfEditCompany } = useAuthorization(
    [ROLES.COMPANY],
    [USER_PERMISSIONS.COMPANIES_SELF_EDIT]
  );
  const companiesItemVisible = useMemo(
    () => canListCompanies || canSelfEditCompany,
    [canListCompanies, canSelfEditCompany]
  );
  const { isAllowed: contractorsItemVisible } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.CONTRACTORS_LIST_VIEW]
  );
  const { isAllowed: sitesItemVisible } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.SITES_LIST_VIEW]
  );
  const { isAllowed: reportsItemVisible } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.REPORTS_VIEW]
  );
  const { isAllowed: messagesItemVisible } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.MESSAGES_VIEW]
  );
  const { isAllowed: formsItemVisible } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.FORMS_VIEW]
  );
  const { isAllowed: qrCodeScanItemVisible } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.QR_CODE_SCAN_VIEW]
  );
  const { isAllowed: tickersItemVisible } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.TICKER_VIEW]
  );

  const handleClick = (pageName: string) => {
    history.replace(`/${MAIN_PATH.ADMIN}/${pageName}`);
    onItemClick();
  };

  return (
    <Box display="flex" flexDirection="column" className={classes.main}>
      <Hidden smUp implementation="css">
        <div className={classes.toolbar} />
      </Hidden>
      <img src={logo} alt="SightPas-logo" className={classes.logo} />
      <Box flexGrow={1}>
        <List>
          {companiesItemVisible && (
            <MainMenuLeftItem
              title={canSelfEditCompany ? "Client" : "Clients"}
              onClick={() => handleClick(MAIN_PAGES.COMPANIES)}
              isActive={location.pathname.includes(companiesPath)}
            />
          )}
          {sitesItemVisible && (
            <MainMenuLeftItem
              title={"Locations"}
              onClick={() => handleClick(MAIN_PAGES.SITES)}
              isActive={location.pathname.includes(sitesPath)}
            />
          )}
          {contractorsItemVisible && (
            <MainMenuLeftItem
              title={"Users"}
              onClick={() => handleClick(MAIN_PAGES.CONTRACTORS)}
              isActive={location.pathname.includes(contractorsPath)}
            />
          )}
          {reportsItemVisible && (
            <MainMenuLeftItem
              title={"Reports"}
              onClick={() => handleClick(MAIN_PAGES.REPORTS)}
              isActive={location.pathname.includes(reportsPath)}
            />
          )}
          {messagesItemVisible && (
            <MainMenuLeftItem
              title={"Messages"}
              onClick={() => handleClick(MAIN_PAGES.MESSAGES)}
              isActive={location.pathname.includes(messagesPath)}
            />
          )}
          {formsItemVisible && (
            <MainMenuLeftItem
              title={"Forms"}
              onClick={() => handleClick(MAIN_PAGES.FORMS)}
              isActive={location.pathname.includes(formsPath)}
            />
          )}
          {usersItemVisible && (
            <MainMenuLeftItem
              title={"Administrators"}
              onClick={() => handleClick(MAIN_PAGES.USERS)}
              isActive={location.pathname.includes(usersPath)}
            />
          )}
          {tickersItemVisible && (
            <MainMenuLeftItem
              title={"Tickers"}
              onClick={() => handleClick(MAIN_PAGES.TICKERS)}
              isActive={location.pathname.includes(tickersPath)}
            />
          )}
          {qrCodeScanItemVisible && (
            <MainMenuLeftItem
              title={"QR Code Scan"}
              onClick={() => handleClick(MAIN_PAGES.QRCODESCAN)}
              isActive={location.pathname.includes(qrCodeScanPath)}
            />
          )}
        </List>
      </Box>
      <MainMenuLeftFooter />
    </Box>
  );
};

export default withAuthorization(MainMenuLeft);
