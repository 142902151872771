import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import withAuthorization from "../../auth/hoc/withAutorization";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  FormControlWrapper,
  ListDetailSplitView,
  PageContent,
  SpListItem,
  SpTextField,
} from "../../common";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import contractorRedux from "./redux";
import { AppDispatch } from "../../appStore";
import { SCROLL_PANEL_WIDTH } from "../../appTheme";
import { SpSearchTextField } from "../../common/components/inputs/SpSearchTextField";
import { ContractorDetailSection } from "./contractorDetail";
import { customDebounce } from "../../services/helpers";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { ROLES, USER_PERMISSIONS } from "../../auth/constants";
import { useAuthorization } from "../../auth";

const useStyles = makeStyles((theme) => ({
  scrollPanel: {
    height: "calc(100vh - 400px)",
    width: SCROLL_PANEL_WIDTH,
  },
  mobileScrollPanel: {
    height: "200px !important",
    width: "100%"
  },
  footerContainer: {
    height: "inherit",
  },
  filterPanel: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  }
}));

const Contractors: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedContractorId, setSelectedContractorId] = useState<string>();
  const [newContractorMode, setNewContractorMode] = useState<boolean>();

  const contractorListRequest = useRef<CancelTokenSource>(
    axios.CancelToken.source()
  );
  const pageSize: number = 50;
  const pageIndex = useRef<number>(0);
  const contractorListHasNextPage = useSelector(
    contractorRedux.selectors.getContractorListHasNextPage
  );
  const contractorList = useSelector(
    contractorRedux.selectors.getContractorList
  );
  const isFetchingContractorList = useSelector(
    contractorRedux.selectors.getIsFetchingContractorList
  );
  const companyList = useSelector(contractorRedux.selectors.getCompanyList);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [selectedSiteId, setSelectedSiteId] = useState<string>();
  const [searchContractor, setSearchContractor] = useState<string>();
  const siteList = useSelector(contractorRedux.selectors.getSiteList);

  const resetPagination = useCallback(() => {
    dispatch(contractorRedux.actions.setContractorListHasNextPage(true));
    dispatch(contractorRedux.actions.setContractorList([]));
    pageIndex.current = 0;
  }, [dispatch]);

  const { isAllowed: canAddContractor } = useAuthorization(
    [ROLES.ADMIN, ROLES.COMPANY, ROLES.SITE_SUPERVISOR],
    [USER_PERMISSIONS.CONTRACTORS_ADD]
  );

  const handleChangeCompany = (event: React.ChangeEvent<{ value: any }>) => {
    setSelectedCompanyId(event.target.value as string);
    setSelectedSiteId(undefined);
    resetPagination();
  };

  const handleChangeSite = (event: React.ChangeEvent<{ value: any }>) => {
    setSelectedSiteId(event.target.value as string);
    resetPagination();
  };
  const handleChangeSearchContractor = (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    setSearchContractor(event.target.value as string);
    resetPagination();
  };

  const debouncedCall = useMemo(
    () =>
      customDebounce(
        (
          searchContractor: string,
          siteIds: string[],
          incrementedPageIndex: number,
          isScrollMode: boolean,
          cancelToken: CancelToken,
          companyId?: string
        ) => {
          dispatch(
            contractorRedux.dispatchActions.fetchListOfContractors(
              {
                Pagination: {
                  PageIndex: incrementedPageIndex,
                  PageSize: pageSize,
                },
                companyId,
                siteIds,
                SearchVal: searchContractor,
              },
              isScrollMode,
              cancelToken
            )
          )
            .then(() => {
              pageIndex.current = incrementedPageIndex;
            })
            .catch(() => {
              pageIndex.current = 0;
            });
        },
        800
      ),
    [dispatch]
  );

  const fetchNextData = useCallback(
    (isScrollMode: boolean = false) => {
      if (contractorListHasNextPage) {
        if (isFetchingContractorList) {
          contractorListRequest.current.cancel("Cancel previous request");
          contractorListRequest.current = axios.CancelToken.source();
        }
        let siteIds = undefined;
        if (selectedSiteId) {
          siteIds = [selectedSiteId];
        }
        const incrementedPageIndex = pageIndex.current + 1;
        debouncedCall(
          searchContractor,
          siteIds,
          incrementedPageIndex,
          isScrollMode,
          contractorListRequest.current.token,
          selectedCompanyId
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSiteId, debouncedCall, searchContractor, selectedCompanyId]
  );

  const resetSelection = useCallback(() => {
    setSelectedContractorId(undefined);
    setNewContractorMode(false);
  }, [setSelectedContractorId, setNewContractorMode]);

  const refetchContractors = useCallback(() => {
    resetPagination();
    fetchNextData();
    resetSelection();
  }, [fetchNextData, resetPagination, resetSelection]);

  useEffect(() => {
    refetchContractors();
  }, [refetchContractors]);

  useEffect(() => {
    return () => {
      dispatch(contractorRedux.dispatchActions.resetContractors());
      contractorListRequest.current.cancel("Contractors were unmounted");
    };
  }, [dispatch]);

  // fetching counters
  useEffect(() => {
    dispatch(contractorRedux.dispatchActions.fetchCompanyCounter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      contractorRedux.dispatchActions.fetchSiteCounters(selectedCompanyId)
    );
  }, [dispatch, selectedCompanyId]);

  // end of fetching counters

  const getFilterPanel = () => {
      return (
          <div className={classes.filterPanel}>
              <FormControlWrapper
                  title="Client"
                  fullWidth
                  input={
                      <SpTextField
                          select
                          value={selectedCompanyId || ""}
                          onChange={handleChangeCompany}
                          withoutHelperText
                      >
                          <MenuItem value={undefined}>
                              <em>All Companies</em>
                          </MenuItem>
                          {companyList.map((site) => (
                              <MenuItem key={site.codeId} value={site.codeId}>
                                  {site.displayName}
                              </MenuItem>
                          ))}
                      </SpTextField>
                  }
              />
              <FormControlWrapper
                  title="Location"
                  fullWidth
                  input={
                      <SpTextField
                          select
                          value={selectedSiteId || ""}
                          onChange={handleChangeSite}
                          withoutHelperText
                      >
                          <MenuItem value={undefined}>
                              <em>All Locations</em>
                          </MenuItem>
                          {siteList.map((site) => (
                              <MenuItem key={site.codeId} value={site.codeId}>
                                  {site.displayName}
                              </MenuItem>
                          ))}
                      </SpTextField>
                  }
              />
          </div>
      )
  }

  const getUserListPanel = () => {
      return (
          <>
              <SpSearchTextField
                  placeholder="Search"
                  value={searchContractor || ""}
                  onChange={handleChangeSearchContractor}
              />
              <InfiniteScroll
                  dataLength={contractorList.length} //This is important field to render the next data
                  next={() => fetchNextData(true)}
                  hasMore={contractorListHasNextPage}
                  loader={
                      <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className={classes.footerContainer}
                      >
                          <Grid item>
                              <CircularProgress />
                          </Grid>
                      </Grid>
                  }
                  endMessage={
                      <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ textAlign: "center" }}
                      >
                          No more data
                      </Typography>
                  }
                  className={window.innerWidth > 600 ? classes.scrollPanel : classes.mobileScrollPanel}
              >
                  {contractorList.map((contractor, index) => (
                      <SpListItem
                          key={index}
                          title={contractor.displayName}
                          isActive={selectedContractorId === contractor.codeId}
                          onClick={() => {
                              setSelectedContractorId(contractor.codeId);
                              setNewContractorMode(false);
                          }}
                          inactiveItem={!contractor.isActive}
                      />
                  ))}
              </InfiniteScroll>
          </>
      )
  }

  const getUserDetailsPanel = () => {
      return (
          <ContractorDetailSection
              contractorId={selectedContractorId}
              setSelectedContractorId={setSelectedContractorId}
              refetchContractors={refetchContractors}
              newContractorMode={newContractorMode}
              setNewContractorMode={setNewContractorMode}
          />
      )
  }

  return (
    <PageContent
      title="Users"
      addNewButtonText="Add User"
      showButton={canAddContractor}
      buttonOnClick={() => {
        setNewContractorMode(true);
        setSelectedContractorId(undefined);
      }}
    >

    {window.innerWidth <= 600 && (
        <>
            {getFilterPanel()}
            {getUserListPanel()}
            <div>
                {getUserDetailsPanel()}
            </div>
        </>
    )}

    {window.innerWidth > 600 && (
      <ListDetailSplitView
        list={
          <>
            {getFilterPanel()}
            {getUserListPanel()}
          </>
        }
        details={
            getUserDetailsPanel()
        }
        />
      )}
    </PageContent>
  );
};

export default withAuthorization(memo(Contractors));
