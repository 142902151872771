import {
  CounterResponseItem,
  CounterResponseModel,
  PaginationModel,
  PaginationResponseType,
} from "../../../services/apiServiceTypes";
import { getAuthApi, postAuthApi, putAuthApi } from "../../../services/authApi";
import { APP_SETTINGS } from "../../../AppSettings";
import { CancelToken } from "axios";

export interface ContractorListRequestType extends PaginationModel {
  companyId?: string;
  siteIds?: Array<string>;
  activeUsers?: boolean;
}

export const getContractorList = <
  T = PaginationResponseType<CounterResponseItem>
>(
  body: ContractorListRequestType,
  cancelToken?: CancelToken
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Contractor`,
    body,
    undefined,
    cancelToken
  );

export type OtherCertification = {
  id?: string;
  name: string;
  validTo: string;
};

export type ContractorDetailResponseType = {
  authCodeRecentlySent: boolean; // tells if auth code was sent to contractor recently
  certificationCards: string[]; // File ids of certificates
  clubMemberId?: string;
  company?: string;
  companyLogo?: string; // File id
  employeeId: string; //
  entrances: number[];
  firstName?: string;
  lastName?: string;
  oshaCertificationFrom?: string;
  oshaCertificationTo?: string;
  vaccinationVerificationDate?: string;
  otherCertifications?: OtherCertification[];
  phoneNumber?: string;
  photo?: string; // File id
  secondaryCompany?: string;
  siteSafetyNumber?: string;
  status: "Active" | "Inactive";
  title?: string;
  unionMember: boolean;
  vaccinationVerified?: boolean;
  userId: string;
  validFrom: string;
  validUntil: string;
  photoUpdated?: string;
  facialRecPhotoUpdated?: string;
  facialRecPhotoUpdatedDateString?: string;
  beaconScannerCard?: string;
  email?: string;
};

export const getDetailOfContractor = <T = ContractorDetailResponseType>(
  contractorId?: string,
  cancelToken?: CancelToken
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Contractor/${contractorId}`,
    cancelToken
  );

export const postResendAuthenticationCodePhone = <T = null>(
  contractorId?: string,
  fromBackupNumber?: boolean
) =>
  postAuthApi<T>(
    `${
      APP_SETTINGS.BACKEND_BASE_URL
    }/Contractor/resendAuthenticationCodePhone/${contractorId}?fromBackupPhoneNumber=${
      fromBackupNumber === true
    }`
  );


export const postResendAuthenticationCodeEmail = <T = null>(
    contractorId?: string
) =>
    postAuthApi<T>(
        `${APP_SETTINGS.BACKEND_BASE_URL
        }/Contractor/resendAuthenticationCodeEmail/${contractorId}`
    );

export interface ChangeContractorStatusRequestType {
  id: string;
  status: "Active" | "Inactive";
}
export const postChangeContractorStatus = <T = null>(
  body: ChangeContractorStatusRequestType
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Contractor/changeStatus`,
    body
  );

export const getCompanyCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Counter/companies`);

export interface SitesCounterModel {
  companyId?: string;
}

export const getSitesFromCompanyCounter = <T = CounterResponseModel>(
  body: SitesCounterModel
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/sitesFromCompany`,
    body
  );

export const putClubData = <T = string>(contractorId: string) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Contractor/getClubData/${contractorId}`
  );

export const putFaceEnroll = <T = string>(contractorId: string) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Contractor/facialRec/enroll/${contractorId}`
  );

export const postNewContractor = <T = any>(body: FormData) =>
  postAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Contractor/create`, body, {
    "Content-Type": "multipart/form-data",
  });

export const putUpdateContractor = <T = any>(body: FormData) =>
  putAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Contractor/update`, body, {
    "Content-Type": "multipart/form-data",
  });

export const putUpdateContractorVaccination = <T = any>(body: FormData) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Contractor/update-vaccination`,
    body,
    {
      "Content-Type": "multipart/form-data",
    }
  );

export const putUpdateContractorEntrances = <T = any>(body: FormData) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Contractor/update-entrances`,
    body,
    {
      "Content-Type": "multipart/form-data",
    }
  );

export const putUpdateContractorVaccinationAndEntrances = <T = any>(
  body: FormData
) =>
  putAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Contractor/update-vaccination-entrances`,
    body,
    {
      "Content-Type": "multipart/form-data",
    }
  );

export const postCreateTempQrCode = <T = TempQrCode>(contractorId: string) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Contractor/tempQrCode/create?contractorId=${contractorId}`,
    undefined,
    {
      "Content-Type": "multipart/form-data",
    }
  );

export const getSecondaryCompanyCounter = <T = CounterResponseModel>(
  companyId: string
) =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/secondary-companies?onlyActiveContractors=false&companyId=${companyId}`
  );

export type TempQrCode = {
  qrCode: string;
  validFrom: string;
  validTo: string;
};

const allActions = {
  getContractorList,
  getCompanyCounter,
  getSitesFromCompanyCounter,
  getDetailOfContractor,
  postResendAuthenticationCodePhone,
  postResendAuthenticationCodeEmail,
  postChangeContractorStatus,
  putClubData,
  postNewContractor,
  putUpdateContractor,
};

export default allActions;
