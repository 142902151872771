import React, { ReactNode } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { COLORS } from "../../../appTheme";
import { SpRadioButton } from "../inputs";

const activeStyle = {
  backgroundColor: `${COLORS.BLUE}4D`, // plus alpha 30%
  cursor: "pointer",
};

let mainEnhancement = (isActive?: boolean, withRadio?: boolean) => {
  if (isActive && !withRadio) {
    return activeStyle;
  }
  return {};
};

const useStyles = makeStyles<Theme, SpListItemProps>((theme) => ({
  main: ({ isActive, withRadio }) => ({
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    "&:hover": activeStyle,
    ...mainEnhancement(isActive, withRadio),
    borderTop: "1px solid #E2E3E5",
  }),
  textWrap: ({ inactiveItem }) => ({
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: inactiveItem ? theme.palette.text.disabled : "inherit",
  }),
}));

export interface SpListItemProps {
  title: string | null;
  description?: string | null;
  onClick: () => void;
  isActive?: boolean;
  inactiveItem?: boolean;
  withRadio?: boolean;
  icon?: ReactNode;
}

const SpListItem: React.FC<SpListItemProps> = ({
  title,
  description,
  onClick,
  isActive,
  inactiveItem,
  withRadio,
  icon,
}) => {
  const classes = useStyles({
    title,
    onClick,
    isActive,
    inactiveItem,
    withRadio,
  });

  return (
    <div className={classes.main} onClick={onClick}>
      <Grid container direction="row" alignItems="center">
        {withRadio && (
          <Grid item>
            <SpRadioButton checked={isActive} />
          </Grid>
        )}
        {icon && <Grid item>{icon}</Grid>}
        <Grid item>
          <Typography variant={"h4"} className={classes.textWrap}>
            {title}
          </Typography>
          <Typography variant={"subtitle2"} className={classes.textWrap}>
            {description}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
export default SpListItem;
