import { createSelector } from "@reduxjs/toolkit";
import { State } from "./model";
import { COMMON } from "./constants";
import { AccessTokenType } from "../../services/localStorageService";
import jwt_decode from "jwt-decode";
import { isNil } from "ramda";
import moment from "moment";

const selectSelf = (state: { [x: string]: State }) =>
  state[COMMON.ROOT_REDUCER];

const getIsAuthenticating = createSelector(
  selectSelf,
  (state: State) => state.isLoading
);

const getUserAccessToken = createSelector(
  selectSelf,
  (state: State) => state.userAccessToken
);

const getUserRefreshToken = createSelector(
  selectSelf,
  (state: State) => state.userRefreshToken
);

const getUserEmail = createSelector(
  getUserAccessToken,
  (userAccessToken: string | null) => {
    if (userAccessToken !== null) {
      return jwt_decode<AccessTokenType>(userAccessToken)?.Email;
    }
    return undefined;
  }
);

const getUsername = createSelector(
  getUserAccessToken,
  (userAccessToken: string | null) => {
    if (userAccessToken !== null) {
      return jwt_decode<AccessTokenType>(userAccessToken)?.Username;
    }
    return undefined;
  }
);

const getRole = createSelector(
  getUserAccessToken,
  (userAccessToken: string | null) => {
    if (userAccessToken !== null) {
      return jwt_decode<AccessTokenType>(userAccessToken)?.Role;
    }
    return undefined;
  }
);

const getPermissions = createSelector(
  getUserAccessToken,
  (userAccessToken: string | null) => {
    if (userAccessToken !== null) {
      const permissions =
        jwt_decode<AccessTokenType>(userAccessToken)?.PermissionClaims;
      if (permissions) {
        return Array.isArray(permissions) ? permissions : [permissions];
      }
      return [];
    }
    return undefined;
  }
);

const getAudience = createSelector(
  getUserAccessToken,
  (userAccessToken: string | null) => {
    if (userAccessToken !== null) {
      return jwt_decode<AccessTokenType>(userAccessToken)?.aud;
    }
    return undefined;
  }
);

const getExpirationDate = createSelector(
  getUserAccessToken,
  (userAccessToken: string | null) => {
    if (userAccessToken !== null) {
      const exp = jwt_decode<AccessTokenType>(userAccessToken)?.exp;
      // accepts value in milliseconds
      return !isNil(exp) && moment(exp * 1000);
    }
    return undefined;
  }
);

const getIssuer = createSelector(
  getUserAccessToken,
  (userAccessToken: string | null) => {
    if (userAccessToken !== null) {
      return jwt_decode<AccessTokenType>(userAccessToken)?.iss;
    }
    return undefined;
  }
);

const getSkipAuthentication = createSelector(
  selectSelf,
  (state: State) => state.skipAuthentication
);

const allSelectors = {
  getIsAuthenticating,
  getUserEmail,
  getUsername,
  getRole,
  getPermissions,
  getAudience,
  getExpirationDate,
  getIssuer,
  getUserAccessToken,
  getUserRefreshToken,
  getSkipAuthentication,
};

export default allSelectors;
