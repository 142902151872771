import React from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, LinearProgress, Typography } from "@material-ui/core";
import logo from "../../assets/img/21_SP_SIGHTPAS-APP-HEADER LOGO_DARK.png";
import authReducer from "../redux";
import { useAppDispatch } from "../../appStore";
import * as yup from "yup";
import { SpFormControl, SpTextField } from "../../common";
import { BORDER_RADIUS } from "../../appTheme";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.common.black,
    height: "100vh",
  },
  avatar: {
    padding: theme.spacing(1),
  },
  mainGrid: {
    height: "inherit",
  },
  paper: {
    padding: theme.spacing(2),
    minWidth: 600,
    borderRadius: BORDER_RADIUS.NORMAL,
  },
  logo: {
    width: 334.82,
    height: 69,
  },
  input: {
    width: 262.4,
  },
  mainTextGrid: {
    maxWidth: "fit-content",
  },
  mainText: {
    textAlign: "center",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  formGrid: {
    paddingBottom: theme.spacing(2),
  },
}));

type Inputs = {
  Email: string;
};

const schema = yup.object().shape({
  Email: yup
    .string()
    .required("Please enter Email")
    .max(80, "Email exceeds 80 character limit."),
});

const ForgotPasswordScreen: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isAuthenticating = useSelector(
    authReducer.selectors.getIsAuthenticating
  );

  const { register, handleSubmit, errors } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: Inputs) => {
    dispatch(authReducer.dispatchActions.forgotPassword(data.Email, history));
  };

  return (
    <div className={classes.background}>
      <Grid
        container
        alignItems={"center"}
        alignContent={"center"}
        justifyContent={"center"}
        className={classes.mainGrid}
      >
        <Grid item>
          <Paper elevation={1} className={classes.paper}>
            <Box pt={6} pb={6}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  className={classes.formGrid}
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <div className={classes.avatar}>
                      <img
                        src={logo}
                        alt="logo-sightPas"
                        className={classes.logo}
                      />
                    </div>
                  </Grid>
                  <Grid item className={classes.mainTextGrid}>
                    <Typography variant={"h4"} className={classes.mainText}>
                      Please enter your email address where we will send you
                      your new password
                    </Typography>
                  </Grid>
                  <Grid item>
                    <SpFormControl
                      title="Email"
                      input={
                        <SpTextField
                          inputRef={register}
                          errors={errors}
                          id="Email"
                          name="Email"
                          autoComplete="Email"
                          autoFocus
                          fullWidth
                        />
                      }
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isAuthenticating}
                      className={classes.input}
                    >
                      Reset my password
                    </Button>
                  </Grid>
                </Grid>
                {isAuthenticating && <LinearProgress />}
              </form>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPasswordScreen;
