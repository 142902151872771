export interface State {
  snackBarMessage: string | null;
  audioEnabled: boolean;
  playAudio: number;
  audioSrc: string | null;
}

export const initialState: State = {
  snackBarMessage: null,
  audioEnabled: false,
  playAudio: 0,
  audioSrc: null,
};
