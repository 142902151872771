import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { debounce, Grid } from "@material-ui/core";
import { isNil } from "ramda";
import { DetailPanelView } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { useFetchData } from "../../../services/useFetchData";
import { getFormsCounter, postNewNotification } from "../redux/apiCalls";
import {
    CounterResponseItem,
    CounterResponseModel,
} from "../../../services/apiServiceTypes";
import messagesRedux from "../redux";
import { useUploadFile } from "../../../services/useUploadFile";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AppDispatch } from "../../../appStore";
import MessageDetail from "./MessageDetail";
import NewMessage, {
    NewMessageInputs,
    NewMessageSchema,
    selectAllContractorsOption,
} from "./NewMessage";
import messagesReducer from "../redux/reducer";

const useStyles = makeStyles<Theme>((theme) => ({
    mainBody: {
        width: "100%",
        height: `calc(100vh - 195px)`,
        overflowY: "auto",
        overflowX: "hidden",
        display: "block",
    },
}));

const MessageDetailSection: React.FC<MessageDetailSectionProps> = ({
    notificationId,
    newMessageMode,
    setNewMessageMode,
    refetchNotifications,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch<AppDispatch>();
    const sendToSitesList = useSelector(
        messagesRedux.selectors.getSendToSitesList
    );
    const pageSize: number = 20;
    const pageIndex = useRef<number>(0);
    const contractorFilter = useRef<string>();
    const getHasNextPage = useSelector(
        messagesRedux.selectors.getHasNextPageOfSendToContractors
    );

    const [selectedContractors, setSelectedContractors] = useState<
        Array<CounterResponseItem>
    >([]);

    const [selectedSiteId, setSelectedSiteId] = useState<string>("");

    const {
        uploadInput,
        clickOnUploadFile,
        selectedFiles,
        rawFiles,
        onRemoveFile,
        removeAllFiles,
    } = useUploadFile({ multiple: true });

    const { register, handleSubmit, control } = useForm<NewMessageInputs>({
        resolver: yupResolver(NewMessageSchema),
    });

    const resetPagination = useCallback(() => {
        dispatch(messagesRedux.actions.setHasNextPageOfSendToContractors(true));
        dispatch(messagesReducer.actions.setSendToContractors([]));
        pageIndex.current = 0;
        contractorFilter.current = undefined;
    }, [dispatch]);

    const resetInputs = useCallback(() => {
        // TODO reset tree
        setSelectedSiteId("");
        setSelectedContractors([]);
        removeAllFiles();
    }, [removeAllFiles]);

    const fetchNextData = useMemo(
        () =>
            debounce((isScrollMode: boolean = false) => {
                if (getHasNextPage) {
                    const incrementedPageIndex = pageIndex.current + 1;
                    dispatch(
                        messagesRedux.dispatchActions.fetchListOfContractors(
                            {
                                Pagination: {
                                    PageIndex: incrementedPageIndex,
                                    PageSize: pageSize,
                                },
                                SearchVal: contractorFilter.current,
                            },
                            isScrollMode
                        )
                    )
                        .then(() => {
                            pageIndex.current = incrementedPageIndex;
                        })
                        .catch(() => {
                            pageIndex.current = 0;
                        });
                }
            }, 1000),
        [dispatch, getHasNextPage, pageSize]
    );

    const { data: formList } = useFetchData<CounterResponseModel>(
        getFormsCounter(),
        isNil(notificationId) && !isNil(newMessageMode),
        isNil(newMessageMode) && !isNil(notificationId),
        [newMessageMode]
    );

    const onSubmitSendMessage = (data: NewMessageInputs) => {
        const formData = new FormData();
        formData.append("SiteId", data.sender);
        formData.append("Body", data.body);
        formData.append("Subject", data.subject);

        if (data.selectedForm && data.selectedForm !== "" && formList) {
            const selectedFormObject = formList.find(
                (f) => f.codeId === data.selectedForm
            );
            if (selectedFormObject) {
                formData.append("SelectedForm", data.selectedForm);
                formData.append("SelectedFormName", selectedFormObject.displayName);
            }
        }

        const selectedEntrances: string[] = [];

        sendToSitesList
            .map((site) =>
                site.children
                    ?.filter((entrance) => entrance.isChecked)
                    .map((entrance) => entrance.id)
            )
            .filter((item) => !isNil(item))
            .forEach((entrance) => {
                selectedEntrances.push(...(entrance || []));
            });

        selectedEntrances.forEach((entrance) => {
            formData.append("Entrances", entrance);
        });

        if (selectedContractors.length > 0) {
            if (selectedContractors[0].codeId === selectAllContractorsOption.codeId) {
                formData.append("SelectAllContractors", "true");
            } else {
                selectedContractors.forEach((contractor) => {
                    formData.append("SelectedContractors", contractor.codeId);
                });
            }
        }

        if (rawFiles && rawFiles?.length > 0) {
            for (let i = 0; i < rawFiles.length; i++) {
                formData.append("Files", rawFiles[i]);
            }
        }

        dispatch(postNewNotification(formData)).then(() => {
            resetInputs();
            refetchNotifications();
            setNewMessageMode(false);
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmitSendMessage)}>
            <DetailPanelView
                title="Message"
                showSaveButton={newMessageMode ? true : false}
                buttonText="Send"
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    className={classes.mainBody}
                >
                    {newMessageMode && (
                        <>
                            <NewMessage
                                setSelectedSiteId={setSelectedSiteId}
                                setSelectedContractors={setSelectedContractors}
                                resetPagination={resetPagination}
                                fetchNextData={fetchNextData}
                                formList={formList}
                                clickOnUploadFile={clickOnUploadFile}
                                selectedFiles={selectedFiles}
                                onRemoveFile={onRemoveFile}
                                selectedSiteId={selectedSiteId}
                                selectedContractors={selectedContractors}
                                contractorFilter={contractorFilter}
                                control={control}
                                register={register}
                            />
                            {uploadInput}
                        </>
                    )}
                    {notificationId && <MessageDetail notificationId={notificationId} />}
                </Grid>
            </DetailPanelView>
        </form>
    );
};

export default memo(MessageDetailSection);

export interface MessageDetailSectionProps {
    notificationId?: number;
    newMessageMode?: boolean;
    setNewMessageMode: (value: boolean) => void;
    refetchNotifications: () => void;
}
