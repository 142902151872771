import React, { FC, memo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

type ResendAuthCodeDialogProps = {
  open: boolean;
  onResend: (fromBackupNumber?: boolean) => void;
  onCancel: () => void;
};

const ResendAuthCodeDialog: FC<ResendAuthCodeDialogProps> = ({
  open,
  onResend,
  onCancel,
}) => {
  return (
    <Dialog onClose={onCancel} open={open}>
      <DialogTitle>Resend authentication code</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Authentication code was recently sent to this contractor. If SMS was
          not delivered you can try to send SMS from backup phone number. Send
          SMS from:{" "}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onResend(true)}>
          Backup Number
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onResend(false)}
        >
          Standard Number
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ResendAuthCodeDialog);
