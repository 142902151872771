import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "ramda";
import authReducer from "../redux";
import { AppDispatch } from "../../appStore";
import { accessTokenFromLocalStorage } from "../../services/localStorageService";
import { MAIN_PATH } from "../../router/pathConstants";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";

const useReloadJwtToken = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  let matchLoginPage = useRouteMatch(`/${MAIN_PATH.LOGIN}`);
  let matchResetPasswordPage = useRouteMatch(`/${MAIN_PATH.RESET_PASSWORD}`);
  let matchForgotPasswordPage = useRouteMatch(`/${MAIN_PATH.FORGOT_PASSWORD}`);

  const accessTokenRedux = useSelector(
    authReducer.selectors.getUserAccessToken
  );
  const refreshTokenRedux = useSelector(
    authReducer.selectors.getUserRefreshToken
  );
  const skipAuthentication = useSelector(
    authReducer.selectors.getSkipAuthentication
  );

  const expirationDate = useSelector(authReducer.selectors.getExpirationDate);

  // initiate tokens from localStorage to redux
  useEffect(() => {
    if (isNil(accessTokenRedux) && !isNil(accessTokenFromLocalStorage)) {
      dispatch(authReducer.dispatchActions.initiateTokensFromLocalStorage());
    } else if (
      isNil(accessTokenRedux) &&
      isNil(accessTokenFromLocalStorage) &&
      !matchResetPasswordPage &&
      !matchForgotPasswordPage &&
      !skipAuthentication
    ) {
      history.replace(`/${MAIN_PATH.LOGIN}`);
    }
  }, [
    dispatch,
    accessTokenRedux,
    history,
    matchResetPasswordPage,
    matchForgotPasswordPage,
    skipAuthentication,
  ]);

  const checkTokensFunc = useCallback(async () => {
    if (
      !matchLoginPage &&
      !matchResetPasswordPage &&
      !matchForgotPasswordPage &&
      !skipAuthentication
    ) {
      const authTokenIsValid = await dispatch(
        authReducer.dispatchActions.checkAuthToken(history)
      );

      if (
        expirationDate &&
        !isNil(refreshTokenRedux) &&
        !isNil(accessTokenRedux) &&
        authTokenIsValid
      ) {
        const momentExpirationDate = moment(expirationDate);
        const currentTime = moment();
        const difference = momentExpirationDate.diff(currentTime, "minutes");

        if (difference < 10) {
          dispatch(
            authReducer.dispatchActions.reauthenticate(
              refreshTokenRedux,
              history
            )
          );
        }
      }
    }
  }, [
    accessTokenRedux,
    dispatch,
    expirationDate,
    history,
    matchForgotPasswordPage,
    matchLoginPage,
    matchResetPasswordPage,
    refreshTokenRedux,
    skipAuthentication,
  ]);

  useEffect(() => {
    checkTokensFunc();
  }, [checkTokensFunc]);
  // restore accessToken by refresh token
  useEffect(() => {
    const timer = setInterval(() => checkTokensFunc(), 60 * 1000);
    return () => clearInterval(timer);
  }, [
    expirationDate,
    refreshTokenRedux,
    dispatch,
    accessTokenRedux,
    history,
    matchLoginPage,
    matchResetPasswordPage,
    matchForgotPasswordPage,
    checkTokensFunc,
  ]);
};

export default useReloadJwtToken;
