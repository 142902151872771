import { createSelector } from "@reduxjs/toolkit";
import { State } from "./model";
import { COMMON } from "./constants";

const selectSelf = (state: { [x: string]: State }) =>
  state[COMMON.ROOT_REDUCER];

const getSiteList = createSelector(
  selectSelf,
  (state: State) => state.siteList
);

const getSiteListHasNextPage = createSelector(
  selectSelf,
  (state: State) => state.siteListHasNextPage
);
const getSelectedSiteId = createSelector(
  selectSelf,
  (state: State) => state.selectedSiteId
);

const getEntranceList = createSelector(
  selectSelf,
  (state: State) => state.entranceList
);

const getEntranceListHasNextPage = createSelector(
  selectSelf,
  (state: State) => state.entranceListHasNextPage
);

const getSelectedEntranceId = createSelector(
  selectSelf,
  (state: State) => state.selectedEntranceId
);
const getIsNewEntranceMode = createSelector(
  selectSelf,
  (state: State) => state.isNewEntranceMode
);

const getSiteSupervisorList = createSelector(
  selectSelf,
  (state: State) => state.siteSupervisorList
);

const getSiteSupervisorListHasNextPage = createSelector(
  selectSelf,
  (state: State) => state.siteSupervisorListHasNextPage
);
const getSelectedSiteSupervisorId = createSelector(
  selectSelf,
  (state: State) => state.selectedSiteSupervisorId
);
const getIsNewSiteSupervisorMode = createSelector(
  selectSelf,
  (state: State) => state.isNewSiteSupervisorMode
);
const getIsEditingCredentials = createSelector(
  selectSelf,
  (state: State) => state.isEditingCredentials
);

const getUnassignedSiteSupervisorList = createSelector(
  selectSelf,
  (state: State) => state.unassignedSiteSupervisorList
);

const getUnassignedSiteSupervisorListHasNextPage = createSelector(
  selectSelf,
  (state: State) => state.unassignedSiteSupervisorListHasNextPage
);

const allSelectors = {
  getSiteList,
  getSiteListHasNextPage,
  getSelectedSiteId,
  getEntranceList,
  getEntranceListHasNextPage,
  getSelectedEntranceId,
  getIsNewSiteSupervisorMode,
  getSiteSupervisorListHasNextPage,
  getSiteSupervisorList,
  getSelectedSiteSupervisorId,
  getIsNewEntranceMode,
  getIsEditingCredentials,
  getUnassignedSiteSupervisorList,
  getUnassignedSiteSupervisorListHasNextPage,
};

export default allSelectors;
