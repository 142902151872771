import { makeStyles } from "@material-ui/core/styles";
import React, { ReactNode, useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { isNil } from "ramda";
import { SpTextField } from "../inputs";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  fullWidthRow: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    // TODO move border color to app theme
    border: "1px solid #E2E3E5;",
    boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.01)",
    borderRadius: "6px",
  },
  subSection: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
    width: "100%",
  },
  reference: {
    width: "100%",
    cursor: "pointer",
  },
}));

const TreeStructure: React.FC<TreeStructureProps> = ({
  title,
  description,
  itemHeaderTitle,
  rightContent,
  items,
  state,
  onChangeState,
  hideChildren,
  oneChildMode = false,
  disabled = false,
  editable = false,
  textFieldProps,
  hideCheckbox = false,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditActive, setIsEditActive] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(title);
  const [inputErrorMessage, setInputErrorMessage] = useState<string>(
    textFieldProps?.errorMessage ?? ""
  );

  useEffect(() => {
    if (textFieldProps?.errorMessage !== inputErrorMessage) {
      if (textFieldProps?.errorMessage) {
        setIsEditActive(true);
      } else {
        setIsEditActive(false);
      }
      setInputErrorMessage(textFieldProps?.errorMessage ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textFieldProps, textFieldProps?.errorMessage]);

  const getIsCheckedChildren = (childrenId: string) =>
    state.children?.find((ch) => ch.id === childrenId)?.isChecked;

  const getIndexOfChildren = (childrenId: string) =>
    state.children?.findIndex((ch) => ch.id === childrenId);

  const handleClickOnChildren = (childrenId: string) => {
    const stateCopy = JSON.parse(JSON.stringify(state));
    const indexOfChildren = getIndexOfChildren(childrenId);

    if (
      !isNil(state.children) &&
      !isNil(indexOfChildren) &&
      !isNil(state.children[indexOfChildren])
    ) {
      if (!oneChildMode) {
        let selectedChildren = state.children[indexOfChildren];
        stateCopy.children[indexOfChildren] = {
          ...selectedChildren,
          isChecked: !selectedChildren.isChecked,
        };
      } else {
        stateCopy.children = stateCopy.children.map(
          (ch: TreeStructureData, index: number) => ({
            ...ch,
            isChecked: index === indexOfChildren,
          })
        );
      }
    }
    onChangeState(stateCopy);
  };

  const listOfCheckedChildren = state.children?.filter(
    (ch) => ch.isChecked === true
  );

  const areCheckedAllChildren =
    (Array.isArray(state.children) &&
      state.children?.length > 0 &&
      listOfCheckedChildren?.length === state.children?.length) ||
    (Array.isArray(state.children) &&
      state.children?.length === 0 &&
      state.isChecked);

  const isSelectedAnyChild =
    (Array.isArray(listOfCheckedChildren) &&
      listOfCheckedChildren.length > 0) ||
    state.isChecked;

  const handleClickOnParent = () => {
    const stateCopy = JSON.parse(JSON.stringify(state));
    if (areCheckedAllChildren) {
      const newChildren: TreeStructureData[] | undefined = [];
      state.children?.forEach((ch) => {
        newChildren.push({
          ...ch,
          isChecked: false,
        });
      });
      stateCopy.children = newChildren;
      stateCopy.isChecked = false;
    } else if (state.children && state.children?.length > 0) {
      const newChildren: TreeStructureData[] | undefined = [];
      state.children?.forEach((ch) => {
        newChildren.push({
          ...ch,
          isChecked: true,
        });
      });
      stateCopy.children = newChildren;
      stateCopy.isChecked = true;
    } else if (hideChildren && state.children && state.children?.length === 0) {
      stateCopy.isChecked = !stateCopy.isChecked;
    }
    onChangeState(stateCopy);
  };

  const onTextFieldKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // disable possible default form submit
    if (event.key === "Enter") {
      event.preventDefault();
      if (
        inputValue !== title &&
        textFieldProps?.onSubmit(inputValue, resetTextField)
      ) {
        setIsEditActive(!isEditActive);
      }
    }
  };

  const onTextFieldFocusLost = () => {
    if (textFieldProps?.onClose(inputValue)) {
      resetTextField();
      setInputErrorMessage("");
    }
  };

  const resetTextField = () => {
    setIsEditActive(!isEditActive);
    setInputValue(title);
  };

  const onTitleClick = () => {
    if (editable) {
      setIsEditActive(!isEditActive);
      setInputErrorMessage("");
    }
  };

  const onOuterClick = () => {
    if (!hideChildren && !editable) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      <Box className={classes.fullWidthRow}>
        <Box
          className={classes.reference}
          display="flex"
          flexDirection={"row"}
          alignItems="center"
        >
          {hideCheckbox ? null : (
            <Checkbox
              color="primary"
              disabled={disabled}
              checked={isSelectedAnyChild}
              indeterminate={
                !hideChildren && !areCheckedAllChildren && isSelectedAnyChild
              }
              onClick={() => {
                if (!oneChildMode) {
                  handleClickOnParent();
                }
              }}
            />
          )}
          <Box
            flexGrow={1}
            marginLeft={hideCheckbox ? "12px" : "0px"}
            onClick={() => onOuterClick()}
          >
            {isEditActive ? (
              <SpTextField
                name={"Title"}
                onBlur={onTextFieldFocusLost}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => setInputValue(event.target.value)}
                defaultValue={title}
                onKeyPress={onTextFieldKeyPress}
                errors={
                  inputErrorMessage && inputErrorMessage.length > 0
                    ? {
                        Title: {
                          message: inputErrorMessage,
                        },
                      }
                    : undefined
                }
                withoutHelperText
                fullWidth
                autoFocus
              />
            ) : (
              <Typography variant={"h4"} onClick={onTitleClick}>
                {inputValue}
              </Typography>
            )}
            <Typography variant={"h6"}>{description}</Typography>
          </Box>
          {hideChildren ? null : (
            <Box onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? (
                <KeyboardArrowUp color="primary" />
              ) : (
                <KeyboardArrowDown color="primary" />
              )}
            </Box>
          )}
        </Box>

        {!hideChildren && isOpen && (
          <Box
            className={classes.subSection}
            display="flex"
            flexDirection="column"
          >
            {items.map((item, index) => (
              <FormControlLabel
                control={
                  <Checkbox
                    key={index}
                    color="primary"
                    checked={getIsCheckedChildren(item.id)}
                    onClick={() => handleClickOnChildren(item.id)}
                    disabled={disabled}
                  />
                }
                label={item.name}
              />
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default TreeStructure;

export interface TreeItemProps {
  name: string;
  id: string;
}

export interface TreeStructureData {
  id: string;
  isChecked: boolean;
  children?: Array<TreeStructureData>;
}

export interface TreeStructureProps {
  title: string;
  description?: string;
  itemHeaderTitle: string;
  rightContent?: ReactNode;
  items: Array<TreeItemProps>;
  state: TreeStructureData;
  onChangeState: (state: TreeStructureData) => void;
  hideChildren?: boolean;
  oneChildMode?: boolean;
  disabled?: boolean;
  editable?: boolean;
  textFieldProps?: TreeStructureTextFieldProps;
  hideCheckbox?: boolean;
}

export interface TreeStructureTextFieldProps {
  errorMessage?: string;
  // value - actual value in text field, boolean - if true default callback action should be executed
  onSubmit: (value: string, resetTextField: () => void) => boolean;
  onClose: (value: string) => boolean;
}
