import { CounterResponseModel } from "../../../services/apiServiceTypes";
import {
  downloadFile,
  getAuthApi,
  postAuthApi,
} from "../../../services/authApi";
import { APP_SETTINGS } from "../../../AppSettings";
import { ENTRANCE_HELPER_VALUES } from "./constants";
import { SitesCounterModel } from "../../contractors/redux/apiCalls";

export const getReportsTypesCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Counter/reports/types`);

export const getSiteCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/sites?onlyActiveSites=false`
  );

export const getSitesFromCompanyCounter = <T = CounterResponseModel>(
  body: SitesCounterModel
) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/sitesFromCompany?onlyActiveSites=false`,
    body
  );

export const getEntranceCounter = <T = CounterResponseModel>(siteId: string) =>
  postAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/entrances?onlyActiveEntrances=false`,
    { siteId }
  );

export const getFormCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Counter/reports/forms`);

export const getCompanyCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/companies?onlyActiveCompanies=false`
  );

export const getSecondaryCompanyCounter = <T = CounterResponseModel>() =>
  getAuthApi<T>(
    `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/secondary-companies?onlyActiveContractors=false`
  );

export const getContractorsCounter = <T = CounterResponseModel>(
  siteId: string,
  entranceId?: string
) => {
  if (entranceId === ENTRANCE_HELPER_VALUES.ALL_ENTRANCES) {
    return getAuthApi<T>(
      `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/contractors?siteId=${siteId}`
    );
  } else {
    return getAuthApi<T>(
      `${APP_SETTINGS.BACKEND_BASE_URL}/Counter/contractors?siteId=${siteId}&entranceId=${entranceId}`
    );
  }
};

export interface InOutTimesViewModel {
  timeIn: string;
  timeOut: string;
}

export interface ContractorsReportItemViewModel {
  employeeId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  validFrom: string;
  validTo: string;
  date: string;
  inOutTimes: InOutTimesViewModel[];
  totalTime: string;
}

export type ContractorsReportViewModel = {
  siteName: string;
  entranceName: string;
  siteSupervisors: string[];
  startDate: string;
  endDate: string;
  generatedDate: string;
  items: ContractorsReportItemViewModel[];
};

export interface ContractorsStatusReportItemViewModel {
  employeeId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  timestamp: string;
  access: string;
}

export type ContractorsStatusReportViewModel = {
  siteName: string;
  entranceName: string;
  siteSupervisors: string[];
  startDate: string;
  endDate: string;
  generatedDate: string;
  accessPermittedCount: number;
  accessDeniedCount: number;
  manualCheckInCount: number;
  totalContractorsOnSite: number;
  items: ContractorsStatusReportItemViewModel[];
};

export interface AllContractorsReportItemViewModel {
  employeeId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  validFrom: string;
  validTo: string;
  status: string;
}

export type AllContractorsReportViewModel = {
  companyName: string;
  allContractors: number;
  activeContractors: number;
  inactiveContractors: number;
  generatedDate: string;
  items: AllContractorsReportItemViewModel[];
};

export interface SiteStatisticsReportItemViewModel {
  companyName: string;
  contractors: number;
  time: string;
}

export type SiteStatisticsReportViewModel = {
  siteName: string;
  siteSupervisors: string[];
  startDate: string;
  endDate: string;
  generatedDate: string;
  allContractorCount: number;
  totalTime: string;
  items: SiteStatisticsReportItemViewModel[];
};

export interface CameraReportItemViewModel {
  photoId: string;
  dateTime: string;
  allowed: boolean;
  companyName: string;
  firstName: string;
  lastName: string;
}

export type CameraReportViewModel = {
  siteName: string;
  entranceName: string;
  siteSupervisors: string[];
  startDate: string;
  endDate: string;
  generatedDate: string;
  items: CameraReportItemViewModel[];
};

export type CertificationViewModel = {
  name: string;
  validTo: string;
};

export interface SiteQualificationReportItemViewModel {
  contractorName: string;
  certifications: CertificationViewModel[];
}

export type SiteQualificationReportViewModel = {
  siteName: string;
  siteSupervisors: string[];
  startDate: string;
  endDate: string;
  generatedDate: string;
  items: SiteQualificationReportItemViewModel[];
};

export interface SiteHelpRequestReportItemViewModel {
  employeeId: number;
  companyName: string;
  firstName: string;
  lastName: string;
  dateTime: string;
  eventType:
    | "Enter"
    | "Leave"
    | "ManualEnter"
    | "ManualLeave"
    | "HelpRequested"
    | "HelpConfirmed"
    | "HelpCanceled";
}

export type SiteHelpRequestReportViewModel = {
  siteName: string;
  siteSupervisors: string[];
  startDate: string;
  endDate: string;
  generatedDate: string;
  items: SiteHelpRequestReportItemViewModel[];
};

export type WebhookModel = {
  uid: number;
  fid: number;
  mid: number;
  ip: string;
  referer: string;
  submissionId: number;
  localDateTime: string;
  entryId: number;
};

export type KeyValue = {
  item1: string;
  item2: string;
};
export type FormSubmissionViewModel = {
  metadata: WebhookModel;
  values: KeyValue[];
};

export type ReportResponse = ContractorsReportViewModel &
  ContractorsStatusReportViewModel &
  AllContractorsReportViewModel &
  SiteStatisticsReportViewModel &
  CameraReportViewModel &
  SiteQualificationReportViewModel &
  SiteHelpRequestReportViewModel &
  FormSubmissionViewModel[];

export interface ReportBodyRequest {
  selectedReportType: string;
  selectedCompany?: string;
  selectedSecondaryCompany?: string;
  selectedSite?: string;
  selectedEntrance?: string;
  selectedContractor?: string;
  selectedForm?: string;
  startDate: string;
  endDate: string;
}
export const postCreateReport = <T = ReportResponse>(body: ReportBodyRequest) =>
  postAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Report/create`, body);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const postDownloadCSVReport = <T = ReportResponse>(
  body: ReportBodyRequest
) =>
  downloadFile(
    `Report_${body.selectedReportType}_${body.startDate}_${body.endDate}.xlsx`,
    undefined,
    `${APP_SETTINGS.BACKEND_BASE_URL}/Report/download`,
    "POST",
    body
  );

export const getCsvAccessToken = <T = string>() =>
  getAuthApi<T>(`${APP_SETTINGS.BACKEND_BASE_URL}/Report/AccessToken`);
